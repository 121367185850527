import { Component } from '@angular/core';
import { DocumentExpensesTableBase } from "../documentExpensesTableBase";
import { DocumentExpensesTotalService } from "../../services/document-expenses-total.service";

@Component({
  selector: 'ts-document-expenses-table',
  templateUrl: './document-expenses-table.component.html',
  styleUrls: ['./document-expenses-table.component.scss'],
  providers: [DocumentExpensesTotalService]
})
export class DocumentExpensesTableComponent extends DocumentExpensesTableBase {
  constructor(documentExpensesTotalService: DocumentExpensesTotalService) {
    super(documentExpensesTotalService);
  }
}
