/**
 * TOPSELLER App
 * Topseller backend API
 *
 * OpenAPI spec version: 23.0222.1716
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Paginated, Comment } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { Commentary } from "../model/commentary";

@Injectable()
export class CommentService {
  protected basePath = 'http://denis.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Удалить комментарий
   *
   * @param entity
   * @param entityId
   * @param commentaryId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteAppCommentaryDelete(
    entity: string,
    entityId: string,
    commentaryId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteAppCommentaryDelete(
    entity: string,
    entityId: string,
    commentaryId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteAppCommentaryDelete(
    entity: string,
    entityId: string,
    commentaryId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteAppCommentaryDelete(
    entity: string,
    entityId: string,
    commentaryId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (entity === null || entity === undefined) {
      throw new Error(
        'Required parameter entity was null or undefined when calling deleteAppCommentaryDelete.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling deleteAppCommentaryDelete.'
      );
    }

    if (commentaryId === null || commentaryId === undefined) {
      throw new Error(
        'Required parameter commentaryId was null or undefined when calling deleteAppCommentaryDelete.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/api/v1/commentaries/${encodeURIComponent(
        String(entity)
      )}/${encodeURIComponent(String(entityId))}/${encodeURIComponent(
        String(commentaryId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Список комментариев
   *
   * @param entity
   * @param entityId
   * @param search Строка поиска
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppCommentaryIndex(
    entity: string,
    entityId: string,
    search?: string,
    limit?: number,
    offset?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<Commentary>> {
    if (entity === null || entity === undefined) {
      throw new Error(
        'Required parameter entity was null or undefined when calling getAppCommentaryIndex.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling getAppCommentaryIndex.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<Commentary>>(
      'get',
      `${this.basePath}/api/v1/commentaries/${encodeURIComponent(
        String(entity)
      )}/${encodeURIComponent(String(entityId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Комментарий
   *
   * @param entity
   * @param entityId
   * @param commentaryId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppCommentaryView(
    entity: string,
    entityId: string,
    commentaryId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Comment>;
  public getAppCommentaryView(
    entity: string,
    entityId: string,
    commentaryId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Comment>>;
  public getAppCommentaryView(
    entity: string,
    entityId: string,
    commentaryId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Comment>>;
  public getAppCommentaryView(
    entity: string,
    entityId: string,
    commentaryId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (entity === null || entity === undefined) {
      throw new Error(
        'Required parameter entity was null or undefined when calling getAppCommentaryView.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling getAppCommentaryView.'
      );
    }

    if (commentaryId === null || commentaryId === undefined) {
      throw new Error(
        'Required parameter commentaryId was null or undefined when calling getAppCommentaryView.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Comment>(
      'get',
      `${this.basePath}/api/v1/commentaries/${encodeURIComponent(
        String(entity)
      )}/${encodeURIComponent(String(entityId))}/${encodeURIComponent(
        String(commentaryId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Отредактировать комментарий
   *
   * @param entity
   * @param entityId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchAppCommentaryUpdate(
    entity: string,
    entityId: string,
    body?: Commentary,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Commentary>;
  public patchAppCommentaryUpdate(
    entity: string,
    entityId: string,
    body?: Commentary,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Commentary>>;
  public patchAppCommentaryUpdate(
    entity: string,
    entityId: string,
    body?: Commentary,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Commentary>>;
  public patchAppCommentaryUpdate(
    entity: string,
    entityId: string,
    body?: Commentary,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (entity === null || entity === undefined) {
      throw new Error(
        'Required parameter entity was null or undefined when calling patchAppCommentaryUpdate.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling patchAppCommentaryUpdate.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Commentary>(
      'patch',
      `${this.basePath}/api/v1/commentaries/${encodeURIComponent(
        String(entity)
      )}/${encodeURIComponent(String(entityId))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param entity
   * @param entityId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppCommentaryBatchdelete(
    entity: string,
    entityId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public postAppCommentaryBatchdelete(
    entity: string,
    entityId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public postAppCommentaryBatchdelete(
    entity: string,
    entityId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public postAppCommentaryBatchdelete(
    entity: string,
    entityId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (entity === null || entity === undefined) {
      throw new Error(
        'Required parameter entity was null or undefined when calling postAppCommentaryBatchdelete.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling postAppCommentaryBatchdelete.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/api/v1/commentaries/${encodeURIComponent(
        String(entity)
      )}/${encodeURIComponent(String(entityId))}/batch-delete`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавить комментарий
   *
   * @param entity
   * @param entityId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppCommentaryCreate(
    entity: string,
    entityId: string,
    body?: Commentary,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Commentary>;
  public postAppCommentaryCreate(
    entity: string,
    entityId: string,
    body?: Commentary,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Commentary>>;
  public postAppCommentaryCreate(
    entity: string,
    entityId: string,
    body?: Commentary,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Commentary>>;
  public postAppCommentaryCreate(
    entity: string,
    entityId: string,
    body?: Commentary,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (entity === null || entity === undefined) {
      throw new Error(
        'Required parameter entity was null or undefined when calling postAppCommentaryCreate.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling postAppCommentaryCreate.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Comment>(
      'post',
      `${this.basePath}/api/v1/commentaries/${encodeURIComponent(
        String(entity)
      )}/${encodeURIComponent(String(entityId))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
