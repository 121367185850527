import { HubEntity, Product } from "../../data";
import { PriceTypes } from "../../data/model/product-prices";

// Получить цену продукта, которая подставляется по умолчанию в таблицы с документами при выборе товара.
export function getDefaultProductPrice(product: Product, parentEntity: HubEntity): number {
  const productPriceType = getProductPriceType(parentEntity);
  return product.productPriceGroups
    ?.find(x => x.isCustom === false)?.productPrices
    ?.find(x => x.productPriceType.type === productPriceType)?.value ?? 0
}


// получить тип цены, который используется по умолчанию в зависимости от типа документа.
export function getProductPriceType(parentEntity: HubEntity):PriceTypes {
  switch (parentEntity) {
    case HubEntity.ORDER:
    case HubEntity.DEMAND:
    case HubEntity.RECALL:
      return PriceTypes.PRICE_SALE;
    default:
      return PriceTypes.PRICE_PURCHASE;
  }
}
