export enum KeyboardEventCodes {
  Unidentified = 'Unidentified',
  Escape = 'Escape',
  KeyA = 'KeyA',
  KeyC = 'KeyC',
  KeyV = 'KeyV',
  KeyX = 'KeyX',
  Enter = 'Enter',
  Tab = 'Tab',
  CapsLock = 'CapsLock',
  Minus = 'Minus',
  Equal = 'Equal',
  Backspace = 'Backspace',
  Comma = 'Comma',
  Period = 'Period',
  Slash = 'Slash',
  Backslash = 'Backslash',
  IntlBackslash = 'IntlBackslash',
  Space = 'Space',
  Home = 'Home',
  End = 'End',
  Insert = 'Insert',
  Delete = 'Delete',
  PageUp = 'PageUp',
  PageDown = 'PageDown',
  NumpadDivide = 'NumpadDivide',
  NumpadSubtract = 'NumpadSubtract',
  NumpadDecimal = 'NumpadDecimal',
  NumpadEnter = 'NumpadEnter',
}
