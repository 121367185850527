import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HubMetadataResolver } from './hub-metadata.resolver';
import { HubComponent } from './hub.component';
import { ProductResolver, ProductGroupsResolver } from './resolvers';
import { WarehouseGroupsResolver } from './resolvers/warehouse-groups.resolver';
import {
  GOODS_ACCOUNTING_VIEW_ROLES,
  HANDBOOKS_VIEW_ROLES, ORDER_VIEW_ROLES, PAYMENT_VIEW_ROLES,
  REPORTS_VIEW_ROLES,
  ROLES,
  RolesGuard, SUPPLY_VIEW_ROLES
} from "@topseller/core";

const routes: Routes = [
  {
    path: '',
    redirectTo: '/hub/dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: HubComponent,
    resolve: {metadata: HubMetadataResolver},
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        canActivate: [RolesGuard],
        data: {
          requiredRole: ROLES.ROLE_DASHBOARD,
          noAccessRedirect: '/hub/noaccess'
        }
      },
      {
        path: 'products',
        loadChildren: () =>
          import('./pages/products-services/product.module').then(
            (m) => m.ProductModule
          ),
        resolve: {ProductGroupsResolver},
        canActivate: [RolesGuard],
        data: {
          requiredRole: ROLES.ROLE_PRODUCT_VIEW,
          noAccessRedirect: '/hub/noaccess'
        }
      },
      {
        path: 'supply',
        loadChildren: () =>
          import('./pages/supply/supply.module').then((m) => m.SupplyModule),
        canActivate: [RolesGuard],
        data: {
          requiredRole: SUPPLY_VIEW_ROLES,
          noAccessRedirect: '/hub/noaccess'
        }
      },
      {
        path: 'goods-accounting',
        loadChildren: () =>
          import('./pages/goods-accounting/goods-accounting.module').then(
            (m) => m.GoodsAccountingModule
          ),
        canActivate: [RolesGuard],
        data: {
          requiredRole: GOODS_ACCOUNTING_VIEW_ROLES,
          noAccessRedirect: '/hub/noaccess'
        }
      },
      {
        path: 'sale',
        loadChildren: () =>
          import('./pages/sales/sales.module').then((m) => m.SalesModule),
        canActivate: [RolesGuard],
        data: {
          requiredRole: ORDER_VIEW_ROLES,
          noAccessRedirect: '/hub/noaccess'
        }
      },
      {
        path: 'handbooks',
        loadChildren: () =>
          import('./pages/handbooks/handbooks.module').then(
            (m) => m.HandbooksModule
          ),
        canActivate: [RolesGuard],
        data: {
          requiredRole: HANDBOOKS_VIEW_ROLES,
          noAccessRedirect: '/hub/noaccess'
        }
      },
      {
        path: 'money',
        loadChildren: () =>
          import('./pages/money/money.module').then((m) => m.MoneyModule),
        canActivate: [RolesGuard],
        data: {
          requiredRole: PAYMENT_VIEW_ROLES,
          noAccessRedirect: '/hub/noaccess'
        }
      },
      {
        path: 'transfer',
        loadChildren: () =>
          import('./pages/transfer/transfer-entity.module').then(
            (m) => m.TransferEntityModule
          ),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./pages/reports/reports.module').then((m) => m.ReportsModule),
        canActivate: [RolesGuard],
        data: {
          requiredRole: REPORTS_VIEW_ROLES,
          noAccessRedirect: '/hub/noaccess'
        }
      },
      {
        path: 'rest',
        loadChildren: () =>
          import('./pages/rest/rest.module').then((m) => m.RestModule),
      },
      {
        path: 'library',
        loadComponent: (() => import('./pages/library/library.component').then((m) => m.LibraryComponent))
      },
      {
        path: 'noaccess',
        loadComponent: (() => import('./pages/no-access-page').then((m) => m.NoAccessPageComponent))
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    HubMetadataResolver,
    ProductResolver,
    WarehouseGroupsResolver,
  ],
})
export class HubRoutingModule {
}
