<div class="w-100 d-flex justify-content-between align-items-center">
  <div class="d-flex gap-2">
    <img *ngIf="file.isImage" [ngSrc]="file?.url!" width="30" height="30" class="rounded">
    <i *ngIf="!file.isImage" [class]="'ts-icon ts-icon-'+fileTypeIcon" class="flex-grow-0 ts-text-tertiary text-2xl"></i>
    <div class="d-flex align-items-center">
      <div class="file-name" [title]="file.originalName">{{fileNameWithoutExtension}}</div>
      <span>.{{fileExtension}}</span>
    </div>
  </div>
  <a type="button" class="flex-shrink-0 text-2lg ts-text-control-primary" [href]="file.url">
    <i class="ts-icon ts-icon-arrow-Import"></i>
  </a>
</div>
