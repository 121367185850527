import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';

import { TsMaskDirective } from '@topseller/mask';

import { BaseInputComponent } from './base-input.component';
import { InputNumberModule } from '../input-number';
import { BaseInputDirective } from './base-input.directive';
import { TsInputResizerDirective } from './base-input-resizer.directive';

@NgModule({
  declarations: [
    BaseInputComponent,
    BaseInputDirective,
    TsInputResizerDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxMaskDirective,
    ToastrModule,
    InputNumberModule,
    TsMaskDirective,
  ],
  exports: [BaseInputComponent, BaseInputDirective],
})
export class BaseInputModule {}
