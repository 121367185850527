import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { TsContext } from '../common';

export class TsLetContext<T> implements TsContext<T> {
  constructor(private readonly internalDirectiveInstance: TsLetDirective<T>) {}

  public get $implicit(): T {
    return this.internalDirectiveInstance.tsLet;
  }

  public get tsLet(): T {
    return this.internalDirectiveInstance.tsLet;
  }
}

@Directive({
  selector: '[tsLet]',
  standalone: true,
})
export class TsLetDirective<T> {
  @Input()
  public tsLet!: T;

  constructor(
    viewContainerRef: ViewContainerRef,
    templateRef: TemplateRef<TsLetContext<T>>
  ) {
    viewContainerRef.createEmbeddedView(templateRef, new TsLetContext<T>(this));
  }
}
