import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AnimationOptions } from 'ngx-lottie';

import { selectCurrentTheme } from '@topseller/core';

export const DEFAULT_LOTTY_ANIMATION_OPTIONS: AnimationOptions = {
  path: '/assets/img/logo_animation.json',
};

@Component({
  selector: 'ts-loader-animated',
  templateUrl: './loader-animated.component.html',
  styleUrls: ['./loader-animated.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderAnimatedComponent {
  @Input() isLoading: boolean | null = false;

  @Input() skeleton = false;

  loaderAnimationOptions = DEFAULT_LOTTY_ANIMATION_OPTIONS;

  public theme$ = this.store.select(selectCurrentTheme);

  constructor(private store: Store) {}
}
