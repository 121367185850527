import { NgModule } from '@angular/core';

import { ValueUnitPipe } from './value-unit.pipe';
import { CurrencyFormatPipe } from '../currency-format';
import { DecimalPipe } from '@angular/common';

@NgModule({
  imports: [],
  exports: [ValueUnitPipe],
  declarations: [ValueUnitPipe],
  providers: [CurrencyFormatPipe, DecimalPipe],
})
export class ValueUnitModule { }
