import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsComponent } from './analytics.component';
import { analyticsRoutes } from './analytics.routes';
import { RouterModule } from '@angular/router';
import { TsCommonModule } from '@topseller/common';

@NgModule({
  imports: [
    RouterModule.forChild(analyticsRoutes),
    CommonModule,
    TsCommonModule,
  ],
  declarations: [AnalyticsComponent],
})
export class AnalyticsModule {}
