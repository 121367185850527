import { createReducer, on } from "@ngrx/store";
import { Restrictions } from "@topseller/core";
import { setSettingsTableColumns } from "./table-settings.actions";

export const initialState: Restrictions = {
  settings: {}
}

export const SETTINGS_TABLE_FEATURE_KEY = 'settings';

export const settingsTableColumnsReducer = createReducer(
  initialState,
  on(setSettingsTableColumns, (state, {settings}) => ({
    ...state,
    settings
  }))
)

