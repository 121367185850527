import { Directive, HostListener, Input } from '@angular/core';
import { NavbarService } from './navbar.service';

@Directive({
  selector: '[tsNavbar]',
})
export class NavbarDirective {
  @Input() public tsNavbar = '';

  constructor(private navbarService: NavbarService) {}

  @HostListener('click')
  public click() {
    if (this.tsNavbar) {
      this.navbarService.handleHistory(this.tsNavbar);
    }
  }
}
