import { LinkedEntity } from '../../../data';
import { ROLES } from "@topseller/core";

export enum LinkedDocumentEntity {
  PURCHASE,
  ORDER,
  TRANSFER_IN,
  TRANSFER_OUT,
  PAYMENT_IN,
  PAYMENT_OUT,
  INVENTORY,
  POSTING,
  WRITE_OFF,
}

export type TsLinkedDocuments = TsLinkedGroup[][];

export interface TsLinkedGroup {
  documents: LinkedEntity[];
  title: string;
  entityName: LinkedDocumentEntity;
  totals?: number | null;
  requiredRole?: string;
}

export function getRequiredRoleForLinkedDoc(entity: LinkedDocumentEntity): string {
  switch (entity) {
    case LinkedDocumentEntity.INVENTORY:
      return ROLES.ROLE_INVENTORY_VIEW;
    case LinkedDocumentEntity.ORDER:
      return ROLES.ROLE_ORDER_VIEW;
    case LinkedDocumentEntity.POSTING:
      return ROLES.ROLE_POSTING_VIEW;
    case LinkedDocumentEntity.PAYMENT_IN:
    case LinkedDocumentEntity.PAYMENT_OUT:
      return ROLES.ROLE_PAYMENT_EDIT;
    case LinkedDocumentEntity.TRANSFER_IN:
      return ROLES.ROLE_RECALL_VIEW;
    case LinkedDocumentEntity.TRANSFER_OUT:
      return ROLES.ROLE_DEMAND_VIEW;
    default:
      return '';
  }
}
