<ts-modal-dialog-header [title]="data.title"></ts-modal-dialog-header>
<div class="d-flex flex-column gap-3 p-3">

  <div class="annotation-container">
    <span class="ts-icon ts-icon-actions-info-circle text-2lg"></span>
    <div>
      <div>При нажатии на кнопку "Начать пробный период" - он активируется на 14 дней.</div>
      <br/>
      <div>По <a href="tel:+74958186165">+7 (495) 818-61-65</a> вы можете связаться с Отделом продаж (доб. 1) и Тех. поддержкой (доб. 2). Также в правом верхнем углу по кнопке "Помощь" всегда доступен чат и мы с радостью подскажем по любому вопросу</div>
    </div>
  </div>

  <ng-container [ngSwitch]="data.serviceId">
    <ng-container *ngSwitchCase="'hub'">
      <ng-template [ngTemplateOutlet]="hub"></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'connect'">
      <ng-template [ngTemplateOutlet]="hub"></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'pim'">
      <ng-template [ngTemplateOutlet]="pim"></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'repricer'">
      <ng-template [ngTemplateOutlet]="repricer"></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'aitools'">
      <ng-template [ngTemplateOutlet]="aitools"></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'wbads'">
      <ng-template [ngTemplateOutlet]="wbads"></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'dropship'">
      <ng-template [ngTemplateOutlet]="dropship"></ng-template>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div>Неизвестный сервис</div>
    </ng-container>
  </ng-container>
</div>
<mat-dialog-actions>
<div class="d-flex gap-3 justify-content-end w-100">
  <button class="ts-btn primary ts-btn-md ts-btn-filled" (click)="save()">Начать пробный период</button>
  <button class="ts-btn primary ts-btn-md ts-btn-bezeled" type="button" (click)="close()">
    Отмена
  </button>
</div>
</mat-dialog-actions>

<ng-template #hub>
  <div class="d-flex flex-column flex-md-row gap-3">
    <div class="flex-1">
      <b>Hub</b> – новый сервис товарного учета от Topseller, разработанный специально для селлеров. Наша система учета поможет управлять бизнесом с легкостью, удобством и точностью.
      <a href="https://help.topseller.ru/services/hub" target="_blank">Подробнее</a>
      <br />
      <br />
    </div>
    <div class="service-modal-video-container">
      <iframe src="https://vk.com/video_ext.php?oid=-215649227&id=456239098&hd=2&autoplay=0" width="100%" height="100%" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>

  <div class="d-flex flex-column flex-md-row gap-3 mt-3">
    <div class="flex-1">
      <b>Connect</b> – это самая функциональная интеграция, оформленная в виде приложения для Ozon, Wildberries, Яндекс.Маркет, AliExpress, СберМегаМаркет и Леруа Мерлен. Интеграция позволяет обмениваться заказами, статусами и отгрузками, переносить товары и синхронизировать остатки. Поддерживается работа по FBS/DBS/FBO/FBW/FBY/FBA, печать этикеток, уведомления.
      <a href="https://help.topseller.ru/services/connect" target="_blank">Подробнее</a>
      <br />
      <br />
    </div>
    <div class="service-modal-video-container">
      <iframe src="https://vk.com/video_ext.php?oid=-215649227&id=456239272&hd=2&autoplay=0" width="100%" height="100%" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
</ng-template>

<ng-template #pim>
  <div class="d-flex flex-column flex-md-row gap-3">
    <div class="flex-1">
      <b>PIM</b> – сервис по работе с карточками товаров на маркетплейсах. PIM значительно упрощает выгрузку карточек товаров в маркетплейсы OZON, Wildberries и Яндекс Маркет. Основное отличие сервиса – это автоматическое связывание категорий и атрибутов между маркетплейсами. Это позволяет экономить время и снижает вероятность ошибок при выгрузке.
      <a href="https://help.topseller.ru/services/pim" target="_blank">Подробнее</a>
      <br />
      <br />
    </div>
    <div class="service-modal-video-container">
      <iframe src="https://vk.com/video_ext.php?oid=-215649227&id=456239245&hd=2&autoplay=0" width="100%" height="100%" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>

</ng-template>


<ng-template #repricer>
  <div class="d-flex flex-column flex-md-row gap-3">
    <div class="flex-1">
      <b>Repricer</b> – это сервис для автоматического управления ценами на Ozon и Wildberries. Сервис автоматически отслеживает ваших конкурентов и их ценовую политику, позволяет обновлять цены в режиме реального времени и устанавливать самую выгодную цену на маркетплейсе.
      <a href="https://help.topseller.ru/services/repricer" target="_blank">Подробнее</a>
      <br />
      <br />
    </div>
    <div class="service-modal-video-container">
      <iframe src="https://vk.com/video_ext.php?oid=-215649227&id=456239237&hd=2&autoplay=0" width="100%" height="100%" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
</ng-template>

<ng-template #aitools>
  <div class="d-flex flex-column flex-md-row gap-3">
    <div class="flex-1">
      <b>AiTools</b> – сервис автоматизации отзывов на Wildberries powered by ChatGPT, обученный под маркетплейсы. AiTools позволяет настроить автоматические ответы на различные типы отзывов, что позволяет поддерживать положительный имидж вашего бренда на маркетплейсе.
      <a href="https://help.topseller.ru/services/aitools" target="_blank">Подробнее</a>
      <br />
      <br />
    </div>
    <div class="service-modal-video-container">
      <iframe src="https://vk.com/video_ext.php?oid=-215649227&id=456239246&hd=2&autoplay=0" width="100%" height="100%" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
</ng-template>


<ng-template #wbads>
  <div class="d-flex flex-column flex-md-row gap-3">
    <div class="flex-1">
      <b>Ads</b> – это автоматизированный сервис для работы с рекламой и рекламными кампаниями на Wildberries.
      <a href="https://help.topseller.ru/services/ads" target="_blank">Подробнее</a>
      <br />
      <br />
    </div>
    <div class="service-modal-video-container">
      <iframe src="https://vk.com/video_ext.php?oid=-215649227&id=456239027&hd=2&autoplay=0" width="100%" height="100%" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>

</ng-template>

<ng-template #dropship>
  <span>Для тех, кто хочет снизить издержки на хранение товара и аренду склада мы разработали сервис <b>Dropship</b>. Теперь вы можете настроить интеграцию с поставщиками в простом и понятном интерфейсе; автоматически получать остатки, цены и товары от поставщиков в учетную систему.</span>
</ng-template>
