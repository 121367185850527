import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { loadCurrentTheme, setTheme, toggleTheme, updateTableColumnWidth, updateTableSortTarget } from "./actions";
import { map, mergeMap, of, switchMap, take, tap, withLatestFrom } from "rxjs";
import { EmployeeSettingsService, LocalStorageService } from "../../providers";
import { CURRENT_THEME_NAME } from "../../constants";
import { select, Store } from "@ngrx/store";
import { selectSettingsTableColumns, setSettingsTableColumns } from "../auth";
import { catchError,  } from "rxjs/operators";

@Injectable()
export class CommonStateEffects {

  public loadCurrentTheme$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadCurrentTheme),
      switchMap(() => {
        const theme = this.localStorageService.get(CURRENT_THEME_NAME) ?? 'light';
        return of(setTheme({theme}));
      })
    )
  );

  public setTheme$ = createEffect(() =>
      this.actions.pipe(
        ofType(setTheme),
        tap((action) => {
          this.localStorageService.set(CURRENT_THEME_NAME, action.theme);
          const isDark = action.theme === 'dark';
          const body = document.body;
          if (isDark) {
            body.classList.remove('light-theme');
            body.classList.add('dark-theme');
          } else {
            body.classList.remove('dark-theme');
            body.classList.add('light-theme');
          }
        })
      ),
    { dispatch: false }
  );

  public toggleTheme$ = createEffect(() =>
    this.actions.pipe(
      ofType(toggleTheme),
      switchMap(() => {
        const currentTheme = this.localStorageService.get(CURRENT_THEME_NAME); // Предположим, что этот метод возвращает 'light' или 'dark'
        const newTheme = currentTheme === 'light' ? 'dark' : 'light';
        return of(setTheme({ theme: newTheme }));
      })
    )
  );


    public updateTableColumnWidth$ = createEffect(()=>
        this.actions.pipe(
            ofType(updateTableColumnWidth),
            switchMap(action =>
                this.store.select(selectSettingsTableColumns(action.tableIdentifier)).pipe(
                    take(1), // Берем только первое значение, чтобы избежать возможных повторных запусков эффекта
                    switchMap(settings => {
                        // Подготовка обновленных настроек и выполнение запроса к API
                        const updatedSettings = { ...JSON.parse(settings),
                            columns: {
                                ...JSON.parse(settings).columns,
                                [action.columnKey]: {
                                    ...JSON.parse(settings).columns[action.columnKey],
                                    settings: {
                                        ...JSON.parse(settings).columns[action.columnKey].settings,
                                        width: action.width
                                    }
                                }
                            }
                        };

                        const body = { [action.tableIdentifier]: JSON.stringify(updatedSettings) };

                        return this.employeeSettingsService.patchAppEmployeesettingSetvalues(body).pipe(
                            map(() => setSettingsTableColumns({ settings: body })),
                            catchError(error => {
                                console.error(error);
                                return of(); // Возвращает пустой Observable, чтобы эффект не прерывался
                            })
                        );
                    })
                )
            )
        ));

    public updateTableSortTarget$ = createEffect(()=>
        this.actions.pipe(
            ofType(updateTableSortTarget),
            switchMap(action =>
                this.store.select(selectSettingsTableColumns(action.tableIdentifier)).pipe(
                    take(1), // Берем только первое значение, чтобы избежать возможных повторных запусков эффекта
                    switchMap(settings => {
                        // Подготовка обновленных настроек и выполнение запроса к API
                        const updatedSettings = { ...JSON.parse(settings),
                            sort: {
                                order: action.sortDirection,
                                target: action.columnKey
                            }
                        };

                        const body = { [action.tableIdentifier]: JSON.stringify(updatedSettings) };

                        return this.employeeSettingsService.patchAppEmployeesettingSetvalues(body).pipe(
                            map(() => setSettingsTableColumns({ settings: body })),
                            catchError(error => {
                                console.error(error);
                                return of(); // Возвращает пустой Observable, чтобы эффект не прерывался
                            })
                        );
                    })
                )
            )
        ));

  constructor(private actions: Actions,
              private localStorageService: LocalStorageService,
              private store: Store,
              private employeeSettingsService: EmployeeSettingsService) {
  }
}
