<div
  class="color-selector-container"
  #parent="activeZone"
  (activeZoneChange)="onActiveZone($event)"
  [dropdown]="dropdownTemplate"
  [class.disabled]="disabled"
  [show]="isVisible"
>
  <div class="color-container" [ngStyle]="{'background-color':computedValue}"></div>
  <span style="margin-left: auto">
    <svg id="check" width="20" viewBox="0 0 20 20">
      <use xlink:href="/assets/img/icons/chevron-down.svg#main"></use>
    </svg>
  </span>
</div>

<ng-template #dropdownTemplate>
  <div [activeZoneParent]="parent">
    <div class="color-picker">
      <div *ngFor="let color of colorsMap | keyvalue">
        <button [tsSelectOption]="color.key"
                [ngStyle]="{'background-color':color.value}"></button>
      </div>
    </div>
  </div>
</ng-template>
