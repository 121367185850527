import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TsScrollComponent } from './scroll.component';
import { TsScrollbarModule } from '../scrollbar';

@NgModule({
  imports: [CommonModule, TsScrollbarModule],
  declarations: [TsScrollComponent],
  exports: [TsScrollComponent],
  providers: [],
})
export class TsScrollModule {}
