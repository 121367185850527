import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { FIRST_DAY, LAST_DAY, TsYear, TsMonth } from '../../../common';

@Component({
  selector: 'ts-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarHeaderComponent {
  @Input() month!: TsMonth;

  @Output()
  readonly valueChange = new EventEmitter<TsMonth>();

  @Output()
  readonly yearClick = new EventEmitter<TsYear>();

  @Output()
  readonly selectToday = new EventEmitter();

  public onPrevMonthClick(): void {
    this.appendMonth({ month: -1 });
  }

  public onNextMonthClick(): void {
    this.appendMonth({ month: 1 });
  }

  public onYearClick(): void {
    this.yearClick.next(this.month);
  }

  private appendMonth(date: Pick<TsMonth, 'month'>): void {
    const newMonth: TsMonth = this.month.append(date);
    if (newMonth.year <= FIRST_DAY.year && newMonth.month <= FIRST_DAY.month) {
      this.updateValue(FIRST_DAY);
      return;
    }
    if (newMonth.year >= LAST_DAY.year && newMonth.month >= LAST_DAY.month) {
      this.updateValue(LAST_DAY);
      return;
    }
    this.updateValue(newMonth);
  }

  private updateValue(month: TsMonth): void {
    if (this.month?.monthSame(month)) {
      return;
    }

    this.month = month;
    this.valueChange.emit(month);
  }

  onSelectTodayClick() {
    this.selectToday.emit();
    return true;
  }
}
