import { Component, Input } from '@angular/core';
import {
  File as AttachedFile,
  getFileExtension,
  getFileNameWithoutExtension,
  getFileType,
  getFileTypeIcon
} from "@topseller/core";
import { CommonModule, NgOptimizedImage } from "@angular/common";

@Component({
  selector: 'ts-file-list-item',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './file-list-item.component.html',
  styleUrls: ['./file-list-item.component.scss'],
})
export class FileListItemComponent {
  @Input() file!: AttachedFile;

  get fileTypeIcon() {
    return getFileTypeIcon(this.file.originalName ?? '');
  }

  get fileType() {
    return getFileType(this.file.originalName);
  }

  get fileExtension() {
    return getFileExtension(this.file.originalName);
  }

  get fileNameWithoutExtension() {
    return getFileNameWithoutExtension(this.file.originalName);
  }
}
