import { Component, Input } from '@angular/core';

export interface AnchorList {
    title: string;
    id: string;
}

@Component({
    selector: 'ts-anchor',
    templateUrl: './anchor-nav.component.html',
    styleUrls: ['./anchor-nav.component.scss']
})

export class AnchorNavComponent {

    @Input() links?: AnchorList[];

    public active_link = '';

    public changeActiveLink(link: string) {
        this.active_link = link
    }

}
