import { Injectable } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { filter, switchMap, take, takeUntil } from 'rxjs';
import { openDB } from 'idb';

function removeParamsFromUrl(url: string) {
  return url.replace(/\?.*$/, ''); // Удаляем все, что идет после знака вопроса
}

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  private dbPromise = openDB(`HISTORY`, 1, {
    upgrade(db) {
      db.createObjectStore('navigation', { keyPath: 'name' });
    },
  });

  constructor(private router: Router) {}

  public handleHistory(appName: string): void {
    const navigationCancel = this.router.events.pipe(
      filter((event) => event instanceof NavigationCancel)
    );

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        switchMap(() => this.router.events),
        filter((event) => event instanceof NavigationEnd),
        takeUntil(navigationCancel),
        take(1)
      )
      .subscribe(async (event) => {
        const db = await this.dbPromise;
        db.put('navigation', {
          name: appName,
          value: removeParamsFromUrl((event as NavigationEnd).url),
        });
      });
  }

  public async getHistory(name: string): Promise<boolean | undefined> {
    const db = await this.dbPromise;
    return db.get('navigation', name).then((variable) => variable?.value);
  }
}
