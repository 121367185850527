import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { ConfirmDialogComponent, SecurityService } from '@topseller/core';

/**
 * Guard который проверяет доступность сервиса по ограничениям.
 * */
@Injectable()
export class ServiceAvailableGuard {
  constructor(
    private securityService: SecurityService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const serviceId = route.data['serviceId'];
    return this.securityService.serviceAvailableByRestrictions(serviceId).pipe(
      tap((available) => {
        if (available) {
          return;
        }
        if (serviceId === 'connect') {
          this.dialog.open(ConfirmDialogComponent, {
            autoFocus: false,
            data: {
              title: 'Сервис не доступен',
              content: 'Нужен активный тариф Hub Standard или выше',
              cancelOnly: true,
            },
          });
        }
        this.router.navigate(['/']);
      })
    );
  }
}
