import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable, Optional } from "@angular/core";
import { Observable } from "rxjs";
import { Configuration } from "../configuration";
import { CustomHttpUrlEncodingCodec } from "../encoder";
import { Paginated } from "../model/paginated";
import { Record } from "@topseller/core";
import { BASE_PATH } from '../variables';

@Injectable({ providedIn: 'root' })
export class FilterApiService {

    protected basePath = 'http://denis.dev.topseller.ru';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

  public getSelectValues(
    filter: any,
    limit?: number,
    offset?: number,
    search?: string,
  ): Observable<Paginated<Record>> {

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', limit);
    }
    if (search) {
      queryParameters = queryParameters.append('search', <string>search);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', offset);
    }

    return this.httpClient.get<Paginated<Record>>(`${this.basePath}/api/v1/filters/values/${filter.target}`, { params: queryParameters });
  }

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
    ) {
        if (basePath) {
            this.basePath = basePath;
        }

    }

  /**
  * ids - это ид нужных сущностей перечисленных через запятую
  * */
    public getSelectValue(ids:string, filter?: any): Observable<Record[]> {
    return this.httpClient.get<Record[]>(`${this.basePath}/api/v1/filters/values/${filter}/${ids}`, );
  }
}
