export function normalizeToIntNumber(
  value: number,
  min: number,
  max: number
): number {
  if (Number.isNaN(value) || value <= min) {
    return min;
  }

  if (value >= max) {
    return max;
  }

  return Math.round(value);
}
