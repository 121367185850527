import { ChangeDetectorRef, Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription, take } from 'rxjs';

import { Bank, BankService } from '../../data';
import { SettlementAccount } from "@topseller/core";

@Injectable()
export class SettlementBaseService {
  public bikLoading = false;

  public settlementAccounts = new FormArray<any>([]);

  private readonly bikSubscriptions = new WeakMap<AbstractControl, Subscription[]>();

  constructor(
    private toastr: ToastrService,
    private bankService: BankService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  get accounts(): SettlementAccount[] {
    return this.settlementAccounts.value!;
  }


  addAccount(): void {
    const newAccountForm = this.createAccountForm(null);
    this.settlementAccounts.push(newAccountForm);
  }

  removeAccount(index: number): void {
    const control = this.settlementAccounts.at(index);
    const subscriptions = this.bikSubscriptions.get(control);
    subscriptions?.forEach((subscription) => subscription.unsubscribe());
    this.bikSubscriptions.delete(control);
    this.settlementAccounts.removeAt(index);
    this.changeDetectorRef.detectChanges();
  }

  setSettlementAccounts(settlementAccounts: SettlementAccount[]): void {
    this.settlementAccounts.clear();
    for (const settlement of settlementAccounts) {
      const accountForm = this.createAccountForm(settlement);
      this.settlementAccounts.push(accountForm);
    }
  }

  createAccountForm(settlementAccount?: SettlementAccount|null): FormGroup {
    // Создание формы
    const accountForm = new FormGroup({
      id: new FormControl(settlementAccount?.id || null),
      bank: new FormGroup({
        id: new FormControl(settlementAccount?.bank?.id || null),
        address: new FormControl(settlementAccount?.bank?.address || ''),
        bik: new FormControl(settlementAccount?.bank?.bik || ''),
        correspondentAccount: new FormControl(settlementAccount?.bank?.correspondentAccount || ''),
        name: new FormControl(settlementAccount?.bank?.name || ''),
      }),
      isDefault: new FormControl(settlementAccount ? settlementAccount.isDefault : !this.settlementAccounts.length),
      name: new FormControl(settlementAccount?.name || '', [Validators.required]),
      settlementAccount: new FormControl(settlementAccount?.settlementAccount || '', [Validators.minLength(20), Validators.maxLength(20)]),
    });

    // Подписка на изменения поля bik и обнуление некоторых полей при изменении
    const bikField = accountForm.get('bank.bik');
    const subscription = bikField!.valueChanges.subscribe(() => {
      accountForm.patchValue({
        bank: {
          id: null,
          address: '',
          name: '',
          correspondentAccount: '',
        },
      });
    });

    // Хранение подписки, чтобы позже можно было от неё отписаться
    this.bikSubscriptions.set(accountForm, [subscription]);

    return accountForm;
  }

  settlementToggleHandler(index: number): void {
    if (this.settlementAccounts.length) {
      for (let i = 0; i < this.settlementAccounts.length; i++) {
        this.settlementAccounts
          .at(i)
          .get('isDefault')
          ?.setValue(i === index);
      }
    }
  }

  onChangeBik(index: number) {
    const bank = this.settlementAccounts.at(index).get('bank');
    const { bik } = bank?.value;

    if (bik.length === 9) {
      this.bikLoading = true;
      this.bankService
        .getAppBankGetbybik(bik)
        .pipe(take(1))
        .subscribe({
          next: (data: Bank) => {
            bank?.patchValue(data, {emitEvent: false, onlySelf: true});
          },
          error: (err) => {
            this.toastr.error(
              err && err.error
                ? err.error
                : 'По указанному БИК ничего не найдено'
            );
          },
          complete: () => {
            this.bikLoading = false;
          },
        });
    }else{
      this.toastr.warning('Неверный формат БИК')
    }
  }

  fillByBik(index: number) {
    this.onChangeBik(index);
  }
}
