import {createFeatureSelector, createSelector} from "@ngrx/store";
import {PRODUCT_GROUPS_FEATURE_KEY, ProductGroupsState} from "./product-groups.reducer";
import {ProductGroup} from "../../../data";

export const selectProductGroupsFeature =
  createFeatureSelector<ProductGroupsState>(PRODUCT_GROUPS_FEATURE_KEY);

export const selectProductGroups = createSelector(
  selectProductGroupsFeature,
  ({ item }: ProductGroupsState) => item as ProductGroup
);
