export const ColorsMap = {
  'celestial_blue': '#009FE3',
  'majorelle_blue': '#744BFF',
  "klein_blue": '#0027AF',
  "light_purple": '#DF5EBB',
  "electric_purple": '#BF06DD',
  "grey": '#858585',
  "black": '#101010',
  "brown": '#80592F',
  "orange": '#FF8A00',
  "red": '#E92919',
  "brunswick_green": '#145545',
  "forest_green": '#149721',
}
export function getColorByEnumValue(key: string): string {
  return (ColorsMap as Record<string, string>)[key];
}
