<ng-template tsSidebarTitle>Фильтры</ng-template>

<div class="filters" *ngIf="form" [formGroup]="form">
  <ts-drawer [expandIconType]="'flat'">
    <span class="ts-subtitle-small">Применённые фильтры</span>
    <div tsDrawerContent>
      <div class="filters__applied">
    <span
      *ngIf="appliedFilters.length !== 0"
      class="filters__applied-item filters__applied-deleteAll "
      (click)="cleanAll()"
    >Удалить всё</span
    >
        <div class="filters__applied-item d-flex flex-row gap-1 align-items-center" *ngFor="let item of appliedFilters">
          <div>{{ getDictionaryName(item.controlName) }} :  {{ getDictionaryValue(item.value, item.controlName) }}</div>
          <div (click)="cleanFilter(item.controlName)">
            <i class="ts-icon ts-icon-actions-Close text-normal cursor-pointer"></i>
          </div>
        </div>
      </div>
    </div>
  </ts-drawer>


  <div *ngFor="let filterGroup of filters">
    <ts-drawer [expandIconType]="'flat'">
      <span class="ts-subtitle-small">{{ filterGroup.name }}</span>
      <div tsDrawerContent class="mt-2">
        <ng-container *ngIf="filterGroup.type ==='daterange';else otherControls">
          <ts-input-date-range-selector
            [formGroup]="form"
            [dateFromControlName]="filterGroup.target? filterGroup.target+'_'+filterGroup.filters[0].target : filterGroup.filters[0].target"
            [dateToControlName]="filterGroup.target?filterGroup.target+'_'+filterGroup.filters[1].target:filterGroup.filters[1].target"
            [dateFromInputLabel]="filterGroup.filters[0].name"
            [dateToInputLabel]="filterGroup.filters[1].name">

          </ts-input-date-range-selector>
        </ng-container>
        <ng-template #otherControls>
          <ts-filter-control *ngFor="let filter of filterGroup.filters" [entityName]="entityName" [filter]="filter" [form]="form"></ts-filter-control>
        </ng-template>

      </div>
    </ts-drawer>
    <div class="hl"></div>
  </div>
</div>

<ng-template tsSidebarActions>
  <button class="ts-btn primary ts-btn-filled ts-btn-md" (click)="applyFilters()">Применить</button>
</ng-template>
