import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
  selector: '[tsTableCellTemplate]',
})
export class TableCellTemplateDirective {

  @Input() public tsTableCellTemplate?: string;

  constructor(public template: TemplateRef<any>) {}
}
