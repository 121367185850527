import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  Optional,
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { TsAbstractNullableControl } from '@topseller/cdk/abstract';

import { INPUT_NUMBER_ACTIONS, extractAction } from './input-number-actions';
import { InputNumberService } from './input-number.service';

import { SelectionHelper, Selection } from './helpers/selection.helper';
import { NumberFormatOptions } from './input-number.types';

export type DecimalSymbol = '.' | ',';

@Directive({
  selector: `input[tsInputNumber]`,
  providers: [InputNumberService],
  exportAs: 'tsInputNumber',
})
export class InputNumberDirective extends TsAbstractNullableControl<number> {
  private tempSelectionForOnChange: Selection = SelectionHelper.fromPosition(0);
  private previousFormatted = ``;

  @Input()
  public set tsInputNumber(options: NumberFormatOptions | string) {
    if (options && typeof options !== 'string') {
      this.inputNumberService.initConfig(options);
    }
  }

  public get nativeElement(): HTMLInputElement {
    return this.elementRef.nativeElement;
  }

  public get nativeValue(): string {
    return this.nativeElement.value;
  }

  public set nativeValue(value: string) {
    this.nativeElement.value = value;
  }

  constructor(
    private elementRef: ElementRef,
    private inputNumberService: InputNumberService,
    @Optional() ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(ngControl, changeDetectorRef);
  }

  public override writeValue(value: number): void {
    super.writeValue(value);
    this.nativeValue = this.previousFormatted =
      this.inputNumberService.format(value);
  }

  @HostListener(`keydown`, [`$event`])
  public onKeyDown(event: KeyboardEvent) {
    const selection = this.selection;
    this.tempSelectionForOnChange = selection;

    const action = extractAction(event);
    if (action === INPUT_NUMBER_ACTIONS.Ignore) {
      return;
    }
    if (action !== INPUT_NUMBER_ACTIONS.Unknown) {
      event.preventDefault();
    }

    switch (action) {
      case INPUT_NUMBER_ACTIONS.Backspace: {
        const { start, end } = selection;
        // const separatorPosition = this.inputNumberService.getSeparatorPosition(
        //   this.previousFormatted
        // );

        // if (start === end && end === separatorPosition + 1) {
        //   this.moveCursor(selection, -1);
        //   return;
        // }

        this.inputValue(
          this.inputNumberService.moveCursor(
            this.previousFormatted,
            selection,
            -1
          ),
          selection.end,
          ''
        );
        return;
      }
      case INPUT_NUMBER_ACTIONS.Delete: {
        const { start, end } = selection;
        // const separatorPosition = this.inputNumberService.getSeparatorPosition(
        //   this.previousFormatted
        // );

        // if (start === end && end === separatorPosition) {
        //   this.moveCursor(selection, +1);
        //   return;
        // }
        this.inputValue(
          selection.start,
          this.inputNumberService.moveCursor(
            this.previousFormatted,
            selection,
            +1
          ),
          ''
        );
        return;
      }
      case INPUT_NUMBER_ACTIONS.Comma: {
        this.insertDelimiter(selection);
        return;
      }
      case INPUT_NUMBER_ACTIONS.MoveCursorLeft: {
        this.moveCursor(selection, -1);
        return;
      }
      case INPUT_NUMBER_ACTIONS.MoveCursorRight: {
        this.moveCursor(selection, +1);
        return;
      }
    }
  }

  @HostListener(`input`)
  public onInput() {
    const selection = this.tempSelectionForOnChange;
    const delta = this.getOnChangeDelta(this.nativeValue);

    if (!this.inputValue(selection.start, selection.end, delta)) {
      this.nativeValue = this.previousFormatted;
      this.setSelection(selection);
    }
  }

  @HostListener(`blur`)
  public onBlur() {
    const value = this.inputNumberService.parse(this.previousFormatted);
    this.previousFormatted = this.inputNumberService.format(value, false);
    this.nativeValue = this.previousFormatted;
  }

  private insertDelimiter(selection: Selection) {
    const { start, end } = selection;
    const separatorPosition = this.inputNumberService.getSeparatorPosition(
      this.previousFormatted
    );

    if (separatorPosition >= 0 && separatorPosition >= end) {
      this.moveCursor(selection, separatorPosition - end + 1);
    } else {
      this.inputValue(start, end, ',');
    }
  }

  private inputValue(start: number, end: number, value: string) {
    const result = this.inputNumberService.safeInsert(
      this.previousFormatted,
      start,
      end,
      value
    );
    if (result) {
      this.previousFormatted = result.value;
      this.nativeElement.value = result.value;

      const parsedValue = this.inputNumberService.parse(this.previousFormatted);
      this.updateValue(parsedValue);

      const selection = SelectionHelper.fromPosition(result.position);
      this.setSelection(selection);
      return true;
    }
    return false;
  }

  private get selection(): Selection {
    return {
      start: this.nativeElement.selectionStart ?? 0,
      end: this.nativeElement.selectionEnd ?? 0,
      direction: 'none',
    };
  }

  private getOnChangeDelta(value: string): string {
    const selection = this.tempSelectionForOnChange;
    const oldValue = this.previousFormatted;
    if (selection.start !== selection.end) {
      return value.substring(
        selection.start,
        value.length - (oldValue.length - selection.end)
      );
    } else if (value.length > oldValue.length) {
      return value.substring(
        selection.start,
        selection.start + value.length - oldValue.length
      );
    }
    return '';
  }

  private moveCursor = (selection: Selection, step: number) => {
    const position = this.inputNumberService.moveCursor(
      this.previousFormatted,
      selection,
      step
    );
    this.setSelection(SelectionHelper.fromPosition(position));
  };

  private setSelection({ start, end }: Selection) {
    this.nativeElement.setSelectionRange(start, end);
  }
}
