<div
  class="apps-nav-container"
  [ngClass]="{ 'with-arrows': shouldDisplayArrows }"
>
  <div class="cursor-pointer ts-icon ts-icon-menu-Menu3" routerLink=""></div>
  <div
    class="arrow left cursor-pointer ts-icon ts-icon-arrow-Expand_left"
    (click)="scroll(-1)"
  ></div>
  <div class="apps-nav-wrapper" #menuWrapper>
    <div class="apps-nav" #menu>
      <ng-container *ngFor="let item of links">
        <ng-container *tsServiceAvailableByRestrictions="getServiceIdByLink(item.url)">
          <a
            *tsHasRole="item.requiredRole??''"
            class="apps-nav__item text-nowrap"
            [routerLink]="item.url"
            [tsRouterLink]="item.url"
            [appKey]="item.match"
            routerLinkActive="apps-nav__item-active"
          >
            <span>{{ item.name }}</span>
          </a>
        </ng-container>

      </ng-container>
    </div>
  </div>
  <div
    class="arrow right cursor-pointer ts-icon ts-icon-arrow-Expand_right"
    (click)="scroll(1)"
  ></div>
</div>
