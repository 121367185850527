import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Sidebar, SidebarModule } from '@topseller/common/sidebar';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, switchMap, takeUntil, tap } from 'rxjs';
import {
  entityNames,
  HubEntity,
  Status,
  StatusApiService,
} from '../../../data';
import { ColorsMap } from '../../../data/model/colors';
import { selectStatus, updateStatuses } from '../../../store';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ConfirmDialogComponent, ROLES } from '@topseller/core';
import { CommonModule } from '@angular/common';
import { TsInputTextModule } from '@topseller/ui/input-text';

import { ControlErrorModule } from '@topseller/common/control-error/control-error.module';
import { TsHintModule } from '@topseller/ui/hint';
import { ColorsSelectorModule } from '../colors-selector';
import { TsSelectModule } from '@topseller/ui/select';
import { ToggleButtonsGroupComponent } from '@topseller/ui/toggle-buttons-group/toggle-buttons-group.component';
import { ToggleButtonModel } from '@topseller/ui';
import { TsDropdownModule } from '@topseller/ui/dropdown';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { RoleAccessWrapperComponent } from '@topseller/ui/role-access-wrapper';

@Component({
  selector: 'ts-status-edit',
  templateUrl: './statuses-edit.component.html',
  styleUrls: ['./statuses-edit.component.scss'],
  standalone: true,
  imports: [
    ColorsSelectorModule,
    CommonModule,
    SidebarModule,
    ReactiveFormsModule,
    TsInputTextModule,
    ControlErrorModule,
    TsHintModule,
    MatDialogModule,
    ColorsSelectorModule,
    TsSelectModule,
    ReactiveFormsModule,
    ToggleButtonsGroupComponent,
    TsDropdownModule,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    RoleAccessWrapperComponent,
  ],
  providers: [StatusApiService],
})
export class StatusesEditComponent
  implements Sidebar<unknown>, OnInit, OnDestroy
{
  destroy$ = new Subject<void>();
  @Input({ required: true }) entityName!: HubEntity;
  @Input() relatedEntities?: HubEntity[];

  public resolveSidebarWith!: (result?: unknown) => void;
  public selectedEntity$?: BehaviorSubject<HubEntity>;
  public selectedEntity: HubEntity = HubEntity.PURCHASE;
  public editItemRole = ROLES.ROLE_STATUS_EDIT;

  form!: FormGroup;
  map = ColorsMap;
  menu: ToggleButtonModel[] = [];

  constructor(
    protected store: Store,
    private fb: FormBuilder,
    private statusApiService: StatusApiService,
    private toastrService: ToastrService,
    private dialog: MatDialog
  ) {}

  public get statusesFormArray(): FormArray {
    return this.form.get('statuses') as FormArray;
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      statuses: this.fb.array([]),
    });
    this.store.select(selectStatus(this.entityName)).subscribe((statuses) => {
      this.form = this.fb.group({
        statuses: this.fb.array([]),
      });
      statuses.forEach((status) =>
        this.statusesFormArray.push(this.createControlsGroup(status))
      );
    });
    if (this.relatedEntities?.length) {
      this.menu = this.relatedEntities.map((x: HubEntity) => {
        return { id: x, name: entityNames[x]! };
      });
      this.menu.unshift({
        id: this.entityName,
        name: entityNames[this.entityName] as HubEntity,
      });
    }

    this.selectedEntity = this.entityName;

    this.selectedEntity$ = new BehaviorSubject(this.entityName);

    this.selectedEntity$
      .pipe(
        tap((entity) => (this.selectedEntity = entity)),
        switchMap((entity) => this.store.select(selectStatus(entity))),
        takeUntil(this.destroy$)
      )
      .subscribe((statuses) => {
        this.statusesFormArray.clear();
        statuses.forEach((status) =>
          this.statusesFormArray.push(this.createControlsGroup(status))
        );
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createControlsGroup(status: Status | null) {
    const group = this.fb.group({
      name: [status?.name, [Validators.required, Validators.pattern(/[\S]/)]],
      id: [status?.id],
      color: [status?.color ?? 'black'],
      type: this.selectedEntity,
      sort: status?.sort ?? this.form.get('statuses')?.value.length + 1,
    });
    return group;
  }

  deleteStatus(index: any) {
    this.statusesFormArray.removeAt(index);
  }

  deleteItemRole(index: number) {
    const item = this.statusesFormArray.at(index).value;
    if (item.id) {
      return ROLES.ROLE_STATUS_DELETE;
    } else {
      return null;
    }
  }

  addStatus() {
    this.statusesFormArray.push(this.createControlsGroup(null));
  }

  public save(): void {
    const entityName = this.selectedEntity$?.value;

    if (this.form.valid && entityName) {
      this.statusApiService
        .bulk(this.form.value.statuses, entityName)
        .subscribe({
          next: (res: any) => {
            this.toastrService.success(`Статусы успешно сохранены`);
            this.form.markAsPristine();
            this.store.dispatch(
              updateStatuses({
                statuses: res.items,
                entityName,
              })
            );
          },
          error: (err: any) => {
            this.toastrService.error(
              err?.errors?.length && err.errors[0].message
                ? err.errors[0].message
                : err?.message || 'Что-то пошло не так'
            );
          },
        });
    }
  }

  clear() {
    this.resolveSidebarWith(null);
  }

  public selectEntity(entity: HubEntity) {
    if (this.form.dirty) {
      const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(
        ConfirmDialogComponent,
        {
          data: {
            title: 'Данные были изменены',
            content:
              'Если продолжить без сохранения, то все внесенные изменения будут утеряны',
            okBtn: 'Продолжить без сохранения',
          },
          width: '500px',
        }
      );

      dialogRef
        .afterClosed()
        .pipe()
        .subscribe((result) => {
          if (result) {
            this.form.markAsPristine();
            this.selectedEntity$?.next(entity);
          }
        });
    } else {
      this.selectedEntity$?.next(entity);
    }
  }

  public onDrop(event: CdkDragDrop<Status>) {
    moveItemInArray(
      this.statusesFormArray.controls,
      event.previousIndex,
      event.currentIndex
    );
    this.statusesFormArray.controls.forEach((control, index) => {
      control.get('sort')?.setValue(index + 1);
    });
  }
}
