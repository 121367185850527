import { TableHeader } from "@topseller/ui";
import { Product } from "../../../data";

export const counterTableHeader: TableHeader = {
  key: 'counter',
  width: '200',
  type: 'number',
  label: 'Количество',
}
export type SelectedProductLine = {
  id: string;
  product: Product;
  quantity: number;
  price:number;
}
