import { FilterDataService } from "@topseller/common";
import { ListQueryFn } from "@topseller/common/base-list";
import { Record } from "@topseller/core";
import { Observable, of, switchMap, take } from "rxjs";
import { FilterApiService } from "../../data/api";
import { Store } from "@ngrx/store";
import { selectMetadata, selectStatus } from "../../store";
import { Injectable } from "@angular/core";
import { HubEntity } from "../../data";
import { FiltersService } from "./filters.service";
import { map } from "rxjs/operators";

@Injectable()
export class HubFilterDataService implements FilterDataService {

  constructor(private filterApiService: FilterApiService,
              private store: Store,
              private filtersService: FiltersService,) {
  }

  public filterValuesQueryFromApiFn(target: string): ListQueryFn<Record> {
    return ({limit = 0, offset = 0, search = '', filter = {}}) => {
      const filters = {target: target};
      return this.filterValuesListFn({
        limit,
        offset,
        search,
        filter: filters
      })
    }
  }

  public filterValuesFromBaseDataFn(target: string, entityName: string): Observable<any[]> {
    if (target === 'status') {
      return this.store.select(selectStatus(entityName));
    }
    return of([]);
  }

  public getSelectValue(entityName: string, id: string, target?: any): Observable<Record[]> {
    if (!entityName) {
      return this.filterApiService.getSelectValue(id, target);
    }else{
      return this.store.select(selectMetadata).pipe(
        take(1),
        map(meta => meta!.filter[entityName]),
        switchMap(filterGroups => {
          const result = { id: '', name: '' };
          for (const filterGroup of filterGroups) {
            for (const filter of filterGroup.filters) {
              if (filter.target === target && filter.defaultValue?.id === id) {
                return of([{ id: filter.defaultValue.id, name: filter.defaultValue.name ?? "По умолчанию" }]);
              }
            }
          }
          return this.filterApiService.getSelectValue(id, target);
        }));
    }
  }

  public getDefaultFilter(entityName: HubEntity | string | undefined): Observable<{ [key: string]: string }> {
    return this.filtersService.getDefaultFilter(entityName);
  }

  private filterValuesListFn: ListQueryFn<Record> = ({
                                                       limit = 0,
                                                       offset = 0,
                                                       search = '',
                                                       filter = ''
                                                     }) => this.filterApiService.getSelectValues(filter, limit, offset, search);
}
