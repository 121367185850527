import { Directive, OnInit } from "@angular/core";
import { ActionItem } from "@topseller/ui";
import { BatchActionsService } from "../services";
import { BaseTableWithFilterComponent } from "./base-table-with-filter-component.directive";
import { TsDataEntity } from "@topseller/core";
import { ApiEntityName, ListControlsConfig } from "../../data";
import { BatchActionTemplate } from "../../data/model/templateRequest";

@Directive()
export class BaseDocumentBatchActionsComponent<T extends TsDataEntity> implements OnInit {
  canReserve = false;

  batchActions: ActionItem[] = [{
    action: () => this.setIsCommitted(true),
    title: 'Провести',
    requiredRole: this.config?.editItemRole
  },
    {
      action: () => this.setIsCommitted(false),
      title: 'Снять проведение',
      requiredRole: this.config?.editItemRole
    }
  ]

  constructor(private batchActionsService: BatchActionsService,
              public host: BaseTableWithFilterComponent<T>,
              protected config?: ListControlsConfig) {
  }

  ngOnInit() {
    if (this.canReserve) {
      this.batchActions.unshift({
        action: () => this.setIsReserve(true),
        title: 'Резервировать',
        requiredRole: this.config?.editItemRole
      });
      this.batchActions.unshift({
        action: () => this.setIsReserve(false),
        title: 'Снять резерв',
        requiredRole: this.config?.editItemRole
      });
    }
  }

  private setIsCommitted(isCommitted: boolean) {
    this.performBatchAction({isCommitted}, isCommitted ? 'Документы проведены' : 'Проведение снято');
  }

  private setIsReserve(isReserve: boolean) {
    this.performBatchAction({isReserve}, isReserve ? 'Документы зарезервированы' : 'Документы сняты с резерва');
  }

  private performBatchAction(template: BatchActionTemplate, successMessage: string) {
    this.host.setLoading();
    this.batchActionsService.performAction(
      ApiEntityName[this.host.entity],
      this.host.selectedColumnsId$.getValue(),
      template,
      {
        successMessage: successMessage,
        postback: () => this.host.afterBatchAction(),
        fallback: () => this.host.setLoading(false)
      },
      this.host.filterForBatchAction)
  }
}
