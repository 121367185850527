import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {ProductGroup} from "../../../data";
import {loadProductGroups, setProductGroups} from "./product-groups.actions";
import {map, switchMap} from 'rxjs/operators';
import {ProductGroupService} from "../../../data/api/product-group.service";

@Injectable()
export class ProductGroupsEffects {

  public loadProductGroups = createEffect(() =>
    this.actions.pipe(
      ofType(loadProductGroups),
      switchMap(() => {
        return this.productGroupService
          .getProductGroups()
          .pipe(map((items: ProductGroup) => setProductGroups({item: items})));
      })
    )
  );

  constructor(
    private readonly productGroupService: ProductGroupService,
    private actions: Actions
  ) {
  }
}
