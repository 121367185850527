import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ts-refresh',
  templateUrl: './refresh.component.html',
  styleUrls: ['./refresh.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsRefreshComponent {
  @Output() refresh = new EventEmitter<void>();
}
