<div class="ts-table-container">
  <div class="overflow-auto h-100 flex-grow-1">
    <ts-scroll>
      <div class="ts-table" [tsTable]="tableHeaders">
        <div>
          <ng-content select="[slot=table-filters]"></ng-content>
        </div>

        <div class="ts-table__header">
          <ts-checkbox
            *ngIf="isSelectable"
            size="medium"
            [active]="!!(host.allSelected$ | async)"
            [indeterminate]="!!(host.indeterminate$ | async)"
            (toggle)="host.toggleSelectAllRows()"
          ></ts-checkbox>
          <ng-container *ngFor="let header of tableHeaders">
            <ts-table-header-cell2
              [tableHeader]="header"
              [entityName]="entityName"
              [tableIdentifier]="host.tableIdentifier"
            ></ts-table-header-cell2>
          </ng-container>
        </div>

        <ts-loader-animated
          [skeleton]="true"
          [isLoading]="host.isLoading$ | async"
        >
          <ng-container *ngIf="host.items$ | async as entities">
            <ng-container
              *ngIf="host.items.length; else $any(emptyContentTemplate)"
            >
              <div
                *ngFor="let item of host.items; let i = index"
                [tsTableRow]="item"
                class="ts-table__row"
                [ngClass]="{
                  selected: (host.isColumnSelected(item.id!) | async),
                  disabled: host.isDisabled(item)
                }"
              >
                <ts-checkbox
                  *ngIf="isSelectable"
                  size="medium"
                  [active]="
                    !!(host.allSelected$ | async) ||
                    (host.isColumnSelected(item.id!) | async) ||
                    false
                  "
                  (toggle)="host.toggleRowSelection(item.id)"
                />
                <ng-container *ngFor="let header of tableHeaders">
                  <div
                    [ngClass]="cellClassFactory ? cellClassFactory(item, header) : ''"
                    class="cell"
                    [ngStyle]="{
                      'width.px': header.width,
                      'flex-basis.px': header.width
                    }"
                    [tsTableCell]="header.key"
                    (click)="openItem.emit(item)"
                  >
                    <div
                      class="ts-content"
                      [ngStyle]="{ 'text-align': header.textAlign || 'left' }"
                    >
                      <ng-container
                        *ngIf="
                          getCellDirectiveByKey(header.key) as cellDirective;
                          else defaultCell
                        "
                      >
                        <ng-container
                          *ngTemplateOutlet="
                            cellDirective.template;
                            context: { $implicit: item, lineNumber: i }
                          "
                        ></ng-container>
                      </ng-container>
                      <ng-template #defaultCell>
                        <span>{{
                          dataItemToTableValueMapper.map(
                            header.key,
                            item,
                            header.type
                          )
                        }}</span>
                      </ng-template>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="lastRowTemplate">
            <ng-container *ngTemplateOutlet="lastRowTemplate"></ng-container>
          </ng-container>
        </ts-loader-animated>
      </div>

      <ng-template>
        <ng-content select="[emptyContent]"></ng-content>
      </ng-template>
    </ts-scroll>
  </div>
  <ng-content select="[slot=table-summary]"></ng-content>
</div>
