import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from "@topseller/core";
import { Observable, of, Subscription } from "rxjs";
import { TsHintModule } from '../hint';

@Component({
  selector: 'ts-role-access-wrapper',
  standalone: true,
  imports: [CommonModule, TsHintModule],
  templateUrl: './role-access-wrapper.component.html',
  styleUrls: ['./role-access-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleAccessWrapperComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() requiredRole: string | string[] | undefined | null;

  hasRole$: Observable<boolean> = of(false);
  private subscription: Subscription = new Subscription();

  constructor(private authService: AuthService,
              private el: ElementRef,
              private renderer: Renderer2) {

  }

  ngOnInit(): void {
    this.hasRole$ = this.authService.employeeHasRole(this.requiredRole);
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.hasRole$.subscribe(hasRole => {
        const elements = this.el.nativeElement.querySelectorAll('*');
        elements.forEach((element: HTMLElement) => {
          if (!hasRole) {
            this.setTabIndex(element, -1);
          }
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setTabIndex(element: HTMLElement, tabIndex: number): void {
    if (element.tabIndex >= 0) {
      this.renderer.setAttribute(element, 'tabindex', tabIndex.toString());
    }

    const focusableElements = element.querySelectorAll<HTMLElement>('a, button, input, textarea, select, details, [tabindex]');
    focusableElements.forEach(child => {
      this.renderer.setAttribute(child, 'tabindex', tabIndex.toString());
    });
  }
}
