import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Product} from "../../../data";
import {CommonModule} from "@angular/common";

@Component({
  imports:[CommonModule],
  standalone: true,
  selector: 'ts-product-dimensions-cell',
  templateUrl: './product-dimensions-cell.component.html',
  styleUrls: ['./product-dimensions-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductDimensionsCellComponent {
  @Input() product!: Product;
}
