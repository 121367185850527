import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from "../providers";
import { Subject, takeUntil } from "rxjs";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[tsHasRole]',
  standalone: true,
})
export class HasRoleDirective implements OnDestroy{

  private destroy$ = new Subject<void>();


  constructor(private authService: AuthService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {}

  @Input() set tsHasRole(role: string) {
    this.authService.employeeHasRole(role).pipe(takeUntil(this.destroy$))
      .subscribe(hasRole => {
      this.viewContainer.clear();
      if (hasRole) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
