import {
  Directive,
  HostBinding,
  Inject,
  InjectionToken,
  Input,
  Optional,
} from '@angular/core';
import { TsDataEntity } from '@topseller/core';

import { BaseListComponent } from '../base-list';

export const ENTITY_ID_KEY = new InjectionToken('Entity ID key');
const DEFAULT_ENTITY_ID_KEY = 'id';

@Directive({
  selector: '[tsTableRow]',
  standalone: true,
})
export class TsTableRowDirective<T extends TsDataEntity> {
  @Input({ required: true }) tsTableRow?: T;

  @HostBinding('class.ts-current-row')
  public get isPreviouslySelected(): boolean {
    return (
      !!this.tsTableRow &&
      this.host.routerState['activeId'] ===
        this.tsTableRow[this.entityIdKey || DEFAULT_ENTITY_ID_KEY]
    );
  }

  constructor(
    @Optional() @Inject(ENTITY_ID_KEY) private entityIdKey: keyof T,
    private host: BaseListComponent<T>
  ) {}
}
