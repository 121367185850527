<div class="ts-search">
<span class="ts-icon ts-icon-search-search-normal_alt text-normal"></span>

  <input
    type="text"
    class="ts-search__field"
    [placeholder]="placeholder"
    [formControl]="search"
  />
</div>
