import {Directive, ElementRef, OnInit, ViewChild} from "@angular/core";
import {
  BehaviorSubject,
  concatMap,
  debounceTime,
  EMPTY,
  filter,
  finalize,
  map,
  Observable,
  ReplaySubject,
  take,
  tap
} from "rxjs";
import {TsScrollComponent} from "@topseller/ui/scroll";
import {ListQueryFn} from "./types";


const PAGE_SIZE = 25;
@Directive()
export class BaseInfiniteScrollList<T> implements OnInit{
  protected scroll$: ReplaySubject<void> = new ReplaySubject<void>(1);
  protected queryFn?: ListQueryFn<T>;
  @ViewChild(TsScrollComponent, { read: ElementRef, static: true })
  private scrollContainer?: ElementRef<HTMLElement>;

  public get dataScrollOptions(): IntersectionObserverInit {
    return { root: this.scrollContainer?.nativeElement };
  }

  isLoading$ = new BehaviorSubject(true);

  limit = 25;
  offset = 0;

  items: T[] = [];
  totalCount = 0;
  public items$?: Observable<T[]>;
  public isLoading = false;
  public isLoaded = false;

  ngOnInit(): void {
    let offset = 0;
    let items: T[] = [];
    this.isLoaded = false;
    this.isLoading = true;

    this.items$ = this.scroll$.pipe(
      filter(() => !this.isLoaded),
      debounceTime(500),
      concatMap(() => {
        if (!this.queryFn) {
          return EMPTY;
        }

        return this.queryFn({
          limit: PAGE_SIZE,
          offset: offset,
        }).pipe(
          take(1),
          tap(({pagination}) => {
            const {offset: pageOffset, total} = pagination;
            offset = (pageOffset || 0) + PAGE_SIZE;
            this.totalCount = total || 0;
            if (offset >= (total || 0)) {
              this.isLoaded = true;
              this.isLoading$.next(false);
            }
          }),
          map(({items: pageItems}) => {
            items = [...items, ...pageItems];
            this.items = items;
            return items;
          }),
          finalize(() => (this.isLoading = false))
        );
      })
    )
  }

  public onScroll(): void {
    this.scroll$.next();
  }

}
