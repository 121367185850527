import { Directive, HostListener, Inject, Input } from '@angular/core';

import { DATA_LIST_HOST } from '../tokens';
import { DataListHost } from '../types';
import { TsDropdownListDirective } from './dropdown-list.directive';

@Directive({
  selector: `[dropdown][tsHosted]`,
})
export class TsDropdownHostedDirective<T> {
  @Input() tsHosted?: TsDropdownListDirective | null;

  constructor(@Inject(DATA_LIST_HOST) private readonly host: DataListHost<T>) {}

  @HostListener('document:keydown.escape', ['$event'])
  public onKeyDownEsc(event: Event): void {
    event.stopPropagation();
    this.host.updateOpen(false);
  }

  @HostListener('keydown.arrowdown', ['$event'])
  @HostListener('keydown.arrowup', ['$event'])
  public onArrowPress(event: KeyboardEvent) {
    event.preventDefault();

    const { options } = this.tsHosted || {};
    if (options?.length) {
      const current = options.first;
      current.nativeElement.focus();
    }
  }
}
