<div (activeZoneChange)="onActiveZone($event)" #parent="activeZone">
  <ng-container *ngIf="mode==='view'">
    <div (click)="setEditMode()">
      <div class="w-100">
        <div class="table-editable-cell" *ngIf="value !== null">{{ value|currencyFormat }}</div>
        <div class="table-editable-cell-placeholder selectable"
             *ngIf="value === null">
          Цена
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="mode==='edit'">
    <ts-dropdown [content]="ddTemplate" [activeZoneParent]="parent">
      <ts-input-currency tsFocusable [ngModel]="value" (ngModelChange)="updateValue($event)"/>
    </ts-dropdown>
    <ng-template #ddTemplate>
      <ts-scroll class="scroll-wrapper">
        <ts-dot-loader *ngIf="isLoading$|async"></ts-dot-loader>

        <ng-container *ngIf="prices$|async as prices">
          <button *ngFor="let price of prices" type="button" class="dropdown-item" dropdownOption
                  (click)="selectPrice(price)">
            {{price.name}}
          </button>
        </ng-container>
      </ts-scroll>
    </ng-template>
  </ng-container>
</div>
