import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Expense } from "../../../data";
import { DocumentExpensesTableBase } from "../documentExpensesTableBase";
import { DocumentExpensesTotalService } from "../../services/document-expenses-total.service";
import { TableHeader } from "@topseller/ui";
import { createExpenseFormGroup } from "../../utils";

interface ExpensesModalData {
  form: FormGroup,
  expenses: Expense[],
}

const productExpensesTableHeaders: TableHeader[] = [
  {key: 'source', label: 'Статья расходов', width: 160,},
  {key: 'sourceGroup', label: 'Группа расходов', width: 140,},
  // {key: 'expenseSpreadType', label: 'Способ размазывания затрат', width: 260,}, TODO: for later
  {key: 'amount', label: 'Сумма ₽', width: 120, textAlign: 'right', type: 'currency'},
  {key: 'expensedAt', label: 'Дата и время', width: 191,},
  {key: 'delete', label: '', width: 60},
]

@Component({
  selector: 'ts-product-expenses-modal',
  templateUrl: './product-expenses-modal.component.html',
  styleUrls: ['./product-expenses-modal.component.scss'],
  providers: [DocumentExpensesTotalService]
})
export class ProductExpensesModalComponent extends DocumentExpensesTableBase {

  private readonly originalExpenses: Expense[] = [];


  constructor(private matDialogRef: MatDialogRef<any>,
              private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: ExpensesModalData,
              documentExpensesTotalService: DocumentExpensesTotalService) {
    super(documentExpensesTotalService);
    this.form = this.formBuilder.group({
      expenses: this.formBuilder.array([])
    });
    this.originalExpenses = data.expenses;
    this.tableHeaders = productExpensesTableHeaders;
    if (this.originalExpenses) {
      this.originalExpenses.forEach((expense: Expense) => {
        const itemsGroup = createExpenseFormGroup();
        itemsGroup.patchValue({...expense});
        this.expenses.push(itemsGroup);
      })
    }
  }

  save() {
    if (!this.form.valid){
      return;
    }
    this.matDialogRef.close(this.form.value.expenses);
  }

  close() {
    this.matDialogRef.close(this.originalExpenses);
  }

  delete(index: number) {
    this.expenses.removeAt(index);
    this.form.markAsDirty();
  }
}
