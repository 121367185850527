import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TsTime } from '../common';

@Component({
  selector: 'ts-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent {
  @Input()
  value: TsTime | null = TsTime.current();

  @Output() public valueChanges: EventEmitter<TsTime> =
    new EventEmitter<TsTime>();

  public onHoursChanges(value: number) {
    const { minutes = 0, seconds } = this.value || {};
    const time = new TsTime(value, minutes, seconds);
    this.updateValue(time);
  }

  public onMinutesChanges(value: number) {
    const { hours = 0, seconds } = this.value || {};
    const time = new TsTime(hours, value, seconds);
    this.updateValue(time);
  }

  public onSecondsChanges(value: number) {
    const { hours = 0, minutes = 0 } = this.value || {};
    const time = new TsTime(hours, minutes, value);
    this.updateValue(time);
  }

  private updateValue(value: TsTime): void {
    this.value = value;
    this.valueChanges.emit(value);
  }
}
