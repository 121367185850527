import { QueryList } from '@angular/core';
import { Observable } from 'rxjs';

import { Paginated } from '../data';

export const EMPTY_QUERY = new QueryList<any>();

export type IdentityMatcher<I> = (previous: I, next: I) => boolean;

export type StringHandler<T> = (item: T) => string;

export type ListOptions = {
  limit: number;
  offset?: number;
  search?: string;
  sortName?: string;
  sortDir?: string;
  filter?: Record<string, any>;
  filterString?: string;
  modificationAttributeId?: string;
};

export type ListQueryFn<R> = (options: ListOptions) => Observable<Paginated<R>>;

export interface DataListHost<T> {
  readonly identityMatcher?: IdentityMatcher<T>;

  handleOption(option?: T): void;
}

export interface ExpenseTableLine {
  amount: number;
}
