<div class="filedrop" appFiledrop (fileDropped)="onFileDropped($any($event))">
  <input
    type="file"
    #fileDropRef
    id="fileDropRef"
    multiple
    (change)="fileBrowseHandler($any($event))"
    [ngModel]="value"
    [accept]="formatsString"
  />
  <span class="filedrop__title"
    ><label for="fileDropRef">Выберите</label> или перетащите файл</span
  >
  <span class="filedrop__descr"
    >Поддерживаемые форматы: {{ formatsString }}</span
  >
  <ng-content select="button"></ng-content>

  <div class="filedrop__files" *ngIf="value && value.length !== 0">
    <div class="filedrop__files-item" *ngFor="let file of value; let i = index">
      <span>{{ file.name }}</span>
      <div (click)="deleteFile(i)">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.33325 12.5L8.33325 10"
            stroke="#DE4646"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M11.6667 12.5L11.6667 10"
            stroke="#DE4646"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M2.5 5.83398H17.5V5.83398C17.0353 5.83398 16.803 5.83398 16.6098 5.87241C15.8164 6.03023 15.1962 6.65042 15.0384 7.4438C15 7.637 15 7.86933 15 8.33398V12.6673C15 14.5529 15 15.4957 14.4142 16.0815C13.8284 16.6673 12.8856 16.6673 11 16.6673H9C7.11438 16.6673 6.17157 16.6673 5.58579 16.0815C5 15.4957 5 14.5529 5 12.6673V8.33398C5 7.86933 5 7.637 4.96157 7.4438C4.80376 6.65042 4.18356 6.03023 3.39018 5.87241C3.19698 5.83398 2.96466 5.83398 2.5 5.83398V5.83398Z"
            stroke="#DE4646"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M8.3902 2.80883C8.48516 2.72023 8.69441 2.64194 8.98548 2.5861C9.27655 2.53027 9.63319 2.5 10.0001 2.5C10.367 2.5 10.7236 2.53027 11.0147 2.5861C11.3058 2.64194 11.515 2.72023 11.61 2.80883"
            stroke="#DE4646"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      </div>
    </div>
  </div>
</div>
