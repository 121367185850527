import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { TsDropdownComponent } from '@topseller/ui/dropdown/dropdown.component';
import { WINDOW } from '@topseller/cdk/common';
import { Store } from '@ngrx/store';
import {
  EmployeeSettingsService,
  selectShowHelpOnStart,
  selectWasShownHelpOnStart,
  setShowHelpOnStart,
  setWasShownHelpOnStart,
  PropAllExample,
} from '@topseller/core';
import {
  BehaviorSubject,
  filter,
  Observable,
  Subject,
  takeUntil,
  withLatestFrom,
} from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ts-key-info',
  templateUrl: './key-info.component.html',
  styleUrls: ['./key-info.component.scss'],
})
export class KeyInfoComponent<T> implements OnInit {
  public showHelpOnStart$: Observable<boolean> = this.store.select(
    selectShowHelpOnStart
  );
  public showModal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public wasShownHelpOnStart$: Observable<boolean> = this.store.select(
    selectWasShownHelpOnStart
  );

  private destroy$: Subject<void> = new Subject<void>();
  @ViewChild(TsDropdownComponent) dropdownComponent!: TsDropdownComponent<T>;

  constructor(
    @Inject(WINDOW) private window: Window,
    private store: Store,
    private employeeSettingsService: EmployeeSettingsService,
    private toastr: ToastrService
  ) {}

  public closeDropDown(): void {
    this.showModal$.next(false);
  }

  public toggleShowHelpOnStart(value: boolean): void {
    const setting = { showHelpOnStart: value };
    this.employeeSettingsService
      .patchAppEmployeesettingSetvalues(setting as PropAllExample)
      .subscribe({
        next: () => {
          this.store.dispatch(setShowHelpOnStart(setting));
        },
        error: () => {
          this.toastr.error('Упс, что-то пошло не так =( Попробуйте позднее');
        },
      });
  }

  public openChat(): void {
    (this.window as any).carrotquest.open();
  }

  public openUrl(url: string): void {
    const prefix = 'https://';
    this.window.open(prefix + url, '_blank');
  }

  public ngOnInit(): void {
    this.showHelpOnStart$
      .pipe(
        filter(Boolean),
        withLatestFrom(this.wasShownHelpOnStart$),
        takeUntil(this.destroy$),
        tap(([_, wasShownHelpOnStart]) => {
          if (!wasShownHelpOnStart) {
            this.showModal$.next(true);
          }
          this.store.dispatch(
            setWasShownHelpOnStart({ wasShownHelpOnStart: true })
          );
        })
      )
      .subscribe();
  }

  public onChangeVisible(event: boolean): void {
    this.showModal$.next(event);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
