<div class="date-time-selector">
  <div class="date-time-selector_content">
    <ts-calendar [value]="value ? value[0] : null" (dayClick)="onDayClick($event)"></ts-calendar>
    <div class="date-time-selector_divider"></div>
    <ts-time-picker (valueChanges)="onTimeSelect($event)" [value]="value ? value[1] : null"></ts-time-picker>
  </div>

  <div class="date-time-selector_actions">
    <button class="ts-btn primary ts-btn-filled ts-btn-md ts-controls-bar-item" (click)="onApply()" [disabled]="!value || !value[0]">Применить</button>
    <button class="ts-btn secondary ts-btn-filled ts-btn-md ts-controls-bar-item" (click)="onCancel()">Oтмена</button>
  </div>
</div>
