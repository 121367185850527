<div [formGroup]="host.form" class="item-edit-header-container d-flex gap-2 align-items-center flex-wrap">
  <ng-container *ngIf="host.entityName !== HubEntity.PAYMENT; else paymentTmpl">
    <span class="ts-subtitle-strong">{{ host.entityName | hubEntityName }} №</span>
  </ng-container>
  <ng-template #paymentTmpl>
    <span class="ts-subtitle-strong">{{ subtitle }} №</span>
  </ng-template>


  <div class="ts-subtitle-strong number-item">
    <ts-role-access-wrapper [requiredRole]="host.documentEditRole">
      <app-title formControlName="number" [placeholder]="'Сгенерируется, если не указать'"/>
    </ts-role-access-wrapper>
  </div>
  <span class="ts-subtitle-strong">от</span>
  <ts-role-access-wrapper [requiredRole]="host.documentEditRole" class="date-time-item">
    <ts-input-date-time
      class="date-time-item"
      formControlName="datedAt"
    />
  </ts-role-access-wrapper>

  <ng-container *ngIf="host.entityName === HubEntity.DEMAND">
    <span class="ts-subtitle-strong">реализовано</span>
    <ts-role-access-wrapper [requiredRole]="host.documentEditRole" class="date-time-item">
      <ts-input-date-time
        class="date-time-item"
        formControlName="payedAt"
      />
    </ts-role-access-wrapper>
  </ng-container>

  <ts-role-access-wrapper [requiredRole]="host.documentEditRole">
    <ts-dropdown-button
      formControlName="status"
      [identityMatcher]="host.recordIdentityMatcher"
      [stringify]="host.recordStringify"
      [tsStatusColorizer]="host.status"
    >
      <app-status-selector
        [entityName]="host.entityName"
      />
    </ts-dropdown-button>
  </ts-role-access-wrapper>


  <ts-role-access-wrapper [requiredRole]="host.documentEditRole">
    <app-toggle-switch
      formControlName="isCommitted"
      label="Проведён"
    />
  </ts-role-access-wrapper>

  <ts-role-access-wrapper [requiredRole]="host.documentEditRole">
    <app-toggle-switch
      *ngIf="host.entityName === HubEntity.ORDER"
      formControlName="isReserve"
      label="Резерв"
    />
  </ts-role-access-wrapper>

  <ts-role-access-wrapper [requiredRole]="host.documentEditRole">
    <app-toggle-switch
      *ngIf="host.entityName === HubEntity.PURCHASE"
      formControlName="isFinalized"
      label="Закрыть заказ"
    />
  </ts-role-access-wrapper>
</div>
