<div class="input-comment">

  <app-title
    [multirow]="true"
    [ngModel]="value"
    (ngModelChange)="onModelChange($event)"
    [readonly]="readonly"
    [title]="placeholder"
    (keydown.enter)="send.emit()"
    [hasCleaner]="false"></app-title>
  <div class="input-comment__controls">
    <label for="uploads" type="button" class="ts-btn ts-btn-sm ts-btn-icon btn-attachment">
      <i class="ts-icon ts-icon-document-document text-2lg"></i>
    </label>
    <input
      class="d-none"
      type="file"
      multiple
      id="uploads"
      (change)="addFiles($event)" alt=""
    >
    <button type="button" class="ts-btn secondary ts-btn-bezeled ts-btn-sm ts-btn-icon btn-send" (click)="send.emit()"
    [disabled]="!hasValue">
      <i class="ts-icon ts-icon-location-direct-right"></i>
    </button>
  </div>
</div>
<div class="d-flex gap-2 flex-wrap">
  <ts-attached-file *ngFor="let file of attachedFiles; let i=index"
                    state="new"
                    [file]="file"
                    (delete)="deleteFile(i)"
  ></ts-attached-file>
</div>
