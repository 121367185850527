import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { TableHeader } from '@topseller/ui';
import { fromEvent, map, Subject, switchMap, takeUntil } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TableSettingsService } from '../table';
import { TS_COLUMN_RESIZE } from '../table-headers/constants';
import {
  TableHeaderFilterComponent
} from "./table-header-filter/table-header-filter.component";

@Component({
  selector: 'ts-table-header-cell2',
  standalone: true,
  imports: [CommonModule, TableHeaderFilterComponent],
  templateUrl: './table-header-cell2.component.html',
  styleUrls: ['./table-header-cell2.component.scss'],
})
export class TableHeaderCell2Component
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() tableHeader!: TableHeader;
  @Input() tableIdentifier?: string;
  @Input() entityName?: string;

  @HostBinding('style.flexBasis') flexBasis!: string;
  @HostBinding('style.width') width!: string;

  @ViewChild('resizer', {read: ElementRef, static: true})
  public resizer?: ElementRef;
  protected destroy$: Subject<void> = new Subject<void>();
  private hasWidthChanged: boolean = false;

  hasFilter = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    private ngZone: NgZone,
    private tableSettingsService: TableSettingsService
  ) {
  }

  ngOnInit() {
    this.flexBasis = `${this.tableHeader.width}px`;
    this.width = `${this.tableHeader.width}px`;
    if (this.entityName){
      this.hasFilter = true;
    }
  }

  ngAfterViewInit() {
    this.resizeColumnTable();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  sort() {
    if (!this.tableHeader.isSortable) {
      return;
    }

    if (this.tableIdentifier && this.tableSettingsService) {
      const sortDir = this.tableHeader.sortDir === 'asc' ? 'desc' : 'asc';
      this.tableSettingsService.updateSortTarget(
        this.tableHeader.key,
        this.tableIdentifier,
        sortDir
      );
    }
  }

  /* логика ресайза столбцов */
  private resizeColumnTable() {
    if (!this.tableHeader.isResizable || !this.resizer?.nativeElement) {
      return;
    }

    const {nativeElement} = this.elementRef;
    let lastWidth: number;

    fromEvent<PointerEvent>(this.resizer?.nativeElement, 'pointerdown')
      .pipe(
        tap((event: PointerEvent) => {
          event.stopPropagation();
          event.preventDefault();
        }),
        switchMap(({screenX}: PointerEvent) => {
          const {width}: DOMRect = nativeElement.getBoundingClientRect();

          return fromEvent<PointerEvent>(this.document, 'pointermove').pipe(
            takeUntil(fromEvent(this.document, 'pointerup')),
            map((event: PointerEvent) => {
              const offset = screenX - event.screenX;

              lastWidth =
                width - offset < this.tableHeader.minWidth!
                  ? this.tableHeader.minWidth!
                  : width - offset;
              return lastWidth;
            })
          );
        })
      )
      .subscribe((event) => {
        this.ngZone.runOutsideAngular(() => {
          const {style} = nativeElement;

          nativeElement.dispatchEvent(
            new CustomEvent(TS_COLUMN_RESIZE, {
              bubbles: true,
              detail: {column: this.tableHeader.key, width: event},
            })
          ),
            requestAnimationFrame(() => {
              style.width = `${event}px`;
              style.flexBasis = `${event}px`;
            });

          this.hasWidthChanged = true;
        });
      });

    fromEvent(this.document, 'pointerup')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.hasWidthChanged) {
          if (this.tableIdentifier && this.tableSettingsService) {
            this.tableSettingsService.updateColumnWidth(
              this.tableHeader.key,
              this.tableIdentifier,
              Math.round(lastWidth)
            );
          }
          this.hasWidthChanged = false;
        }
      });
  }
}
