import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectComponent } from '.';
import { ActiveZoneModule } from "@topseller/ui/active-zone";
import { BaseInputModule } from "@topseller/ui/base-input";
import { TsCustomContentComponent } from "@topseller/ui";
import { TsChipModule } from "@topseller/ui/chip/chip.module";
import { TsDropdownModule } from "@topseller/ui/dropdown";



@NgModule({
  declarations: [MultiSelectComponent],
  imports: [
    CommonModule,
    ActiveZoneModule,
    BaseInputModule,
    TsCustomContentComponent,
    TsChipModule,
    TsDropdownModule
  ],
  exports:[MultiSelectComponent]
})
export class MultiSelectModule { }
