/**
 * TOPSELLER HUB API
 * Topseller HUB backend API
 *
 * OpenAPI spec version: 23.0915.0422
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Trash } from '../model/trash';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { Paginated } from "@topseller/core";
import { Batch } from "@topseller/core";


@Injectable()
export class TrashService {

    protected basePath = 'http://denis.dev.topseller.ru';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Список удаленных записей
     * Список удаленных записей
     * @param limit Кол-во, выводимое на страницу
     * @param offset Смещение указателя
     * @param filter Коллекция фильтров
     * @param search Строка поиска
     * @param sort Сортировка ?sort[type]&#x3D;desc&amp;sort[name]&#x3D;asc
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppTrashIndex(
      limit?: number,
      offset?: number,
      search?: string,
      filter?: string,
      sortName?: string,
      sortDir?: string,
      observe?: 'body', reportProgress?: boolean): Observable<Paginated<Trash>>;
    public getAppTrashIndex(
      limit?: number,
      offset?: number,
      search?: string,
      filter?: string,
      sortName?: string,
      sortDir?: string,
      observe: any = 'body',
      reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }

        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }
      if(sortName !== undefined && sortDir !== null) {
        queryParameters = queryParameters.set(`sort[${sortName}]`, <any>sortDir)
      }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Trash>>('get',`${this.basePath}/api/v1/trash?${filter??''}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Восстановить
     * Восстановить удаленную запись
     * @param trash
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppTrashRestore(trash: string, observe?: 'body', reportProgress?: boolean): Observable<Trash>;
    public getAppTrashRestore(trash: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Trash>>;
    public getAppTrashRestore(trash: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Trash>>;
    public getAppTrashRestore(trash: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (trash === null || trash === undefined) {
            throw new Error('Required parameter trash was null or undefined when calling getAppTrashRestore.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Trash>('get',`${this.basePath}/api/v1/trash/${encodeURIComponent(String(trash))}/restore`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Удаленная запись
     * Удаленная запись
     * @param trashId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppTrashView(trashId: string, observe?: 'body', reportProgress?: boolean): Observable<Trash>;
    public getAppTrashView(trashId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Trash>>;
    public getAppTrashView(trashId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Trash>>;
    public getAppTrashView(trashId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (trashId === null || trashId === undefined) {
            throw new Error('Required parameter trashId was null or undefined when calling getAppTrashView.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Trash>('get',`${this.basePath}/api/v1/trash/${encodeURIComponent(String(trashId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


  public postAppTrashBatchDelete(
    body?: { ids: string[] },
    filter?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false ): Observable<HttpEvent<Batch>> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (filter) {
      filter.forEach((element) => {
        queryParameters = queryParameters.append('filter', <any>element);
      })
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Batch>('post',`${this.basePath}/api/v1/trash/batch-delete`,
      {
        body: body,
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public postAppTrashBatchDeleteAll(observe: any = 'body', reportProgress: boolean = false ): Observable<HttpEvent<Batch>> {

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<Batch>('delete',`${this.basePath}/api/v1/trash/all`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
