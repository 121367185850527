import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';

import { BaseInputModule } from '../base-input';

import { TsInputTextComponent } from './input-text.component';
import { ActiveZoneDirective } from '../active-zone';
import { TsDropdownModule } from '../dropdown';

@NgModule({
  imports: [
    ActiveZoneDirective,
    CommonModule,
    BaseInputModule,
    FormsModule,
    TsDropdownModule,
    ReactiveFormsModule,
    NgxMaskDirective,
  ],
  declarations: [TsInputTextComponent],
  exports: [TsInputTextComponent],
  providers: [],
})
export class TsInputTextModule {}
