import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';

import { BaseInputModule } from '../base-input';
import { CalendarModule } from '../calendar';
import { ActiveZoneModule } from '../active-zone';
import { TsDropdownModule } from '../dropdown';

import { InputDateComponent } from './input-date.component';

const maskConfig: Partial<IConfig> = {
  leadZeroDateTime: true,
};

@NgModule({
  declarations: [InputDateComponent],
  imports: [
    BaseInputModule,
    CommonModule,
    CalendarModule,
    FormsModule,
    ActiveZoneModule,
    TsDropdownModule,
  ],
  providers: [provideEnvironmentNgxMask(maskConfig)],

  exports: [InputDateComponent],
})
export class InputDateModule {}
