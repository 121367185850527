/**
 * TOPSELLER HUB API
 * Topseller HUB backend API
 *
 * OpenAPI spec version: 23.0706.0654
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Audit, Paginated } from '../model/models';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class AuditService {
  protected basePath = 'http://denis.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Список изменений сущностей
   * &lt;b&gt;Available filters&lt;/b&gt;:&lt;br&gt;         filter[entity]&#x3D;Order&lt;br&gt;         filter[entityId]&#x3D;01860dc6-05bb-7a3d-861b-56b7343544ec&lt;br&gt;         filter[parentEntityId]&#x3D;01860dc6-0580-73d3-be23-1c0c0be10445&lt;br&gt;         filter[eventType]&#x3D;2&lt;br&gt;         filter[action]&#x3D;NOT IMPLEMENTED YET&lt;br&gt;         filter[createdAt][dateFrom]&#x3D;2023-02-01T16:18:46Z&lt;br&gt;         filter[createdAt][dateTo]&#x3D;2023-02-01T16:18:46Z
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param filter Коллекция фильтров
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppAuditIndex(
    limit?: number,
    offset?: number,
    filter?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<Audit>>;
  public getAppAuditIndex(
    limit?: number,
    offset?: number,
    filter?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Paginated<Audit>>>;
  public getAppAuditIndex(
    limit?: number,
    offset?: number,
    filter?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Paginated<Audit>>>;
  public getAppAuditIndex(
    limit?: number,
    offset?: number,
    filter?: string,
    observe = 'body',
    reportProgress = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<Audit>>(
      'get',
      `${this.basePath}/api/v1/audits?${filter ?? ''}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe as any,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Получить одну запись из лога
   * Получить одну запись из лога
   * @param auditId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppAuditView(
    auditId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Audit>;
  public getAppAuditView(
    auditId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Audit>>;
  public getAppAuditView(
    auditId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Audit>>;
  public getAppAuditView(
    auditId: string,
    observe = 'body',
    reportProgress = false
  ): Observable<any> {
    if (auditId === null || auditId === undefined) {
      throw new Error(
        'Required parameter auditId was null or undefined when calling getAppAuditView.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<Audit>(
      'get',
      `${this.basePath}/api/v1/audits/${encodeURIComponent(String(auditId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe as any,
        reportProgress: reportProgress,
      }
    );
  }
}
