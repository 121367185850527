import {
  AfterContentInit,
  Attribute,
  ElementRef,
  Renderer2,
  Inject,
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { from, switchMap } from 'rxjs';

import { WINDOW } from '@topseller/cdk/common';

import { LOCAL_URL } from './yandex-auth.module';

@Component({
  standalone: true,
  selector: 'ts-yandex-auth',
  template: '',
})
export class YandexAuthComponent implements AfterContentInit {
  @Input() redirectUrl?: string;

  @Output() token = new EventEmitter<string>();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Attribute('id') private elementId: string,
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_URL) private url: string
  ) {}

  public ngAfterContentInit(): void {
    if (!this.elementId) {
      this.elementId = new Date().getTime().toString();
      this.renderer.setAttribute(this.el.nativeElement, 'id', this.elementId);
    }

    from(
      (this.window as any).YaAuthSuggest?.init(
        {
          client_id: 'f228bf181b464b98963b12cba832112b',
          response_type: 'token',
          redirect_uri: `${
            this.redirectUrl || `${this.url}/yandex-token`
          }?event=${this.elementId}`,
        },
        this.url,
        {
          view: 'button',
          parentId: this.elementId,
          buttonSize: 'xs',
          buttonView: 'iconBg',
          buttonTheme: 'light',
          buttonBorderRadius: '0',
          buttonIcon: 'ya',
          customBgColor: 'transparent',
          customBorderColor: 'transparent',
          customBgHoveredColor: 'transparent',
          customBorderHoveredColor: 'transparent',
          customBorderWidth: '0',
        }
      )
    )
      .pipe(switchMap(({ handler }: any) => handler()))

      .subscribe(({ access_token: accessToken, event }: any) => {
        if (event === this.elementId) {
          this.token.emit(accessToken);
        }
      });
  }
}
