<div style="display: flex; flex-direction: row; gap: 10px">
  <div style="width: 25px; height: 25px">
    <img style="width: 100%; width: 100%; object-fit: cover" [src]="getProductImage(item)" alt="product" />
  </div>
  <div style="
                          display: flex;
                          flex-direction: column;
                          flex: 1;
                          overflow: hidden;
                        ">
    <span style="font-size: 10px; opacity: 0.7">{{
      item?.sku
      }}</span>
    {{ item?.name }}
  </div>
  <div>{{ item?.stocks }}</div>
</div>