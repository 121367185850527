import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

export interface INavProductInfo {
    title: string;
    path: string;
}

@Component({
    selector: 'ts-nav-product-creation-tab',
    templateUrl: './nav-product-creation-tab.component.html',
    styleUrls: ['./nav-product-creation-tab.component.scss']
})
export class ProductCreationTabComponent {
    @Input()
    public id: string | null = null;

    @Output() public resetForm: EventEmitter<void> = new EventEmitter<void>();

    public links: INavProductInfo[] = [
        {
            title: 'Товар',
            path: '/hub/products/goods/create',
        },
        {
            title: 'Услуга',
            path: '/hub/products/service/create'
        },
        {
            title: 'Комплект',
            path: '/hub/products/set/create'
        },
    ];

    @HostListener('click')
    public onClick(): void {
        if(this.id) {
            this.resetForm.emit();
        }
    }

}
