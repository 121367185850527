import { Component, Inject } from '@angular/core';
import {
  ColumnConfig,
  ColumnSettings,
  EmployeeSettingsService,
  HubInterfaceTable, selectSettingsTableColumns, setSettingsTableColumns,
  TableColumnSettings
} from "@topseller/core";
import { Subject, take, takeUntil } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { Store } from "@ngrx/store";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { map } from "rxjs/operators";

@Component({
  selector: 'ts-table-settings-modal',
  templateUrl: './table-settings-modal.component.html',
  styleUrls: ['./table-settings-modal.component.scss'],
})
export class TableSettingsModalComponent {
  public settingsTable!: TableColumnSettings;

  public settingsColumns!: ColumnConfig;

  public columnNames!: string[];

  public tableHeaders!: ColumnSettings[];

  private destroy$: Subject<void> = new Subject<void>();

  private identifier!: string;

  private disabledColumns: number[] = [];

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<any>,
    private toastrService: ToastrService,
    private store: Store,
    private employeeSettingsService: EmployeeSettingsService,
  ) {
    this.form = this.fb.group({});
  }

  public ngOnInit() {
    this.setSettingsTable();

    this.sortingByOrder();

    this.canBeSortedColumns();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private sortingByOrder(): void {
    this.tableHeaders = Object.values(this.settingsColumns);
    this.tableHeaders.sort((a, b) => a.settings.order - b.settings.order);
  }

  public onDrop(event: CdkDragDrop<ColumnSettings>) {
    const currentIndex = event.currentIndex;
    const previousIndex = event.previousIndex;


    if(!(this.disabledColumns?.includes(event.currentIndex))) {
      const movedColumn = this.columnNames.splice(previousIndex, 1)[0];
      this.columnNames.splice(currentIndex, 0, movedColumn);
      this.columnNames.forEach((columnName, index) => {
        this.settingsColumns[columnName].settings.order = index;
      });
    }
  }

  private setSettingsTable() {
    if (!this.data.hubEntity) return;

    this.identifier = HubInterfaceTable[this.data.hubEntity];

    this.store.select(selectSettingsTableColumns(this.identifier)).pipe(
      takeUntil(this.destroy$),
      map((setting) => JSON.parse(setting))
    ).subscribe((data: TableColumnSettings) => {
      this.settingsTable = data;
      this.settingsColumns = data.columns;
      this.columnNames = Object.keys(data.columns).sort((a, b) => data.columns[a].settings.order - data.columns[b].settings.order);
    })
  }

  public close() {
    this.matDialogRef.close(false);
  }

  public save() {
    const body = {[this.identifier]: JSON.stringify((this.settingsTable))};

    this.employeeSettingsService.patchAppEmployeesettingSetvalues(body).pipe(
      take(1),
    ).subscribe({
      next: () => this.store.dispatch(setSettingsTableColumns({settings: body})),
    });
    this.matDialogRef.close();
  }

  public attach(columnName: string) {
    if (!this.settingsTable.columns[columnName].metadata.canBeHidden)
      return;

    const columnSettings: ColumnSettings = this.settingsColumns[columnName];

    const changeSettings = {
      ...columnSettings,
      settings: {
        ...columnSettings.settings,
        sticky: !columnSettings.settings.sticky
      }
    }

    this.updateSettingsTable(columnName, changeSettings);
  }

  public toggleVisibility(columnName: string) {
    if (!this.settingsTable.columns[columnName].metadata.canBeHidden)
      return;

    const columnSettings: ColumnSettings = this.settingsColumns[columnName];

    const changeSettings = {
      ...columnSettings,
      settings: {
        ...columnSettings.settings,
        hidden: !columnSettings.settings.hidden
      }
    };

    this.updateSettingsTable(columnName, changeSettings);
  }

  private updateSettingsTable(columnName: string, changeColumnSettings: ColumnSettings) {
    this.settingsColumns = {
      ...this.settingsColumns,
      [columnName]: changeColumnSettings
    }

    this.settingsTable = {
      ...this.settingsTable,
      columns: {
        ...this.settingsColumns
      }
    }
  }

  private canBeSortedColumns() {
    this.columnNames.forEach((name) => {
      if(!this.settingsColumns[name].metadata.canBeOrdered) {
        this.disabledColumns?.push(this.settingsColumns[name].settings.order);
      }
    })
  }
}
