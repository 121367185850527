import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';

import { BaseInputModule } from '@topseller/ui/base-input';

import { ExtraFieldsComponent } from './extra-fields.component';
import {ToggleSwitchModule} from "@topseller/ui/toggle-switch";
import { ControlErrorModule } from "@topseller/common/control-error/control-error.module";
import { InputDateTimeModule } from "@topseller/ui/input-date-time";
import { InputLinkComponent } from "@topseller/ui/input-link";
import { InputCurrencyModule } from "@topseller/ui/input-currency/input-currency.module";

@NgModule({
  imports: [
    CommonModule,
    NgxMaskDirective,
    FormsModule,
    ReactiveFormsModule,
    BaseInputModule,
    ToggleSwitchModule,
    ControlErrorModule,
    InputDateTimeModule,
    InputLinkComponent,
    InputCurrencyModule,
  ],
  declarations: [ExtraFieldsComponent],
  exports: [ExtraFieldsComponent],
  providers: [],
})
export class ExtraFieldsModule {}
