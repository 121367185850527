import { createSelector } from '@ngrx/store';

export const selectCommonFeature = (state: any) => state.application;

export const selectShowLoader = createSelector(
  selectCommonFeature,
  (state) => state?.showLoader
);

export const selectCurrentProductInfo = createSelector(
  selectCommonFeature,
  (state) => state?.currentProductInfo
);

export const selectCurrentTheme = createSelector(
  selectCommonFeature,
  (state) => state?.currentTheme
)
