import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TsCheckboxModule } from '../checkbox';

import { TsSelectOptionComponent } from './option.component';
import { TsSelectOptionDirective } from './option.directive';

@NgModule({
  declarations: [TsSelectOptionComponent, TsSelectOptionDirective],
  imports: [CommonModule, TsCheckboxModule],
  exports: [TsSelectOptionComponent, TsSelectOptionDirective],
})
export class TsSelectOptionModule {}
