<form [formGroup]="form" (ngSubmit)="save()" class="d-flex flex-column gap-3 ts-page__content">
  <ts-handbook-item-control-bar [handbooksSettings]="'Статья ДДС'" [editItemRole]="editItemRole" />

  <ts-scroll>
    <ts-form-controls-card label="Общее" [isExpandable]="true">
      <div slot="card-actions">
        <ts-role-access-wrapper [requiredRole]="deleteItemRole">
          <button
            *ngIf="showHeaderBtns$ | async"
            type="button"
            class="ts-btn error ts-btn-bezeled ts-btn-md"
            (click)="deleteSource()"
          >
            <span class="ts-icon ts-icon-actions-Trash"></span>
            <span class="">Удалить статью</span>
          </button>
        </ts-role-access-wrapper>
      </div>

      <div class="form-controls-card__content">
        <ts-role-access-wrapper [requiredRole]="editItemRole">
          <div class="ts-general-controls-grid">
            <ts-select
              label="Группа статей ДДС"
              [identityMatcher]="recordIdentityMatcher"
              [stringify]="recordStringify"
              formControlName="cashflowSourceGroup"
            >
              <ts-entity-list [queryFn]="cashflowGroupQueryFn" />
            </ts-select>
          </div>

          <div class="col-9 col-xl-12">
            <app-title
              formControlName="note"
              title="Примечание"
              [multirow]="true"
            />
          </div>
        </ts-role-access-wrapper>
      </div>
    </ts-form-controls-card>
  </ts-scroll>
</form>

<!-- Confirm Dialogs -->

<app-confirm-dialog *ngIf="showDialog" (selection)="onDeactivateSelection($event)"></app-confirm-dialog>

