<div class="calendar-row">
  <div
    *ngFor="let day of weekDays; let i=index"
    class="day-cell weekday"
    [ngClass]="{'day-off':i>4}"
    [textContent]="day"
  ></div>
</div>

<div *ngFor="let calendarRow of month | calendarMonth" class="calendar-row">
  <div
    *ngFor="let calendarDay of calendarRow"
    class="day-cell item"
    [class.day-cell_today]="itemIsToday(calendarDay)"
    [class.day-cell_dimmed]="!sameMonth(calendarDay)"
    [class.day-cell_active]="daySame(calendarDay)"
    (click)="onItemClick(calendarDay)"
  >
    {{ calendarDay.day }}
  </div>
</div>
