import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { TableHeaderService } from "../table/table-settings-service";
import { map, Observable } from "rxjs";
import { TableHeader } from "@topseller/ui";
import { selectSettingsTableColumns, TableColumnSettings } from "@topseller/core";

@Injectable()
export class FromStoreTableHeaderService implements TableHeaderService {
    constructor(private store:Store) {
    }

    getTableHeaders(tableIdentifier: string, defaultHeaders: TableHeader[]): Observable<TableHeader[]> {
        return this.store.select(selectSettingsTableColumns(tableIdentifier)).pipe(
            map(setting => {
                if (!setting || setting === '') {
                    return defaultHeaders;
                }

                const parsedSetting = JSON.parse(setting) as unknown as TableColumnSettings;
                const { sort, columns } = parsedSetting;

                return Object.entries(columns)
                    .filter(([, columnSettings]) => !columnSettings.settings.hidden)
                    .sort(([, a], [, b]) => a.settings.order - b.settings.order)
                    .map(([key, columnSettings]): TableHeader => ({
                        key: key,
                        label: columnSettings.metadata.label,
                        type: columnSettings.metadata.type,
                        width: columnSettings.settings.width,
                        isSortable: columnSettings.metadata.canBeSorted,
                        isResizable: columnSettings.metadata.canBeResized,
                        isPinned: columnSettings.settings.sticky,
                        isPinnable: columnSettings.metadata.canBeSticked,
                        textAlign: columnSettings.metadata.textAlign as 'left' | 'right' | 'center',
                        isSortedBy: sort.target === key,
                        sortDir: sort.target === key ? sort.order : undefined,
                        minWidth: columnSettings.metadata.minWidth,
                      filter:columnSettings.metadata.filters
                    }));
            })
        );
    }
}
