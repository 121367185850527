<div class="ts-chip">
  <span>
    {{ stringify(value) | uppercase }}
  </span>

  <span
    class="trailing-icon ts-icon ts-icon-actions-Close cursor-pointer"
    *ngIf="canRemove"
    (click)="remove($event)"
  ></span>
</div>
