import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TsDropdownModule } from '@topseller/ui/dropdown';

import { HubCommonModule } from '../../common.module';

import { MultiplyPipe } from './linked-document/amount-fraction.pipe';
import { LinkedDocumentComponent } from './linked-document/linked-document.component';
import { LinkedDocumentsComponent } from './linked-documents.component';
import { LinkedDocumentEffects } from './store';
import {
  linkedDocumentsReducer,
  LINKED_DOCUMENTS_FEATURE_KEY,
} from './store/linked-documents.reducer';
import {CurrencyFormatModule} from "@topseller/ui/pipes/currency-format";
import { RoleAccessWrapperComponent } from "@topseller/ui/role-access-wrapper";

@NgModule({
    imports: [
        CommonModule,
        HubCommonModule,
        TsDropdownModule,
        StoreModule.forFeature(
            LINKED_DOCUMENTS_FEATURE_KEY,
            linkedDocumentsReducer
        ),
        EffectsModule.forFeature([LinkedDocumentEffects]),
        CurrencyFormatModule,
        RoleAccessWrapperComponent,
    ],
  declarations: [
    LinkedDocumentsComponent,
    LinkedDocumentComponent,
    MultiplyPipe,
  ],
  exports: [LinkedDocumentsComponent, LinkedDocumentComponent],
})
export class TsLinkedDocumentsModule {}
