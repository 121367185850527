<ts-base-input
  #parent="activeZone"
  (activeZoneChange)="onActiveZone($event)"
  [dropdown]="dropdownTemplate"
  [show]="isVisible"
  mask="d0.M0.0000 Hh:m0:s0"
  [value]="computedValue"
  [label]="label"
  [readonly]="readonly || disabled"
  [class.disabled]="disabled"
  [disabled]="disabled"
  trailingIcon="ts-icon-time-calendar"
  (onClear)="clear()"
  [cleaner]="cleaner"
/>

<ng-template #dropdownTemplate>
  <ts-date-time-selector
    [value]="value"
    [activeZoneParent]="parent"
    (onSelect)="onDayTimeSelect($event)"
  ></ts-date-time-selector>
</ng-template>
