import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TsTableCellDirective } from './table-cell.directive';
import { TsTableDirective } from './table.directive';
import { TsTableHeaderComponent } from './table-header';
import { TableCellTemplateDirective } from './table-cell-template.directive';
import { CopyableCellComponent } from './copyable-cell/copyable-cell.component';

@NgModule({
  declarations: [
    TsTableCellDirective,
    TsTableDirective,
    TsTableHeaderComponent,
    TableCellTemplateDirective,
    CopyableCellComponent,
  ],
  imports: [CommonModule],
  exports: [
    TsTableCellDirective,
    TsTableDirective,
    TsTableHeaderComponent,
    TableCellTemplateDirective,
    CopyableCellComponent,
  ],
  providers: [],
})
export class TsTableModule {}
