import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { TsAbstractNullableControl } from '@topseller/cdk/abstract';
import { DATA_LIST_HOST } from '@topseller/ui';

import { DataListHost, IdentityMatcher, StringHandler } from '../../types';
import { defaultIdentityMatcher, defaultStringHandler } from "@topseller/ui/utils";

export function getNativeFocused(documentRef: Document): Element | null {
  if (!documentRef.activeElement?.shadowRoot) {
    return documentRef.activeElement;
  }

  let element = documentRef.activeElement.shadowRoot.activeElement;

  while (element?.shadowRoot) {
    element = element.shadowRoot.activeElement;
  }

  return element;
}

@Component({
  selector: 'ts-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DATA_LIST_HOST,
      useExisting: forwardRef(() => DropdownButtonComponent),
    },
  ],
})
export class DropdownButtonComponent<T>
  extends TsAbstractNullableControl<T>
  implements DataListHost<T>
{
  private localIsVisible = false;

  @ViewChild('dropdown', { read: ElementRef })
  private dropdownContainer!: ElementRef;

  @ViewChild('textfield', { read: ElementRef })
  private readonly textfield?: ElementRef<HTMLInputElement>;

  @Input() public placeholder = '';

  @Input()
  public stringify: StringHandler<T> = defaultStringHandler;

  @Input()
  public identityMatcher: IdentityMatcher<T> = defaultIdentityMatcher;

  @Output()
  public readonly focusChanged = new EventEmitter<boolean>();

  @HostListener('click')
  public onClick() {
    this.toggleDropdown();
  }

  @HostBinding('class.disabled')
  @Input()
  public readonly = false;

  // Эти события слушать обязательно для корректного отслеживания focused
  @HostListener('focusin', ['true'])
  @HostListener('focusout', ['false'])
  public onFocused(focused: boolean) {
    this.focusChanged.emit(focused);
  }

  @HostBinding(`class.focused`)
  public get focused(): boolean {
    const node = this.elementRef.nativeElement;
    const documentRef = node.ownerDocument;

    const element = getNativeFocused(documentRef);
    return !!element && node.contains(element);
  }

  public set isVisible(val: boolean) {
    this.localIsVisible = val;
  }

  public get isVisible(): boolean {
    return this.localIsVisible;
  }

  public get computedValue(): string {
    return this.value === null ? `` : this.stringify(this.value) || ` `;
  }

  public get hasValue(): boolean {
    return !!this.value;
  }

  public get labelRaised(): boolean {
    return this.focused || this.hasValue;
  }

  get hasCleaner(): boolean {
    return this.hasValue;
  }

  constructor(
    @Self() @Optional() ngControl: NgControl,
    private elementRef: ElementRef,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(ngControl, changeDetectorRef);
  }

  public stringified(value: T): string {
    return this.stringify(value);
  }

  public clear(): void {
    if (this.textfield) {
      this.textfield.nativeElement.value = ``;
    }

    this.updateValue(null);
  }

  public handleOption(option: T): void {
    this.updateValue(option);
    this.isVisible = false;
  }

  public onActiveZone(active: boolean) {
    if (!active && this.isVisible) {
      this.isVisible = false;
    }
  }

  public toggleDropdown() {
    if(!this.disabled) {
      this.isVisible = !this.isVisible;
    }
  }
}
