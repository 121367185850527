import { Injectable } from '@angular/core';
import { Project } from '../../data/model/project';
import { Counterparty, Organization, Warehouse } from '@topseller/core';

import { ListQueryFn } from '../types';
import {
  CashflowSource,
  CounterpartyService,
  OrganizationService,
  Product,
  ProductService,
  ProjectService,
  SourceService,
  Status,
  StatusApiService,
  WarehouseService,
  BrandService,
  CountryService,
  UnitService,
  Brand,
  Country,
  Unit,
  CashflowSourceService,
  Source,
} from '../../data';
import { PaginatedModificationAttribute } from "../../data/model/paginated-modification-attribute";

@Injectable()
export class CatalogsDataService {
  public companyListFn: ListQueryFn<Organization> = ({
    limit = 0,
    offset = 0,
    search = '',
  }) => this.organizationService.getAppOrganizationIndex(limit, offset, search);

  public partnerListFn: ListQueryFn<Counterparty> = ({
    limit = 0,
    offset = 0,
    search = '',
  }) => this.counterpartyService.getAppCounterpartyIndex(limit, offset, search);

  public storageListFn: ListQueryFn<Warehouse> = ({
    limit = 0,
    offset = 0,
    search = '',
  }) => this.storageService.getAppWarehouseIndex(search, limit, offset);

  public projectListFn: ListQueryFn<Project> = ({
    limit = 0,
    offset = 0,
    search = '',
  }) => this.projectService.getAppProjectIndex(search, limit, offset);

  public productListFn: ListQueryFn<Product> = ({
    limit = 0,
    offset = 0,
    search = '',
  }) => this.productService.getAppProductIndex(limit, offset, search);

  public countryListFn: ListQueryFn<Country> = ({
    limit = 0,
    offset = 0,
    search = ''
  }) => this.countryService.getAppCountryIndex(limit, offset, search);

  public brandListFn: ListQueryFn<Brand> = ({ limit, offset, search }) =>
    this.brandService.getBrands(limit, offset, search);

  public parameterListFn: ListQueryFn<PaginatedModificationAttribute> = ({ limit, offset, search }) =>
    this.productService.getAppModificationattributeIndex(limit, offset, search);

  public unitListFn: ListQueryFn<Unit> = ({ limit = 0, offset = 0, search }) =>
    this.unitService.getAppUnitIndex(limit, offset, search);

  public statusListFn: ListQueryFn<Status> = ({ limit = 0, offset = 0 }) =>
    this.statusService.getAppStatusIndex(limit, offset);

  public sourceListFn: ListQueryFn<Source> = ({
    limit = 0,
    offset = 0,
    search = '',
    filter,
  }) => this.sourceService.getAppSourceIndex(limit, offset, search, filter);

  public goodListFn: ListQueryFn<Product> = ({
    limit = 0,
    offset = 0,
    search = '',
  }) => this.productService.getAppProductIndex(limit, offset, search);

  public cashflowSourceListFn: ListQueryFn<CashflowSource> = ({
    limit = 0,
    offset = 0,
    search = '',
  }) =>
    this.cashflowSourceService.getAppCashflowsourceIndex(limit, offset, search);

  constructor(
    private organizationService: OrganizationService,
    private counterpartyService: CounterpartyService,
    private productService: ProductService,
    private storageService: WarehouseService,
    private projectService: ProjectService,
    private countryService: CountryService,
    private brandService: BrandService,
    private unitService: UnitService,
    private statusService: StatusApiService,
    private sourceService: SourceService,
    private cashflowSourceService: CashflowSourceService
  ) {}
}
