<div class="time-wrapper" (activeZoneChange)="onActiveZone($event)">
  <div class="time-wrapper__header" *ngIf="title">{{ title }}</div>
  <div class="content">
    <button
      class="ts-btn primary ts-btn-filled ts-btn-sm w-100 btn-time-select"
      (click)="setEdit(true)"
      *ngIf="!isEdit; else edit"
    >
      {{ value | number : '2.0-0' }}
    </button>

    <ng-template #edit>

      <button class="ts-btn btn-grey ts-btn-bezeled ts-btn-sm ts-btn-icon w-100" (click)="moveTo(-skip)">
        <span class="ts-icon ts-icon-arrow-Expand_up_double"></span>
      </button>

      <button class="ts-btn btn-grey ts-btn-bezeled ts-btn-sm ts-btn-icon w-100 my-2" (click)="moveTo(-1)">
        <span class="ts-icon ts-icon-arrow-Expand_up"></span>
      </button>


      <div class="time-column" [style.height.px]="blockHeight">
        <div #container>
          <div
            *ngFor="let item of items; index as idx"
            class="cell"
            [class.current]="idx === offset + 1"
            [ngStyle]="{
              'height.px': height,
              'margin-top.px': margin,
              'margin-bottom.px': margin
            }"
          >
            {{ item | number : '2.0-0' }}
          </div>
        </div>
      </div>


      <button class="ts-btn btn-grey ts-btn-bezeled ts-btn-sm ts-btn-icon w-100 my-2" (click)="moveTo(1)">
        <span class="ts-icon ts-icon-arrow-Expand_down"></span>
      </button>

      <button class="ts-btn btn-grey ts-btn-bezeled ts-btn-sm ts-btn-icon w-100" (click)="moveTo(skip)">
        <span class="ts-icon ts-icon-arrow-Expand_down_double"></span>
      </button>

    </ng-template>
  </div>
</div>
