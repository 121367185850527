import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoadableSelector } from "../../../loadable-selector";
import { TableHeader } from "@topseller/ui";
import { FormControl } from "@angular/forms";
import { Product } from "../../../../data";
import { CatalogsDataService } from "../../../services";

@Component({
  selector: 'ts-trade-document-table-empty-row',
  templateUrl: './trade-document-table-empty-row.component.html',
  styleUrls: ['./trade-document-table-empty-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TradeDocumentTableEmptyRowComponent extends LoadableSelector {
  @Input() tableHeaders: TableHeader[] = [];
  @Input() selectedProductControl = new FormControl<Product | null>(null);

  constructor(catalogsDataService: CatalogsDataService) {
    super(catalogsDataService);
  }

  get firstColumnWidth(): number {
    return +this.tableHeaders[0]!.width! + 24;
  }
}


