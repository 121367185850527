import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorsSelectorComponent } from './colors-selector.component';
import { TsDropdownModule } from '@topseller/ui/dropdown';
import { ActiveZoneModule } from '@topseller/ui/active-zone';
import { TsSelectModule } from '@topseller/ui/select';
import { TsSelectOptionModule } from '@topseller/ui/select-option';

@NgModule({
  declarations: [ColorsSelectorComponent],
  imports: [
    CommonModule,
    TsDropdownModule,
    ActiveZoneModule,
    TsSelectModule,
    TsSelectOptionModule,
  ],
  providers: [],
  exports: [ColorsSelectorComponent],
})
export class ColorsSelectorModule {}
