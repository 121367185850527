import { createAction, props } from '@ngrx/store';
import { CurrentProductInfo } from "./reducer";

export const setShowLoader = createAction(
  '[COMMON] Set Show Loader',
  props<{ showLoader: boolean }>()
);

export const setCurrentProductInfo = createAction(
  '[COMMON] Set Current Product Info',
  props<{ currentProductInfo?: CurrentProductInfo }>()
);

export const loadCurrentTheme= createAction(
  '[COMMON] Load Current Theme'
)

export const setTheme = createAction(
  '[COMMON] Set Theme',
  props<{ theme: string }>()
);

export const toggleTheme = createAction(
  '[COMMON] Toggle Theme'
);

export const updateTableColumnWidth= createAction(
    '[COMMON] updateTableColumnWidth',
    props<{columnKey: string, tableIdentifier: string, width: number}>()
)

export const updateTableSortTarget= createAction(
    '[Auth] updateTableSortTarget',
    props<{columnKey: string, tableIdentifier: string, sortDirection: string}>()
)
