import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { TsAbstractNullableControl } from '@topseller/cdk/abstract';
import { DATA_LIST_HOST } from '@topseller/ui';

import { DataListHost, IdentityMatcher } from '../../types';
import { ColorsMap, getColorByEnumValue } from '../../../data/model/colors';
import { defaultIdentityMatcher } from "@topseller/ui/utils";

export function getNativeFocused(documentRef: Document): Element | null {
  if (!documentRef.activeElement?.shadowRoot) {
    return documentRef.activeElement;
  }

  let element = documentRef.activeElement.shadowRoot.activeElement;

  while (element?.shadowRoot) {
    element = element.shadowRoot.activeElement;
  }

  return element;
}

@Component({
  selector: 'ts-colors-selector',
  templateUrl: './colors-selector.component.html',
  styleUrls: ['./colors-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DATA_LIST_HOST,
      useExisting: forwardRef(() => ColorsSelectorComponent),
    },
  ],
})
export class ColorsSelectorComponent
  extends TsAbstractNullableControl<string>
  implements DataListHost<string>
{
  private localIsVisible = false;

  colorsMap = ColorsMap;

  @Input() public placeholder = '';

  @Input()
  public identityMatcher: IdentityMatcher<string> = defaultIdentityMatcher;

  @Output()
  public readonly focusChanged = new EventEmitter<boolean>();

  @HostListener('click')
  public onClick() {
    this.toggleDropdown();
  }

  // Эти события слушать обязательно для корректного отслеживания focused
  @HostListener('focusin', ['true'])
  @HostListener('focusout', ['false'])
  public onFocused(focused: boolean) {
    this.focusChanged.emit(focused);
  }

  @HostBinding(`class.focused`)
  public get focused(): boolean {
    const node = this.elementRef.nativeElement;
    const documentRef = node.ownerDocument;

    const element = getNativeFocused(documentRef);
    return !!element && node.contains(element);
  }

  public set isVisible(val: boolean) {
    this.localIsVisible = val;
  }

  public get isVisible(): boolean {
    return this.localIsVisible;
  }

  public get computedValue(): string {
    return this.value === null
      ? this.colorsMap.celestial_blue
      : getColorByEnumValue(this.value);
  }

  public get hasValue(): boolean {
    return !!this.value;
  }

  public get labelRaised(): boolean {
    return this.focused || this.hasValue;
  }

  get hasCleaner(): boolean {
    return this.hasValue;
  }

  constructor(
    @Self() @Optional() ngControl: NgControl,
    private elementRef: ElementRef,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(ngControl, changeDetectorRef);
  }

  public clear(): void {
    this.updateValue(null);
  }

  public handleOption(option: string): void {
    this.updateValue(option);
    this.isVisible = false;
  }

  public onActiveZone(active: boolean) {
    if (!active && this.isVisible) {
      this.isVisible = false;
    }
  }

  public toggleDropdown() {
    this.isVisible = !this.isVisible;
  }
}
