import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent, DialogData } from '../guards/confirm-dialog/confirm-dialog.component';

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog) {
  }

  openConfirmDialog(data: DialogData): MatDialogRef<ConfirmDialogComponent, boolean> {
    return this.dialog.open(ConfirmDialogComponent, {
      data,
      backdropClass: 'ts-modal-backdrop',
      minHeight: 'auto',
      panelClass: ['ts-modal-panel','ts-modal-panel-lg' ],
      width: '470px',
      id: 'ts-modal',
    });
  }
}
