import {
  animate,
  state,
  style,
  transition,
  trigger,
  AnimationEvent,
} from '@angular/animations';
import { Component, TemplateRef, Type } from '@angular/core';
import { Subject } from 'rxjs';

import { Sidebar, SidebarOptions } from './sidebar.types';

@Component({
  selector: 'ts-sidebar-container',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('state', [
      state(
        'void, hidden',
        style({ transform: 'translateX(100%)', opacity: 0 })
      ),
      state('visible', style({ transform: 'translateX(0)', opacity: 1 })),
      transition('* => visible', animate('200ms linear')),
      transition('* => hidden', animate('200ms linear')),
    ]),
  ],
})
export class SidebarComponent<T extends Sidebar<any>> {
  private sidebarOpen = false;
  private result: any;

  public actionsTemplateRef$ = new Subject<TemplateRef<any>>();
  public titleTemplateRef$ = new Subject<TemplateRef<any>>();
  public visibility = 'visible';
  public childComponentType!: Type<T>;
  public options?: SidebarOptions<T>;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public closeSidebar: (result?: any) => void = () => {};

  public get open(): boolean {
    return this.sidebarOpen;
  }

  public onCreate(componentInstance: any) {
    componentInstance.resolveSidebarWith = (result?: any) => {
      this.result = result;
      this.visibility = 'hidden';
      this.sidebarOpen = false;
    };
    if (this.options && this.options.locals) {
      for (const key in this.options.locals) {
        componentInstance[key] = this.options.locals[key] as T[Extract<
          keyof T,
          string
        >];
      }
    }
  }

  public animationDone(event: AnimationEvent) {
    if (event.toState === 'hidden') {
      this.closeSidebar(this.result);
    }
  }

  public close(): void {
    this.sidebarOpen = false;
    this.visibility = 'hidden';
  }

  public registerActionsTemplate<T>(actionsTemplateRef: TemplateRef<T>): void {
    this.actionsTemplateRef$.next(actionsTemplateRef);
  }

  public registerTitleTemplate(titleTemplateRef: TemplateRef<any>): void {
    this.titleTemplateRef$.next(titleTemplateRef);
  }
}
