import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HubEntity } from "../../data";

@Injectable()
export abstract class CrudDataService {
  public abstract modalConfig: {
    entity: HubEntity,
    relatedEntities?: HubEntity[]
  };

  public abstract delete(ids: string[]): Observable<unknown>;
}

