import { Environment } from './interface';

export const environment: Environment = {
  production: true,
  apiUrl: 'https://app.topseller.ru/api/v1/',
  apiKey: '',
  wbAdsUrl: 'https://wbads.topseller.ru',
  hubUrl: 'https://app.topseller.ru',
  dropshipUrl: 'https://ds.topseller.ru/api',
  aiUrl: 'https://ai-tools.topseller.ru',
  connectUrl: 'https://connect.topseller.ru/apps/hub',
  pimUrl: 'https://pim.topseller.ru/hub',
  journalUrl: 'https://journal.topseller.ru',
  rpUrl: 'https://repricer-api.topseller.ru',
  eaUrl: 'https://externalytics.topseller.ru/v1',
  rnpUrl: 'https://rnp.dev.topseller.ru',
  roistApiKey: '015460b1fed5d50258430c5dcbb300dd',
  roistProjectId: 261361,
  frontendUrl: 'https://app.topseller.ru',
};
