import {Directive, Input, TemplateRef} from "@angular/core";

@Directive(
  {
    selector: '[tsViewMode]',
    standalone: true
  }
)
export class ViewModeDirective {
  constructor(public tpl: TemplateRef<any>) {
  }

  @Input() tsViewMode: 'view' | 'edit' = 'view';
}
