import { FormControl, FormGroup, Validators } from "@angular/forms";

export function createExpenseFormGroup(): FormGroup {
  return new FormGroup({
    id: new FormControl(''),
    source: new FormControl(null),
    expenseSpreadType: new FormControl('amount'),
    expensedAt: new FormControl(new Date().toISOString(), [Validators.required]),
    amount: new FormControl(null, [Validators.required]),
  });
}

