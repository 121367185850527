import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, Observable, of, share, switchMap, take, tap } from 'rxjs';

import {
  logout,
  selectEmployee,
  selectIsActive,
  selectIsLoggedIn,
  setAccountSelectLogin,
  setAuthRestrictions,
  setAuthTokenLogin, setSubscriptions,
  UserState
} from '../store';
import { AccountSelectQuery, AccountSelectResponse, AuthTokenResponse, LoginQuery, Restrictions, User } from '../model';

import { LocalStorageService } from './storage.service';
import { AUTH_TOKEN_NAME, REFRESH_TOKEN_NAME } from '../constants';
import { BASE_PATH } from '../tokens';
import { Login } from '../model/login';
import { hasRole } from "../utils";
import { SecurityService } from './security.service';

@Injectable()
export class AuthService {
  protected basePath = 'http://denis.dev.topseller.ru';
  private temporaryAuth = false;

  constructor(
    private readonly localStorageService: LocalStorageService,
    private http: HttpClient,
    private store: Store<UserState>,
    @Optional() @Inject(BASE_PATH) basePath: string,
    private securityService: SecurityService,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
  }

  public login(params: LoginQuery): Observable<AccountSelectResponse> {
    this.temporaryAuth = params.temporaryAuth!;
    return this.http
      .post<AccountSelectResponse>(`${this.basePath}security/login`, params)
      .pipe(
        tap((response: AccountSelectResponse) => {
          this.setTempToken(response);
        }),
        share()
      );
  }

  public signUp(params: User): Observable<AuthTokenResponse> {
    return this.http
      .post<AuthTokenResponse>(`${this.basePath}security/signup`, params)
      .pipe(
        tap((response: AuthTokenResponse) => this.setToken(response)),
        share()
      );
  }

  public logout(): void {
    this.setToken(null);
  }

  public refresh(refreshToken: string): Observable<string> {
    const params = new HttpParams({fromObject: {refreshToken}});
    return this.http
      .post<AuthTokenResponse>(`${this.basePath}security/refresh`, params)
      .pipe(
        tap((response: AuthTokenResponse) => this.setToken(response)),
        map(({token}: AuthTokenResponse) => token!)
      );
  }

  public getCode(params: Login): Observable<void> {
    return this.http.post<void>(`${this.basePath}security/login/code`, params);
  }

  public isLoggedIn(): Observable<boolean> {
    return this.store.select(selectIsLoggedIn).pipe(
      take(1),
      concatMap((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          return of(isLoggedIn);
        }
        return this.http
          .get<Restrictions>(`${this.basePath}security/restrictions`)
          .pipe(
            switchMap((authData: Restrictions) => {
              this.store.dispatch(setAuthRestrictions({ authData }));
              return this.securityService.getSubscriptions()
                .pipe(
                  tap((apiResponse) => this.store.dispatch(setSubscriptions({ subscriptions: apiResponse })))
                );
            }),
            map(() => true),
            catchError(() => {
              this.setToken(null);
              return of(false);
            })
          );
      })
    );
  }

  public isSignedUp(): Observable<boolean> {
    return this.store.select(selectIsActive).pipe(
      take(1),
      map((isSignedUp: boolean) => {
        if (isSignedUp) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  public select(params: AccountSelectQuery): Observable<AuthTokenResponse> {
    return this.http
      .post<AuthTokenResponse>(`${this.basePath}security/select`, params)
      .pipe(tap((response: AuthTokenResponse) => this.setToken(response)));
  }

  public setTempToken(response: AccountSelectResponse | null): void {
    if (response) {
      const {items, token, refreshToken, user} = response;
      this.localStorageService.set(AUTH_TOKEN_NAME, token!, this.temporaryAuth);

      if (refreshToken) {
        this.localStorageService.set(
          REFRESH_TOKEN_NAME,
          refreshToken,
          this.temporaryAuth
        );
      }

      this.store.dispatch(
        setAccountSelectLogin({
          authData: {items, token, refreshToken, user},
        })
      );
    }
  }

  public setToken(response: AuthTokenResponse | null): void {
    if (response) {
      const {account, employee, token, refreshToken, settings, user} =
        response;

      this.localStorageService.set(AUTH_TOKEN_NAME, token!, this.temporaryAuth);

      if (refreshToken) {
        this.localStorageService.set(
          REFRESH_TOKEN_NAME,
          refreshToken,
          this.temporaryAuth
        );
      }

      this.store.dispatch(
        setAuthTokenLogin({
          authData: {account, employee, token, refreshToken, settings, user},
        })
      );
    } else {
      sessionStorage.clear();
      this.store.dispatch(logout());

      this.removeAuthTokensFromLocalStorage();
    }
  }

  public removeAuthTokensFromLocalStorage(): void {
    this.localStorageService.remove(AUTH_TOKEN_NAME);
    this.localStorageService.remove(REFRESH_TOKEN_NAME);
  }


  public employeeHasRole(role: string | string[] | undefined | null): Observable<boolean> {
    return this.store.select(selectEmployee).pipe(
      map(employee => {
        return hasRole(role, employee.roles);
      })
    );
  }
}
