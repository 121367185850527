import {
  Component,
  EventEmitter,
  forwardRef, HostBinding,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleSwitchComponent),
      multi: true,
    },
  ],
})
export class ToggleSwitchComponent implements ControlValueAccessor {
  @Input() label?: string;
  @Input() onlyActivation = false;

  @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Input() value = false;

  @HostBinding('class.hasBackground')
  @Input() hasBackground = false;

   @HostBinding('class.disabled')
   @Input() disabled = false;

  @Input()
  @HostBinding('class.isDisabled')
  isDisabled = false;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (value: boolean) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = () => {};


  writeValue(value: boolean = false): void {
    this.value = value;
  }
  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
