import { EMPTY, Observable, of } from "rxjs";
import { TableHeader } from "@topseller/ui";
import { Injectable } from "@angular/core";

/**
 * Базовый интерфейс сервиса для управления настройками колонок таблиц
 * */
@Injectable()
export class TableSettingsService {

    updateColumnWidth(columnKey: string, tableIdentifier: string, width: number): void{
      // implement in child if needed
    }


    updateSortTarget(columnKey: string, tableIdentifier: string, sortDirection: string): void{
      // implement in child if needed
    }


    getDefaultSort(tableIdentifier: string): Observable<{ columnKey: string, sortDirection: string }>{
      return EMPTY;
    }
}


export interface TableHeaderService {
    getTableHeaders(tableIdentifier: string, defaultHeaders: TableHeader[]): Observable<TableHeader[]>;
}
