import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Brand } from './../model/brand';

import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';

import { Paginated } from '../model/paginated';
import { Record } from "@topseller/core";

@Injectable()
export class BrandService {
  protected basePath = 'http://denis.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * Список брендов
   * Список брендов
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param filter Коллекция фильтров
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBrands(
    limit?: number,
    offset?: number,
    search?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<Brand>> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }
    if (search) {
      queryParameters = queryParameters.append('search', <string>search);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<Paginated<Brand>>(
      'get',
      `${this.basePath}/api/v1/brands`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
