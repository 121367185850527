import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('show') display!: boolean;
  @Output() selection = new EventEmitter();

  @Input() title = 'Сохранить изменения?';
  @Input() showCancelBtn = true;

  constructor(private matDialogRef: MatDialogRef<any>) {}

  selected(value: string) {
    this.selection.emit(value);
    this.display = false;
    this.matDialogRef.close(value);
  }
}
