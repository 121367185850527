import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LinkedDocument } from '../../../../data';

import {
  LinkedDocumentState,
  LINKED_DOCUMENTS_FEATURE_KEY,
} from './linked-documents.reducer';

export const selectLinkedDocumentFeature =
  createFeatureSelector<LinkedDocumentState>(LINKED_DOCUMENTS_FEATURE_KEY);

export const selectLinkedDocuments = createSelector(
  selectLinkedDocumentFeature,
  ({ items }: LinkedDocumentState) => items as LinkedDocument
);
