import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BASE_PATH } from '../tokens';
import { User } from '../model';

@Injectable()
export class UserService {
  protected basePath = 'http://denis.dev.topseller.ru';
  private ownerSections = [
    'page',
    'dashboard',
    'counterparty',
    'department',
    'employee',
    'expense',
    'order',
    'organization',
    'product',
    'product-group',
    'project',
    'purchase',
    'warehouse',
    'product-stock',
    'transaction',
  ];

  private currentUser!: User;
  // authSub?: Subscription;

  constructor(
    private readonly http: HttpClient, // private readonly localStorageService: LocalStorageService // private authService: AuthService
    @Optional() @Inject(BASE_PATH) basePath: string
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    // this.authSub = this.authService.user$.subscribe(user => {
    //   this.setUser(user);
    // })
  }

  // public getUser(): User {
  //   if (!this.currentUser) {
  //     this.initUser();
  //   }
  //   return this.currentUser;
  // }

  // setUser(user: User | null) {
  //   if (user) {
  //     this.currentUser = user;
  //     this.localStorageService.set('user', this.currentUser);
  //     // this.initUser()
  //   } else {
  //     localStorage.removeItem('user');
  //   }
  // }

  // initUser(): void {
  //   console.log('INIT USER');
  //   let user = this.localStorageService.get('user');
  //   if (!user) {
  //     // this.authService.logout();
  //     return;
  //   }
  //   this.currentUser = user;
  // }

  signup(user: User): any {
    return this.http.post(`${this.basePath}security/signup`, user);
  }

  confirm(user: User): any {
    return this.http.post(`${this.basePath}security/signup/confirm`, user);
  }

  requestCode(user: User): any {
    return this.http.post(`${this.basePath}security/signup/code`, user);
  }

  // updateProfile(user: User): any {
  //   return this.http
  //     .patch<User>(`${environment.apiUrl}security/profile`, user)
  //     .pipe(tap(this.setUser.bind(this)));
  // }

  // canAccess(url: string) {
  //   let section = '';
  //   url = url.substring(url.lastIndexOf('/') + 1);

  //   switch (url) {
  //     case 'page':
  //     case 'dashboard':
  //       section = url;
  //       break;
  //     case 'counterparties':
  //       section = 'counterparty';
  //       break;
  //     case 'purchase-statuses':
  //       section = 'purchase-status';
  //       break;
  //     default:
  //       section = url.substring(url.lastIndexOf('/') + 1, url.length - 1);
  //   }

  //   const role = 'ROLE_' + section.toUpperCase() + '_VIEW';

  //   return this.isGranted(role);
  // }

  // isGranted(role: string): boolean {
  //   const user = this.getUser();

  //   if (!user || !user.roles) {
  //     return false;
  //   }

  //   let granted = user.roles.includes('ROLE_ADMIN');

  //   if (!granted && user.roles.includes('ROLE_OWNER')) {
  //     const section = role
  //       .substring(0, role.indexOf('_', 5))
  //       .replace('ROLE_', '')
  //       .toLowerCase();
  //     if (section) {
  //       granted = this.ownerSections.includes(section);
  //     }
  //   }

  //   if (!granted) {
  //     granted = user.roles.includes(role);
  //   }

  //   return granted;
  // }
}
