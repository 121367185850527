<ts-form-controls-card
  label="Товары"
  [isExpandable]="true"
  [formGroup]="host.form"
>
  <div
    slot="card-breakable-actions"
    class="flex-grow d-flex gap-2 align-items-center flex-wrap"
  >
    <button
      class="ts-btn primary ts-btn-filled ts-btn-md"
      type="button"
      (click)="openHandbookModal()"
    >
      Добавить
    </button>

    <ts-actions-dropdown
      [actions]="host.productTableActionsList"
      [isDisabledBtnAction]="host.isDisabledBtnAction"
    />

    <app-toggle-switch
      formControlName="isVat"
      [hasBackground]="true"
      label="НДС"
    />

    <app-toggle-switch
      formControlName="isPriceIncludesVat"
      [hasBackground]="true"
      [isDisabled]="!host.form.value['isVat']"
      label="Цена включает НДС"
    />

    <ng-content></ng-content>

    <div class="product-table-search-input">
      <app-title
        [formControl]="host.search"
        placeholder="Поиск среди добавленных позиций.."
        leadingIcon="ts-icon-search-search-normal_alt"
      ></app-title>
    </div>

    <ts-dropdown [content]="productActions" *ngIf="host.productTableIdentifier">
      <button
        type="button"
        class="ts-btn btn-grey ts-btn-bezeled ts-btn-md ts-btn-icon ts-controls-bar-item"
      >
        <span class="ts-icon ts-icon-settings-setting-2"></span>
      </button>
    </ts-dropdown>
    <ng-template #productActions>
      <button
        type="button"
        class="dropdown-item"
        dropdownOption
        (click)="host.openProductTableSettingsModal()"
      >
        Настройка таблицы
      </button>
    </ng-template>
  </div>

  <div class="ts-table-container ts-table-container-short" [tsTable]="host.productTableHeaders$ | async">
    <div class="overflow-auto h-100 flex-grow-1 me-3">
      <ts-scroll class="ts-trade-document-scroll">
        <div class="ts-table d-flex flex-column h-100 flex-grow-1">
          <div class="ts-table__header">
            <ts-checkbox
              size="medium"
              [active]="host.allSelected"
              [indeterminate]="
                !host.allSelected && !!host.selectedProducts.length
              "
              (toggle)="host.toggleAll($event)"
            />

            <ng-container
              *ngFor="
                let header of host.productTableHeaders$ | async;
                let i = index
              "
            >
              <ts-product-price-table-header
                *ngIf="header.key === 'price'; else commonHeader"
                [tableHeader]="header"
                [tableIdentifier]="host.productTableIdentifier"
                (selectProductPrice)="host.setPrices($event)"
              />
              <ng-template #commonHeader>
                <ts-table-header-cell2
                  [tableIdentifier]="host.productTableIdentifier"
                  [tableHeader]="header"
                ></ts-table-header-cell2>
              </ng-template>
            </ng-container>
          </div>
          <div class="d-flex flex-column">
            <div
              class="ts-table__row"
              *ngFor="
              let line of host.products$ | async;
              index as i;
              let last = last;
              let ind = index
            "
              [ngClass]="{ selected: host.isSelectedProduct(i) }"
              [formGroup]="line"
            >
              <ts-checkbox
                size="medium"
                [active]="host.isSelectedProduct(i)"
                (toggle)="host.selectProduct($event, i)"
              />
              <ng-container
                *ngFor="
                let header of host.productTableHeaders$ | async;
                let i = index
              "
              >
                <ng-container [ngSwitch]="header.key">
                  <div
                    class="cell"
                    [ngStyle]="{
                    'width.px': header.width,
                    'flex-basis.px': header.width
                  }"
                    [tsTableCell]="header.key"
                  >
                    <div
                      class="ts-content"
                      [ngStyle]="{ 'text-align': header.textAlign || 'left' }"
                    >
                      <div *ngSwitchCase="'lineNumber'">
                        <span>{{ ind + 1 }}</span>
                      </div>

                      <div *ngSwitchCase="'unit'">
                        <span>{{ line.value?.product?.unit?.name ?? '' }}</span>
                      </div>

                      <ts-product-name-cell
                        *ngSwitchCase="'name'"
                        [product]="line.value?.product"
                        [showButtonOpenProduct]="true"
                      />

                      <ts-product-skus-cell
                        *ngSwitchCase="'identifiers'"
                        [product]="line.value?.product"
                      ></ts-product-skus-cell>

                      <div
                        *ngSwitchCase="'quantity'"
                        [ngClass]="{
                        'cell-error': line.get('quantity')?.invalid
                      }"
                      >
                        <ts-view-edit-mode-switch [disable]="host.isTrashed">
                          <ng-template tsViewMode="view">
                            <div class="w-100">
                              <div
                                class="table-editable-cell"
                                *ngIf="line.value.quantity !== null"
                              >
                                {{ line.value.quantity }}
                              </div>
                              <div
                                class="table-editable-cell-placeholder"
                                *ngIf="line.value.quantity === null"
                              >
                                Количество
                              </div>
                            </div>
                          </ng-template>
                          <ng-template tsViewMode="edit">
                            <input
                              class="cell-input error-control"
                              tsFocusable
                              tsInputNumber
                              formControlName="quantity"
                            />
                          </ng-template>
                        </ts-view-edit-mode-switch>
                      </div>

                      <div
                        *ngSwitchCase="'expensesSum'"
                        class="d-flex gap-3 align-items-center"
                      >
                        <div>
                          {{
                            calculateExpensesSum(line.value.expenses)
                              | currencyFormat
                          }}
                        </div>
                        <button
                          type="button"
                          class="ts-btn btn-grey ts-btn-bezeled ts-btn-md ts-btn-icon ts-controls-bar-item"
                          (click)="openExpensesModal(line)"
                        >
                          <i class="ts-icon ts-icon-actions-Add"></i>
                        </button>
                        <div></div>
                      </div>

                      <div *ngSwitchCase="'price'">
                        <ts-product-price-selector-cell
                          formControlName="price"
                          [productId]="line.value?.product.id"
                          [readonly]="host.isTrashed"
                        />
                      </div>

                      <ng-container *ngSwitchCase="'amount'">
                      <span>{{
                          host.calculatePercent(line.value) +
                          (host.priceIncludesVat!.value
                            ? 0
                            : host.calculateVat(line.value)) | currencyFormat
                        }}</span>
                      </ng-container>

                      <ng-container *ngSwitchCase="'amountWithoutVat'">
                      <span>{{
                          line.value?.quantity * line.value?.price -
                          (host.priceIncludesVat!.value
                            ? host.calculateVat(line.value)
                            : 0) | currencyFormat
                        }}</span>
                      </ng-container>

                      <ts-product-dimensions-cell
                        *ngSwitchCase="'dimensions'"
                        [product]="line.value.product"
                      />

                      <ng-container *ngSwitchCase="'vatPrice'">
                      <span>{{
                          host.calculateVat(line.value) | currencyFormat
                        }}</span>
                      </ng-container>

                      <ng-container *ngSwitchCase="'reserved'">
                        <span>{{ line.value?.product?.reserved | number }}</span>
                      </ng-container>

                      <ng-container *ngSwitchCase="'available'">
                      <span>{{
                          line.value?.product?.stocks -
                          line.value?.product?.reserved | number
                        }}</span>
                      </ng-container>

                      <ng-container *ngSwitchCase="'stocks'">
                      <span>{{
                          line.value?.product?.stocks | number : '1.0-3'
                        }}</span>
                      </ng-container>

                      <ts-select
                        *ngSwitchCase="'vatType'"
                        size="cell"
                        formControlName="vatType"
                        [identityMatcher]="host.recordIdentityMatcher"
                        [stringify]="host.recordStringify"
                        [hasCleaner]="false"
                      >
                        <div>
                          <button
                            *ngFor="let vat of host.vatList"
                            class="dropdown-item"
                            [tsSelectOption]="vat"
                          >
                            {{ vat.name }}
                          </button>
                        </div>
                      </ts-select>

                      <div *ngSwitchCase="'discountPricePercent'">
                        <ts-view-edit-mode-switch [disable]="host.isTrashed">
                          <ng-template tsViewMode="view">
                            <div class="w-100">
                              <div
                                class="table-editable-cell"
                                *ngIf="line.value.discountPricePercent !== null"
                              >
                                {{ line.value.discountPricePercent }}
                              </div>
                              <div
                                class="table-editable-cell-placeholder"
                                *ngIf="line.value.discountPricePercent === null"
                              >
                                Скидка
                              </div>
                            </div>
                          </ng-template>
                          <ng-template tsViewMode="edit">
                            <input
                              class="cell-input error-control"
                              tsFocusable
                              tsInputNumber
                              formControlName="discountPricePercent"
                            />
                          </ng-template>
                        </ts-view-edit-mode-switch>
                      </div>

                      <span *ngSwitchDefault>{{
                          dataItemToTableValueMapper.map(
                            header.key,
                            line.value,
                            header.type
                          )
                        }}</span>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <ts-trade-document-table-empty-row
            *ngIf="!host.isTrashed"
            [selectedProductControl]="host.productLinePlaceholder"
            [tableHeaders]="(host.productTableHeaders$ | async)!"
          />
        </div>
      </ts-scroll>
    </div>
    <ts-item-products-table-summary
      *ngIf="host.totals$ | async as totals"
      [totals]="totals"
    />
  </div>
</ts-form-controls-card>
