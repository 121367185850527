import { Pipe, PipeTransform } from '@angular/core';
import {entityNames, HubEntity} from "../../data";

@Pipe({
  name: 'hubEntityName',
})
export class HubEntityNamePipe implements PipeTransform {
  transform(value: HubEntity | string, args?: { toLowerCase: boolean }): unknown {
    if(args?.toLowerCase) {
      return entityNames[value]?.toLowerCase()
    }

    return entityNames[value];
  }
}
