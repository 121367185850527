import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { StringHandler } from '../types';
import { defaultStringHandler } from '../utils';

@Component({
  selector: 'ts-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsChipComponent<T> {
  @Input()
  value!: T;

  @Input()
  public stringify: StringHandler<T> = defaultStringHandler;

  @Input()
  @HostBinding(`class.disabled`)
  disabled = false;

  @Input()
  removable = false;

  @Output()
  readonly changeChip = new EventEmitter<T>();

  public get canRemove(): boolean {
    return this.removable && !this.disabled;
  }

  public remove(event: Event): void {
    if (!this.canRemove) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    this.changeChip.emit(this.value);
  }
}
