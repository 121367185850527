<div class="product-dimensions-cell">
  <div class="caption">Вес</div>
  <ng-container *ngIf="product.weight; else empty">
    <div class="caption-strong text-truncate">{{product.weight}}</div>
  </ng-container>


  <div class="caption">Объем</div>
  <ng-container *ngIf="product.volume; else empty">
    <div class="caption-strong text-truncate">{{product.volume}}</div>
  </ng-container>

  <ng-template #empty> <span> - </span> </ng-template>
</div>
