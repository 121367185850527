import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { CustomField } from '../model/customField';
import { Paginated } from '../model/paginated';
import { BASE_PATH, HubEntity } from '../variables';

@Injectable()
export class CustomFieldsApiService {
  protected basePath = 'http://hub.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
  }

  public batch(
    items: CustomField[],
    entity: HubEntity
  ): Observable<Paginated<CustomField>> {
    return this.httpClient.post<Paginated<CustomField>>(
      `${this.basePath}/api/v1/custom-fields/bulk/${entity}`,
      { items }
    );
  }
}
