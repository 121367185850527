import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TsCommonModule } from '@topseller/common';

import { routedComponents, routes } from './identity.routes';
import { TsIdentityDataModule } from './data';
import { ListItemsDeleteService } from "@topseller/common/base-list";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  declarations: [...routedComponents],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TsCommonModule,
    TsIdentityDataModule,
    MatDialogModule
  ],
  providers: [ListItemsDeleteService],
})
export class IdentityModule {}
