import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppWrapperComponent } from './layouts/app-wrapper/app-wrapper.component';

import { RolesGuard } from '@topseller/core';

import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { PageComponent } from './pages/page/page.component';
import { APPS_ROUTES } from './shared/routes/apps.routes';
import { CONTENT_ROUTES } from './shared/routes/content-layout.routes';
import { AuthGuard } from './shared/services/auth.guard';

export const routedComponents = [PageComponent, ContentLayoutComponent];

const appRoutes: Routes = [
  {
    path: 'yandex-token',
    loadComponent: () =>
      import('./yandex-token.component').then((m) => m.YandexTokenComponent),
  },
  {
    path: '',
    component: AppWrapperComponent,
    children: APPS_ROUTES,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('../admin/admin.module').then((m) => m.AdminModule),
    canLoad: [AuthGuard],
  },
  {
    path: '',
    component: ContentLayoutComponent,
    data: { title: 'content Views' },
    children: CONTENT_ROUTES,
  },
  {
    path: '**',
    redirectTo: 'pages/error',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, RolesGuard],
})
export class AppRoutingModule {}
