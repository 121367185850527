import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Configuration } from "../model";
import { BASE_PATH } from "../tokens";

@Injectable()
export class CommonBatchDeleteApiService {
  protected basePath = 'http://denis.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  public batchDelete(apiEntityName: string, ids: string[], filter: string | null) {
    let url = `${this.basePath}${apiEntityName}/batch-delete`;
    let body = null;

    if (filter) {
      url += `?${filter}`;
    } else {
      body = {ids: ids};
    }

    return this.httpClient.post(url, body);
  }


}
