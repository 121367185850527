import {Component, ElementRef, forwardRef, TemplateRef, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BaseFileDropComponent} from "./base-file-drop.component";
import {FormsModule, NG_VALUE_ACCESSOR} from "@angular/forms";
import {TsFileDropDirective} from "./file-drop.directive";

@Component({
  selector: 'ts-drop-upload-container',
  standalone: true,
  imports: [CommonModule, FormsModule, TsFileDropDirective],
  templateUrl: './drop-upload-container.component.html',
  styleUrls: ['./drop-upload-container.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropUploadContainerComponent),
      multi: true,
    },
  ],

})
export class DropUploadContainerComponent extends BaseFileDropComponent {
  @ViewChild('inputDialog') inputDialog?: ElementRef;

  public openDialog() {
    this.inputDialog?.nativeElement.click();
  }
}
