import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { TsDay, TsTime } from '../../common';

@Component({
  selector: 'ts-date-time-selector',
  templateUrl: './date-time-selector.component.html',
  styleUrls: ['./date-time-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimeSelectorComponent {
  @Input() value: [TsDay | null, TsTime | null] | null = null;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSelect: EventEmitter<[TsDay | null, TsTime | null] | null> =
    new EventEmitter<[TsDay | null, TsTime | null] | null>();

  public onDayClick(day: TsDay): void {
    const [_, time = new TsTime(0, 0, 0)] =
      this.value !== null ? this.value : [];
    this.value = [day, time];
  }

  public onTimeSelect(time: TsTime): void {
    const [day = TsDay.currentLocal()] = this.value !== null ? this.value : [];
    this.value = [day, time];
  }

  public onApply() {
    this.onSelect.emit(this.value);
  }

  public onCancel() {
    this.onSelect.emit(null);
  }
}
