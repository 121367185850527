export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;

export const MILLISECONDS_IN_MINUTE =
  MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE;
export const MILLISECONDS_IN_HOUR = MILLISECONDS_IN_MINUTE * MINUTES_IN_HOUR;
export const MILLISECONDS_IN_DAY = MILLISECONDS_IN_HOUR * HOURS_IN_DAY;

export class TsTime {
  constructor(
    readonly hours: number,
    readonly minutes: number,
    readonly seconds: number = 0
  ) {}

  public static current(): TsTime {
    return TsTime.fromAbsoluteMilliseconds(Date.now() % MILLISECONDS_IN_DAY);
  }

  public static fromString(time: string): TsTime {
    const hours = Number(time.slice(0, 2));
    const minutes = Number(time.slice(3, 5)) | 0;
    const seconds = Number(time.slice(6, 8)) || 0;

    return new TsTime(hours, minutes, seconds);
  }

  public static fromAbsoluteMilliseconds(milliseconds: number): TsTime {
    const hours = Math.floor(milliseconds / MILLISECONDS_IN_HOUR);
    const minutes = Math.floor(
      (milliseconds % MILLISECONDS_IN_HOUR) / MILLISECONDS_IN_MINUTE
    );
    const seconds =
      Math.floor(
        ((milliseconds % MILLISECONDS_IN_HOUR) % MILLISECONDS_IN_MINUTE) / 1000
      ) || 0;

    return new TsTime(hours, minutes, seconds);
  }

  public static fromLocalNativeDate(date: Date): TsTime {
    return new TsTime(date.getHours(), date.getMinutes(), date.getSeconds());
  }

  public toString(): string {
    return `${this.formatTime(this.hours)}:${this.formatTime(
      this.minutes
    )}:${this.formatTime(this.seconds)}`;
  }

  public toAbsoluteMilliseconds(): number {
    return (
      this.hours * MILLISECONDS_IN_HOUR +
      this.minutes * MILLISECONDS_IN_MINUTE +
      this.seconds * MILLISECONDS_IN_SECOND
    );
  }

  private formatTime(time: number, digits: number = 2): string {
    return String(time).padStart(digits, `0`);
  }
}
