<div class="form-controls-card">
  <div class="form-controls-card__header break">
    <div class="d-flex flex-column flex-sm-row ">
      <div class="label-container " [ngClass]="{'expandable':isExpandable}">
        <span class="label" *ngIf="label">{{label}}</span>
        <ng-content select="[slot=label-content]"></ng-content>
        <button type="button"
                class="ts-btn btn-grey ts-btn-bezeled ts-btn-md ts-btn-icon ts-controls-bar-item"
                *ngIf="isExpandable"
                (click)="isExpanded = !isExpanded">
                <span class="ts-icon "
                      [ngClass]="{'ts-icon-arrow-Expand_up': isExpanded, 'ts-icon-arrow-Expand_down': !isExpanded}">
                </span>
        </button>
      </div>

      <div class="actions">
        <ng-content select="[slot=card-actions]"></ng-content>
      </div>
    </div>

    <div class="breakable-actions">
      <ng-content select="[slot=card-breakable-actions]"></ng-content>
    </div>

  </div>
  <ng-container *ngIf="isExpanded">
    <ng-content></ng-content>

    <ng-container *ngIf="isEmpty">
      <div class="form-controls-card__empty">
        <div class="ts-table__no-items">
          <span [class]="'ts-icon ts-icon-'+emptyIcon" *ngIf="emptyIcon"></span>
          <span class="ts-subtitle" *ngIf="emptyText">{{emptyText}}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

