import {Inject, Injectable, Optional} from "@angular/core";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {ReportOpiu, ReportOpiuFull} from "../model/reports/report-opiu";
import {Observable} from "rxjs";
import {BASE_PATH} from '../variables';

@Injectable()
export class ReportOpiuApiService {
  protected basePath = 'http://denis.dev.topseller.ru';

  constructor(protected httpClient: HttpClient,
              @Optional() @Inject(BASE_PATH) basePath: string,) {
    if (basePath) {
      this.basePath = basePath;
    }
  }

  createReport(filter: string): Observable<ReportOpiu> {
    return this.httpClient.get<ReportOpiu>(`${this.basePath}/api/v1/report/opiu?${filter}`);
  }

  getReportDetail(reportId: string): Observable<HttpResponse< ReportOpiuFull>> {
    return this.httpClient.get<ReportOpiuFull>(`${this.basePath}/api/v1/report/opiu/${encodeURIComponent(
      String(reportId)
    )}/detail`, { observe: 'response' });
  }
}

