import {
  Component,
  EventEmitter,
  Inject,
  Input, OnInit,
  Optional,
  Output,
} from '@angular/core';
import { HubEntity } from '../../../../data';
import { ENTITY_NAME } from '../../../tokens';
import { getRequiredRoleForLinkedDoc } from "../linked-documents.type";

@Component({
  selector: 'ts-linked-document',
  templateUrl: './linked-document.component.html',
  styleUrls: ['./linked-document.component.scss'],
})
export class LinkedDocumentComponent implements OnInit{
  @Input() public edge?: any;

  public get items() {
    return this.edge.documents || [];
  }

  @Output() public itemClick = new EventEmitter<{
    entity: HubEntity;
    id: string;
  }>();

  requiredRole?:string;

  constructor(
    @Optional() @Inject(ENTITY_NAME) public readonly entity: HubEntity
  ) {
  }

 ngOnInit() {
    this.requiredRole = getRequiredRoleForLinkedDoc(this.edge.entityName);
 }
}
