import {
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnDestroy,
  TemplateRef,
} from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';

import { WINDOW } from '@topseller/cdk/common';

import { HOST_POSITION, PositionProvider } from './dropdown-position.directive';

export const DEFAULT_MAX_HEIGHT = 300;
export const DEFAULT_MARGIN = 2;

const animationFrame$ = new Observable<DOMHighResTimeStamp>((subscriber) => {
  let id = NaN;
  const callback = (timestamp: DOMHighResTimeStamp) => {
    subscriber.next(timestamp);
    id = requestAnimationFrame(callback);
  };

  id = requestAnimationFrame(callback);

  return () => {
    cancelAnimationFrame(id);
  };
});

@Component({
  templateUrl: './dropdown-host.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'dropdown-menu' },
  styleUrls: ['./dropdown-host.component.scss'],
})
export class DropdownHostComponent implements OnDestroy {
  private prevDirectionIsTop = false;
  private destroy$: Subject<void> = new Subject<void>();

  public get nativeElement() {
    return this.elementRef.nativeElement;
  }

  public templateRef!: TemplateRef<unknown>;

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(HOST_POSITION) private host: PositionProvider,
    private elementRef: ElementRef,
    ngZone: NgZone
  ) {
    animationFrame$.pipe(takeUntil(this.destroy$)).subscribe(() =>
      ngZone.runOutsideAngular(() => {
        this.calculatePosition();
      })
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private calculatePosition() {
    const { style } = this.nativeElement;
    this.calculateDropdownPosition(style);
    this.calculateWidth(style);
  }

  private calculateWidth(style: CSSStyleDeclaration) {
    const { width } = this.host.clientRect;
    style.minWidth = `${width}px`;
    style.maxWidth = `700px`;
  }

  private calculateDropdownPosition(style: CSSStyleDeclaration): void {
    const windowHeight = this.window.innerHeight;

    const { top: directiveTop, bottom: directiveBottom } = this.host.clientRect;

    const offset = DEFAULT_MARGIN * 2;
    const topAvailableHeight = directiveTop - offset;
    const bottomAvailableHeight = windowHeight - directiveBottom - offset;

    const dropdownHeight = this.nativeElement.getBoundingClientRect().height;
    let finalDirection;

    if (this.prevDirectionIsTop && topAvailableHeight >= dropdownHeight) {
      finalDirection = 'top';
    } else if (bottomAvailableHeight >= dropdownHeight) {
      finalDirection = 'bottom';
    } else {
      finalDirection =
        bottomAvailableHeight >= topAvailableHeight ? 'bottom' : 'top';
    }

    this.prevDirectionIsTop = finalDirection === 'top';

    style.right = 'auto';
    // TODO закомантировал в задаче [HUB-1915], мешает растягиваться дропдауну если контент в нее динамически пополняется.
    // style.height = `${dropdownHeight}px`;

    if (finalDirection === 'top') {
      style.top = 'auto';
      style.bottom = `${windowHeight - directiveTop}px`;
    } else {
      style.top = `${directiveBottom + offset}px`;
      style.bottom = 'auto';
    }

    this.calculateHorizontalPosition(style, offset);
  }

  calculateHorizontalPosition(style: CSSStyleDeclaration, offset: number) {
    const { left: directiveLeft } = this.host.clientRect;

    const windowWidth = this.window.innerWidth;
    const dropdownWidth = this.nativeElement.getBoundingClientRect().width;

    let leftPosition = directiveLeft;
    const leftAvailableWidth = windowWidth - directiveLeft - offset;
    if (leftAvailableWidth < dropdownWidth) {
      leftPosition = windowWidth - dropdownWidth - offset;
    }
    style.left = `${leftPosition}px`;
  }
}
