import { Pipe, PipeTransform } from '@angular/core';

import { DAYS_IN_WEEK, TsDay, TsMonth } from '../../common';

const FIRST_DAY_OF_WEEK = 1;

const getMonthStartDaysOffset = (month: TsMonth): number => {
  const startMonthOffsetFromSunday = new Date(
    month.year,
    month.month,
    1
  ).getDay();

  return startMonthOffsetFromSunday >= FIRST_DAY_OF_WEEK
    ? startMonthOffsetFromSunday - FIRST_DAY_OF_WEEK
    : DAYS_IN_WEEK - (FIRST_DAY_OF_WEEK - startMonthOffsetFromSunday);
};

export const getDayFromMonthRowCol = ({
  month,
  rowIndex,
  colIndex,
}: {
  month: TsMonth;
  rowIndex: number;
  colIndex: number;
}): TsDay => {
  let day =
    rowIndex * DAYS_IN_WEEK + colIndex - getMonthStartDaysOffset(month) + 1;

  if (day > month.daysCount) {
    day -= month.daysCount;
    month = month.append({ month: 1 });
  }

  if (day <= 0) {
    month = month.append({ month: -1 });
    day = month.daysCount + day;
  }

  return new TsDay(month.year, month.month, day);
};

@Pipe({
  name: `calendarMonth`,
})
export class CalendarTablePipe implements PipeTransform {
  private currentMonth: TsMonth | null = null;
  private currentSheet: ReadonlyArray<readonly TsDay[]> = [];

  public transform(month: TsMonth): ReadonlyArray<readonly TsDay[]> {
    if (this.currentMonth?.monthSame(month)) {
      return this.currentSheet;
    }

    return [...Array(6)].map((_, rowIndex) => {
      return [...Array(DAYS_IN_WEEK)].map((_, colIndex) => {
        return getDayFromMonthRowCol({
          month,
          rowIndex,
          colIndex,
        });
      });
    });
  }
}
