import { Component, Input } from '@angular/core';

import { Product } from '../../../data';

@Component({
  selector: 'ts-product-list-item',
  templateUrl: './product-list-item.component.html',
  styleUrls: ['./product-list-item.component.scss'],
})
export class ProductListItemComponent {
  @Input() item?: Product;

  public getProductImage(item?: Product): string | undefined {
    return item?.image
      ? item?.image
      : '/assets/img/hub/empty-conditions/empty-table-img.svg';
  }
}
