import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, combineLatest, takeUntil } from 'rxjs';

import { filterNonNullValues, FilterType, isRecord, TableFilter } from '@topseller/core';
import { TsDropdownModule } from '@topseller/ui/dropdown';
import { InputDateRangeSelectorModule } from '@topseller/ui/input-date-range-selector';
import { ActiveZoneDirective } from '@topseller/ui/active-zone';

import { FilterControlComponent, FilterDataService, TableFilterComponentBase } from '../../table';


@Component({
  selector: 'ts-table-header-filter',
  standalone: true,
  imports: [
    CommonModule,
    TsDropdownModule,
    InputDateRangeSelectorModule,
    ReactiveFormsModule,
    FilterControlComponent,
    ActiveZoneDirective,
  ],
  templateUrl: './table-header-filter.component.html',
  styleUrls: ['./table-header-filter.component.scss'],
  providers:[DatePipe]
})
export class TableHeaderFilterComponent
  extends TableFilterComponentBase
  implements OnInit {
  DATE_FROM = 'dateFrom';
  DATE_TO = 'dateTo';

  @Input() filters!: TableFilter[];

  isVisible$ = new BehaviorSubject(false);


  constructor(
    activatedRoute: ActivatedRoute,
    fb: FormBuilder,
    router: Router,
    filterApiService: FilterDataService,
    datePipe: DatePipe,
  ) {
    super(router, activatedRoute, fb, filterApiService, datePipe);
  }

  ngOnInit(): void {
    if (this.filters && this.filters.length) {
      combineLatest([this.queryParams$, this.filterDataService.getDefaultFilter(this.entityName)])
        .pipe(takeUntil(this.destroy$))
        .subscribe(([params, defaultFilterValues]) => {
          if (params) {
            this.defaultFilterValues = defaultFilterValues;
            this.fillControlsDictionary();
            this.createForm();
            super.cleanAll();
            this.setFormValuesFromQueryParams(params);
          }
        });
    }
  }

  fillControlsDictionary() {
    this.filters?.forEach((el) => {
      if (el.type === FilterType.DATERANGE && el.target) {
        this.byTargetDictionary[el.target + '_' + this.DATE_FROM] = {
          label: el.name + ': ' + 'От',
          type: FilterType.DATERANGEFROM,
        };
        this.byTargetDictionary[el.target + '_' + this.DATE_TO] = {
          label: el.name + ': ' + 'До',
          type: FilterType.DATERANGETO,
        };
      } else {
        this.byTargetDictionary[el.target] = {label: el.name, type: el.type};
      }
    });
  }

  createForm() {
    for (const control of this.filters) {
      let controlName = control.target;

      // для периода используем составное название
      if (control.type === FilterType.DATERANGE) {
        controlName = control.target + '_' + this.DATE_FROM;

        const controlNameTo = control.target + '_' + this.DATE_TO;
        this.form.addControl(controlNameTo, this.fb.control(null));
      }
      this.form.addControl(controlName, this.fb.control(null));
    }
  }

  apply() {
    const values = filterNonNullValues(this.form.value);
    const queryParams: { [key: string]: any } = {};

    // проходим по всем имеющимся фильтрам и добавляем в queryParams значение если есть
    // или явно устанавливаем в null тот фильтр, для которого в контроле нет значения
    this.filters?.forEach((el) => {

      if (el.type === FilterType.DATERANGE && el.target) {
        const dateFromControlName = el.target + '_' + this.DATE_FROM;
        const dateToControlName = el.target + '_' + this.DATE_TO;

        const dateFromParamName = `filter[${el.target}][${this.DATE_FROM}]`
        const dateToParamName = `filter[${el.target}][${this.DATE_TO}]`

        if (values[dateFromControlName]) {
          queryParams[dateFromParamName] = values[dateFromControlName];
        } else {
          queryParams[dateFromParamName] = null;
        }

        if (values[dateToControlName]) {
          queryParams[dateToParamName] = values[dateToControlName];
        } else {
          queryParams[dateToParamName] = null;
        }
      } else {
        const filterValue = values[el.target];
        if (filterValue !== null && filterValue !== undefined) {
          queryParams[`filter[${el.target}]`] = Array.isArray(filterValue) ? filterValue.map(x=>x.id).join()
          :
          isRecord(filterValue) ? filterValue.id : filterValue;
        } else {
          queryParams[`filter[${el.target}]`] = null;
        }
      }
    });

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
    this.isVisible$.next(false);
  }

  onChangeVisible($event: boolean) {
    this.isVisible$.next($event);
  }

  override cleanAll() {
    super.cleanAll();
    this.apply();
  }

}
