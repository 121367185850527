export interface TariffSetting {
  expanded: boolean;
  groupTitle: string;
  groupFunctional: Record<string, TariffSettingItem>
  sort: number;
}

export interface PricePlanItem {
  name: string;
  discount: number;
  isDefault?: boolean;
  sort: number;
}

export interface TariffSettingItem {
  type: string;
  title: string;
  sort: number;
}

export interface TariffPriceInfo {
  price: number;
  fullPrice: number | null;
  identifier: string;
}

export interface TariffInfo {
  name: string;
  description: string;
  pricePlans: Record<string, TariffPriceInfo>;
  settings: Record<string, string | boolean>
  additionalInfo?: { title?: string, description?: string };
}

export enum SubscriptionConfigurationType {
  standard = 'standard',
  calculator = 'calculator'
}

export interface TariffsResponse {
  pricePlans: Record<string, PricePlanItem>;
  settings: TariffSetting[];
  subscriptions: TariffInfo[];
  configurationType: SubscriptionConfigurationType;
  defaultTariffIdentifier:string;
}

export interface BillingTariffInfo {
  id: string;
  identifier: string;
}

export interface PaywallResponse {
  id: string,
  identifier: string,
  name: string,
  payload: TariffsResponse[];
  tariffs: BillingTariffInfo[];
}
