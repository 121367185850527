import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { AppLink, appsLinks } from './apps-links';
import { Store } from '@ngrx/store';
import { appServices } from '@topseller/core';

@Component({
  selector: 'app-state-nav',
  templateUrl: './state-navigation.component.html',
  styleUrls: ['./state-navigation.component.scss'],
})
export class StateNavigationComponent implements OnDestroy, AfterViewInit {
  @ViewChild('menuWrapper', { static: false }) menuWrapper?: ElementRef;
  @ViewChild('menu', { static: false }) menu?: ElementRef;

  public get links(): AppLink[] {
    return this.production
      ? appsLinks.filter((item) => item.production === this.production)
      : appsLinks;
  }

  public production = environment.production;

  private destroy$: Subject<void> = new Subject<void>();

  shouldDisplayArrows = false;

  constructor(private store: Store) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    this.checkArrowsVisibility();
  }

  ngAfterViewInit() {
    this.checkArrowsVisibility();
  }

  scroll(direction: number) {
    const scrollAmount = 100; // Измените это значение, если хотите другое перемещение
    const currentScroll = this.menuWrapper?.nativeElement.scrollLeft;
    if (this.menuWrapper) {
      this.menuWrapper.nativeElement.scrollLeft =
        currentScroll + direction * scrollAmount;
    }
  }

  checkArrowsVisibility() {
    if (this.menu && this.menuWrapper) {
      this.shouldDisplayArrows =
        this.menu.nativeElement.scrollWidth >
        this.menuWrapper.nativeElement.clientWidth;
    }
  }

  getServiceIdByLink(url: string): string {
    const appService = appServices.find((item) => item.url === url);
    return appService ? appService.id : '';
  }
}
