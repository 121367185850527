<ul class="nav_product navbar-nav flex-row sub-nav gap-3 toggle-buttons-list">
  <ng-container *ngFor="let link of links">
    <li *ngIf="link.title"
        class="nav_product__item nav-item d-flex align-items-center"
        routerLinkActive="active"
        style="padding: 0 5px"
    >
      <ng-container *ngIf="id">
        <svg id="check" width="12" viewBox="0 0 14 14">
          <use
                  style="fill: 'white'"
                  xlink:href="/assets/img/icons/icons.svg#plus"
          ></use>
        </svg>
      </ng-container>
      <a class="nav-link nav-link py-2 px-2" [routerLink]="link.path">
        {{ link.title }}
      </a>
    </li>
  </ng-container>
</ul>
