import { ChangeDetectorRef, Component, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ListQueryFn } from '@topseller/common/base-list';
import { IdentityMatcher, StringHandler } from '@topseller/ui';
import { ToastrService } from 'ngx-toastr';
import { first, Subject, Subscription, takeUntil } from 'rxjs';
import { BaseItemInfoComponent } from './../../../../common/components/base-item-info.component';
import { Cashflow, CashflowService } from './../../../../data';
import { Store } from "@ngrx/store";
import { ConfirmDialogComponent, DialogService, Record, ROLES, setShowLoader } from '@topseller/core';
import { BaseInnItemComponent } from "../../../../common/components/base-inn-item.component";

@Component({
  selector: 'ts-cashflow-item',
  templateUrl: './cashflow-item.component.html',
  styleUrls: ['../../company/company.component.scss'],
  providers: [{provide: BaseInnItemComponent, useExisting: CashflowItemComponent}]
})
export class CashflowItemComponent
  extends BaseItemInfoComponent
  implements OnInit {
  public form: FormGroup = new FormGroup({
    id: new FormControl<string | null>(null),
    name: new FormControl<string>('', [Validators.maxLength(255), Validators.required]),
    note: new FormControl<string>('', [Validators.maxLength(255)]),
    cashflowSourceGroup: new FormControl<Record | null>(null, [Validators.required]),
  });

  public showHeaderBtns = false;
  public showHeaderBtnsSub$?: Subscription;

  public id = '';
  override backLinkUrl = '/hub/handbooks/cashflow';
  public editItemRole = ROLES.ROLE_CASHFLOW_SOURCE_EDIT;
  public deleteItemRole = ROLES.ROLE_CASHFLOW_SOURCE_DELETE;
  private destroy$ = new Subject<void>();

  constructor(
    activatedRoute: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    matDialog: MatDialog,
    changeDetectorRef: ChangeDetectorRef,
    store: Store,
    private cashflowService: CashflowService,
    @Optional() private dialogRef: MatDialogRef<CashflowItemComponent>,
    private dialogService: DialogService
  ) {
    super(activatedRoute, toastr, router, changeDetectorRef, matDialog, store);
  }

  public get cashflowGroupQueryFn(): ListQueryFn<Record> {
    return this.cashflowGroupListFn;
  }

  public get isModal(): boolean {
    return this.dialogRef?.componentInstance instanceof CashflowItemComponent;
  }

  ngOnInit(): void {
    this.getResolvedData(this.form, 'cashflow');
  }

  public recordIdentityMatcher: IdentityMatcher<Record> = (
    {id: previousId},
    {id: nextId}
  ) => previousId === nextId;

  public recordStringify: StringHandler<Record> = (item) => item.name;

  public deleteSource(): void {
    const data = {
      title: 'Удаление статьи ДДС',
      content: 'Вы уверены, что хотите удалить статью ДДС ?',
      okBtn: 'Удалить'
    };
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialogService.openConfirmDialog(data);

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe({
        next: (result: boolean) => {
          this.deleteItem(
            this.cashflowService,
            'deleteCashflowItem',
            '/hub/handbooks/cashflow',
            result ? 'да' : 'нет'
          );
        }
      });
  }

  public save() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.toastr.error('Форма содержит ошибки');
      return
    }
    this.store.dispatch(setShowLoader({showLoader: true}));

    const {id} = this.form.value;
    if (id === null || id === undefined) {
      this.create();
    } else {
      this.update();
    }
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  private create() {
    this.cashflowService
      .postCashflowItem(this.form.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: Cashflow) => {
          this.toastr.success('Статья успешно создана');
          this.store.dispatch(setShowLoader({showLoader: false}));
          if (this.isModal) {
            this.dialogRef.close(res);
          } else {
            this.form.markAsPristine();
            this.navigateByUrl(`/hub/handbooks/cashflow/${res.id}`);
          }
        },
        error: (err: any) => {
          this.creationState$.next('error');
          this.store.dispatch(setShowLoader({showLoader: false}))

          this.toastr.error(
            err?.errors?.length && err.errors[0].message
              ? err.errors[0].message
              : err?.message || 'Что-то пошло не так'
          );
        },
      });
  }

  private update() {
    this.cashflowService
      .patchCashflowItem(this.form.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: Cashflow) => {
          this.toastr.success('Статья успешно сохранена');
          this.store.dispatch(setShowLoader({showLoader: false}))
          this.form.markAsPristine();

          this.form.patchValue(res);
        },
        error: (err: any) => {
          this.creationState$.next('error');
          this.store.dispatch(setShowLoader({showLoader: false}))

          this.toastr.error(
            err?.errors?.length && err.errors[0].message
              ? err.errors[0].message
              : err?.message || 'Что-то пошло не так'
          );
        },
      });
  }

  private cashflowGroupListFn: ListQueryFn<Record> = ({
                                                        limit = -1,
                                                        offset = 0,
                                                        search
                                                      }) => this.cashflowService.getCashflowGroups(limit, offset,search);
}
