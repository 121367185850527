export enum LegalFormsEnum {
  COMPANY,
  PRIVATE,
  PERSON,
}

export type LegalForms = {
  [key in LegalFormsEnum]: string;
};

export const legalForms: LegalForms = {
  [LegalFormsEnum.COMPANY]: 'Юридическое лицо',
  [LegalFormsEnum.PRIVATE]: 'Индивидуальный предприниматель',
  [LegalFormsEnum.PERSON]: 'Физическое лицо',
};
