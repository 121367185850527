<div
  [dropdown]="dropdownTemplate"
  [show]="isVisible"
  #parent="activeZone"
  [class.open]="isVisible"
  (activeZoneChange)="onActiveZone($event)"
>
  <ng-content></ng-content>
</div>

<ng-template #dropdownTemplate>
  <div class="dropdown-list" [activeZoneParent]="parent">
    <ng-container
      *ngTemplateOutlet="
        $any(content);
        injector: injector;
        context: { $implicit: parent }
      "
    >
    </ng-container>
  </div>
</ng-template>
