import {
  AfterContentInit,
  Directive,
  ElementRef,
  Input,
  Optional,
} from '@angular/core';
import { CalendarYearSelectorComponent } from './calendar-year-selector.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[currentYear]',
})
export class CurrentYearDirective implements AfterContentInit {
  private scrollToView = false;

  @Input()
  public set currentYear(current: boolean) {
    this.scrollToView = current;
  }

  constructor(
    private elementRef: ElementRef,
    @Optional() private host: CalendarYearSelectorComponent
  ) {}

  public ngAfterContentInit(): void {
    if (this.host && this.scrollToView) {
      this.host.scrollToView(this.elementRef.nativeElement);
    }
  }
}
