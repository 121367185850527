import { Directive, ElementRef, InjectionToken } from '@angular/core';

export interface PositionProvider {
  clientRect: DOMRect;
}

export const HOST_POSITION = new InjectionToken<PositionProvider>(
  'Dropdown host position'
);

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: `[dropdown]`,
  providers: [
    { provide: HOST_POSITION, useExisting: DropdownPositionDirective },
  ],
})
export class DropdownPositionDirective implements PositionProvider {
  public get clientRect(): DOMRect {
    return this.elementRef.nativeElement.getBoundingClientRect();
  }

  constructor(private elementRef: ElementRef) {}
}
