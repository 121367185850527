<ts-base-input
  [label]="label"
  [placeholder]="placeholder"
  [value]="value??''"
  [cleaner]="cleaner"
  (onClear)="clear()"
  [labelInside]="labelInside"
  [class.disabled]="disabled"
  [disabled]="disabled"
>
  <ts-custom-content ngProjectAs='customContent'>
    <input
      class="w-100"
      #input
      type="text"
      [ngModel]="value"
      (ngModelChange)="onModelChange($event)"
      [readOnly]="readonly"
      [tsInputNumber]="{formatMultiplier, precision}"
    />
  </ts-custom-content>
</ts-base-input>
