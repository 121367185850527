import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { TsDay, TsMonth } from '../../../common';

@Component({
  selector: 'ts-calendar-month',
  templateUrl: './calendar-month.component.html',
  styleUrls: ['./calendar-month.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarMonthComponent {
  private readonly today = TsDay.currentLocal();

  @Input() public month = TsMonth.currentMonth();

  @Input()
  public value: TsDay | null = null;

  @Output()
  readonly dayClick: EventEmitter<TsDay> = new EventEmitter<TsDay>();

  public weekDays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

  public itemIsToday(item: TsDay): boolean {
    return this.today.daySame(item);
  }

  public sameMonth(item: TsDay): boolean {
    return this.month.monthSame(item);
  }

  public onItemClick(item: TsDay): void {
    this.dayClick.emit(item);
  }

  public daySame(item: TsDay): boolean {
    const { value } = this;

    if (!value) {
      return false;
    }

    return value.daySame(item);
  }
}
