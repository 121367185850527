import { InjectionToken } from '@angular/core';

export const BASE_PATH = new InjectionToken<string>('basePath');
export const COLLECTION_FORMATS = {
  csv: ',',
  tsv: '   ',
  ssv: ' ',
  pipes: '|',
};

export enum HubEntity {
  PURCHASE = 'purchase',
  ORDER = 'order',
  MOVEMENT = 'movement',
  PRODUCT = 'product',
  PAYMENT = 'payment',
  TRANSFER = 'transfer',
  POSTING = 'posting',
  WRITE_OFF = 'writeOff',
  INVENTORY = 'inventory',
  CORRECTION = 'correction',
  EMPLOYEE = 'employee'
}
