<ts-dropdown [content]="actionsTemplate"
             (changeVisible)="setIsExpanded($event)">
  <button
    type="button"
    class="ts-btn btn-grey ts-btn-bezeled ts-btn-md ts-btn-icon w-100"
  >
    Печать
    <i
      class="ts-icon"
      [ngClass]="isExpanded ? 'ts-icon-arrow-Expand_up' : 'ts-icon-arrow-Expand_down'"
    ></i>
  </button>
</ts-dropdown>
<ng-template #actionsTemplate>
  <ts-dot-loader *ngIf="isLoading$|async"></ts-dot-loader>
  <ng-container *ngIf="docTypes$|async as docTypes">
    <button *ngFor="let docType of docTypes" type="button" class="dropdown-item" dropdownOption (click)="getDocument(docType)">
      {{ docType.name }}
    </button>
  </ng-container>
</ng-template>

