import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';

import { DATA_LIST_HOST } from '../tokens';
import { DataListHost } from '../types';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[dropdownOption], [dropdownSelect]',
})
export class DropdownOptionDirective<T> {
  @Input()
  public disabled?: boolean;

  @Output() public dropdownSelect = new EventEmitter<void>();

  public get nativeElement() {
    return this.elementRef.nativeElement;
  }

  constructor(
    @Inject(DATA_LIST_HOST) private readonly host: DataListHost<T>,
    private elementRef: ElementRef
  ) {}

  @HostListener('click')
  public onClick(): void {
    if (!this.disabled) {
      this.host.handleOption();
      this.dropdownSelect.emit();
    }
  }
}
