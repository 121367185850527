import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { isDevMode, LOCALE_ID, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import {
  appNavlinkReducer,
  NAVLINKS_FEATURE_KEY,
} from './shared/components/state-navigation/store/state-nav.reducer';

import { TsCommonModule } from '@topseller/common';
import {
  BASE_PATH,
  HasRoleDirective, ServiceAvailableByRestrictionsDirective,
  TopsellerCoreModule
} from '@topseller/core';
import { BASE_PATH as HUB_BASE_PATH } from '@topseller/hub';
import { BASE_PATH as IDENTITY_BASE_PATH } from '@topseller/identity';
import { BASE_PATH as REPRICER_BASE_PATH } from '@topseller/repricer';
import { BASE_PATH as ANALYTICS_BASE_PATH } from '@topseller/analytics';
import { OverlayHostComponent } from '@topseller/ui/overlay-host';
import { LoaderAnimatedModule } from '@topseller/ui/loader-animated';

import { StoreModule } from '@ngrx/store';
import { LottieModule } from 'ngx-lottie';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppWrapperComponent } from './layouts/app-wrapper/app-wrapper.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { PageComponent } from './pages/page/page.component';
import { StateNavigationComponent } from './shared/components/state-navigation/state-navigation.component';
import { AuthInterceptor } from './shared/services/auth.interceptor';
import { SharedModule } from './shared/shared.module';
import { MetrikaModule } from 'ng-yandex-metrika';

import { YandexAuthModule } from '@topseller/common';

import { StateNavigationDirective } from './shared/components/state-navigation/state-navigation.directive';
import { ServiceActivatedGuard, ServiceAvailableGuard } from './pages/content-pages/guards';
import { MatDialogModule } from '@angular/material/dialog';

registerLocaleData(localeRu);

const INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  multi: true,
  useClass: AuthInterceptor,
};

const ym = 84473377;

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

const PRODUCTION_MODULES = environment.production
  ? ([
      MetrikaModule.forRoot({
        id: ym,
        webvisor: false,
        clickmap: false,
      }),
    ] as const)
  : [];

@NgModule({
  declarations: [
    AppComponent,
    ContentLayoutComponent,
    PageComponent,
    StateNavigationComponent,
    AppWrapperComponent,
    StateNavigationDirective,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    TsCommonModule,
    StoreModule.forFeature(NAVLINKS_FEATURE_KEY, appNavlinkReducer),
    SharedModule,
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    NgbModule,
    OverlayHostComponent,
    LottieModule.forRoot({ player: playerFactory }),
    TopsellerCoreModule,
    ...PRODUCTION_MODULES,
    LoaderAnimatedModule,
    HasRoleDirective,
    ServiceAvailableByRestrictionsDirective,
    YandexAuthModule.forRoot(environment.frontendUrl),
    MatDialogModule
  ],
  providers: [
    {
      provide: BASE_PATH,
      useValue: environment.apiUrl,
    },
    {
      provide: HUB_BASE_PATH,
      useValue: environment.hubUrl,
    },
    {
      provide: IDENTITY_BASE_PATH,
      useValue: environment.hubUrl,
    },
    {
      provide: REPRICER_BASE_PATH,
      useValue: environment.rpUrl,
    },
    {
      provide: ANALYTICS_BASE_PATH,
      useValue: environment.eaUrl,
    },
    {
      provide: 'TS_PRODUCTION_MODE',
      useValue: environment.production,
    },

    INTERCEPTOR_PROVIDER,
    provideEnvironmentNgxMask(),
    { provide: LOCALE_ID, useValue: 'ru' },
    ServiceActivatedGuard,
    ServiceAvailableGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
