import {Pipe, PipeTransform} from "@angular/core";
import {CurrencyFormatConfig, CurrencyFormatPipe, DEFAULT_CURRENCY_FORMAT_CONFIG} from "../currency-format/currency-format";
import {DecimalPipe} from "@angular/common";

@Pipe({
  name: 'valueUnit'
})
export class ValueUnitPipe implements PipeTransform {
  constructor(private currencyFormatPipe: CurrencyFormatPipe,
              private decimalPipe: DecimalPipe) {
  }

  transform(value: any, valueUnit: string, currencyFormatConfig: Partial<CurrencyFormatConfig> = DEFAULT_CURRENCY_FORMAT_CONFIG): string {
    switch (valueUnit) {
      case 'currency':
        return this.currencyFormatPipe.transform(value, currencyFormatConfig);
      case 'percent':
        return `${value.toFixed(2)}%`;
      case 'count':
        return this.decimalPipe.transform(value, '1.0') ?? '0';
    }
    const num = Number(value);
    if (isNaN(num)) {
      return value ? String(value) : '0';
    }
    return this.decimalPipe.transform(num, '1.2-3') ?? '0';
  }
}
