import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Product } from '../../../data';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  imports: [CommonModule],
  standalone: true,
  selector: 'ts-product-skus-cell',
  templateUrl: './product-skus-cell.component.html',
  styleUrls: ['./product-skus-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSkusCellComponent {
  constructor(
    private toastr: ToastrService,
    private clipboardService: ClipboardService
  ) {}

  @Input() product!: Product;

  @Input() canCopy = true;

  copyToClipboard(event:MouseEvent,value: string) {
    event.preventDefault();
    event.stopPropagation();
    this.clipboardService.copyFromContent(value);
    this.toastr.info(`${value} скопировано`);
  }
}
