/**
 * TOPSELLER App
 * Topseller backend API
 *
 * OpenAPI spec version: 23.0220.1356
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { Paginated } from '../model/paginated';
import { Unit } from '../model/unit';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';

@Injectable()
export class UnitService {
  protected basePath = 'http://denis.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Удалить единицу измерения
   *
   * @param unitId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteAppUnitDelete(
    unitId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Unit>;
  public deleteAppUnitDelete(
    unitId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Unit>>;
  public deleteAppUnitDelete(
    unitId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Unit>>;
  public deleteAppUnitDelete(
    unitId: string,
    observe: any = 'body',
    reportProgress = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        'Required parameter unitId was null or undefined when calling deleteAppUnitDelete.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Unit>(
      'delete',
      `${this.basePath}/api/v1/units/${encodeURIComponent(String(unitId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Список единиц измерения
   *
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppUnitIndex(
    limit?: number,
    offset?: number,
    search?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<Unit>>;
  public getAppUnitIndex(
    limit?: number,
    offset?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Paginated<Unit>>>;
  public getAppUnitIndex(
    limit?: number,
    offset?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Paginated<Unit>>>;
  public getAppUnitIndex(
    limit?: number,
    offset?: number,
    search?: string,
    observe: any = 'body',
    reportProgress = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<Unit>>(
      'get',
      `${this.basePath}/api/v1/units`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Единица измерения
   *
   * @param unitId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppUnitView(
    unitId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Unit>;
  public getAppUnitView(
    unitId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Unit>>;
  public getAppUnitView(
    unitId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Unit>>;
  public getAppUnitView(
    unitId: string,
    observe: any = 'body',
    reportProgress = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        'Required parameter unitId was null or undefined when calling getAppUnitView.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Unit>(
      'get',
      `${this.basePath}/api/v1/units/${encodeURIComponent(String(unitId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Отредактировать единицу измерения
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchAppUnitUpdate(
    body?: Unit,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Unit>;
  public patchAppUnitUpdate(
    body?: Unit,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Unit>>;
  public patchAppUnitUpdate(
    body?: Unit,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Unit>>;
  public patchAppUnitUpdate(
    body?: Unit,
    observe: any = 'body',
    reportProgress = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Unit>(
      'patch',
      `${this.basePath}/api/v1/units`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppUnitBatchdelete(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public postAppUnitBatchdelete(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public postAppUnitBatchdelete(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public postAppUnitBatchdelete(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/api/v1/units/batch-delete`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавить единицу измерения
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppUnitCreate(
    body?: Unit,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Unit>;
  public postAppUnitCreate(
    body?: Unit,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Unit>>;
  public postAppUnitCreate(
    body?: Unit,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Unit>>;
  public postAppUnitCreate(
    body?: Unit,
    observe: any = 'body',
    reportProgress = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Unit>(
      'post',
      `${this.basePath}/api/v1/units`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
