import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

function getNativeFocused(documentRef: Document): Element | null {
  if (!documentRef.activeElement?.shadowRoot) {
    return documentRef.activeElement;
  }

  let element = documentRef.activeElement.shadowRoot.activeElement;

  while (element?.shadowRoot) {
    element = element.shadowRoot.activeElement;
  }

  return element;
}

@Component({
  selector: 'app-textarea',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
  ],
})
export class TextAreaComponent implements ControlValueAccessor {
  @ViewChild('input', { static: true, read: ElementRef })
  public inputElement?: ElementRef<HTMLInputElement>;

  public value = '';
  public disabled = false;

  private readonly focusableElement?: ElementRef<HTMLInputElement>;

  @Input() public readonly = false;

  @Input() public placeholder = '';

  @Output()
  public readonly valueChange = new EventEmitter<string>();

  @Output()
  public readonly focusChanged = new EventEmitter<boolean>();

  // Эти события слушать обязательно для корректного отслеживания focused
  @HostListener('focusin', ['true'])
  @HostListener('focusout', ['false'])
  public onFocused(focused: boolean) {
    this.focusChanged.emit(focused);
  }

  @HostBinding(`class.focused`)
  public get focused(): boolean {
    const node = this.elementRef.nativeElement;
    const documentRef = node.ownerDocument;

    const element = getNativeFocused(documentRef);
    return !!element && node.contains(element);
  }

  private get hasValue(): boolean {
    return !!this.value;
  }

  public get labelRaised(): boolean {
    return this.focused || this.hasValue;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onChange = (value: string) => { };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouch = () => { };

  constructor(
    private elementRef: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) { }

  public writeValue(value: string = ''): void {
    this.value = value;

    this.changeDetector.detectChanges();
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onModelChange(value: string): void {
    this.updateValue(value);
  }

  private updateValue(value: string): void {
    this.value = value;
    this.valueChange.emit(value);
    this.onChange(value);
  }
}
