import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LoadableSelector } from "../../../loadable-selector";
import { TableHeader } from "@topseller/ui";
import { FormControl } from "@angular/forms";
import { Product } from "../../../../data";
import { CatalogsDataService } from "../../../services";

@Component({
  selector: 'ts-document-expenses-table-empty-row',
  templateUrl: './document-expenses-table-empty-row.component.html',
  styleUrls: ['./document-expenses-table-empty-row.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentExpensesTableEmptyRowComponent extends LoadableSelector {
  @Input() tableHeaders: TableHeader[] = [];
  @Input() selectedItemControl = new FormControl<Product | null>(null);
  @Input() canSelectItems = true;

  constructor(catalogsDataService: CatalogsDataService) {
    super(catalogsDataService);
  }
}
