import { createReducer, on } from '@ngrx/store';

import { ProductGroup } from '../../../data';

import { setProductGroups } from './product-groups.actions';

export interface ProductGroupsState {
  isLoading: boolean;
  item: ProductGroup | null;
}

export const initialState: ProductGroupsState = {
  isLoading: false,
  item: null,
};

export const PRODUCT_GROUPS_FEATURE_KEY = 'product-groups';

export const productGroupsReducer = createReducer(
  initialState,
  on(setProductGroups, (state, { item }) => ({
    ...state,
    item,
  }))
);
