import { Vat } from '../../data';

export function calculateVat(
  price: number,
  quantity: number,
  vatType: Vat,
  priceIncludesVat: boolean ): number {
  if (!vatType?.vatValue) {
    return 0;
  }
  const total = price * quantity;

  return priceIncludesVat
    ? (total / (100 + vatType.vatValue)) * vatType.vatValue
    : (total * vatType.vatValue) / 100;
}

export function  calculatePercent(value: {
  price: number;
  quantity: number;
  discountPricePercent: number;
}): number {
  const {price, quantity, discountPricePercent} = value;
  const amount = quantity * price;
  if (!discountPricePercent) {
    return amount;
  }
  return amount - (amount * discountPricePercent) / 100;
}
