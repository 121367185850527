/**
 * TOPSELLER HUB API
 * Topseller HUB backend API
 *
 * OpenAPI spec version: 23.0406.1952
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import {
  Paginated,
  PaginatedTotal,
  ReportProduct,
  ReportProductDetail,
  ReportProfit,
  ReportProfitDetail,
} from '../model/models';
import {
  ReportProfitDaily,
  ReportProfitDetailTotal,
} from '../model/reports/report-profit-detail';

@Injectable()
export class ReportProductService {
  protected basePath = 'http://denis.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Удалить отчет
   * Удалить отчет
   * @param reportId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteAppReportsReportproductDelete(
    reportId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any> {
    if (reportId === null || reportId === undefined) {
      throw new Error(
        'Required parameter reportId was null or undefined when calling deleteAppReportsReportproductDelete.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/api/v1/report/products/${encodeURIComponent(
        String(reportId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Удалить отчет
   *
   * @param reportId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteAppReportsReportprofitDelete(
    reportId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any> {
    if (reportId === null || reportId === undefined) {
      throw new Error(
        'Required parameter reportId was null or undefined when calling deleteAppReportsReportprofitDelete.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/api/v1/report/profits/${encodeURIComponent(
        String(reportId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Все отчеты
   * Все отчеты
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppReportsReportproductIndex(
    limit?: number,
    offset?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<ReportProduct>> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<ReportProduct>>(
      'get',
      `${this.basePath}/api/v1/report/products`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Детализация отчета
   * Детализация отчета
   * @param reportId
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getReportDetails(
    reportId: string,
    limit?: number,
    offset?: number,
    sortName?:string,
    sortDir?:string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<ReportProductDetail>>;
  public getReportDetails(
    reportId: string,
    limit?: number,
    offset?: number,
    sortName?:string,
    sortDir?:string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public getReportDetails(
    reportId: string,
    limit?: number,
    offset?: number,
    sortName?:string,
    sortDir?:string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (reportId === null || reportId === undefined) {
      throw new Error(
        'Required parameter reportId was null or undefined when calling getAppReportsReportproductReportdetail.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }
    if(sortName !== undefined && sortDir !== null) {
      queryParameters = queryParameters.set(`sort[${sortName}]`, <any>sortDir)
    }
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<ReportProductDetail>>(
      'get',
      `${this.basePath}/api/v1/report/products/${encodeURIComponent(
        String(reportId)
      )}/detail`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Детализация отчета
   *
   * @param hash
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppReportsReportproductReporthashdetail(
    hash: string,
    limit?: number,
    offset?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<ReportProductDetail>> {
    if (hash === null || hash === undefined) {
      throw new Error(
        'Required parameter hash was null or undefined when calling getAppReportsReportproductReporthashdetail.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<ReportProductDetail>>(
      'get',
      `${this.basePath}/api/v1/report/products/hash/${encodeURIComponent(
        String(hash)
      )}/detail`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Отчет
   * Отчет
   * @param reportId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppReportsReportproductView(
    reportId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ReportProduct> {
    if (reportId === null || reportId === undefined) {
      throw new Error(
        'Required parameter reportId was null or undefined when calling getAppReportsReportproductView.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ReportProduct>(
      'get',
      `${this.basePath}/api/v1/report/products/${encodeURIComponent(
        String(reportId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Все отчеты
   *
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppReportsReportprofitIndex(
    limit?: number,
    offset?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<ReportProfit>> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<ReportProfit>>(
      'get',
      `${this.basePath}/api/v1/report/profits`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Детализация отчета
   *
   * @param reportId
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProfitReportDetails(
    reportId: string,
    limit?: number,
    offset?: number,
    sortName?: string,
    sortDir?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<PaginatedTotal<ReportProfitDetail, ReportProfitDetailTotal>>;
  public getProfitReportDetails(
    reportId: string,
    limit?: number,
    offset?: number,
    sortName?: string,
    sortDir?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<
    HttpResponse<PaginatedTotal<ReportProfitDetail, ReportProfitDetailTotal>>
  >;
  public getProfitReportDetails(
    reportId: string,
    limit?: number,
    offset?: number,
    sortName?: string,
    sortDir?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (reportId === null || reportId === undefined) {
      throw new Error(
        'Required parameter reportId was null or undefined when calling getAppReportsReportprofitReportdetail.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }
    if(sortName !== undefined  && sortName && sortDir !== null) {
      queryParameters = queryParameters.set(`sort[${sortName}]`, <any>sortDir)
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<ReportProfitDetail>>(
      'get',
      `${this.basePath}/api/v1/report/profits/${encodeURIComponent(
        String(reportId)
      )}/detail`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Детализация отчета
   *
   * @param reportId
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProfitReportDailyDetails(
    reportId: string,
    limit?: number,
    offset?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<ReportProfitDaily>>;
  public getProfitReportDailyDetails(
    reportId: string,
    limit?: number,
    offset?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Paginated<ReportProfitDaily>>>;
  public getProfitReportDailyDetails(
    reportId: string,
    limit?: number,
    offset?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (reportId === null || reportId === undefined) {
      throw new Error(
        'Required parameter reportId was null or undefined when calling getAppReportsReportprofitReportdetail.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<ReportProfitDetail>>(
      'get',
      `${this.basePath}/api/v1/report/profits/${encodeURIComponent(
        String(reportId)
      )}/daily`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Детализация отчета
   *
   * @param hash
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppReportsReportprofitReporthashdetail(
    hash: string,
    limit?: number,
    offset?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<ReportProfitDetail>> {
    if (hash === null || hash === undefined) {
      throw new Error(
        'Required parameter hash was null or undefined when calling getAppReportsReportprofitReporthashdetail.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<ReportProfitDetail>>(
      'get',
      `${this.basePath}/api/v1/report/profits/hash/${encodeURIComponent(
        String(hash)
      )}/detail`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Отчет
   *
   * @param reportId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppReportsReportprofitView(
    reportId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ReportProfit> {
    if (reportId === null || reportId === undefined) {
      throw new Error(
        'Required parameter reportId was null or undefined when calling getAppReportsReportprofitView.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ReportProfit>(
      'get',
      `${this.basePath}/api/v1/report/profits/${encodeURIComponent(
        String(reportId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавить отчет
   * Добавить отчет
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppReportsReportproductCreate(
    filter?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ReportProduct> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ReportProduct>(
      'get',
      `${this.basePath}/api/v1/report/products?${filter??''}`,
      {
       // body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавить отчет
   *
   * @param filter
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createReport(
    filter?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ReportProfit> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ReportProfit>(
      'get',
      `${this.basePath}/api/v1/report/profits?${filter??''}`,
      {
        // body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
