import {createReducer, on} from '@ngrx/store';

import { BaseData } from '../data';

import { setHubMetadata, updateCustomFields, updateStatuses } from './metadata.actions';

export interface HubState {
  metadata: BaseData | null;
}

export const initialState: HubState = {
  metadata: null,
};

export const metadataReducer = createReducer(
  initialState,
  on(setHubMetadata, (state, {metadata}) => ({
    ...state,
    metadata,
  })),
  on(updateStatuses, (state, {statuses, entityName}) => {
    if (state.metadata) {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          status: {
            ...state.metadata.status,
            [entityName]: statuses,
          },
        },
      };
    } else {
      return state;
    }
  }),
  on(updateCustomFields, (state, {fields, entityName}) => {
    if (state.metadata) {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          customField: {
            ...state.metadata.customField,
            [entityName]: fields,
          },
        },
      };
    } else {
      return state;
    }
  }),
);
