import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';

import { concatMap, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '@topseller/core';

@Injectable()
export class AuthGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.isLoggedIn().pipe(
      concatMap((bol: boolean) => {
        if (!bol) {
          return of(false);
        }
        return this.authService.isSignedUp().pipe(
          map((isSignedUp: boolean) => {
            if (isSignedUp) {
              return true;
            } else {
              this.router.navigate(['signUp']);
              return false;
            }
          })
        );
      })
    );
  }

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.isLoggedIn();
  }

  private isLoggedIn(): Observable<boolean> {
    return this.authService.isLoggedIn().pipe(
      tap((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.router.navigate(['login']);
        }
      })
    );
  }
}
