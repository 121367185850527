/**
 * TOPSELLER HUB API
 * Topseller HUB backend API
 *
 * OpenAPI spec version: 23.0517.1005
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import {
  Correction,
  Paginated,
  BatchUpdate,
  BatchDelete,
} from '../model/models';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class CorrectionService {
  protected basePath = 'http://denis.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Удалить корректировку
   * Удалить корректировку
   * @param correctionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteAppCorrectionDelete(
    correctionId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteAppCorrectionDelete(
    correctionId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteAppCorrectionDelete(
    correctionId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteAppCorrectionDelete(
    correctionId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (correctionId === null || correctionId === undefined) {
      throw new Error(
        'Required parameter correctionId was null or undefined when calling deleteAppCorrectionDelete.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/api/v1/corrections/${encodeURIComponent(
        String(correctionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Отправить корректировку в корзину
   * Отправить корректировку в корзину
   * @param correctionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteAppCorrectionTrash(
    correctionId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Correction>;
  public deleteAppCorrectionTrash(
    correctionId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Correction>>;
  public deleteAppCorrectionTrash(
    correctionId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Correction>>;
  public deleteAppCorrectionTrash(
    correctionId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (correctionId === null || correctionId === undefined) {
      throw new Error(
        'Required parameter correctionId was null or undefined when calling deleteAppCorrectionTrash.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Correction>(
      'delete',
      `${this.basePath}/api/v1/corrections/trash/${encodeURIComponent(
        String(correctionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Список корректировок
   * Список корректировок
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param filter Коллекция фильтров
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppCorrectionIndex(
    limit?: number,
    offset?: number,
    filter?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<Correction>>;
  public getAppCorrectionIndex(
    limit?: number,
    offset?: number,
    filter?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Paginated<Correction>>>;
  public getAppCorrectionIndex(
    limit?: number,
    offset?: number,
    filter?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Paginated<Correction>>>;
  public getAppCorrectionIndex(
    limit?: number,
    offset?: number,
    filter?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }
    if (filter) {
      filter.forEach((element) => {
        queryParameters = queryParameters.append('filter', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<Correction>>(
      'get',
      `${this.basePath}/api/v1/corrections`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Вернуть корректировку из корзины
   * Вернуть корректировку из корзины
   * @param correctionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppCorrectionUntrash(
    correctionId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Correction>;
  public getAppCorrectionUntrash(
    correctionId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Correction>>;
  public getAppCorrectionUntrash(
    correctionId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Correction>>;
  public getAppCorrectionUntrash(
    correctionId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (correctionId === null || correctionId === undefined) {
      throw new Error(
        'Required parameter correctionId was null or undefined when calling getAppCorrectionUntrash.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Correction>(
      'get',
      `${this.basePath}/api/v1/corrections/untrash/${encodeURIComponent(
        String(correctionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Документ корректировки \&quot;Баланс контрагента\&quot;
   * Документ корректировки \&quot;Баланс контрагента\&quot;
   * @param counterpartyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppCorrectionViewcorrectioncounterparty(
    counterpartyId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Correction>;
  public getAppCorrectionViewcorrectioncounterparty(
    counterpartyId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Correction>>;
  public getAppCorrectionViewcorrectioncounterparty(
    counterpartyId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Correction>>;
  public getAppCorrectionViewcorrectioncounterparty(
    counterpartyId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (counterpartyId === null || counterpartyId === undefined) {
      throw new Error(
        'Required parameter counterpartyId was null or undefined when calling getAppCorrectionViewcorrectioncounterparty.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Correction>(
      'get',
      `${this.basePath}/api/v1/corrections/counterparty/${encodeURIComponent(
        String(counterpartyId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Документ корректировки \&quot;Остаток на счете\&quot;
   * Документ корректировки \&quot;Остаток на счете\&quot;
   * @param organizationId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppCorrectionViewcorrectionorganization(
    organizationId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Correction>;
  public getAppCorrectionViewcorrectionorganization(
    organizationId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Correction>>;
  public getAppCorrectionViewcorrectionorganization(
    organizationId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Correction>>;
  public getAppCorrectionViewcorrectionorganization(
    organizationId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (organizationId === null || organizationId === undefined) {
      throw new Error(
        'Required parameter organizationId was null or undefined when calling getAppCorrectionViewcorrectionorganization.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Correction>(
      'get',
      `${this.basePath}/api/v1/corrections/organization/${encodeURIComponent(
        String(organizationId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Изменить документ корректировки \&quot;Баланс контрагента\&quot;
   * Изменить документ корректировки \&quot;Баланс контрагента\&quot;
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchAppCorrectionUpdatecorrectioncounterparty(
    body?: Correction,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Correction>;
  public patchAppCorrectionUpdatecorrectioncounterparty(
    body?: Correction,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Correction>>;
  public patchAppCorrectionUpdatecorrectioncounterparty(
    body?: Correction,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Correction>>;
  public patchAppCorrectionUpdatecorrectioncounterparty(
    body?: Correction,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Correction>(
      'patch',
      `${this.basePath}/api/v1/corrections/counterparty`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Изменить документ корректировки \&quot;Остаток на счете\&quot;
   * Изменить документ корректировки \&quot;Остаток на счете\&quot;
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchAppCorrectionUpdatecorrectionorganization(
    body?: Correction,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Correction>;
  public patchAppCorrectionUpdatecorrectionorganization(
    body?: Correction,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Correction>>;
  public patchAppCorrectionUpdatecorrectionorganization(
    body?: Correction,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Correction>>;
  public patchAppCorrectionUpdatecorrectionorganization(
    body?: Correction,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Correction>(
      'patch',
      `${this.basePath}/api/v1/corrections/organization`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Удалить несколько корректировок
   * Удалить несколько корректировок
   * @param filter Коллекция фильтров
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppCorrectionBatchdelete(
    filter?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BatchDelete>;
  public postAppCorrectionBatchdelete(
    filter?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BatchDelete>>;
  public postAppCorrectionBatchdelete(
    filter?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BatchDelete>>;
  public postAppCorrectionBatchdelete(
    filter?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (filter) {
      filter.forEach((element) => {
        queryParameters = queryParameters.append('filter', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<BatchDelete>(
      'post',
      `${this.basePath}/api/v1/corrections/batch-delete`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавить несколько документов корректировки \&quot;Баланс контрагента\&quot;
   * Добавить несколько документов корректировки \&quot;Баланс контрагента\&quot;
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppCorrectionCounterpartybatchcreate(
    body?: Paginated<Correction>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BatchUpdate>;
  public postAppCorrectionCounterpartybatchcreate(
    body?: Paginated<Correction>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BatchUpdate>>;
  public postAppCorrectionCounterpartybatchcreate(
    body?: Paginated<Correction>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BatchUpdate>>;
  public postAppCorrectionCounterpartybatchcreate(
    body?: Paginated<Correction>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<BatchUpdate>(
      'post',
      `${this.basePath}/api/v1/corrections/counterparty/batch-create`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Отредактировать несколько документов корректировки \&quot;Баланс контрагента\&quot;
   * Отредактировать несколько документов корректировки \&quot;Баланс контрагента\&quot;
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppCorrectionCounterpartybatchupdate(
    body?: Paginated<Correction>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BatchUpdate>;
  public postAppCorrectionCounterpartybatchupdate(
    body?: Paginated<Correction>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BatchUpdate>>;
  public postAppCorrectionCounterpartybatchupdate(
    body?: Paginated<Correction>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BatchUpdate>>;
  public postAppCorrectionCounterpartybatchupdate(
    body?: Paginated<Correction>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<BatchUpdate>(
      'post',
      `${this.basePath}/api/v1/corrections/counterparty/batch-update`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавить документ корректировки \&quot;Баланс контрагента\&quot;
   * Добавить документ корректировки \&quot;Баланс контрагента\&quot;
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppCorrectionCreatecorrectioncounterparty(
    body?: Correction,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Correction>;
  public postAppCorrectionCreatecorrectioncounterparty(
    body?: Correction,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Correction>>;
  public postAppCorrectionCreatecorrectioncounterparty(
    body?: Correction,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Correction>>;
  public postAppCorrectionCreatecorrectioncounterparty(
    body?: Correction,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Correction>(
      'post',
      `${this.basePath}/api/v1/corrections/counterparty`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавить документ корректировки \&quot;Остаток на счете\&quot;
   * Добавить документ корректировки \&quot;Остаток на счете\&quot;
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppCorrectionCreatecorrectionorganization(
    body?: Correction,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Correction>;
  public postAppCorrectionCreatecorrectionorganization(
    body?: Correction,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Correction>>;
  public postAppCorrectionCreatecorrectionorganization(
    body?: Correction,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Correction>>;
  public postAppCorrectionCreatecorrectionorganization(
    body?: Correction,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Correction>(
      'post',
      `${this.basePath}/api/v1/corrections/organization`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавить несколько документов корректировки \&quot;Остаток на счете\&quot;
   * Добавить несколько документов корректировки \&quot;Остаток на счете\&quot;
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppCorrectionOrganizationbatchcreate(
    body?: Paginated<Correction>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BatchUpdate>;
  public postAppCorrectionOrganizationbatchcreate(
    body?: Paginated<Correction>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BatchUpdate>>;
  public postAppCorrectionOrganizationbatchcreate(
    body?: Paginated<Correction>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BatchUpdate>>;
  public postAppCorrectionOrganizationbatchcreate(
    body?: Paginated<Correction>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<BatchUpdate>(
      'post',
      `${this.basePath}/api/v1/corrections/organization/batch-create`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Отредактировать несколько документов корректировки \&quot;Остаток на счете\&quot;
   * Отредактировать несколько документов корректировки \&quot;Остаток на счете\&quot;
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppCorrectionOrganizationbatchupdate(
    body?: Paginated<Correction>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BatchUpdate>;
  public postAppCorrectionOrganizationbatchupdate(
    body?: Paginated<Correction>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BatchUpdate>>;
  public postAppCorrectionOrganizationbatchupdate(
    body?: Paginated<Correction>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BatchUpdate>>;
  public postAppCorrectionOrganizationbatchupdate(
    body?: Paginated<Correction>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<BatchUpdate>(
      'post',
      `${this.basePath}/api/v1/corrections/organization/batch-update`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
