import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Observable, of, tap } from 'rxjs';

import { Entity } from '../model';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

@Injectable()
export class UnsavedChangesGuard {
  constructor(private dialog: MatDialog) {}

  public canDeactivate(component: Entity): Observable<boolean> {
    if (component.form.dirty && !component.isDeletedState) {
      const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(
        ConfirmDialogComponent,
        {
          data: {
            title: 'Данные были изменены',
            content:
              'Если продолжить без сохранения, то все внесенные изменения будут утеряны',
            okBtn: 'Продолжить без сохранения',
          },
          panelClass: ['ts-modal-panel'],
          backdropClass: 'ts-modal-backdrop',
          id: 'ts-modal',
          width: '500px',
        }
      );

      return dialogRef.afterClosed().pipe(
        tap((result) => {
          if (result) {
            component.form.markAsPristine();
          }
        })
      );
    }

    return of(true);
  }
}
