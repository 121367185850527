import {createAction, props} from "@ngrx/store";
import {ProductGroup} from "../../../data";

export const loadProductGroups = createAction(
  '[HUB Product Groups] Load Product Groups'
);

export const setProductGroups = createAction(
  '[HUB Product Groups] Set Product Groups',
  props<{ item: ProductGroup}>()
);
