import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RemoveButtonComponent} from "./remove-button.component";



@NgModule({
  declarations: [RemoveButtonComponent],
  imports: [
    CommonModule
  ],
  exports:[RemoveButtonComponent]
})
export class TsRemoveButtonModule { }
