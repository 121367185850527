<form [formGroup]="form" (ngSubmit)="!showHeaderBtns ? createProject() : updateProject()"
      class="d-flex flex-column gap-3 ts-page__content">
  <ts-handbook-item-control-bar [handbooksSettings]="'Проект'" [editItemRole]="editItemRole"/>
  <ts-scroll>
    <ts-form-controls-card label="Общее" [isExpandable]="false">
      <div slot="card-actions">
        <ts-role-access-wrapper [requiredRole]="deleteItemRole">
          <button *ngIf="showHeaderBtns$ | async" type="button" class="ts-btn error ts-btn-bezeled ts-btn-md"
                  (click)="deleteProject()">
            <span class="ts-icon ts-icon-actions-Trash"></span>
            <span class="">Удалить проект</span>
          </button>
        </ts-role-access-wrapper>
      </div>
      <div slot="card-breakable-actions" class="h-100 d-flex align-items-center">
        <app-toggle-switch formControlName="isDefault" label="Сделать основным" />
      </div>
      <div class="form-controls-card__content">
        <ts-role-access-wrapper [requiredRole]="editItemRole">
          <div class="col-9 col-xl-12">
            <app-title formControlName="note" title="Примечание" [multirow]="true" />
          </div>
        </ts-role-access-wrapper>
      </div>
    </ts-form-controls-card>
  </ts-scroll>
</form>

<!-- Confirm Dialogs -->

<app-confirm-dialog
  *ngIf="showDialog"
  (selection)="onDeactivateSelection($event)"
></app-confirm-dialog>
