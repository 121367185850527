<div class="year-container calendar-wrapper" *ngIf="year; else calendar" style="max-width: 600px; max-height: 400px">
  <ts-scroll>
  <ts-year-selector [value]="year" (yearClick)="onSelectYear($event)"></ts-year-selector>
  </ts-scroll>
</div>

<ng-template #calendar>
  <div class="ts-calendar calendar-wrapper">
    <ts-calendar-header [month]="month" (yearClick)="onYearClick($event)"
      (valueChange)="onMonthChange($event)"
                        (selectToday)="selectToday()"
    ></ts-calendar-header>

    <ts-calendar-month [month]="month" [value]="value" (dayClick)="onDayClick($event)"></ts-calendar-month>
  </div>
</ng-template>
