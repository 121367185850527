import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[tsFocusable]',
  standalone: true,
})
export class FocusableDirective implements AfterViewInit {
  constructor(private host: ElementRef) {
  }

  ngAfterViewInit() {
    // Проверяем, является ли host.nativeElement элементом input
    if (this.host.nativeElement.tagName === 'INPUT') {
      // Если это input, фокусируемся на нем напрямую
      this.host.nativeElement.focus();
    } else {
      // Если это контейнер, ищем первый input внутри
      const firstInput = this.host.nativeElement.querySelector('input');
      if (firstInput) {
        firstInput.focus();
      }
    }
  }
}
