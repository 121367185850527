import { Routes } from '@angular/router';

import { environment } from '../../../environments/environment';
import { AuthGuard } from "../services/auth.guard";
import { ROLES, RolesGuard } from "@topseller/core";
import { ServiceActivatedGuard, ServiceAvailableGuard } from '../../pages/content-pages/guards';

export const APPS_ROUTES: Routes = [
  {
    path: 'wb-ads',
    canActivate: [ServiceActivatedGuard],
    data: {
      serviceId: 'wbads',
    },
    loadChildren: () =>
      import('../../../wb-ads/wb-ads.module').then((m) => m.WbAdsModule),
  },
  {
    path: 'connect',
    loadChildren: () =>
      import('../../../connect/connect.module').then((m) => m.ConnectModule),
    canActivate: [RolesGuard, ServiceActivatedGuard, ServiceAvailableGuard],
    data: {
      externalUrl: environment.connectUrl,
      appName: 'application.connect',
      requiredRole: ROLES.ROLE_ACCOUNT_APPLICATION_VIEW,
      serviceId: 'connect',
    },
  },
  {
    path: 'pim',
    loadChildren: () =>
      import('../../../connect/connect.module').then((m) => m.ConnectModule),
    canActivate: [RolesGuard, ServiceActivatedGuard],
    data: {
      externalUrl: environment.pimUrl,
      appName: 'application.pim',
      requiredRole: ROLES.ROLE_ACCOUNT_APPLICATION_VIEW,
      serviceId: 'pim',
    },
  },
  {
    path: 'journal',
    loadChildren: () =>
      import('../../../connect/connect.module').then((m) => m.ConnectModule),
    canActivate: [RolesGuard],
    data: {
      externalUrl: environment.journalUrl,
      appName: 'application.journal',
    }
  },
  {
    path: 'hub',
    canActivate: [ServiceActivatedGuard],
    data: {
      serviceId: 'hub',
    },
    loadChildren: () =>
      import('../../../../../../libs/hub/src/lib/hub.module').then(
        (m) => m.HubModule
      ),
  },
  {
    path: 'ai',
    canActivate: [ServiceActivatedGuard],
    data: {
      serviceId: 'aitools',
    },
    loadChildren: () => import('../../../ai/ai.module').then((m) => m.AiModule),
  },
  {
    path: 'dropship',
    canActivate: [ServiceActivatedGuard],
    data: {
      serviceId: 'aitools',
    },
    loadChildren: () =>
      import('../../../dropship/dropship.module').then((m) => m.DropshipModule),
  },
  {
    path: 'repricer',
    canActivate: [ServiceActivatedGuard],
    data: {
      serviceId: 'repricer',
    },
    loadChildren: () =>
      import('../../../../../../libs/repricer/src/lib/repricer.module').then(
        (m) => m.RepricerModule
      ),
  },
  {
    path: 'topsellerid',
    loadChildren: () =>
      import('../../../../../../libs/identity/src/lib/identity.module').then(
        (m) => m.IdentityModule
      ),
  },
  {
    path: 'analytics',
    canActivate: [ServiceActivatedGuard],
    data: {
      serviceId: 'analytics',
    },
    loadChildren: () =>
      import('../../../../../../libs/analytics/src/lib/analytics.module').then(
        (m) => m.AnalyticsModule
      ),
  },
  {
    path: 'rnp',
    loadChildren: () =>
      import('../../../../../../libs/rnp/src/lib/rnp.module').then(
        (m) => m.RnpModule
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../pages/main-page/main-page.module').then((m) => m.MainPageModule)
  }
];
