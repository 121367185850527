import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableHeader} from "../../types";

@Component({
  selector: 'ts-table-header-cell',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './table-header-cell.component.html',
  styleUrls: ['./table-header-cell.component.scss'],
})
export class TableHeaderCellComponent {
  @Input() tableHeader!: TableHeader;
}
