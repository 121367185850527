import {IdentityMatcher, PeriodDateRange, PeriodType, StringHandler} from './types';

export const defaultIdentityMatcher: IdentityMatcher<unknown> = (
  prevItem,
  nextItem
) => prevItem === nextItem || bothEmpty(prevItem, nextItem);

export const defaultStringHandler: StringHandler<unknown> = (item) =>
  String(item);

function bothEmpty(prevItem: unknown, nextItem: unknown): boolean {
  return (
    Array.isArray(prevItem) &&
    Array.isArray(nextItem) &&
    !prevItem.length &&
    !nextItem.length
  );
}

export function getNativeFocused(documentRef: Document): Element | null {
  if (!documentRef.activeElement?.shadowRoot) {
    return documentRef.activeElement;
  }

  let element = documentRef.activeElement.shadowRoot.activeElement;

  while (element?.shadowRoot) {
    element = element.shadowRoot.activeElement;
  }

  return element;
}


export function periodTypeToDateRange(periodType:PeriodType):PeriodDateRange{
  const now = new Date();
  let startDate: Date;
  let endDate: Date;

  switch (periodType) {
    case PeriodType.yesterday:
      startDate = new Date(now);
      startDate.setDate(now.getDate() - 1);
      startDate.setHours(0, 0, 0, 0);

      endDate = new Date(now);
      endDate.setDate(now.getDate() - 1);
      endDate.setHours(23, 59, 59, 999);
      break;

    case PeriodType.today:
      startDate = new Date(now);
      startDate.setHours(0, 0, 0, 0);

      endDate = new Date(now);
      endDate.setHours(23, 59, 59, 999);
      break;

    case PeriodType.week:
      // Получение даты ближайшего прошедшего понедельника
      startDate = new Date(now);
      startDate.setDate(now.getDate() - (now.getDay() || 7) + 1);
      startDate.setHours(0, 0, 0, 0);

      // Получение даты ближайшего наступающего воскресенья
      endDate = new Date(now);
      endDate.setDate(now.getDate() + (7 - (now.getDay() || 7)));
      endDate.setHours(23, 59, 59, 999);
      break;

    case PeriodType.month:
      startDate = new Date(now);
      startDate.setDate(1);
      startDate.setHours(0, 0, 0, 0);

      endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0); // 0 - это последний день предыдущего месяца
      endDate.setHours(23, 59, 59, 999);
      break;
    default:
      startDate = new Date();
      endDate = new Date();
  }

  return {dateFrom:startDate.toISOString(),dateTo:endDate.toISOString(),periodType}
}

export function setHours(dateStr:string, hours: number, min?: number, sec?: number, ms?: number):string{
  const date = new Date(dateStr);
  date.setHours(hours, min, sec, ms);
  return  date.toISOString();
}

export function dateRangeToPeriodType(startDateStr:string, endDateStr:string){
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const periodTypes: PeriodType[] = [PeriodType.yesterday, PeriodType.today, PeriodType.week, PeriodType.month];

  for (const periodType of periodTypes) {
    const range = periodTypeToDateRange(periodType);
    if (datesAreEqual(startDate, new Date(range.dateFrom)) && datesAreEqual(endDate, new Date(range.dateTo))) {
      return periodType;
    }
  }

  return PeriodType.custom;
}

function datesAreEqual(date1: Date, date2: Date): boolean {
  return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate() &&
      date1.getHours() === date2.getHours() &&
      date1.getMinutes() === date2.getMinutes() &&
      date1.getSeconds() === date2.getSeconds();
}


export function getDeclension(count: number, nominativ: string, genetiv: string, plural: string): string {
  count = count % 100;
  if (count >= 11 && count <= 19) {
    return plural;
  }

  const i = count % 10;

  switch (i) {
    case 1: {
      return nominativ;
    }
    case 2:
    case 3:
    case 4: {
      return genetiv;
    }
    default: {
      return plural;
    }
  }
}
