import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {EMPTY, finalize, Observable, of, tap} from 'rxjs';
import {Product, ProductService} from '../data';
import {catchError, map} from 'rxjs/operators';
import {Store} from "@ngrx/store";
import {setShowLoader} from "@topseller/core";

@Injectable({
  providedIn: 'root',
})
export class ProductResolver {
  constructor(private productService: ProductService,
              private router: Router,
              private store: Store) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Product | boolean> {
    const id: string = route.paramMap.get('id') ?? '';

    if (id === 'create') {
      return of(true);
    } else if (id.length > 10) {
      this.store.dispatch(setShowLoader({showLoader: true}));
      return this.productService.getProductView(id).pipe(
        tap(_ => this.store.dispatch(setShowLoader({showLoader: false}))),
        catchError(() => {
          this.store.dispatch(setShowLoader({showLoader: false}))
          this.router.navigate(['/hub/products']);
          return EMPTY;
        }),
      );
    }

    this.router.navigate(['/hub/products']);
    return of(false);
  }
}
