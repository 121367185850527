<div class="form-controls-card">
  <div class="grid-container">
    <div class="grid-label">Справочник</div>
    <div class="actions-dd">
      <ts-actions-dropdown [actions]="actions" [isDisabledBtnAction]="!selectedProductsIds.length"/>
    </div>
    <div class="btn-close-modal">
      <i class="ts-icon ts-icon-actions-Close text-2lg ts-text-icon-accent-dim-color cursor-pointer"
         (click)="closeDialog.emit()"></i>
    </div>
    <div class="search-input">
      <app-title [formControl]="search" placeholder="Поиск среди добавленных позиций.."
                 leadingIcon="ts-icon-search-search-normal_alt" [hasCleaner]="true"></app-title>
    </div>
    <div class="toggle-selector">
      <div class="ts-toggle-group flex-shrink-0">
        <div class="ts-toggle-group__button " title="Выбор товаров" (click)="changeView.emit()">
          Выбор товаров
        </div>
        <div class="ts-toggle-group__button selected" title="Список выбранных"
        >
          Список выбранных
        </div>
      </div>
    </div>
    <div class="btn-apply">
      <button
        type="button"
        class="ts-btn primary ts-btn-filled ts-btn-md ts-btn-icon flex-shrink-0"
        (click)="apply()"
      >
        <span class="ts-icon ts-icon-actions-Add"></span>
        Добавить позиции ({{selectedProducts.length}})
      </button>
    </div>
  </div>


  <div class="ts-table-container">
    <div class="overflow-auto h-100 flex-grow-1">
      <ts-scroll>
        <div class="ts-table">
          <div class="ts-table__header">
            <ts-checkbox
              size="medium"
              [active]="allSelected"
              [indeterminate]="!allSelected && !!selectedProductsIds.length"
              (toggle)="toggleAll($event)"
            />

            <ng-container *ngFor="let header of tableHeaders; let i = index">
              <div class="cell" [ngStyle]="{ 'min-width.px': header.width, 'flex-basis.px': header.width }">
                <ts-table-header-cell2 [tableHeader]="header"></ts-table-header-cell2>
              </div>
            </ng-container>
          </div>

          <div class="ts-table__row" *ngFor="let line of productsToDisplay$|async;
                  index as i;
                  let last = last;
                  let ind = index"
               [ngClass]="{'selected':isSelectedProduct(line.product.id)}"
          >
            <ts-checkbox
              size="medium"
              [active]="isSelectedProduct(line.product.id)"
              (toggle)="selectProduct($event, line.product.id)"
            />
            <ng-container *ngFor="let header of tableHeaders; let i = index">
              <ng-container [ngSwitch]="header.key">
                <div class="cell" [ngStyle]="{ 'width.px': header.width, 'flex-basis.px': header.width }">

                  <div class="ts-content" [ngStyle]="{'text-align': header.textAlign || 'left'}">

                    <div *ngSwitchCase="'counter'" class="d-flex justify-content-center">
                      <ts-input-counter [(ngModel)]="line.quantity" [maxValue]="maxItemsCount" [minValue]="0"/>
                    </div>

                    <ts-product-name-cell
                      *ngSwitchCase="'name'"
                      [product]="line.product"
                    />

                    <ts-product-skus-cell *ngSwitchCase="'identifiers'"
                                          [product]="line.product"></ts-product-skus-cell>


                    <ts-product-dimensions-cell *ngSwitchCase="'dimensions'" [product]="line.product"/>

                    <ts-modified-by-cell *ngSwitchCase="'modifiedAt'"
                                         [changeableRecord]="line.product"></ts-modified-by-cell>
                  </div>

                </div>
              </ng-container>
            </ng-container>

          </div>

        </div>

        <ng-template #empty>
          Empty
        </ng-template>
      </ts-scroll>
    </div>
  </div>

</div>

