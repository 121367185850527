import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
  TsLetDirective,
  TsRepeatTimesDirective,
} from '@topseller/cdk/directives';

import { TsPaginatorComponent } from './paginator.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TsLetDirective,
    TsRepeatTimesDirective,
  ],
  declarations: [TsPaginatorComponent],
  exports: [TsPaginatorComponent],
  providers: [],
})
export class TsPaginatorModule {}
