import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TsChipComponent } from "./chip.component";

@NgModule({
  imports: [CommonModule],
  declarations: [TsChipComponent],
  exports: [TsChipComponent],
})

export class TsChipModule {}
