<div [formGroup]="formGroup" class="d-flex flex-row gap-2 mt-2 align-items-center"
     [ngClass]="{'flex-column':layout==='vertical'}">
  <ts-input-date-time class="flex-grow-1 w-100" [label]="dateFromInputLabel"
                      [formControlName]="dateFromControlName"></ts-input-date-time>
  <!-- TYPE DATERANGE TO -->
  <ts-input-date-time class="flex-grow-1 w-100" [label]="dateToInputLabel" [defaultTime]="endOfDay"
                      [formControlName]="dateToControlName"></ts-input-date-time>
</div>
<div class="d-flex flex-row gap-2 mt-2">
  <div class="period-select-button" *ngFor="let period of periodItems"
       [ngClass]="{'selected': selectedPeriod === period.type}"
       (click)="selectPeriod(period.type)">
    {{  period.label }}
  </div>
</div>
