import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { HubEntity } from '../../../../data';
import { CustomFieldsModalComponent } from '../add-edit-custom-fields-modal.component';

import { closeCustomFieldsModal, openCustomFieldsModal } from './actions';

@Injectable()
export class CustomFieldModalEffects {
  public openCustomFieldsModal = createEffect(() =>
    this.actions.pipe(
      ofType(openCustomFieldsModal),
      exhaustMap(({ entity,relatedEntities }: { entity: HubEntity,relatedEntities?:HubEntity[] }) => {
        const dialogConfig = {
          disableClose: true,
          backdropClass: 'ts-modal-backdrop',
          data: {
            hubEntity: entity,
            relatedEntities: relatedEntities,
            title: 'Настройка дополнительных полей',
          },
          panelClass: ['ts-modal-panel','ts-modal-panel-lg' ],
          width: '100vw',
          maxWidth: '100vw',
          id: 'ts-modal',
        };

        const dialogRef = this.dialog.open(
          CustomFieldsModalComponent,
          dialogConfig
        );

        return dialogRef.afterClosed();
      }),
      map(() => closeCustomFieldsModal())
    )
  );

  constructor(private actions: Actions, private dialog: MatDialog) {}
}
