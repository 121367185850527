<div class="ts-table__row ts-empty-row">
  <div class="cell cell-empty cell-empty__select">
    <div class="ts-content">
      <ts-select
        size="cell"
        placeholder="Статья расходов"
        [identityMatcher]="recordIdentityMatcher"
        [stringify]="recordStringify"
        [formControl]="selectedItemControl"
      >
        <ts-entity-list
          [queryFn]="sourceQueryFn"
          [itemTemplate]="sourceItem"
        ></ts-entity-list>
      </ts-select>
    </div>
  </div>
  <div class="cell" *ngFor="let header of tableHeaders|slice:  canSelectItems?2:1"
       [style]="'flex-basis:'+header.width+'px'">
    <div class="ts-content">

    </div>
  </div>
</div>

<ng-template #sourceItem let-item>
  <ts-source-list-item [item]="item"></ts-source-list-item>
</ng-template>
