import { Directive, inject } from '@angular/core';
import { ApiEntityName, HubEntity } from '../../data';
import {
  debounceTime,
  distinctUntilChanged,
  from,
  map,
  merge,
  of,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs';
import {
  BaseTableWithSortComponent,
  toFilterString,
} from '@topseller/common/base-list';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SidebarService } from '@topseller/common/sidebar';
import { ValueUnitPipe } from '@topseller/ui/pipes/value-unit';
import { FiltersService } from '../services/filters.service';
import { TableFiltersComponent } from './table-filters/table-filters.component';
import { TsDataEntity } from '@topseller/core';
import { StatusesEditComponent } from './statuses-edit/statuses-edit.component';
import { TableSettingsService } from '@topseller/common/table/table-settings-service';
import { ExportDocumentsService } from "../services";
import {take} from "rxjs/operators";

@Directive()
export abstract class BaseTableWithFilterComponent<
  T extends TsDataEntity
> extends BaseTableWithSortComponent<T> {
  abstract entity: HubEntity;
  lastAppliedFilter?: any;
  protected canUseSavedFilters = true;

  private isFilterOpened = false;
  private isStatusEditOpened = false;
  private exportDocumentsService: ExportDocumentsService;


  protected constructor(
    private sidebarService: SidebarService,
    route: ActivatedRoute,
    valueUnitPipe: ValueUnitPipe,
    protected filtersService: FiltersService,
    router: Router,
    tableSettingsService?: TableSettingsService
  ) {
    super(route, router, tableSettingsService);
    this.exportDocumentsService = inject(ExportDocumentsService);
  }

  public openFilter(): void {
    if (this.isFilterOpened) {
      return;
    }
    this.isFilterOpened = true;
    this.sidebarService
      .sidebar<TableFiltersComponent, unknown>(TableFiltersComponent, {
        locals: {
          entityName: this.entity,
          applyFilterAction: this.applyFilter,
        },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isFilterOpened = false;
      });
  }

  public openEditStatus(relatedEntities: HubEntity[] = []) {
    if (this.isStatusEditOpened) {
      return;
    }
    this.isStatusEditOpened = true;
    this.sidebarService
      .sidebar<StatusesEditComponent, unknown>(StatusesEditComponent, {
        locals: {entityName: this.entity, relatedEntities},
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isStatusEditOpened = false;
      });
  }

  applyFilter = (filter: any) => {
    return;
  };

  override ngOnInit() {
    this.apiEntityName = ApiEntityName[this.entity];
    super.ngOnInit();
  }

  override initializeCurrentFilter() {
    this.currentFilter$ = merge(
      this.route.queryParams,
      this.refresh$.pipe(switchMap(() => this.route.queryParams))
    ).pipe(
      debounceTime(200), // Отклоняем первичный запрос без фильтров
      switchMap((params: Params) => {
        // Получаем сохраненный фильтр асинхронно
        return from(this.filtersService.getSavedFilter(this.entity)).pipe(
          switchMap((savedFilter) => {
            if (
              Object.keys(params).length === 0 &&
              savedFilter &&
              this.canUseSavedFilters
            ) {
              // Если в URL нет параметров и есть сохраненные фильтры
              this.router.navigate([], {
                relativeTo: this.route,
                queryParams: savedFilter,
                queryParamsHandling: 'merge',
                state: this.state,
                replaceUrl: true
              });
              return of({
                ...savedFilter,
                page: +savedFilter['page'] || 0,
                limit: +savedFilter['limit'] || 50,
                search: savedFilter['search'] || '',
                filter: this.extractFilter(savedFilter),
                sortName: savedFilter['sortName'],
                sortDir: savedFilter['sortDir'],
              });
            }

            // Получаем объект фильтра из параметров
            let filterObject = this.extractFilter(params);
            const {page, search = '', sortName, sortDir, limit} = params;

            // Объединяем логику получения дефолтного фильтра и сортировки
            return this.filtersService.getDefaultFilter(this.entity).pipe(
              withLatestFrom(
                this.tableIdentifier && this.tableSettingsService
                  ? this.tableSettingsService.getDefaultSort(
                    this.tableIdentifier
                  )
                  : of(null)
              ),
              map(([defaultFilter, defaultSort]) => {
                let filterString = toFilterString(filterObject);
                let queryParamsUpdate: any = {};

                if (filterString === '') {
                  if (this.lastAppliedFilter) {
                    filterObject = this.lastAppliedFilter;
                  } else {
                    filterObject = defaultFilter;
                  }
                  filterString = toFilterString(filterObject);
                  queryParamsUpdate = {...filterObject};
                }

                // Проверяем и обновляем параметры сортировки, если они отсутствуют и доступны
                if (!sortName && !sortDir && defaultSort) {
                  queryParamsUpdate.sortName = defaultSort.columnKey;
                  queryParamsUpdate.sortDir = defaultSort.sortDirection;
                }

                // Навигация с обновленными параметрами, если требуется
                if (Object.keys(queryParamsUpdate).length > 0) {
                  this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: queryParamsUpdate,
                    queryParamsHandling: 'merge',
                    state: this.state,
                    replaceUrl: true
                  });
                }

                this.lastAppliedFilter = filterObject;

                if (this.canUseSavedFilters) {
                  // Сохраняем последний примененный фильтр асинхронно
                  this.filtersService.saveLastAppliedFilter(this.entity, {
                    ...params,
                    ...queryParamsUpdate,
                  });
                }
                return {
                  page: page ? page - 1 : 0,
                  limit: limit || 50,
                  search,
                  filter: filterObject,
                  filterString,
                  sortName: queryParamsUpdate.sortName || sortName,
                  sortDir: queryParamsUpdate.sortDir || sortDir,
                };
              })
            );
          })
        );
      }),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    );
  }

  public exportToExcel() {
    this.exportDocumentsService.exportToExcel(this.entity, this.filterStringFromQueryParams)
      .pipe(take(1))
      .subscribe(
      res=>{
        if (res.blob) {
          const url = window.URL.createObjectURL(res.blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', res.filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      }
    );
  }

}
