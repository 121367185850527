<div [formGroup]="form" class="ts-modal-layout">
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center"><span class="ts-subtitle">Расходы на товар</span>
      <span class="ts-icon ts-icon-actions-Close ts-text-icon-neutral-color text-2xl" mat-dialog-close></span>
    </div>
  </div>
  <mat-dialog-content>
    <div class="ts-table-container ts-table-container-short ts-expenses-table-modal">
      <div class="overflow-auto h-100 flex-grow-1">
        <ts-scroll>
          <div class="ts-table ts-table-fixed-row">
            <div class="ts-table__header">

              <ng-container *ngFor="let header of tableHeaders; let i = index">
                <div class="cell" [ngStyle]="{ 'min-width.px': header.width, 'flex-basis.px': header.width }">
                  <ts-table-header-cell [tableHeader]="header"></ts-table-header-cell>
                </div>
              </ng-container>
            </div>

            <div class="ts-table__row " *ngFor="let line of items$ | async;
                  index as i;
                  let last = last;
                  let ind = index"
                 [ngClass]="{'selected':isSelected(i)}"
                 [formGroup]="line"
            >
              <ng-container *ngFor="let header of tableHeaders; let i = index">
                <ng-container [ngSwitch]="header.key">
                  <div class="cell" [ngStyle]="{ 'width.px': header.width, 'flex-basis.px': header.width }"
                  [ngClass]="{'error':line.invalid}">

                    <div class="ts-content ts-caption-strong" [ngStyle]="{'text-align': header.textAlign || 'left'}">

                      <div *ngSwitchCase="'source'">
                        <span>{{ line.value.source.name }}</span>
                      </div>

                      <div *ngSwitchCase="'sourceGroup'">
                        <span>{{ line.value.source.sourceGroup.name }}</span>
                      </div>
                      <div *ngSwitchCase="'amount'" [ngClass]="{'cell-error':!line.get('amount')?.valid}">
                        <ts-view-edit-mode-switch>
                          <ng-template tsViewMode="view">
                            <div class="w-100">
                              <div class="table-editable-cell"
                                   *ngIf="line.value.amount !== null">{{line.value.amount | currencyFormat}}</div>
                              <div class="table-editable-cell-placeholder"
                                   *ngIf="line.value.amount === null">
                                Сумма расхода
                              </div>
                            </div>
                          </ng-template>
                          <ng-template tsViewMode="edit">
                            <input
                              [ngClass]="{ 'error-field': line.invalid }"
                              class="cell-input error-control" tsFocusable [tsInputNumber]="{ formatMultiplier: 100 }"
                              formControlName="amount"/>
                          </ng-template>
                        </ts-view-edit-mode-switch>
                      </div>

                      <div *ngSwitchCase="'expensedAt'">
                        <ts-input-date-time [cleaner]="false" formControlName="expensedAt"
                                            data-size="cell"></ts-input-date-time>

                      </div>

                      <div *ngSwitchCase="'delete'">
                        <button class="ts-btn error ts-btn-bezeled ts-btn-sm ts-btn-icon" (click)="delete(ind)">
                          <i class="ts-icon ts-icon-actions-Trash"></i>
                        </button>
                      </div>
                    </div>

                  </div>
                </ng-container>
              </ng-container>

            </div>

            <ts-document-expenses-table-empty-row [selectedItemControl]="tableLinePlaceholder"
                                                  [tableHeaders]="tableHeaders"
                                                  [canSelectItems]="false"/>

          </div>
        </ts-scroll>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="d-flex gap-2 w-100">
      <button type="button" (click)="save()" class="ts-btn primary ts-btn-filled ts-btn-md w-100">Сохранить</button>
      <button class="ts-btn btn-grey ts-btn-bezeled ts-btn-md w-100" type="button" (click)="close()">
        Отмена
      </button>
    </div>
  </mat-dialog-actions>

</div>

