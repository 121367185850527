import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable, Optional } from "@angular/core";
import { AccountsAbstractService } from "./abstract.service";
import { AccountInfo, AddAccount, Marketplace, PatchAccount } from "../models/account.interface";
import { EntityRecord, Paginated } from "../types";
import { Observable } from "rxjs";
import { BASE_PATH } from "../variables";


@Injectable()
export class AccountsService extends AccountsAbstractService {
  

  protected basePath = 'http://repricer'
  

  constructor(
    private http: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,

  ) {
    super()
    if (basePath) {
      this.basePath = basePath;
    }
  }

  // getAccounts(marketplace_id: string) {
  //   const params = new HttpParams().set('marketplace_id', marketplace_id);
  //   return this.http.get<Paginated<AccountInfo>>(`${this.rpUrl}/organization/`, { params: params });
  // }

  getAccounts(
    limit?: number,
    offset?: number,
    search?: string,
    marketplace_id?: string,
  ): Observable<Paginated<AccountInfo>> {
    let queryParameters = new HttpParams;
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', offset);
    }
    if (marketplace_id !== undefined && marketplace_id !== null) {
      queryParameters = queryParameters.set('marketplace_id', marketplace_id);
    }
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', search);
    }
    

    return this.http.request<Paginated<AccountInfo>>('get', `${this.basePath}/organization/`, {
      params: queryParameters,
    })
  }

  getAccountsShort() {
    return this.http.get<EntityRecord[]>(`${this.basePath}/organization/short/`);
  
  }

  getMarketplaces() {
    return this.http.get<Marketplace[]>(`${this.basePath}/marketplace/`);
  }

  postAccount(json: AddAccount) {
    return this.http.post(`${this.basePath}/organization/`, json);
  }

  patchAccount(id: string, json: PatchAccount) {
    return this.http.patch(`${this.basePath}/organization/${id}/`, json);
  }

  deleteAccount(id: string) {
    return this.http.delete(`${this.basePath}/organization/${id}/`);
  
  }
}