import { ChangeDetectorRef, Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ContactPerson } from '../../data';

@Injectable()
export class ContactPersonBaseService {
  public contactPersons = new FormArray<any>([]);

  constructor(
    private toastr: ToastrService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  get accounts(): ContactPerson[] {
    return this.contactPersons.value!;
  }

  addContact(): void {
    this.contactPersons.push(
      new FormGroup({
        id: new FormControl(null),
        firstName: new FormControl('',[Validators.required]),
        middleName: new FormControl(''),
        lastName: new FormControl(''),
        position: new FormControl(''),
        phone: new FormControl(''),
        email: new FormControl(''),
        note: new FormControl(''),
      })
    );
  }

  removeContact(index: number): void {
    this.contactPersons.removeAt(index);
    this.changeDetectorRef.detectChanges();
  }

  getControl(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  setContactsToForm(form: FormGroup): void {
    if (this.accounts.length) {
      form.get('contactPersons')?.setValue(
        this.accounts.map((account) => ({
          ...account,
        }))
      );
    } else {
      form.get('contactPersons')?.setValue([]);
    }
  }

  setContactPersons(contactPersons: ContactPerson[]): void {
    for (const contact of contactPersons) {
      this.contactPersons.push(
        new FormGroup({
          id: new FormControl(contact.id || ''),
          firstName: new FormControl(contact.firstName || ''),
          middleName: new FormControl(contact.middleName || ''),
          lastName: new FormControl(contact.lastName || ''),
          position: new FormControl(contact.position || ''),
          phone: new FormControl(contact.phone || ''),
          email: new FormControl(contact.email || ''),
          note: new FormControl(contact.note || ''),
        })
      );
    }
  }

  //   contactToggleHandler(index: number): void {
  //     if (this.contactPersons.length) {
  //       for (let i = 0; i < this.contactPersons.length; i++) {
  //         this.contactPersons
  //           .at(i)
  //           .get('isDefault')
  //           ?.setValue(i === index);
  //       }
  //     }
  //   }
}
