import { ChangeDetectorRef, Component, Inject, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToggleButtonModel } from "@topseller/ui";
import { allowedFilesFormats, AuthService, File as AttachedFile, FileService } from "@topseller/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, map, Subject, takeUntil } from "rxjs";
import { FilesBlockComponent } from "../files-block";
import { FormControl } from "@angular/forms";
import { HubEntity, ItemRolesConfig } from "../../../data";
import { EDIT_ITEM_ROLES_CONFIG } from "../../tokens";

@Component({
  selector: 'ts-item-edit-comments-files',
  templateUrl: './item-edit-comments-files.component.html',
  styleUrls: ['./item-edit-comments-files.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ItemEditCommentsFilesComponent {
  @ViewChild('filesList', {static: false}) filesList?: FilesBlockComponent;
  @Input() entityType: HubEntity = HubEntity.INVENTORY;
  @Input() entityId?: string;
  public mode: 'comments' | 'files' = 'comments';
  public formats = allowedFilesFormats;
  public filesControl: FormControl = new FormControl<File[]>([]);
  destroy$: Subject<void> = new Subject<void>();

  public filesUploading$: Subject<boolean> = new Subject<boolean>();
  public filesUploadingCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public tabs: ToggleButtonModel[] = [
    {
      name: 'Комментарии',
      id: 'comments',
      requiredRole: this.editItemRoles.commentsViewRole
    },
    {
      name: 'Файлы',
      id: 'files',
      requiredRole: this.editItemRoles.filesViewRole
    }
  ];

  constructor(
              private fileService: FileService,
              private toastr: ToastrService,
              private changeDetectorRef: ChangeDetectorRef,
              @Inject(EDIT_ITEM_ROLES_CONFIG) public editItemRoles:  ItemRolesConfig,
              private authService: AuthService) {
    this.filesControl.valueChanges.pipe(
      takeUntil(this.destroy$)
    )
      .subscribe(
        (files: File[]) => {
          this.uploadFiles(files);
        }
      );

    this.authService.employeeHasRole(editItemRoles.commentsViewRole).pipe(
      map(canViewComments=> canViewComments? 'comments':'files'),
      takeUntil(this.destroy$)
    ).subscribe(
      mode=> this.mode = mode
    )
  }

  public uploadFiles(files: File[]): void {
    this.filesUploading$.next(true);
    this.filesUploadingCount$.next(files.length);
    this.changeDetectorRef.detectChanges();
    this.fileService.postAppFileBulkcreateForm(this.entityType, files, this.entityId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: AttachedFile[]) => {
          this.toastr.success('Файлы успешно загружены');
          if (this.filesList) {
            this.filesList.updateItems([...this.filesList.items, ...res]);
          }
         this.reset();
        },
        error: (err: any) => {
          this.reset();
          this.toastr.error(
            err?.errors?.length && err.errors[0].message
              ? err.errors[0].message
              : err?.message || 'Что-то пошло не так'
          );
        },
      });
  }

  private reset(){
    this.filesControl.patchValue([], {emitEvent: false, onlySelf: true});
    this.filesUploading$.next(false);
    this.filesUploadingCount$.next(0);
    this.changeDetectorRef.detectChanges();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
