import { Injectable } from "@angular/core";
import { Record } from "@topseller/core";
import { EMPTY, Observable } from "rxjs";
import { ListQueryFn } from "../base-list";

/**
 * Базовый интерфейс сервиса для предоставления данных в фильтрах
 * */
@Injectable()
export class FilterDataService {
  public filterValuesQueryFromApiFn(target: string):ListQueryFn<Record>{
    return () => EMPTY
  }

  public filterValuesFromBaseDataFn(target: string, entityName: string): Observable<any[]>{
    return EMPTY;
  }

  public getSelectValue(entityName: string | undefined, id:string, filter?: any): Observable<Record[]>{
    return EMPTY;
  }

  public getDefaultFilter(entityName: string | undefined): Observable<{ [key: string]: string }>{
    return EMPTY;
  }
}
