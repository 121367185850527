import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Commentary } from "../../../../data";
import { Employee } from "@topseller/core";


@Component({
  selector: 'ts-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent {
  @Input() comment?: Commentary;

  @Input() isEditing: boolean = false;

  @Input() employee?: Employee;

  @Output()
  edit = new EventEmitter<Commentary>();

  @Output()
  delete = new EventEmitter<Commentary>();

  get canEdit() {
    return this.comment?.createdBy?.id === this.employee?.id
  }

  public onEdit(): void {
    this.edit.emit(this.comment);
  }

  public onDelete(): void {
    this.delete.emit(this.comment);
  }
}
