import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ActiveZoneDirective } from './active-zone.directive';

@NgModule({
  declarations: [],
  imports: [CommonModule, ActiveZoneDirective],
  exports: [ActiveZoneDirective],
})
export class ActiveZoneModule {}
