import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Configuration } from '../configuration';
import { Paginated } from '../model/paginated';
import { BASE_PATH } from '../variables';
import { WarehouseGroup } from './../model/warehouseGroup';

@Injectable()
export class WarehouseGroupService {

    protected basePath = 'http://denis.dev.topseller.ru';
    public configuration = new Configuration();

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration
    ) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    public getWarehouseGroups() {
        return this.httpClient.get<Paginated<WarehouseGroup>>(`${this.basePath}/api/v1/warehouse-groups`);
    }

    public deleteWarehouseGroups(id: string) {
        return this.httpClient.delete<WarehouseGroup[]>(`${this.basePath}/api/v1/warehouse-groups/${id}`);
    }

    public postWarehouseGroups(item: WarehouseGroup) {
        return this.httpClient.post(`${this.basePath}/api/v1/warehouse-groups`, item);
    }

    public patchWarehouseGroups(item: WarehouseGroup) {
        return this.httpClient.patch<WarehouseGroup[]>(`${this.basePath}/api/v1/warehouse-groups`, item);
    }

}
