<div class="menu" [ngClass]="{'first-item': index === 0}">
  <ng-container *ngFor="let item of menu">
    <div class="menu__header" #menu>
      <div [ngClass]="{'selected':selectedItemId === item.id}">
        <div (click)="item.open = !item.open" *ngIf="item.dropdown.length>0">
          <span style="font-size: 20px" class="ts-icon ts-icon-arrow-Expand_down" [ngStyle]="{
            transform: item.open ? '' : 'rotate(180deg)'
          }"></span>

        </div>
        <span class="w-100" (click)="onSelectChildItem($event,item.id)">{{ item.title }}</span>


      </div>
      <ts-dropdown class="settings-icon" [content]="actions" *ngIf="!isModal">
        <div>
          <span class="ts-icon ts-icon-settings-more"></span>
        </div>
      </ts-dropdown>
      <ng-template #actions>
        <ts-role-access-wrapper [requiredRole]="config?.addItemRole">
          <button (click)="editTreeItem.emit(item.id)" *ngIf="canEdit"
                  class="dropdown-item settings-block__item"
                  dropdownOption>
            <span class="ts-icon ts-icon-actions-Edit"></span>
            <span class="ts-caption-strong">Редактировать</span>
          </button>
        </ts-role-access-wrapper>

        <ts-role-access-wrapper [requiredRole]="config?.deleteItemsRole">
          <button (click)="delete(item)"
                  class="dropdown-item settings-block__item"
                  dropdownOption>
            <span class="ts-icon ts-icon-actions-Trash"></span>
            <span class="ts-caption-strong">Удалить</span>
          </button>
        </ts-role-access-wrapper>
      </ng-template>


    </div>
    <div *ngIf="item.dropdown.length && item.open">
      <app-nav-tree-item
        [index]="index + 1"
        [menu]="item.dropdown"
        [isModal]="isModal"
        [selectedItemId]="selectedItemId"
        [canEdit]="canEdit"
        (selectItem)="onSelectItem($event)"
        (deleteTreeItem)="delete($event)"
        (editTreeItem)="editTreeItem.emit($event)"
      />
    </div>
  </ng-container>
</div>
