import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TsCommonModule } from '@topseller/common';
import { APP_NAME } from '@topseller/core';

import { repricerRoutes } from './repricer.routes';
import { RepricerComponent } from './repricer.component';
// import { BASE_PATH } from '@topseller/core';
// import { environment } from './data/environment';
import { AccountsService } from './data/api/accounts.service';
import { RepricerResolver } from './repricer.resolver';

@NgModule({
  declarations: [RepricerComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(repricerRoutes),
    TsCommonModule,
  ],
  providers: [
    {
      provide: APP_NAME,
      useValue: 'repricer',
    },
    AccountsService,
    RepricerResolver,
  ],
})
export class RepricerModule {}
