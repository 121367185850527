import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

export interface CurrencyFormatConfig {
  digitsAfterDecimalPoint: number | null;
  addSuffix: boolean;
  addCurrencySymbol: boolean;
  divideBy: number;
}

export const DEFAULT_CURRENCY_FORMAT_CONFIG: CurrencyFormatConfig = {
  digitsAfterDecimalPoint: 2,
  divideBy: 100,
  addCurrencySymbol: false,
  addSuffix: false,
};

/**
 * Пайпа для перевода входящих от апи копеек в рубли
 * Делим число на 100. Если остаток - целое число, то выводим строку без нулей.
 * */
@Pipe({
  name: 'currencyFormat',
})
export class CurrencyFormatPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(
    value: any,
    config: Partial<CurrencyFormatConfig> = {}
  ): any {
    const finalConfig: CurrencyFormatConfig = {
      ...DEFAULT_CURRENCY_FORMAT_CONFIG,
      ...config,
    };
    const { divideBy, addSuffix, addCurrencySymbol, digitsAfterDecimalPoint } =
      finalConfig;
    const num = Number(value);
    if (isNaN(num)) {
      return '';
    }

    let transformed = num;

    transformed /= divideBy;

    if (!addSuffix) {
      const decimalPart = transformed % 1;
      const digitsAfterDecimalPointCalculated =
        digitsAfterDecimalPoint ?? (decimalPart === 0 ? 0 : 2);
      const formattedValue = this.decimalPipe.transform(
        transformed,
        `1.${digitsAfterDecimalPointCalculated}-${digitsAfterDecimalPointCalculated}`
      );

      return addCurrencySymbol ? `${formattedValue} \u20BD` : formattedValue;
    } else {
      if (transformed < 1000000) {
        return `${transformed.toLocaleString('ru-RU')}\u20BD`;
      } else if (transformed >= 1000000 && transformed < 1000000000) {
        const formattedValue = `${(transformed / 1000000)
          .toFixed(2)
          .replace('.', ',')} млн`;
        return `${formattedValue} \u20BD`;
      } else if (transformed >= 1000000000) {
        const formattedValue = `${(transformed / 1000000000)
          .toFixed(2)
          .replace('.', ',')} млрд`;
        return addCurrencySymbol ? `${formattedValue} \u20BD` : formattedValue;
      }
    }
  }
}
