import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { TsDay, TsMonth, TsYear } from '../../../common';

@Component({
  selector: 'ts-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent {
  @Input()
  public value: TsDay | null = null;

  @Input() public month = TsMonth.currentMonth();

  @Output()
  public readonly dayClick: EventEmitter<TsDay> = new EventEmitter<TsDay>();

  public year?: TsYear;

  public onDayClick(day: TsDay): void {
    this.dayClick.emit(day);
  }

  public onYearClick(year: TsYear) {
    this.year = year;
  }

  public onMonthChange(month: TsMonth): void {
    this.updateMonth(month);
  }

  public onSelectYear({ year }: TsYear): void {
    this.year = undefined;
    const month = new TsMonth(year, this.month.month);

    this.updateMonth(month);
  }

  private updateMonth(month: TsMonth): void {
    if (this.month.monthSame(month)) {
      return;
    }

    this.month = month;
  }

  selectToday() {
    this.dayClick.emit(TsDay.currentLocal());
  }
}
