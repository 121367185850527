export type CursorMap = number[];

export class CursorHelper {
  public static calculatePosition(
    map: CursorMap,
    position: number,
    step: number
  ) {
    if (position < 0 || map.length <= position) {
      throw new Error(
        `position out of range [${0} .. ${
          map.length - 1
        }], actual value: ${position}`
      );
    }
    const raw = CursorHelper.toRawPosition(map, position);
    return CursorHelper.toFormattedPosition(map, raw + step);
  }

  public static toRawPosition(
    map: CursorMap,
    formattedPosition: number
  ): number {
    const count = Math.min(Math.max(0, formattedPosition), map.length - 1);
    return new Set(map.slice(0, count + 1)).size - 1;
  }

  public static toFormattedPosition(map: CursorMap, rawPosition: number) {
    const count = Math.max(0, rawPosition) + 1;
    const unique = new Set();

    for (const position of map) {
      unique.add(position);
      if (unique.size === count) {
        return position;
      }
    }

    return map[map.length - 1];
  }
}
