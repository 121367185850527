import { HubInterfaceTable, Paginated, setShowLoader, Warehouse } from "@topseller/core";
import { DocumentEditBase } from "./document-edit-base";
import { ChangeDetectorRef, Directive, inject, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import {
  debounceTime,
  filter,
  map,
  Observable,
  of, shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from "rxjs";
import { ActionItem, TableHeader } from "@topseller/ui";
import { HubDocument, HubEntity, Product, ProductService, ProductStock, ProductStockService } from "../data";
import { Store } from "@ngrx/store";
import { HubState } from "../store";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CatalogsDataService,
  DocumentItemsTableTotalService,
  DocumentUntrashService,
  ItemDataService
} from "./services";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { Location } from "@angular/common";
import { ProductLine, TotalLine } from "./interfaces";
import { catchError } from "rxjs/operators";
import { tradeDocumentProductsTableHeaders } from "../constants";
import { FromStoreTableHeaderService } from "@topseller/common";
import { openSettingsTableModal } from "@topseller/common/table-settings-modal";

@Directive()
export abstract class DocumentWithProductsEditBase<T extends HubDocument>
  extends DocumentEditBase<T>
  implements OnInit {

  public productLinePlaceholder: FormControl = new FormControl(null);
  public selectedProducts: number[] = [];
  public totals$?: Observable<TotalLine[]>;
  public products$?: Observable<FormGroup[]>;
  public search: FormControl = new FormControl(null);
  public isTableIdle = true;
  public isDisabledBtnAction: boolean = true;

  public productTableHeaders$: Observable<TableHeader[]> = of([]);
  productTableIdentifier?: string;
  abstract productTableEntityName: HubEntity;
  tableHeaderService: FromStoreTableHeaderService

  public productTableActionsList: ActionItem[] = [{
    title: `Удалить`,
    action: () => this.deleteProductLines()
  }];

  private productService: ProductService;

  protected constructor(catalogsDataService: CatalogsDataService,
                        store: Store<HubState>,
                        activatedRoute: ActivatedRoute,
                        protected productStockService: ProductStockService,
                        itemService: DocumentUntrashService<T>,
                        itemDataService: ItemDataService<T>,
                        toastrService: ToastrService,
                        dialog: MatDialog,
                        router: Router,
                        location: Location,
                        changeDetectorRef: ChangeDetectorRef,
                        protected documentProductsTotalService: DocumentItemsTableTotalService<ProductLine>) {
    super(catalogsDataService, store, activatedRoute, itemService, itemDataService, toastrService, dialog, router, location, changeDetectorRef);
    this.productService = inject(ProductService);
    this.tableHeaderService = inject(FromStoreTableHeaderService);
  }

  public get allSelected(): boolean {
    return !!(
      this.products.value.length &&
      this.products.value.length === this.selectedProducts.length
    );
  }

  public get productControlsValue(): Product[] {
    return this.products.value;
  }

  protected get products(): FormArray {
    return this.form.controls['products'] as FormArray;
  }

  protected get productControls(): FormGroup[] {
    return this.products.controls as FormGroup[];
  }

  protected abstract get productGroup(): FormGroup;

  public toggleAll(value: boolean): void {
    if (!value) {
      this.selectedProducts = [];
    } else {
      this.selectedProducts = this.products.value.map((_: unknown, idx: number) => idx);
    }

    this.updateIsDisabledBtnAction();
  }

  public isSelectedProduct(index: number): boolean {
    return this.selectedProducts.includes(index);
  }

  public override ngOnInit() {
    super.ngOnInit();
    this.setupProductTableHeader();
    this.handleProductNewLine();
    this.setupProductsTable();
    this.handleWarehouseChange();
    this.setupTotals();
  }


  public deleteProductLines(): void {
    for (let i = this.selectedProducts.length - 1; i >= 0; i--) {
      this.products.removeAt(this.selectedProducts[i]);
    }

    this.selectedProducts = [];
    this.form.markAsDirty();
  }

  public selectProduct(value: boolean, idx: number): void {
    const index = this.selectedProducts.indexOf(idx);

    if (!value && index > -1) {
      this.selectedProducts.splice(index, 1);
    } else if (value && index === -1) {
      this.selectedProducts.push(idx);
    }

    this.updateIsDisabledBtnAction();
  }

  public setPrices(priceTypeId: string) {
    this.store.dispatch(setShowLoader({showLoader: true}));

    this.products$?.pipe(
      take(1),
      map((formArray: FormGroup[]) => formArray.map(x => x.value.product)),
      switchMap((products: Product[]) => {
        const productIds = products.map(x => x.id);
        return this.productService.getPricesBatch(productIds, priceTypeId).pipe(
          catchError(error => {
            console.error('Error fetching prices:', error);
            return of([]);
          })
        );
      }),
      withLatestFrom(this.products$),
      take(1)
    ).subscribe({
      next: ([prices, products]) => {
        products.forEach(product => {
          const priceForProduct = prices.find(x => x.product.id === product.value.product.id)
          product.get('price')?.setValue(priceForProduct?.value ?? 0);
        });

        this.store.dispatch(setShowLoader({showLoader: false}));
      },
      error: () => {
        this.store.dispatch(setShowLoader({showLoader: false}));
      }
    });
  }

  protected setupProductTableHeader() {
    this.productTableIdentifier = HubInterfaceTable[this.productTableEntityName];
    this.productTableHeaders$ = this.tableHeaderService.getTableHeaders(this.productTableIdentifier!,[]).pipe(
      map(headers => {
        if (!headers.find(x=>x.key === 'lineNumber')) {
          headers.unshift({label: '№', key: 'lineNumber', width: '65', isSortable: false, isResizable: false, textAlign: 'right' });
        }
        return headers;
      }),
      shareReplay(1)
    );
  }


  protected handleProductNewLine(): void {
    this.productLinePlaceholder.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter((value: Product) => !!value)
      )
      .subscribe((product: Product) => {
        const warehouse: Warehouse = this.form.get('warehouse')?.value;

        if (!warehouse) {
          this.addProduct(product);
          return;
        }

        this.productStockService
          .postAppProductstockProducts(warehouse.id, {ids: [product?.id]})
          .pipe(takeUntil(this.destroy$))
          .subscribe(({items}: Paginated<ProductStock>) => {
            items.length
              ? this.addProduct(product, items)
              : this.addProduct(product);
          });
      });
  }

  protected setupTotals() {
    this.totals$ = this.products.valueChanges.pipe(
      startWith(this.products.value),
      map((value) => this.documentProductsTotalService.getTotals({items: value})))
  }

  protected updateProductFormWithStocks(items: ProductStock[]) {
    this.products.controls.forEach((item, index) => {
      (item as FormGroup).controls['product'].value.stocks = items.length
        ? items[index].stock
        : 0;
    });
  }

  protected handleWarehouseChange() {
    this.form
      .get('warehouse')
      ?.valueChanges.pipe(
      takeUntil(this.destroy$),
      filter(() => this.products.value.length),
      switchMap((warehouse: Warehouse) => {
        const productsId = this.products.value.map((productLine: ProductLine) => productLine.product.id);
        return this.productStockService.postAppProductstockProducts(warehouse.id, {ids: productsId});
      })
    )
      .subscribe(({items}: Paginated<ProductStock>) => {
        this.updateProductFormWithStocks(items);
        this.changeDetectorRef.markForCheck();
      });

    this.form.get('warehouse')?.setValue(this.item.warehouse, {emitEvent: true});
  }

  protected setupProductsTable() {
    this.products$ = this.search.valueChanges.pipe(
      startWith(''),
      map((search: string) => search.toLowerCase()),
      debounceTime(300),
      takeUntil(this.destroy$),
      tap(() => (this.selectedProducts = [])),
      switchMap((search: string) =>
        this.products.valueChanges.pipe(
          map(() => this.productControls),
          startWith(this.productControls),
          map((formArr: FormGroup[]) =>
            formArr.filter((group: FormGroup) => {
              const {name = '', sku = ''} = group.get('product')?.value || {};
              return (
                name.toLowerCase().includes(search) ||
                sku.toLowerCase().includes(search)
              );
            })
          ),
          tap(() => {
            if (this.isTrashed) {
              this.products.disable();
            }
          })
        )
      )
    );
  }

  protected abstract addProduct(product: Product, stockArray?: Array<ProductStock> | (Array<Paginated<ProductStock> | null>)): void;

  private updateIsDisabledBtnAction(): void {
    this.isDisabledBtnAction = this.selectedProducts.length === 0;
  }

  openProductTableSettingsModal() {
    this.store.dispatch(openSettingsTableModal({entity: this.productTableEntityName!}));
  }

}
