<div class="comment" *ngIf="comment">
  <div class="comment__avatar">
    <img
      *ngIf="comment.createdBy?.image"
      [ngSrc]="comment.createdBy?.image!"
      width="50"
      height="50"
    />
  </div>

  <div class="comment__content">
    <div class="comment__name">{{ comment.createdBy?.name }}</div>
    <div class="comment__text">{{ comment.text }}</div>
    <div class="comment__files" *ngIf="comment.files?.length">
      <ts-attached-file
        *ngFor="let file of comment.files!"
        [file]="file"
        state="saved"
      ></ts-attached-file>
    </div>
    <div class="comment__date">
      {{ comment.createdAt | date : 'dd.MM.yyyy HH:mm:ss' }}
    </div>
  </div>

  <ts-dropdown class="comment__settings" [content]="actions" *ngIf="canEdit">
    <div class="rotate-90">
      <span class="ts-icon ts-icon-settings-more"></span>
    </div>
  </ts-dropdown>
  <ng-template #actions>
    <button
      (click)="onEdit()"
      class="dropdown-item comment__settings__item"
      dropdownOption
    >
      <span class="ts-icon ts-icon-actions-Edit"></span>
      <span class="ts-caption-strong">Редактировать</span>
    </button>
    <button
      (click)="onDelete()"
      class="dropdown-item comment__settings__item"
      dropdownOption
    >
      <span class="ts-icon ts-icon-actions-Trash"></span>
      <span class="ts-caption-strong">Удалить</span>
    </button>
  </ng-template>
</div>
