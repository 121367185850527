import { Component, OnInit } from '@angular/core';
import { FilterDataService, TableFilterComponentBase } from "@topseller/common";
import { Store } from "@ngrx/store";
import { HubState, selectMetadata } from "../../../store";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder } from "@angular/forms";
import { CommonModule, DatePipe } from "@angular/common";
import { FilterGroup } from "../../../data/model/filter";
import { combineLatest, takeUntil } from "rxjs";
import { FilterType, getControlNameFromFilter } from "@topseller/core";

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'ts-applied-filters-row',
  templateUrl: './applied-filters-row.component.html',
  styleUrls: ['./applied-filters-row.component.scss'],
  providers: [DatePipe]
})
export class AppliedFiltersRowComponent extends TableFilterComponentBase implements OnInit {
  public filters: FilterGroup[] = [];

  constructor(
    private store: Store<HubState>,
    filterDataService: FilterDataService,
    router: Router,
    activatedRoute: ActivatedRoute,
    fb: FormBuilder,
    datePipe: DatePipe,
  ) {
    super(router, activatedRoute, fb, filterDataService, datePipe)
  }

  ngOnInit() {
    combineLatest([
      this.store.select(selectMetadata),
      this.queryParams$,
      this.filterDataService.getDefaultFilter(this.entityName!)
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([res, queryParams, defaultFilterValues]) => {
      if (res) {
        this.filters = res.filter[this.entityName!];

        this.defaultFilterValues = defaultFilterValues;

        this.filters.forEach(el => {
          if (el.type === FilterType.DATERANGE && el.target) {
            // временной интервал - это в конечном итоге сложный объект
            // у которого основным свойством является target группы фильтров,
            // а внутренние свойства - target конкретного поля фильтра
            // поэтому здесь и далее в качестве ключа используем составное имя: el.target + '_' + filter.target
            el.filters.forEach(filter => {
              this.byTargetDictionary[el.target + '_' + filter.target] = {
                label: el.name + ': ' + filter.name,
                type: filter.type
              }
            })
          } else {
            el.filters.forEach(filter => {
              this.byTargetDictionary[filter.target!] = {label: filter.name ?? el.name, type: filter.type};
            })
          }
        });
        this.fillAppliedFilters(queryParams);
      }
    });
  }

  cleanFilter(filterQueryParamName: string) {
    const queryParams: { [key: string]: any } = {};
    queryParams[filterQueryParamName] = null;
    if (this.defaultFilterValues[filterQueryParamName]) {
      queryParams[filterQueryParamName] = this.defaultFilterValues[filterQueryParamName];
    }
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }

  clearAll() {
    const queryParams: { [key: string]: any } = {};
    this.appliedFilters.forEach(x => {
      queryParams[x.filterQueryParamName] = null;
      if (this.defaultFilterValues[x.filterQueryParamName]) {
        queryParams[x.filterQueryParamName] = this.defaultFilterValues[x.filterQueryParamName];
      }
    });

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }

  private rewriteFilterValue(name: string, value: any, filterQueryParamName: string) {
    const existed = this.appliedFilters.find(x => x.controlName === name);
    if (existed) {
      existed.value = value;
    } else {
      this.appliedFilters.push({controlName: name, value: value, filterQueryParamName});
    }
  }

  private fillAppliedFilters(params: any) {
    this.appliedFilters = [];
    Object.keys(params).forEach(key => {
      const formControlName = getControlNameFromFilter(key);
      if (formControlName) {
        this.getAndSetControlValue(formControlName, params[key], (data) => {
          if (data && !(this.isDefaultFilterValue(formControlName, data))) {
            this.rewriteFilterValue(formControlName, data, key);
          }
        });
      }
    });
  }
}
