import {Inject, Injectable, Optional} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Configuration} from "../configuration";
import {BASE_PATH} from "../variables";
import {CustomHttpUrlEncodingCodec} from "../encoder";
import {Paginated} from "../model/paginated";
import {BatchDetail, BatchListTotal, BatchResponse} from "../model/batches/batchResponse";
import {PaginatedTotal} from "../model/paginated-total";


@Injectable()
export class BatchesApiService {
  protected basePath = 'http://denis.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  getBatchesList(limit: number, offset: number, filter?: string, sortName?: string, sortDir?: string,) {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }

    if(sortName !== undefined && sortDir !== null) {
      queryParameters = queryParameters.set(`sort[${sortName}]`, <any>sortDir)
    }

    return this.httpClient.get<PaginatedTotal<BatchResponse, BatchListTotal>>(`${this.basePath}/api/v1/batches?${filter ?? ''}`, {params: queryParameters})
  }

  getBatchById(id: string) {
    return this.httpClient.get<BatchResponse>(`${this.basePath}/api/v1/batches/${encodeURIComponent(String(id))}`)
  }

  getBatchDetails(id: string,limit: number, offset: number) {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    queryParameters = queryParameters.set('limit', <any>limit);
    queryParameters = queryParameters.set('offset', <any>offset);
    return this.httpClient.get<Paginated<BatchDetail>>(`${this.basePath}/api/v1/batches/${encodeURIComponent(String(id))}/detail`,{params: queryParameters})
  }

  download(url: string) {
    return this.httpClient.get(`${this.basePath}${url}`, {responseType: 'blob'});
  }

  deleteCompleted() {
    return this.httpClient.delete(`${this.basePath}/api/v1/batches/delete-completed`);
  }
}
