import { TableHeader } from "@topseller/ui";

export const hubFeatureKey = 'hub';


export const tradeDocumentProductsTableHeaders: TableHeader[] = [
    {label: '№', key: 'lineNumber', width: '60', isSortable: true, isResizable: false, textAlign: 'right'},
    {label: 'Наименование', key: 'name', width: '374', isSortable: true, isResizable: false},
    {label: 'Идентификаторы товара', key: 'identifiers', width: '330'},
    {label: 'Количество', key: 'quantity', width: '170', textAlign: 'right'},
    {label: 'Сумма расходов, ₽', key: 'expensesSum', width: '190', textAlign: 'right'},
    {label: 'Отгружено', key: 'demanded', width: '170', textAlign: 'right'},
    {label: 'Возвращено', key: 'recalled', width: '170', textAlign: 'right'},
    {label: 'Цена, ₽', key: 'price', width: '140', type: 'currency', textAlign: 'right'},
    {label: 'Скидка, %', key: 'discountPricePercent', width: '140', textAlign: 'right'},
    {label: 'Сумма, ₽', key: 'amount', width: '140', type: 'currency', textAlign: 'right'},
    {label: 'Доступно', key: 'available', width: '150', textAlign: 'right'},
    {label: 'Остаток', key: 'stocks', width: '150', textAlign: 'right'},
    {label: 'Резерв', key: 'reserved', width: '150', textAlign: 'right'},
    {label: 'Габариты', key: 'dimensions', width: '150', textAlign: 'right'},
];

export const tradeDocumentProductsSupplierTableHeaders: TableHeader[] = [
  {label: 'Принято', key: 'received', width: '170', textAlign: 'right'},
  {label: 'Возвращено', key: 'reverted', width: '170', textAlign: 'right'},
];

export const tradeDocumentProductsBuyerTableHeaders: TableHeader[] = [
  {label: 'Отгружено', key: 'demanded', width: '170', textAlign: 'right'},
  {label: 'Возвращено', key: 'recalled', width: '170', textAlign: 'right'},
];

export const tradeDocumentProductsWithVatTableHeaders:TableHeader[] = [
    {label: 'Ставка НДС', key: 'vatType', width: '150'},
    {label: 'Сумма без НДС', key: 'amountWithoutVat', width: '150', type: 'currency', textAlign:'right'},
    {label: 'Сумма НДС', key: 'vatPrice', width: '150', type: 'currency', textAlign:'right'},
]
