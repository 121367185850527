import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DBSchema, IDBPDatabase, openDB } from 'idb';

import { selectEmployee } from '@topseller/core';

export enum ShowNames {
  SHOW_BARCODES = 'showBarcodes',
  SHOW_EXTRA_FIELDS = 'showExtraFields',
  SHOW_DIMENSIONS = 'showDimensions',
  SHOW_PRODUCT = 'showProduct',
  SHOW_PRICES = 'showPrices',
  SHOW_MODIF = 'showModif',
  SHOW_STOCKS = 'showStocks',
  SHOW_NAV = 'showNav',
}

interface Variable {
  name: ShowNames;
  value: boolean;
}

interface MyDB extends DBSchema {
  variables: {
    key: ShowNames;
    value: Variable;
  };
}

@Injectable()
export class ProductShowVariablesService {
  private dbPromise?: Promise<IDBPDatabase<MyDB>>;

  constructor(private store: Store<any>) {
    this.store.select(selectEmployee).subscribe((res) => {
      this.initDB(res.id);
    });
  }

  private async initDB(user_id: string) {
    this.dbPromise = openDB(`${user_id}`, 1, {
      upgrade(db) {
        db.createObjectStore('variables', { keyPath: 'name' });
      },
    });

    Object.values(ShowNames).forEach((name) => {
      this.getValueFromDB(name).then((value) => {
        if (value === undefined) {
          this.setValueInDB(name, true);
        }
      });
    });
  }

  public getVar(name: ShowNames) {
    return this.getValueFromDB(name).then((value) => {
      if (value !== undefined) {
        return value;
      } else {
        // Return default value if variable doesn't exist in the database
        return true;
      }
    });
  }

  public setVar(name: ShowNames, value: boolean) {
    this.setValueInDB(name, value);
  }

  private async getValueFromDB(name: ShowNames): Promise<boolean | undefined> {
    const db = await this.dbPromise;
    return db!.get('variables', name).then((variable) => variable?.value);
  }

  private async setValueInDB(name: ShowNames, value: boolean) {
    const db = await this.dbPromise;
    return db!.put('variables', { name, value });
  }
}
