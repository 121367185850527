import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CalendarHeaderComponent } from './components/calendar-header/calendar-header.component';
import { CalendarMonthComponent } from './components/calendar-month/calendar-month.component';
import { CalendarYearSelectorComponent } from './components/calendar-year-selector/calendar-year-selector.component';
import { CurrentYearDirective } from './components/calendar-year-selector/current-year.directive';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarTablePipe } from './pipes/month-table.pipe';
import { TextMonthPipe } from './pipes/text-month.pipe';
import {TsScrollModule} from "../scroll/scroll.module";

@NgModule({
  declarations: [
    CalendarComponent,
    CalendarMonthComponent,
    CalendarTablePipe,
    CalendarHeaderComponent,
    CalendarYearSelectorComponent,
    CurrentYearDirective,
    TextMonthPipe,
  ],
    imports: [CommonModule, TsScrollModule],
  exports: [CalendarComponent],
})
export class CalendarModule {}
