<div class="confirm-dialog">
  <div class="confirm-dialog__inner">
    <div class="dialog__header">
      <h5>{{ title }}</h5>
    </div>
    <div class="dialog__actions">
      <button class="btn btn-primary" (click)="selected('нет')">
        Нет
      </button>
      <button class="btn btn-outline-light" (click)="selected('да')">
        Да
      </button>
    </div>
  </div>
</div>
