import { Directive, Input, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AppliedFilter, FilterType, getControlNameFromFilter, isRecord, Record } from "@topseller/core";
import { BehaviorSubject, map, shareReplay, Subject } from "rxjs";
import { FilterDataService } from "../table";
import { tap } from "rxjs/operators";
import { StringHandler } from "@topseller/ui";
import { DatePipe } from "@angular/common";

const FILTER_DISPLAY_LENGTH = 15;
const FILTER_DATE_DISPLAY_LENGTH = 22;

@Directive()
export abstract class TableFilterComponentBase implements OnDestroy {
  @Input() entityName?: string;

  public form: FormGroup = this.fb.group({});
  isFilterSet$ = new BehaviorSubject(false);
  queryParams$ = this.activatedRoute.queryParams.pipe(tap(_ => this.isFilterSet$.next(false)));
  defaultFilterValues: { [key: string]: string } = {};
  // словарик, в котором храним инфо о контроле, где ключ - это таргет фильтра.
  public byTargetDictionary: { [key: string]: { label: string, type?: FilterType } } = {};
  public appliedFilters: AppliedFilter[] = [];
  protected destroy$: Subject<void> = new Subject<void>();

  protected constructor(protected router: Router,
                        protected activatedRoute: ActivatedRoute,
                        protected fb: FormBuilder,
                        protected filterDataService: FilterDataService,
                        protected datePipe: DatePipe,
  ) {

  }

  public recordStringify: StringHandler<Record> = (item) => item.name;

  public getDictionaryName(target: string): string {
    return this.byTargetDictionary[target].label;
  }

  public getDictionaryValue(value: any, target: string): string {
    if (value == null) {
      return '';
    }
    if (isRecord(value)) {
      return this.trimString(this.recordStringify(value), FILTER_DISPLAY_LENGTH);
    }

    const controlInfo = this.byTargetDictionary[target];
    if (controlInfo && controlInfo.type === FilterType.CHECKBOX) {
      return value == 'true' ? "Да" : "Нет"
    }

    if (controlInfo && controlInfo.type === FilterType.MULTISELECT) {
      return value.length === 1 ? value[0].name : `Выбрано ${value.length}`;
    }

    // Проверяем, соответствует ли controlInfo.type одному из типов, связанных с датами
    const dateTypes: FilterType[] = [FilterType.DATERANGEFROM, FilterType.DATERANGETO, FilterType.DATE, FilterType.DATERANGE];

    if (controlInfo && dateTypes.includes(controlInfo.type!) && this.isValidDate(value)) {
      return this.trimString(this.datePipe.transform(value, 'medium')!, FILTER_DATE_DISPLAY_LENGTH, false);
    }
    return this.trimString(value.toString(), FILTER_DISPLAY_LENGTH);
  }

  public isValidDate(dateString: string): boolean {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isDefaultFilterValue(formControlName: string, value: any) {
    const filterKey = `filter[${formControlName}]`;
    value = isRecord(value) ? value.id : value;
    return this.defaultFilterValues[filterKey] === value;
  }

  // сбросить всю форму (Удалить всё, выставив значения по умолчанию)
  public cleanAll() {
    for (const controlName in this.byTargetDictionary) {
      const filterKey = `filter[${controlName}]`;
      if (this.defaultFilterValues[filterKey] !== undefined) {
        this.setControlValue(controlName, this.defaultFilterValues[filterKey]);
      } else {
        this.form.controls[controlName].setValue(null);
      }
    }
  }

  /**
   * Установка значений формы на основе параметров из адресной строки
   * */
  protected setFormValuesFromQueryParams(params: any): void {
    Object.keys(params).forEach(key => {
      const formControlName = getControlNameFromFilter(key);
      if (formControlName) {
        this.setControlValue(formControlName, params[key]);
      }
    });
  }

  protected getAndSetControlValue(formControlName: string, value: any, applyDataFn: (data: any) => void) {
    const controlInfo = this.byTargetDictionary[formControlName];
    if (controlInfo) {
      if (controlInfo.type === FilterType.SELECT) {
        this.filterDataService.getSelectValue(this.entityName, value, formControlName)
          .pipe(shareReplay(1),
            map(values => (values?.length && values[0]) ? values[0] : null))
          .subscribe(data => {
            applyDataFn(data);
          });
      } else if (controlInfo.type === FilterType.MULTISELECT) {
        const ids = (value as string).split(',');
        if (ids.length > 0) {
          this.filterDataService.getSelectValue(this.entityName, value, formControlName)
            .pipe(shareReplay(1))
            .subscribe(data => {
              applyDataFn(data);
            });
        }
      } else {
        applyDataFn(value);
      }
    }
  }

  protected setControlValue(formControlName: string, value: any) {
    this.getAndSetControlValue(formControlName, value, (data) => {
      this.form.controls[formControlName].setValue(data);
      if (data && !(this.isDefaultFilterValue(formControlName, data))) {
        this.isFilterSet$.next(true);
      }
    });
  }

  private trimString(input: string, length: number, addEllipsis = true): string {
    if (input.length > length) {
      return input.substring(0, length) + (addEllipsis ? '...' : '');
    }
    return input;
  }
}
