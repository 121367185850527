import { IdentityMatcher, ListQueryFn, StringHandler } from './types';
import {
  CashflowSource,
  Product,
  Project,
  Status,
} from '../data';
import { Brand, Country, Source, Unit } from '../data';
import { CatalogsDataService } from './services/catalogs-data.service';
import { Counterparty, Organization, Warehouse, Record, ROLES } from "@topseller/core";

/**
 * Класс, представляющий доступ к загрузке списков.
 */
export abstract class LoadableSelector {
  protected constructor(protected catalogsDataService: CatalogsDataService) {}

  public recordIdentityMatcher: IdentityMatcher<Record> = (
    { id: previousId },
    { id: nextId }
  ) => previousId === nextId;

  public recordStringify: StringHandler<Record> = (item: Record) => item.name;

  public get companyQueryFn(): ListQueryFn<Organization> {
    return this.catalogsDataService.companyListFn;
  }

  public get partnerQueryFn(): ListQueryFn<Counterparty> {
    return this.catalogsDataService.partnerListFn;
  }

  public get warehouseQueryFn(): ListQueryFn<Warehouse> {
    return this.catalogsDataService.storageListFn;
  }

  public get productQueryFn(): ListQueryFn<Product> {
    return this.catalogsDataService.productListFn;
  }

  public get projectQueryFn(): ListQueryFn<Project> {
    return this.catalogsDataService.projectListFn;
  }

  public get countryQueryFn(): ListQueryFn<Country> {
    return this.catalogsDataService.countryListFn;
  }

  public get brandQueryFn(): ListQueryFn<Brand> {
    return this.catalogsDataService.brandListFn;
  }

  public get unitQueryFn(): ListQueryFn<Unit> {
    return this.catalogsDataService.unitListFn;
  }

  public get statusQueryFn(): ListQueryFn<Status> {
    return this.catalogsDataService.statusListFn;
  }

  public get sourceQueryFn(): ListQueryFn<Source> {
    return this.catalogsDataService.sourceListFn;
  }

  public get cashflowSourceQueryFn(): ListQueryFn<CashflowSource> {
    return this.catalogsDataService.cashflowSourceListFn;
  }

  viewOrganizationsRole = ROLES.ROLE_ORGANIZATION_VIEW;
  viewWarehousesRole = ROLES.ROLE_WAREHOUSE_VIEW;
  viewCounterpartyRole = ROLES.ROLE_COUNTERPARTY_VIEW;
  viewProjectsRole = ROLES.ROLE_PROJECT_VIEW;
  viewSourcesRole = ROLES.ROLE_SOURCE_VIEW;
  viewCashflowSourceRole = ROLES.ROLE_CASHFLOW_SOURCE_VIEW;
  viewProductsRole = ROLES.ROLE_PRODUCT_VIEW;
}
