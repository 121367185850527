import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TsDataListComponent } from "./data-list.component";

@NgModule({
  imports: [CommonModule],
  declarations: [TsDataListComponent],
  exports: [TsDataListComponent],
})

export class TsDataListModule {}
