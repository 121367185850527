<div
  class="dropdown"
  #parent="activeZone"
  [class.open]="isVisible"
  [class.disabled]="disabled"
  (activeZoneChange)="onActiveZone($event)"
  [dropdown]="dropdownTemplate"
  [show]="isVisible"
  [attr.data-size]="size"
>
  <ts-base-input
    [value]="computedValue"
    [label]="label"
    [readonly]="true"
    [trailingIcon]="icon"
    [cleaner]="hasCleaner && !disabled"
    (onClear)="clear()"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [labelInside]="labelInside"
  >
    <ts-custom-content ngProjectAs='customContent' *ngIf="value && customContent">
      <ng-container *ngTemplateOutlet="customContent; context: {$implicit: value}"></ng-container>
    </ts-custom-content>
  </ts-base-input>
</div>

<ng-template #dropdownTemplate>
  <div [activeZoneParent]="parent"><ng-content></ng-content></div>
</ng-template>
