<ts-base-input #parent="activeZone"
               (activeZoneChange)="onActiveZone($event)"
               (valueChange)="valueChanges($event)"
               [cleaner]="hasCleaner"
               [copier]="copier"
               [dropdown]="dropdownTemplate"
               [labelInside]="labelInside"
               [label]="title"
               [leadingIcon]="leadingIcon"
               [mask]="mask"
               [tsMask]="tsMask"
               [readonly]="readonly || disabled"
               [show]="!!dropdownList && open"
               [trailingIcon]="trailingIcon"
               [value]="value"
               [multirow]="multirow"
               [class.disabled]="disabled"
               [placeholder]="placeholder"
               (trailingIconClick)="trailingIconClick.emit()"
               [trailingIconClickable]="trailingIconClickable"

/>

<ng-template #dropdownTemplate>
  <div [activeZoneParent]="parent">
    <ng-content></ng-content>
  </div>
</ng-template>
