import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

import { CustomField, HubEntity, Status } from '../data';

import { LoadableSelector } from './loadable-selector';
import { CatalogsDataService } from './services/catalogs-data.service';
import { Store } from '@ngrx/store';
import { selectFormFields, selectStatus } from '../store';
import { Directive, OnDestroy, OnInit } from '@angular/core';

import { map } from 'rxjs/operators';
import {
  distinctUntilChanged,
  filter,
  Observable,
  of,
  Subject,
  takeUntil,
} from 'rxjs';

export interface EntityForm {
  id: FormControl<string>;
  status: FormControl<Status>;
  [key: string]: AbstractControl;
}

@Directive()
export abstract class AbstractDocument
  extends LoadableSelector
  implements OnInit, OnDestroy
{
  protected destroy$: Subject<void> = new Subject<void>();
  public abstract form: FormGroup<EntityForm>;
  public entityCompany: HubEntity = HubEntity.COMPANY;
  public entityPartner: HubEntity = HubEntity.PARTNER;
  public entityWarehouse: HubEntity = HubEntity.WAREHOUSE;
  public entityProject: HubEntity = HubEntity.PROJECT;
  public entityCashflow: HubEntity = HubEntity.CASHFLOW;
  public entitySource: HubEntity = HubEntity.SOURCE;

  public hubEntity = HubEntity;
  public abstract entityName: HubEntity;

  public get backLinkState(): { activeId: string } {
    return { activeId: this.id };
  }

  public get status(): Status {
    return this.form?.controls?.status?.value;
  }

  public get id(): string {
    return this.form.controls.id.value;
  }

  public hasCustomFields$: Observable<boolean> = of(false);

  protected constructor(
    catalogsDataService: CatalogsDataService,
    protected store: Store
  ) {
    super(catalogsDataService);
  }

  ngOnInit(): void {
    this.hasCustomFields$ = this.store
      .select(selectFormFields(this.entityName))
      .pipe(map((fields: CustomField[]) => !!fields?.length));

    this.store
      .select(selectStatus(this.entityName))
      .pipe(
        takeUntil(this.destroy$),
        filter((statuses) => Boolean(statuses)),
        map((statuses) => {
          return statuses?.find((x) => x.id == this.status?.id);
        }),
        filter((status) => Boolean(status)),
        distinctUntilChanged((prevStatus, currStatus) => {
          return (
            prevStatus?.color === currStatus?.color &&
            prevStatus?.name === currStatus?.name
          );
        })
      )
      .subscribe((statusInStore?: Status) => {
        const value: Status = {
          ...this.status,
          color: statusInStore!.color,
          name: statusInStore!.name,
        };
        this.form.controls.status.setValue(value);
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
