import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { concatMap, map, Observable, of, tap } from 'rxjs';

import { AuthService, selectIsActive, selectIsLoggedIn } from '@topseller/core';

@Injectable()
export class RegisterGuard  {
  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.select(selectIsLoggedIn).pipe(
      concatMap((isLoggedIn: boolean) => {
        return !isLoggedIn
          ? of(true).pipe(tap(() => this.router.navigate(['/login'])))
          : this.store.select(selectIsActive).pipe(
              tap((isActive) => {
                if (isActive) {
                  this.router.navigate(['/']);
                }
              }),
              map(() => true)
            );
      })
    );
  }
}
