import { createAction, props } from '@ngrx/store';

import { HubEntity, LinkedDocument } from '../../../../data';

export const loadLinkedDocuments = createAction(
  '[HUB Linked Document] Load Linked Documents',
  props<{ entity: HubEntity; id: string }>()
);

export const setLinkedDocuments = createAction(
  '[HUB Linked Document] Set Linked Documents',
  props<{ items: LinkedDocument }>()
);

export const clearLinkedDocuments = createAction(
  '[HUB Linked Document] Clear Linked Documents'
);
