import { ROLES } from "./const";

export interface RoleItem {
  name: string;
  columnName: string;
  isAlwaysOn?: boolean;
}

export interface RoleCategoryItem {
  name: string;
  roles: RoleItem[];
  adminRole?: string;
  hint?: string;
}

export interface RoleCategory {
  name: string;
  items: RoleCategoryItem[];
  isHidden?: boolean;
  globalSwitchHidden?: boolean;
  isAlwaysOn?: boolean;
}

export interface RolesData {
  categories: RoleCategory[];
}

//список ролей которые всегда включены и которые нельзя выключить.
export const alwaysOnList: string[] = [
  ROLES.ROLE_COMMON_SETTINGS_VIEW,  ROLES.ROLE_MARKETPLACE_VIEW, ROLES.ROLE_STATUS_VIEW, ROLES.ROLE_PRODUCT_PRICE_VIEW, ROLES.ROLE_COMMON_SETTINGS, ROLES.ROLE_DIRECTORY_VIEW
]

// здесь храним роли, которые отображаются на интерфейсе, но которых на самом деле не существует.
export const fakeDisplayRoles: string[] = [
  ROLES.ROLE_COMMON_SETTINGS_VIEW
]

const transformRoles = (rolesArray: string[]): RoleItem[] => {
  return rolesArray.map(role => {
    let columnName = 'VIEW';
    if (role.includes('_EDIT')) {
      columnName = 'EDIT';
    } else if (role.includes('_DELETE')) {
      columnName = 'DELETE';
    }
    return {
      name: role,
      columnName: columnName,
      isAlwaysOn: alwaysOnList.includes(role)
    };
  });
};

export const ROLES_DATA: RolesData = {
  categories: [
    {
      name: "Настройки профиля",
      isAlwaysOn: true,
      items: [
        {
          name: " Менять информацию о себе",
          roles: [{name: ROLES.ROLE_COMMON_SETTINGS_VIEW, columnName: 'VIEW'}, {
            name: ROLES.ROLE_COMMON_SETTINGS,
            columnName: 'EDIT'
          }],

        }
      ]
    },
    {
      name: "Справочники",
      items: [
        {
          name: "Глобальные",
          roles: transformRoles([ROLES.ROLE_DIRECTORY_VIEW]),
          adminRole: ROLES.ROLE_DIRECTORY_ADMIN,
          hint: 'Доступ к базовым справочникам: Валюты, Ед. изм., Страны, Ставки НДС, Типы Штрихкодов, Банки',
        },
        {
          name: "Товары",
          roles: transformRoles([
            ROLES.ROLE_PRODUCT_VIEW,
            ROLES.ROLE_PRODUCT_EDIT,
            ROLES.ROLE_PRODUCT_DELETE
          ]),
          adminRole: ROLES.ROLE_PRODUCT_ADMIN
        },
        {
          name: "Организации",
          roles: transformRoles([
            ROLES.ROLE_ORGANIZATION_VIEW,
            ROLES.ROLE_ORGANIZATION_EDIT,
            ROLES.ROLE_ORGANIZATION_DELETE
          ]),
          adminRole: ROLES.ROLE_ORGANIZATION_ADMIN
        },
        {
          name: "Контрагенты",
          roles: transformRoles([
            ROLES.ROLE_COUNTERPARTY_VIEW,
            ROLES.ROLE_COUNTERPARTY_EDIT,
            ROLES.ROLE_COUNTERPARTY_DELETE
          ]),
          adminRole: ROLES.ROLE_COUNTERPARTY_ADMIN
        },
        {
          name: "Склады",
          roles: transformRoles([
            ROLES.ROLE_WAREHOUSE_VIEW,
            ROLES.ROLE_WAREHOUSE_EDIT,
            ROLES.ROLE_WAREHOUSE_DELETE
          ]),
          adminRole: ROLES.ROLE_WAREHOUSE_ADMIN
        },
        {
          name: "Проекты",
          roles: transformRoles([
            ROLES.ROLE_PROJECT_VIEW,
            ROLES.ROLE_PROJECT_EDIT,
            ROLES.ROLE_PROJECT_DELETE
          ]),
          adminRole: ROLES.ROLE_PROJECT_ADMIN
        },
        {
          name: "Статьи расходов и доходов",
          roles: transformRoles([
            ROLES.ROLE_SOURCE_VIEW,
            ROLES.ROLE_SOURCE_EDIT,
            ROLES.ROLE_SOURCE_DELETE
          ]),
          adminRole: ROLES.ROLE_SOURCE_ADMIN
        },
        {
          name: "Статьи ДДС",
          roles: transformRoles([
            ROLES.ROLE_CASHFLOW_SOURCE_VIEW,
            ROLES.ROLE_CASHFLOW_SOURCE_EDIT,
            ROLES.ROLE_CASHFLOW_SOURCE_DELETE
          ]),
          adminRole: ROLES.ROLE_CASHFLOW_SOURCE_ADMIN
        },
        {
          name: "Расходы маркетплейсов",
          roles: transformRoles([
            ROLES.ROLE_EXPENSE_VIEW,
            ROLES.ROLE_EXPENSE_EDIT,
            ROLES.ROLE_EXPENSE_DELETE
          ]),
          adminRole: ROLES.ROLE_EXPENSE_ADMIN
        },
        {
          name: "Отделы",
          roles: transformRoles([
            ROLES.ROLE_DEPARTMENT_VIEW,
            ROLES.ROLE_DEPARTMENT_EDIT,
            ROLES.ROLE_DEPARTMENT_DELETE
          ]),
          adminRole: ROLES.ROLE_DEPARTMENT_ADMIN
        },
        {
          name: "Сотрудники",
          roles: transformRoles([
            ROLES.ROLE_EMPLOYEE_VIEW,
            ROLES.ROLE_EMPLOYEE_EDIT,
            ROLES.ROLE_EMPLOYEE_DELETE
          ]),
          adminRole: ROLES.ROLE_EMPLOYEE_ADMIN
        },
        {
          name: "Дополнительные поля",
          roles: transformRoles([
            ROLES.ROLE_CUSTOM_FIELD_VIEW,
            ROLES.ROLE_CUSTOM_FIELD_EDIT,
            ROLES.ROLE_CUSTOM_FIELD_DELETE
          ]),
          adminRole: ROLES.ROLE_CUSTOM_FIELD_ADMIN
        },
        {
          name: "Маркетплейсы",
          roles: transformRoles([ROLES.ROLE_MARKETPLACE_VIEW]),
          adminRole: ROLES.ROLE_MARKETPLACE_ADMIN
        },
        {
          name: "Аккаунты маркетплейсов",
          roles: transformRoles([
            ROLES.ROLE_MARKETPLACE_ACCOUNT_VIEW,
            ROLES.ROLE_MARKETPLACE_ACCOUNT_EDIT,
            ROLES.ROLE_MARKETPLACE_ACCOUNT_DELETE
          ]),
          adminRole: ROLES.ROLE_MARKETPLACE_ACCOUNT_ADMIN
        },
        {
          name: "Бренды",
          roles: transformRoles([
            ROLES.ROLE_BRAND_VIEW,
          ]),
          adminRole: ROLES.ROLE_BRAND_ADMIN
        },
        {
          name: "Статусы",
          roles: transformRoles([
            ROLES.ROLE_STATUS_VIEW,
            ROLES.ROLE_STATUS_EDIT,
            ROLES.ROLE_STATUS_DELETE
          ]),
          adminRole: ROLES.ROLE_STATUS_ADMIN
        },
        {
          name: "Группы цен",
          roles: transformRoles([
            ROLES.ROLE_PRODUCT_PRICE_VIEW,
            ROLES.ROLE_PRODUCT_PRICE_EDIT,
            ROLES.ROLE_PRODUCT_PRICE_DELETE
          ]),
          adminRole: ROLES.ROLE_PRODUCT_PRICE_ADMIN
        },
        {
          name: "Комментарии",
          roles: transformRoles([
            ROLES.ROLE_COMMENTARY_VIEW,
            ROLES.ROLE_COMMENTARY_EDIT,
            ROLES.ROLE_COMMENTARY_DELETE
          ]),
          adminRole: ROLES.ROLE_COMMENTARY_ADMIN
        }
      ],
    },
    {
      name: "Документы",
      items: [
        {
          name: "Оприходования",
          roles: transformRoles([
            ROLES.ROLE_POSTING_VIEW,
            ROLES.ROLE_POSTING_EDIT,
            ROLES.ROLE_POSTING_DELETE
          ]),
          adminRole: ROLES.ROLE_POSTING_ADMIN
        },
        {
          name: "Списания",
          roles: transformRoles([
            ROLES.ROLE_WRITE_OFF_VIEW,
            ROLES.ROLE_WRITE_OFF_EDIT,
            ROLES.ROLE_WRITE_OFF_DELETE
          ]),
          adminRole: ROLES.ROLE_WRITE_OFF_ADMIN
        },
        {
          name: "Перемещения",
          roles: transformRoles([
            ROLES.ROLE_MOVEMENT_VIEW,
            ROLES.ROLE_MOVEMENT_EDIT,
            ROLES.ROLE_MOVEMENT_DELETE
          ]),
          adminRole: ROLES.ROLE_MOVEMENT_ADMIN
        },
        {
          name: "Инвентаризации",
          roles: transformRoles([
            ROLES.ROLE_INVENTORY_VIEW,
            ROLES.ROLE_INVENTORY_EDIT,
            ROLES.ROLE_INVENTORY_DELETE
          ]),
          adminRole: ROLES.ROLE_INVENTORY_ADMIN
        },
        {
          name: "Заказы поставщикам",
          roles: transformRoles([
            ROLES.ROLE_PURCHASE_VIEW,
            ROLES.ROLE_PURCHASE_EDIT,
            ROLES.ROLE_PURCHASE_DELETE
          ]),
          adminRole: ROLES.ROLE_PURCHASE_ADMIN
        },
        {
          name: "Приемки",
          roles: transformRoles([
            ROLES.ROLE_RECEIVE_VIEW,
            ROLES.ROLE_RECEIVE_EDIT,
            ROLES.ROLE_RECEIVE_DELETE
          ]),
          adminRole: ROLES.ROLE_RECEIVE_ADMIN
        },
        {
          name: "Возвраты поставщикам",
          roles: transformRoles([
            ROLES.ROLE_REVERT_VIEW,
            ROLES.ROLE_REVERT_EDIT,
            ROLES.ROLE_REVERT_DELETE
          ]),
          adminRole: ROLES.ROLE_REVERT_ADMIN
        },
        {
          name: "Заказы покупателей",
          roles: transformRoles([
            ROLES.ROLE_ORDER_VIEW,
            ROLES.ROLE_ORDER_EDIT,
            ROLES.ROLE_ORDER_DELETE
          ]),
          adminRole: ROLES.ROLE_ORDER_ADMIN
        },
        {
          name: "Отгрузки",
          roles: transformRoles([
            ROLES.ROLE_DEMAND_VIEW,
            ROLES.ROLE_DEMAND_EDIT,
            ROLES.ROLE_DEMAND_DELETE
          ]),
          adminRole: ROLES.ROLE_DEMAND_ADMIN
        },
        {
          name: "Возвраты покупателей",
          roles: transformRoles([
            ROLES.ROLE_RECALL_VIEW,
            ROLES.ROLE_RECALL_EDIT,
            ROLES.ROLE_RECALL_DELETE
          ]),
          adminRole: ROLES.ROLE_RECALL_ADMIN
        },
        {
          name: "Платежи",
          roles: transformRoles([
            ROLES.ROLE_PAYMENT_VIEW,
            ROLES.ROLE_PAYMENT_EDIT,
            ROLES.ROLE_PAYMENT_DELETE
          ]),
          adminRole: ROLES.ROLE_PAYMENT_ADMIN
        }
      ],
    },
    {
      name: "Прочее",
      items: [
        {
          name: "Файлы",
          roles: transformRoles([
            ROLES.ROLE_FILE_VIEW,
            ROLES.ROLE_FILE_EDIT,
            ROLES.ROLE_FILE_DELETE
          ]),
          adminRole: ROLES.ROLE_FILE_ADMIN
        },
        {
          name: "Аудит",
          roles: transformRoles([ROLES.ROLE_AUDIT_VIEW]),
          adminRole: ROLES.ROLE_AUDIT_ADMIN
        },
        {
          name: "Вебхуки",
          roles: transformRoles([
            ROLES.ROLE_WEBHOOK_VIEW,
            ROLES.ROLE_WEBHOOK_EDIT,
            ROLES.ROLE_WEBHOOK_DELETE
          ]),
          adminRole: ROLES.ROLE_WEBHOOK_ADMIN
        },
        {
          name: "Доступ к сервисам: Connect, PIM",
          roles: transformRoles([
            ROLES.ROLE_ACCOUNT_APPLICATION_VIEW,
          ]),
          adminRole: ROLES.ROLE_ACCOUNT_APPLICATION_ADMIN
        }
      ],
    },
    {
      name: "Отчеты",
      items: [
        {
          name: "На главной",
          roles: [{name: ROLES.ROLE_DASHBOARD, columnName: 'VIEW'},],
          adminRole: "ROLES.ROLE_DASHBOARD_ADMIN"
        },
        {
          name: "Остатки",
          roles: [{name: ROLES.ROLE_REPORT_STOCKS, columnName: 'VIEW'},],
          adminRole: "ROLES.ROLE_REPORT_STOCKS_ADMIN"
        },
        {
          name: "Обороты",
          roles: [{name: ROLES.ROLE_REPORT_TURNOVER, columnName: 'VIEW'},],
          adminRole: "ROLES.ROLE_REPORT_TURNOVER_ADMIN"
        },
        {
          name: "Прибыльность",
          roles: [{name: ROLES.ROLE_REPORT_PROFIT, columnName: 'VIEW'},],
          adminRole: "ROLES.ROLE_REPORT_PROFIT_ADMIN"
        },
        {
          name: "Прибыли и убытки",
          roles: [{name: ROLES.ROLE_REPORT_LOSS, columnName: 'VIEW'},],
          adminRole: "ROLES.ROLE_REPORT_LOSS"
        }
      ],
    }
  ]
};




