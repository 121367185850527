import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Sidebar, SidebarModule } from '@topseller/common/sidebar';
import { combineLatest, debounceTime, take, takeUntil } from 'rxjs';
import { FilterGroup } from '../../../data/model/filter';
import { HubState, selectMetadata } from '../../../store';
import { CommonModule, DatePipe } from "@angular/common";
import { filterNonNullValues, FilterType, isRecord } from "@topseller/core";
import { TableFilterComponentBase } from "@topseller/common/table/table-filter-component-base";
import { FilterDataService } from "@topseller/common";
import { TsSelectModule } from "@topseller/ui/select";
import { TsEntityListModule } from "@topseller/common/entity-list";
import { TsInputTextModule } from "@topseller/ui/input-text";
import { InputDateRangeSelectorModule } from "@topseller/ui/input-date-range-selector";
import { TsDrawerModule } from "@topseller/ui/drawer";
import { InputDateModule } from "@topseller/ui/input-date";
import { FilterControlComponent } from "@topseller/common/table/filter-control/filter-control.component";
import { HubFilterDataService } from "../../services";


@Component({
  selector: 'ts-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss'],
  providers: [
    DatePipe,
    {
      provide: FilterDataService,
      useClass: HubFilterDataService
    }
  ],
  standalone: true,
  imports: [
    CommonModule,
    TsSelectModule,
    TsEntityListModule,
    ReactiveFormsModule,
    FormsModule,
    TsInputTextModule,
    InputDateRangeSelectorModule,
    TsDrawerModule,
    InputDateModule,
    FilterControlComponent,
    SidebarModule,
  ]
})

export class TableFiltersComponent extends TableFilterComponentBase implements OnDestroy, OnInit, Sidebar<unknown> {
  @Input() applyFilterAction!: (filter: any) => void;

  public resolveSidebarWith!: (result?: unknown) => void;
  public filters: FilterGroup[] = [];

  constructor(
    private store: Store<HubState>,
    filterDataService: FilterDataService,
    router: Router,
    activatedRoute: ActivatedRoute,
    fb: FormBuilder,
    datePipe: DatePipe,
  ) {
    super(router, activatedRoute, fb, filterDataService,datePipe)
  }

  ngOnInit(): void {
    combineLatest([
      this.store.select(selectMetadata),
      this.queryParams$,
      this.filterDataService.getDefaultFilter(this.entityName!)
    ]).pipe(
      take(1),
      takeUntil(this.destroy$)
    ).subscribe(([res, queryParams, defaultFilterValues]) => {
      if (res) {
        this.filters = res.filter[this.entityName!];

        this.defaultFilterValues = defaultFilterValues;

        this.filters.forEach(el => {
          if (el.type === FilterType.DATERANGE && el.target) {
            // временной интервал - это в конечном итоге сложный объект
            // у которого основным свойством является target группы фильтров,
            // а внутренние свойства - target конкретного поля фильтра
            // поэтому здесь и далее в качестве ключа используем составное имя: el.target + '_' + filter.target
            el.filters.forEach(filter => {
              this.byTargetDictionary[el.target + '_' + filter.target] = {
                label: el.name + ': ' + filter.name,
                type: filter.type
              }
            })
          } else {
            el.filters.forEach(filter => {
              this.byTargetDictionary[filter.target!] = {label: filter.name ?? el.name, type: filter.type};
            })
          }
        });

        this.createForm();
        this.form.valueChanges
          .pipe(
            debounceTime(300),
            takeUntil(this.destroy$)
          )
          .subscribe(value => {
            this.appliedFilters = [];
            for (const key in value) {
              if (value[key] !== null && value[key] !== '') {

                if (Array.isArray(value[key]) && !value[key].length){
                  continue;
                }

                // дефолтные значения фильтров не отображаем в списке выбранных.
                const filterKey = `filter[${key}]`;
                const formControlValue = isRecord(value[key]) ? value[key].id : value[key];
                if (this.defaultFilterValues[filterKey] !== undefined && this.defaultFilterValues[filterKey] === formControlValue) {
                  continue;
                }
                this.appliedFilters.push({
                  controlName: key,
                  value: value[key],
                  filterQueryParamName: filterKey
                })
              }
            }
          })

        this.setFormValuesFromQueryParams(queryParams);
      }
    });
  }


  public createForm() {
    this.filters.forEach(el => {
      for (const control of el.filters) {
        // В последствие, Ваня сформирует требования для валидаторов
        // которые будут добавляться динамически
        // const validators = [];
        let controlName = control.target!;

        // для периода используем составное название
        if (el.type === FilterType.DATERANGE && el.target) {
          controlName = el.target + '_' + controlName;
        }
        this.form.addControl(
          controlName,
          this.fb.control(null)
        )
      }

    })
  }

  /**
   * Удалить выбор определенного фильтра.
   * Если для поля есть фильтр по умолчанию - будет установлено значение по умолчанию
   */
  public cleanFilter(target: string) {
    const filterKey = `filter[${target}]`;
    if (this.defaultFilterValues[filterKey] !== undefined) {
      this.setControlValue(target, this.defaultFilterValues[filterKey]);
    } else {
      this.form.controls[target].patchValue(null);
    }
  }

  public applyFilters() {
    const values = filterNonNullValues(this.form.value);

    const queryParams: { [key: string]: any } = {};

    this.filters.forEach(filterGroup => {
      if (filterGroup.type === FilterType.DATERANGE && filterGroup.target) {
        const dateFromControlName = filterGroup.filters[0].target;
        const dateToControlName = filterGroup.filters[1].target;

        const dateFromParamName = filterGroup.target + '_' + dateFromControlName;
        const dateToParamName = filterGroup.target + '_' + dateToControlName;

        if (values[dateFromParamName] || values[dateToParamName]) {
          const fromDateValue = values[dateFromParamName];
          const toDateValue = values[dateToParamName];
          queryParams[`filter[${filterGroup.target}][${dateFromControlName}]`] = fromDateValue;
          queryParams[`filter[${filterGroup.target}][${dateToControlName}]`] = toDateValue;
        }
      } else {
        filterGroup.filters.forEach(innerFilter => {
          const filterValue = values[innerFilter.target!];
          if (filterValue) {
            queryParams[`filter[${innerFilter.target}]`] = Array.isArray(filterValue)
              ?
              filterValue.map(x=>x.id).join()
              :
              isRecord(filterValue)
                ? filterValue.id
                :
                filterValue;
          }
        });
      }
    });

    if (this.applyFilterAction) {
      this.applyFilterAction(values);
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        ...queryParams,
        page: 1,
      },
    });
  }
}
