import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';

import { TimePickerModule } from '../time-picker/time-picker.module';
import { BaseInputModule } from '../base-input';
import { CalendarModule } from '../calendar';
import { ActiveZoneModule } from '../active-zone';
import { TsDropdownModule } from '../dropdown';

import { InputDateTimeComponent } from './input-date.component';
import { DateTimeSelectorComponent } from './date-time-selector/date-time-selector.component';

const maskConfig: Partial<IConfig> = {
  leadZeroDateTime: true,
};

@NgModule({
  declarations: [InputDateTimeComponent, DateTimeSelectorComponent],
  imports: [
    BaseInputModule,
    CommonModule,
    CalendarModule,
    FormsModule,
    ActiveZoneModule,
    TimePickerModule,
    TsDropdownModule,
  ],
  providers: [provideEnvironmentNgxMask(maskConfig)],
  exports: [InputDateTimeComponent],
})
export class InputDateTimeModule {}
