import { Directive, ElementRef, Input } from '@angular/core';

import { Status, getColorByEnumValue } from '../../../data';

@Directive({
  selector: '[tsStatusColorizer]',
})
export class StatusColorizerDirective {
  @Input() set tsStatusColorizer(value: Status | undefined) {
    if (value?.color) {
      const { style } = this.elenentRef.nativeElement;
      style.setProperty('--custom-btn-color', getColorByEnumValue(value.color));
    }
  }

  constructor(private elenentRef: ElementRef) {}
}
