/**
 * TOPSELLER App
 * Topseller backend API
 *
 * OpenAPI spec version: 22.1205.1237
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Paginated, BatchDelete } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { Organization } from "@topseller/core";

@Injectable()
export class OrganizationService {
  protected basePath = 'http://denis.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Удалить организацию
   *
   * @param organizationId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteAppOrganizationDelete(
    organizationId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteAppOrganizationDelete(
    organizationId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteAppOrganizationDelete(
    organizationId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteAppOrganizationDelete(
    organizationId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (organizationId === null || organizationId === undefined) {
      throw new Error(
        'Required parameter organizationId was null or undefined when calling deleteAppOrganizationDelete.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/api/v1/organizations/${encodeURIComponent(
        String(organizationId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Поиск реквизитов организации по ИНН
   *
   * @param inn
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppOrganizationFindbyinn(
    inn: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Organization>;
  public getAppOrganizationFindbyinn(
    inn: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Organization>>;
  public getAppOrganizationFindbyinn(
    inn: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Organization>>;
  public getAppOrganizationFindbyinn(
    inn: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (inn === null || inn === undefined) {
      throw new Error(
        'Required parameter inn was null or undefined when calling getAppOrganizationFindbyinn.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Organization>(
      'get',
      `${this.basePath}/api/v1/organizations/inn/${encodeURIComponent(
        String(inn)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Список организаций
   *
   * @param search Строка поиска
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppOrganizationIndex(
    limit?: number,
    offset?: number,
    search?: string,
    sortName?: string,
    sortDir?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<Organization>>;
  public getAppOrganizationIndex(
    limit?: number,
    offset?: number,
    search?: string,
    sortName?: string,
    sortDir?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Paginated<Organization>>>;
  public getAppOrganizationIndex(
    limit?: number,
    offset?: number,
    search?: string,
    sortName?: string,
    sortDir?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Paginated<Organization>>>;
  public getAppOrganizationIndex(
    limit?: number,
    offset?: number,
    search?: string,
    sortName?: string,
    sortDir?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }
    if(sortName !== undefined && sortDir !== null) {
      queryParameters = queryParameters.set(`sort[${sortName}]`, <any>sortDir)
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<Organization>>(
      'get',
      `${this.basePath}/api/v1/organizations`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Список найденных организаций
   *
   * @param search Искомая строка
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppOrganizationSearch(
    search?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<Organization>>;
  public getAppOrganizationSearch(
    search?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Organization>>>;
  public getAppOrganizationSearch(
    search?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Organization>>>;
  public getAppOrganizationSearch(
    search?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Organization>>(
      'get',
      `${this.basePath}/api/v1/organizations/search`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Организация
   *
   * @param organizationId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppOrganizationView(
    organizationId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Organization>;
  public getAppOrganizationView(
    organizationId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Organization>>;
  public getAppOrganizationView(
    organizationId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Organization>>;
  public getAppOrganizationView(
    organizationId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (organizationId === null || organizationId === undefined) {
      throw new Error(
        'Required parameter organizationId was null or undefined when calling getAppOrganizationView.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Organization>(
      'get',
      `${this.basePath}/api/v1/organizations/${encodeURIComponent(
        String(organizationId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Отредактировать организацию
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchAppOrganizationUpdate(
    body?: Organization,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Organization>;
  public patchAppOrganizationUpdate(
    body?: Organization,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Organization>>;
  public patchAppOrganizationUpdate(
    body?: Organization,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Organization>>;
  public patchAppOrganizationUpdate(
    body?: Organization,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Organization>(
      'patch',
      `${this.basePath}/api/v1/organizations`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавить организацию
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppOrganizationCreate(
    body?: Organization,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Organization>;
  public postAppOrganizationCreate(
    body?: Organization,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Organization>>;
  public postAppOrganizationCreate(
    body?: Organization,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Organization>>;
  public postAppOrganizationCreate(
    body?: Organization,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Organization>(
      'post',
      `${this.basePath}/api/v1/organizations`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Удалить несколько документов Организации
   */
  public batchDeleteOrganization(ids: { ids: string[] }): Observable<BatchDelete> {
    return this.httpClient.post(
      `${this.basePath}/api/v1/organizations/batch-delete`,
      ids
    );
  }
}
