import {Pipe, PipeTransform} from '@angular/core';

/**
 * console.log(formatBytes(99));          // Вывод: '99 байт'
 * console.log(formatBytes(1024));        // Вывод: '1.0 КБ'
 * console.log(formatBytes(1234));        // Вывод: '1.2 КБ'
 * console.log(formatBytes(123456789));   // Вывод: '117.7 МБ'
 * console.log(formatBytes(1234567890123)); // Вывод: '1.1 ТБ'
 */

const units = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ'];
@Pipe({
  standalone: true,
  name: 'bytesToMegabytes'
})
export class BytesToMegabytesPipe implements PipeTransform {
  transform(bytes: any): string {
    let l = 0, n = parseInt(bytes, 10) || 0;

    while(n >= 1024 && ++l){
      n = n/1024;
    }

    return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  }
}
