<button class="ts-btn btn-grey ts-btn-bezeled ts-btn-sm ts-btn-icon" (click)="onPrevMonthClick()">
  <span class="ts-icon ts-icon-arrow-Expand_left"></span>
</button>

  <span class="header-month">{{ month | textMonth }}</span>
  <button class="ts-btn btn-grey ts-btn-bezeled ts-btn-sm" (click)="onYearClick()">
    {{ month.formattedYear }}
  </button>

<button class="ts-btn btn-grey ts-btn-bezeled ts-btn-sm ts-btn-icon" (click)="onNextMonthClick()">
  <span class="ts-icon ts-icon-arrow-Expand_right"></span>
</button>

<button class="ts-btn btn-grey ts-btn-bezeled ts-btn-sm ts-btn-icon" (click)="onSelectTodayClick()">
  <span class="ts-icon ts-icon-time-calendar"></span>
</button>

