import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableSettingsModalComponent } from './table-settings-modal.component';
import { CdkDrag, CdkDragHandle, CdkDropList } from "@angular/cdk/drag-drop";
import { MatDialogModule } from "@angular/material/dialog";
import { ModalDialogHeaderComponent } from "../dialog-header/modal-dialog-header.component";
import { TsScrollModule } from "@topseller/ui/scroll";
import { EffectsModule } from "@ngrx/effects";
import { TableSettingsModalEffects } from "../table-settings-modal/store";

@NgModule({
  declarations: [TableSettingsModalComponent],
  imports: [CommonModule, TsScrollModule, CdkDrag, CdkDragHandle, CdkDropList, MatDialogModule, ModalDialogHeaderComponent, EffectsModule.forFeature([TableSettingsModalEffects]),],
  exports: [TableSettingsModalComponent]
})
export class TableSettingsModalModule {
}
