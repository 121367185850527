import { Directive, ViewChild } from "@angular/core";
import { BaseTableWithFilterComponent } from "../base-table-with-filter-component.directive";
import { HubEntity, NavTreeItem, Product, ProductGroup, ProductService } from "../../../data";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { ProductGroupService } from "../../../data/api";
import {
  ProductShowVariablesService,
  ShowNames
} from "../../../pages/products-services/product/services/show-variables.service";
import { SidebarService } from "@topseller/common/sidebar";
import { ValueUnitPipe } from "@topseller/ui/pipes";
import { BatchActionsService, FiltersService } from "../../services";
import { FromStoreTableHeaderService, TableSettingsService } from "@topseller/common";
import { ListItemsDeleteService } from "@topseller/common/base-list";
import { TsResizeDirective } from "../../directives";
import { from, Observable, of, share, switchMap, tap } from "rxjs";
import { map } from "rxjs/operators";
import { productHeaders } from "../../../pages/products-services/product-list/product-list.mock";
import { TableHeader } from "@topseller/ui";
import { HubInterfaceTable, ROLES } from "@topseller/core";
import { selectProductGroups } from "../../../pages/products-services/store";

@Directive()
export class BaseProductsListComponent extends BaseTableWithFilterComponent<Product> {
  override entity: HubEntity = HubEntity.PRODUCT;

  public showNav = false;

  @ViewChild(TsResizeDirective) resizer!: TsResizeDirective;

  showNav$ = from(this.showVariablesService.getVar(ShowNames.SHOW_NAV)).pipe(
    tap(value => this.showNav = value),
    map(value => value ? 'show' : 'hide')
  );

  displayableTableHeaders$!: Observable<TableHeader[]>;
  override tableIdentifier = HubInterfaceTable[HubEntity.PRODUCT]

  protected editProductsRole = ROLES.ROLE_PRODUCT_EDIT;
  protected deleteProductsRole = ROLES.ROLE_PRODUCT_DELETE;

  selectedProductGroupId = '';
  public selectedProductGroupId$ = this.route.queryParams.pipe(
    map((params) => params['filter[productGroup]']),
    map(groupId => groupId ?? ''),
    tap(groupId => this.selectedProductGroupId = groupId),
    share()
  );


  navTreeMenu$ = this.store
    .select(selectProductGroups)
    .pipe(
      map((productGroup) => this.mapProductGroupsToNavTreeItem(productGroup))
    );

  minNavTreeWidth = 260;

  constructor(
    router: Router,
    protected productService: ProductService,
    protected store: Store,
    protected dialog: MatDialog,
    protected showVariablesService: ProductShowVariablesService,
    activatedRoute: ActivatedRoute,
    sidebarService: SidebarService,
    valueUnitPipe: ValueUnitPipe,
    filtersService: FiltersService,
    private hubTableHeaderService: FromStoreTableHeaderService,
    tableSettingsService: TableSettingsService,
  ) {
    super(sidebarService, activatedRoute, valueUnitPipe, filtersService, router, tableSettingsService);
  }

  public selectProductGroup(groupId: string | undefined) {
    if (groupId === this.selectedProductGroupId) {
      groupId = undefined;
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        'filter[productGroup]': groupId,
        page: 1,
      },
      queryParamsHandling: 'merge',
    });
  }

  public toggleProductGroups(): void {
    this.showVariablesService.setVar(ShowNames.SHOW_NAV, !this.showNav);
    this.showNav = !this.showNav;
    this.resizer.reset();
  }

  protected configureTableHeaders(){
    this.displayableTableHeaders$ = this.hubTableHeaderService.getTableHeaders(this.tableIdentifier, productHeaders).pipe(
      switchMap(baseHeaders => this.items$ ? this.items$.pipe(
        map(products => {
          const headers = [...baseHeaders]; // Клонируем массив, чтобы не мутировать оригинал
          const changerHeader = headers.pop();
          const pricesHeaders: TableHeader[] = [];
          for (const product of products) {
            if (product.productPriceGroups && product.productPriceGroups.length > 0) {
              for (const priceGroup of product.productPriceGroups)
                if (pricesHeaders.findIndex(x => x.key === priceGroup.id) < 0) {
                  pricesHeaders.push({
                    key: priceGroup.id!,
                    isResizable: false,
                    label: priceGroup.name,
                    width: '200',
                    type: 'priceGroup'
                  });
                }
            }
          }
          headers.push(...pricesHeaders);
          if (changerHeader) headers.push(changerHeader);
          return headers;
        })
      ) : of(baseHeaders))
    );
  }


  private mapProductGroupsToNavTreeItem(
    productGroups: ProductGroup | ProductGroup[]
  ): NavTreeItem[] {
    const result: NavTreeItem[] = [];
    if (!productGroups) {
      return result;
    }
    if (Array.isArray(productGroups)) {
      for (const group of productGroups) {
        result.push(this.groupToNavTreeItem(group));
      }
    } else {
      result.push(this.groupToNavTreeItem(productGroups));
    }
    return result;
  }

  private groupToNavTreeItem(productGroup: ProductGroup) {
    const item: NavTreeItem = {
      title: productGroup.name,
      open: true,
      showSettings: false,
      dropdown: this.mapProductGroupsToNavTreeItem(
        productGroup.productGroups ?? []
      ),
      id: productGroup.id!,
    };

    return item;
  }

  override isDisabled(item: Product): boolean {
    return item.isArchived;
  }
}
