import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { NavTreeItem } from '../../../../data';
import { NAV_TREE_CONFIG, NavTreeConfig } from "../config";

@Component({
  selector: 'app-nav-tree-item',
  templateUrl: './nav-tree-item.component.html',
  styleUrls: ['./nav-tree-item.component.scss'],
})
export class NavTreeItemComponent {
  @Input() menu!: NavTreeItem[];
  @Input() index = 0;
  @Input() canEdit = false;
  @Input() isModal?: boolean;

  @Input() selectedItemId?: string | null = null;

  @Output() position = new EventEmitter<number>();
  @Output() selectItem = new EventEmitter();
  @Output() deleteTreeItem = new EventEmitter();
  @Output() editTreeItem = new EventEmitter();
  @Output() createTreeItem = new EventEmitter();

  constructor(@Optional() @Inject(NAV_TREE_CONFIG) public config: NavTreeConfig) {
  }

  // сначала вызывается этот метод при клике на элемент дерева
  // предотвратим сворачивание/разворачивание дерева с помощью $event.stopPropagation();
  // далее вызов метода поднимается вверх по дереву вызывая onSelectItem, пока мы не дойдем до корневого узла
  // и не передадим данные в базовый компонент.
  public onSelectChildItem($event: MouseEvent, itemId: string) {
    $event.stopPropagation(); // prevent tree Item from opening/closing
    this.selectItem.emit(itemId);
  }

  public onSelectItem(itemId: string) {
    this.selectItem.emit(itemId);
  }

  delete(item: NavTreeItem) {
    this.deleteTreeItem.emit(item);
  }
}
