import { normalizeToIntNumber } from './utils';

export class TsYear {
  public get isLeapYear(): boolean {
    return TsYear.isLeapYear(this.year);
  }

  public get formattedYear(): string {
    return String(this.year).padStart(4, '0');
  }

  constructor(readonly year: number) {}

  protected static isLeapYear(year: number): boolean {
    return year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0);
  }

  static currentYear(): TsYear {
    const nativeDate = new Date();
    return new TsYear(nativeDate.getFullYear());
  }

  protected static normalizeYear(year: number): number {
    return normalizeToIntNumber(year, 0, 9999);
  }

  public yearBefore({ year }: TsYear): boolean {
    return this.year < year;
  }

  public yearAfter({ year }: TsYear): boolean {
    return this.year > year;
  }

  public yearSame({ year }: TsYear): boolean {
    return this.year === year;
  }
}
