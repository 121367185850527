import { Injectable } from '@angular/core';

import { entityClass, HubEntity, Product } from '../../data';
import { ComponentType } from "@angular/cdk/overlay";

@Injectable()
export class TsEntityService {

  public getComponent<T>(name: HubEntity): ComponentType<T> {
    return entityClass[name as keyof typeof entityClass] as ComponentType<T>;
  }
}
