import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Optional, Output, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first, Subscription, take } from 'rxjs';

import { BaseItemInfoComponent } from '../../../common/components/base-item-info.component';
import { ProjectService } from '../../../data';
import { Store } from "@ngrx/store";
import { ConfirmDialogComponent, Counterparty, DialogService, ROLES, setShowLoader } from '@topseller/core';
import { BaseInnItemComponent } from "../../../common/components/base-inn-item.component";

@Component({
  selector: 'app-project-item',
  templateUrl: './project-item.component.html',
  styleUrls: ['../company/company.component.scss'],
  providers: [{provide: BaseInnItemComponent, useExisting: ProjectItemComponent}]
})
export class ProjectItemComponent
  extends BaseItemInfoComponent
  implements OnInit, OnDestroy {
  public showHeaderBtns = false;
  public showHeaderBtnsSub$?: Subscription;

  public form: FormGroup = new FormGroup({
    name: new FormControl<string>('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
    note: new FormControl<string>('', [Validators.maxLength(255)]),
    isDefault: new FormControl<boolean>(false),
  });

  @Output() showCreatingBlock = new EventEmitter();

  override backLinkUrl = '/hub/handbooks/projects';
  public editItemRole = ROLES.ROLE_PROJECT_EDIT;
  public deleteItemRole = ROLES.ROLE_PROJECT_DELETE;

  constructor(
    activatedRoute: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    public projectService: ProjectService,
    changeDetectorRef: ChangeDetectorRef,
    matDialog: MatDialog,
    store: Store,
    @Optional() private dialogRef: MatDialogRef<ProjectItemComponent>,
    private dialogService: DialogService
  ) {
    super(activatedRoute, toastr, router, changeDetectorRef, matDialog, store);
  }

  public get isModal(): boolean {
    return this.dialogRef?.componentInstance instanceof ProjectItemComponent;
  }

  ngOnInit(): void {
    this.getResolvedData(this.form, 'project');

    this.showHeaderBtnsSub$ = this.showHeaderBtns$.subscribe(
      (show) => (this.showHeaderBtns = show)
    );
  }

  createProject(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.toastr.error('Форма содержит ошибки');
      return
    }
    this.store.dispatch(setShowLoader({showLoader: true}))
    this.projectService
      .postAppProjectCreate(this.form.value)
      .pipe(take(1))
      .subscribe({
        next: (result: Counterparty) => {
          this.toastr.success('Проект успешно сохранен');
          this.store.dispatch(setShowLoader({showLoader: false}))
          const url = '/hub/handbooks/projects';
          if (this.isModal) {
            this.dialogRef.close(result);
          } else {
            this.form.markAsPristine();
            this.navigateByUrl(`${url}/${result.id}`);
          }
        },
        error: (err: any) => {
          this.creationState$.next('error');
          this.store.dispatch(setShowLoader({showLoader: false}))

          this.toastr.error(
            err?.errors?.length && err.errors[0].message
              ? err.errors[0].message
              : err?.message || 'Что-то пошло не так'
          );
        },
      });
  }

  updateProject(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.store.dispatch(setShowLoader({showLoader: true}))
    this.updateItem(
      this.form,
      this.projectService,
      'patchAppProjectUpdate',
      'Проект'
    );
  }

  public deleteProject(): void {
    const data = {
      title: 'Удаление проекта',
      content: 'Вы уверены, что хотите удалить проект ?',
      okBtn: 'Удалить'
    };
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialogService.openConfirmDialog(data);

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe({
        next: (result: boolean) => {
          this.deleteItem(
            this.projectService,
            'deleteAppProjectDelete',
            '/hub/handbooks/projects',
            result ? 'да' : 'нет'
          );
        }
      });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.showHeaderBtnsSub$?.unsubscribe();
  }
}
