import { Directive, ElementRef, Inject, NgZone, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  filter,
  fromEvent,
  map,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

import { BaseInputDirective } from './base-input.directive';

const MAX_HEIGHT = 500;
const MIN_HEIGHT = 50;

@Directive({
  selector: '[tsInputResizer]',
})
export class TsInputResizerDirective implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private host: BaseInputDirective,
    private resizer: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private ngZone: NgZone
  ) {}

  public ngOnInit(): void {
    const { nativeElement } = this.host;

    fromEvent<PointerEvent>(this.resizer?.nativeElement, 'pointerdown')
      .pipe(
        tap((event: PointerEvent) => {
          event.stopPropagation();
          event.preventDefault();
        }),
        switchMap(({ screenY }: PointerEvent) => {
          const { height }: DOMRect = nativeElement.getBoundingClientRect();

          return fromEvent<PointerEvent>(this.document, 'pointermove').pipe(
            takeUntil(fromEvent(this.document, 'pointerup')),
            map((event: PointerEvent) => height - screenY + event.screenY)
          );
        }),
        filter((height) => height <= MAX_HEIGHT && height >= MIN_HEIGHT),
        takeUntil(this.destroy$)
      )
      .subscribe((calcuatedHeight: number) => {
        this.ngZone.runOutsideAngular(() => {
          const { style } = nativeElement;

          requestAnimationFrame(() => {
            style.height = `${calcuatedHeight}px`;
          });
        });
      });
  }
}
