import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { FIRST_DAY, LAST_DAY, TsMonth, TsYear } from '../../../common';

const LIMIT = 50;

@Component({
  selector: 'ts-year-selector',
  templateUrl: './calendar-year-selector.component.html',
  styleUrls: ['./calendar-year-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarYearSelectorComponent {
  private readonly currentYear = TsMonth.currentMonth().year;

  @Input()
  value: TsYear | null = TsYear.currentYear();

  @Output()
  readonly yearClick = new EventEmitter<TsYear>();

  constructor(private elementRef: ElementRef) {}

  public items: string[] = [
    ...Array(this.calculatedMax - this.calculatedMin),
  ].map((_, index) => {
    const year = this.calculatedMin + index;
    return String(year).padStart(4, '0');
  });

  public onItemClick(item: string): void {
    this.yearClick.emit(new TsYear(+item));
  }

  public get calculatedMin(): number {
    const initial = this.value ? this.value.year - LIMIT : 0;
    return FIRST_DAY.year > initial ? FIRST_DAY.year : initial;
  }

  public get calculatedMax(): number {
    const initial = this.value ? this.value.year + LIMIT : LIMIT * 2;
    return LAST_DAY.year < initial ? LAST_DAY.year : initial;
  }

  public itemIsToday(item: string): boolean {
    return this.currentYear === +item;
  }

  public isCurrent(item: string): boolean {
    return this.value?.year === +item;
  }

  public scrollToView(element: HTMLElement): void {
    const { offsetTop, offsetHeight } = element;
    const nativeElement = this.elementRef.nativeElement;

    nativeElement.scrollTop =
      offsetTop + offsetHeight / 2 - nativeElement.clientHeight / 2;
  }
}
