import { createAction, props } from "@ngrx/store";

export const openSettingsTableModal = createAction(
  '[COMMON Settings Table] Open settings table modal',
  props<{ entity: string }>()
)

export const closeSettingsTableModal = createAction(
  '[COMMON Settings Table] Close settings table modal'
)

export const setSettingsTableColumns = createAction(
  '[COMMON Settings Table] Set settings table columns', props<{ settings: { [key: string]: string } }>()
)

