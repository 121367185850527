import {TableHeader} from "@topseller/ui";

export const productHeaders: TableHeader[] = [
  {label: 'Наименование', key: 'name', width: '336', isPinned: true, isSortable: true, isResizable: false},
  {label: 'Идентификаторы товара', key: 'identifiers', width: '316',},
  {label: 'Ед. изм.', key: 'unit', width: '136', isSortable: true,},
  {label: 'Страна', key: 'country', width: '140', isSortable: true,},
  {label: 'Габариты', key: 'dimensions', width: '102', },
  {label: 'НДС', key: 'vat', width: '67', isSortable: true},
  {label: 'Поставщик', key: 'supplier', width: '260',isSortable: true},
  {label: 'Последнее изменение', width: '220', key: 'modifiedBy', isSortable: true,},
];
