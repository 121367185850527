import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsCustomContentComponent } from "@topseller/ui";
import { TsAbstractControl } from "@topseller/cdk/abstract";
import { FormsModule, NgControl, ReactiveFormsModule } from "@angular/forms";
import { BaseInputModule } from "@topseller/ui/base-input";
import { ActiveZoneDirective } from "@topseller/ui/active-zone";
import { TsDropdownModule } from "@topseller/ui/dropdown";
import { InputNumberModule } from "@topseller/ui/input-number";
import { TsSelectOptionModule } from "@topseller/ui/select-option";
import { TsEntityListModule } from "@topseller/common/entity-list";
import { ProductService } from "../../../data";
import { BehaviorSubject, map, Observable, of, shareReplay, tap } from "rxjs";
import { ControlErrorModule } from "@topseller/common/control-error";
import { InputCurrencyModule } from "@topseller/ui/input-currency/input-currency.module";
import { DotLoaderComponent } from "@topseller/ui/dot-loader";
import { TsScrollModule } from "@topseller/ui/scroll";
import { CurrencyFormatModule, CurrencyFormatPipe } from "@topseller/ui/pipes";
import { FocusableDirective } from "@topseller/ui/directives/focusable.directive";
import { ViewEditModeSwitchComponent } from "@topseller/ui/view-edit-mode-switch/view-edit-mode-switch.component";
import { ViewModeDirective } from "@topseller/ui/view-edit-mode-switch/view-mode.directive";
import { TsDropdownComponent } from "@topseller/ui/dropdown/dropdown.component";

interface PriceModel {
  name: string;
  value: number;
}

@Component({
  selector: 'ts-product-price-selector-cell',
  standalone: true,
  imports: [CommonModule, BaseInputModule, TsCustomContentComponent, ActiveZoneDirective, TsDropdownModule, FormsModule, InputNumberModule, TsSelectOptionModule, TsEntityListModule, ControlErrorModule, InputCurrencyModule, ReactiveFormsModule, DotLoaderComponent, TsScrollModule, FocusableDirective, ViewEditModeSwitchComponent, ViewModeDirective, CurrencyFormatModule],
  templateUrl: './product-price-selector-cell.component.html',
  styleUrls: ['./product-price-selector-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPriceSelectorCellComponent
  extends TsAbstractControl<number> implements OnInit {

  mode: 'view' | 'edit' = 'view';

  isLoading$ = new BehaviorSubject(true);
  prices$: Observable<PriceModel[]> = of([])
  @Input() productId: string = '';

  @HostBinding('class.disabled')
  @Input()
  public readonly = false;

  @ViewChild(TsDropdownComponent) dropdown?: TsDropdownComponent<any>;

  constructor(
    @Self() @Optional() ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef,
    private productService: ProductService,
    private numberToCurrencyPipe: CurrencyFormatPipe
  ) {
    super(ngControl, changeDetectorRef);
  }

  ngOnInit() {
    this.prices$ = this.productService.getPriceGroups(this.productId).pipe(
      map(response => {
        const flatList = response.items.flatMap(productPriceGroup =>
          productPriceGroup.productPrices?.map(productPrice => ({
            value: productPrice.value!,
            name: `${productPriceGroup.name} ${productPrice.productPriceType.name}: ${this.numberToCurrencyPipe.transform(productPrice.value)}`
          })) || []
        );
        return flatList;
      }),
      shareReplay(1),
      tap(_ => this.isLoading$.next(false))
    );
  }


  selectPrice(price: PriceModel) {
    this.updateValue(price.value);
  }

  setEditMode(){
    this.mode = 'edit';

    setTimeout(() => {
      if (this.dropdown){
        this.dropdown.updateOpen(true);
      }
    }, 100);
  }

  onActiveZone(active: boolean) {
    if (!active) {
      this.mode = 'view';
    }
  }

  protected getFallbackValue(): number {
    return 0;
  }
}
