import { ProductPriceModel, ProductPrices } from './../model/product-prices';
/**
 * TOPSELLER App
 * Topseller backend API
 *
 * OpenAPI spec version: 0.1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Paginated } from '../model/paginated';
import { ProductDescription } from '../model/product-description';

import { Product, ProductModifValues, ProductPriceWithProduct } from '../model/product';
import { ProductSet } from '../model/product-set';

import { Configuration } from '../configuration';
import { ProductGroup } from '../model/product-group';
import { ProductStocks } from '../model/product-stocks';
import { Record } from '@topseller/core';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { PaginatedModificationAttribute } from '../model/paginated-modification-attribute';
import { PaginatedProductModificationValue } from '../model/paginated-product-modification-value';
import { BatchActionTemplate, TemplateRequest } from '../model/templateRequest';

@Injectable()
export class ProductService {
  protected basePath = 'http://denis.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Удалить товар
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteProductDelete(
    id: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteProductDelete(
    id: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteProductDelete(
    id: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteProductDelete(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteProductDelete.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/api/v1/products/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Удалить описание товара [для маркетплейса]
   * Если marketplaceId &#x3D; 0, то удаляется основное описание
   * @param productId
   * @param marketplaceId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteProductDeleteProductDescription(
    productId: string,
    marketplaceId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteProductDeleteProductDescription(
    productId: string,
    marketplaceId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteProductDeleteProductDescription(
    productId: string,
    marketplaceId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteProductDeleteProductDescription(
    productId: string,
    marketplaceId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling deleteProductDeleteProductDescription.'
      );
    }

    if (marketplaceId === null || marketplaceId === undefined) {
      throw new Error(
        'Required parameter marketplaceId was null or undefined when calling deleteProductDeleteProductDescription.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/api/v1/products/${encodeURIComponent(
        String(productId)
      )}/descriptions/${encodeURIComponent(String(marketplaceId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Удалить модификацию
   *
   * @param productId
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteProductDeleteProductModifications(
    productId: string,
    id: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteProductDeleteProductModifications(
    productId: string,
    id: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteProductDeleteProductModifications(
    productId: string,
    id: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteProductDeleteProductModifications(
    productId: string,
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling deleteProductDeleteProductModifications.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteProductDeleteProductModifications.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Product>(
      'delete',
      `${this.basePath}/api/v1/products/${encodeURIComponent(
        String(productId)
      )}/modifications/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Копирование товара
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProductCopy(
    id: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Product>;
  public getProductCopy(
    id: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Product>>;
  public getProductCopy(
    id: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Product>>;
  public getProductCopy(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getProductCopy.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Product>(
      'get',
      `${this.basePath}/api/v1/products/${encodeURIComponent(String(id))}/copy`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Описания товара
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProductDescriptions(
    id: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ProductDescription>;
  public getProductDescriptions(
    id: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ProductDescription>>;
  public getProductDescriptions(
    id: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ProductDescription>>;
  public getProductDescriptions(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getProductDescriptions.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ProductDescription>(
      'get',
      `${this.basePath}/api/v1/products/${encodeURIComponent(
        String(id)
      )}/descriptions`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Описание товара [на маркетплейсе]
   *
   * @param productId
   * @param marketplaceId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProductGetDescription(
    productId: string,
    marketplaceId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ProductDescription>;
  public getProductGetDescription(
    productId: string,
    marketplaceId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ProductDescription>>;
  public getProductGetDescription(
    productId: string,
    marketplaceId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ProductDescription>>;
  public getProductGetDescription(
    productId: string,
    marketplaceId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling getProductGetDescription.'
      );
    }

    if (marketplaceId === null || marketplaceId === undefined) {
      throw new Error(
        'Required parameter marketplaceId was null or undefined when calling getProductGetDescription.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ProductDescription>(
      'get',
      `${this.basePath}/api/v1/products/${encodeURIComponent(
        String(productId)
      )}/descriptions/${encodeURIComponent(String(marketplaceId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Список товаров
   *
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param filterProductGroup категория
   * @param search Строка поиска
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppProductIndex(
    limit?: number,
    offset?: number,
    search?: string,
    filter: string = '',
    sortName?: string,
    sortDir?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<Product>> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }

    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }

    if (sortName !== undefined && sortDir !== null) {
      queryParameters = queryParameters.set(`sort[${sortName}]`, <any>sortDir);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<Product>>(
      'get',
      `${this.basePath}/api/v1/products?${filter}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Модификации товара
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProductIndexProductModifications(
    id: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Product>;
  public getProductIndexProductModifications(
    id: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Product>>;
  public getProductIndexProductModifications(
    id: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Product>>;
  public getProductIndexProductModifications(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getProductIndexProductModifications.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Product>(
      'get',
      `${this.basePath}/api/v1/products/${encodeURIComponent(
        String(id)
      )}/modifications`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Поиск товаров по названию
   *
   * @param q текст для поиска
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProductSearchName(
    q?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<ProductSet>>;
  public getProductSearchName(
    q?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ProductSet>>>;
  public getProductSearchName(
    q?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ProductSet>>>;
  public getProductSearchName(
    q?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (q !== undefined && q !== null) {
      queryParameters = queryParameters.set('q', <any>q);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<ProductSet>>(
      'get',
      `${this.basePath}/api/v1/products/searchByName`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Товар
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProductView(
    id: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Product>;
  public getProductView(
    id: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Product>>;
  public getProductView(
    id: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Product>>;
  public getProductView(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getProductView.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Product>(
      'get',
      `${this.basePath}/api/v1/products/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Модификация товара
   *
   * @param productId
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProductViewProductModifications(
    productId: string,
    id: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Product>;
  public getProductViewProductModifications(
    productId: string,
    id: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Product>>;
  public getProductViewProductModifications(
    productId: string,
    id: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Product>>;
  public getProductViewProductModifications(
    productId: string,
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling getProductViewProductModifications.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getProductViewProductModifications.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Product>(
      'get',
      `${this.basePath}/api/v1/products/${encodeURIComponent(
        String(productId)
      )}/modifications/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Отредактировать товар
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchProductUpdate(
    body?: any,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ProductSet> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ProductSet>(
      'patch',
      `${this.basePath}/api/v1/products`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Изменить описание товара для маркетплейса
   *
   * @param productId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchProductUpdateProductDescription(
    productId: string,
    body?: ProductDescription,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ProductDescription>;
  public patchProductUpdateProductDescription(
    productId: string,
    body?: ProductDescription,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ProductDescription>>;
  public patchProductUpdateProductDescription(
    productId: string,
    body?: ProductDescription,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ProductDescription>>;
  public patchProductUpdateProductDescription(
    productId: string,
    body?: ProductDescription,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling patchProductUpdateProductDescription.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ProductDescription>(
      'patch',
      `${this.basePath}/api/v1/products/${encodeURIComponent(
        String(productId)
      )}/descriptions`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Отредактировать модификацию
   *
   * @param productId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchProductUpdateProductModifications(
    productId: string,
    body?: any,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public patchProductUpdateProductModifications(
    productId: string,
    body?: any,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public patchProductUpdateProductModifications(
    productId: string,
    body?: any,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public patchProductUpdateProductModifications(
    productId: string,
    body?: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling patchProductUpdateProductModifications.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Product>(
      'patch',
      `${this.basePath}/api/v1/products/${encodeURIComponent(
        String(productId)
      )}/modifications`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавить описание товара [для маркетплейса]
   *
   * @param productId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postProductAddProductDescription(
    productId: string,
    body?: ProductDescription,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ProductDescription>;
  public postProductAddProductDescription(
    productId: string,
    body?: ProductDescription,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ProductDescription>>;
  public postProductAddProductDescription(
    productId: string,
    body?: ProductDescription,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ProductDescription>>;
  public postProductAddProductDescription(
    productId: string,
    body?: ProductDescription,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling postProductAddProductDescription.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ProductDescription>(
      'post',
      `${this.basePath}/api/v1/products/${encodeURIComponent(
        String(productId)
      )}/descriptions`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавить модификации товара
   *
   * @param productId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postProductAddProductModifications(
    productId: string,
    body?: any,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public postProductAddProductModifications(
    productId: string,
    body?: any,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public postProductAddProductModifications(
    productId: string,
    body?: any,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public postProductAddProductModifications(
    productId: string,
    body?: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling postProductAddProductModifications.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Product>(
      'post',
      `${this.basePath}/api/v1/products/${encodeURIComponent(
        String(productId)
      )}/modifications`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавить товар
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postProductCreate(
    body?: ProductSet,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ProductSet>;
  public postProductCreate(
    body?: ProductSet,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ProductSet>>;
  public postProductCreate(
    body?: ProductSet,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ProductSet>>;
  public postProductCreate(
    body?: ProductSet,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ProductSet>(
      'post',
      `${this.basePath}/api/v1/products`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  // в свагере не правильный тип респонса, там productSet обертка,
  // пришлось новые методы простые написать
  public postNewProduct(obj: Product) {
    return this.httpClient.post(`${this.basePath}/api/v1/products`, obj);
  }

  public patchNewProduct(obj: Product) {
    return this.httpClient.patch(`${this.basePath}/api/v1/products`, obj);
  }

  public deleteNewProduct(id: string) {
    return this.httpClient.delete(`${this.basePath}/api/v1/products/${id}`);
  }

  public batchDeleteNewProduct(ids: any) {
    return this.httpClient.post(
      `${this.basePath}/api/v1/products/batch-delete`,
      ids
    );
  }

  //группы цен
  public getPriceModel() {
    return this.httpClient.get<Paginated<ProductPriceModel>>(
      `${this.basePath}/api/v1/product/price/groups`
    );
  }

  public postPriceModel(priceModel: ProductPriceModel[]) {
    const obj = {
      items: priceModel,
    };

    return this.httpClient.post(
      `${this.basePath}/api/v1/product/price/groups/bulk`,
      obj
    );
  }

  public getPriceGroups(id: string):Observable<Paginated<ProductPrices>> {
    return this.httpClient.get<Paginated<ProductPrices>>(
      `${this.basePath}/api/v1/products/${id}/prices`
    );
  }

  public postPriceGroups(id: string, obj: ProductPrices[]) {
    return this.httpClient.post(
      `${this.basePath}/api/v1/products/${id}/prices`,
      obj
    );
  }

  public patchPriceGroups(id: string, obj: ProductPrices[]) {
    return this.httpClient.patch(
      `${this.basePath}/api/v1/products/${id}/prices`,
      obj
    );
  }

  // продуктовые группы

  //остатки товара
  public getProductStocks(id: string) {
    return this.httpClient.get<Paginated<ProductStocks>>(
      `${this.basePath}/api/v1/product-stocks?filter[product]=${id}`
    );
  }

  //модификации
  public getModificationAttributes(
    limit?: number,
    offset?: number,
    search?: string
  ) {
    return this.httpClient.get<Paginated<Record>>(
      `${this.basePath}/api/v1/modification-attributes`
    );
  }

  /**
   * Список характеристик для модификаций
   * Список характеристик для модификаций
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param search Строка поиска
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppModificationattributeIndex(
    limit?: number,
    offset?: number,
    search?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<PaginatedModificationAttribute>> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<PaginatedModificationAttribute>>(
      'get',
      `${this.basePath}/api/v1/modification-attributes`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Свойства характеристики
   * Свойства характеристики
   * @param modificationAttributeId
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param search Строка поиска
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppModificationattributeValues(
    modificationAttributeId: string,
    search?: string,
    limit?: number,
    offset?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<ProductModifValues>> {
    if (
      modificationAttributeId === null ||
      modificationAttributeId === undefined
    ) {
      throw new Error(
        'Required parameter modificationAttributeId was null or undefined when calling getAppModificationattributeValues.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<ProductModifValues>>(
      'get',
      `${this.basePath}/api/v1/modification-attributes/${encodeURIComponent(
        String(modificationAttributeId)
      )}/values`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  //свойсва под характеристики
  public getPropertiesModifications(modificationAttributeId: string) {
    return this.httpClient.get<Paginated<Record>>(
      `${this.basePath}/api/v1/modification-attributes/${modificationAttributeId}/values`
    );
  }

  public postGenerateModifications(
    id: string,
    modifList: any
  ): Observable<Product[]> {
    return this.httpClient.post<Product[]>(
      `${this.basePath}/api/v1/products/${id}/modifications/create`,
      modifList
    );
  }

  public template(template: TemplateRequest) {
    return this.httpClient.patch(
      `${this.basePath}/api/v1/products/template`,
      template
    );
  }

  /**
   * Получить цену priceTypeId для списка продуктов.
   * */
  public getPricesBatch(productIds:string[], priceTypeId:string):Observable<ProductPriceWithProduct[]>{
    const filter = `filter[productPriceType]=${priceTypeId}`;
    const request = {ids:productIds};
    return this.httpClient.post<ProductPriceWithProduct[]>(
      `${this.basePath}/api/v1/products/prices/ids?${filter}`,
      request
    );
  }
}
