import {
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SecurityService } from '../providers';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[tsServiceAvailableByRestrictions]',
  standalone: true
})
export class ServiceAvailableByRestrictionsDirective implements OnDestroy {

  private destroy$ = new Subject<void>();

  constructor(private securityService: SecurityService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
  }

  @Input() set tsServiceAvailableByRestrictions(serviceId: string) {
    this.securityService.serviceAvailableByRestrictions(serviceId).pipe(takeUntil(this.destroy$))
      .subscribe(available => {
        this.viewContainer.clear();
        if (available) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
