import { FormControl } from "@angular/forms";
import { Product, Vat } from "../../../data";
import { DocumentCreationLink } from "../../../common";

// TODO: удалить когда будет перерабатываться эта часть
export interface Tab {
    title: string;
    isActive: boolean;
}
// TODO: удалить когда будет перерабатываться эта часть
export const TAB_LIST: Tab[] = [
    {
        title: 'Комментарии',
        isActive: true,
    },
    {
        title: 'Файлы',
        isActive: false,
    },
];




export interface ProductLine {
    id: string;
    product: Product;
    quantity: number;
    vatType: number;
    vatPrice: number;
    price: number;
    reserved: number;
    discountPricePercent: number;
    demanded: number;
    recalled: number;
}

export const inOrderCreationList: DocumentCreationLink[] = [
    {
        title: 'Отгрузить товар',
        url: '/hub/sale/demand/ref'
    },
    {
        title: 'Принять оплату',
        url: '/hub/money/payment/in/order'
    },
    {
        title: 'Возврат от покупателя',
        url: '/hub/sale/recall/ref'
    },
    {
        title: 'Вернуть деньги покупателю',
        url: '/hub/money/payment/out/order'
    },
]
