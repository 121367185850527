import { createAction, props } from "@ngrx/store";
import { WarehouseGroup } from "../../../../data/model/warehouseGroup";

export const loadWarehouseGroups = createAction(
    '[HUB Warehouse Groups] Load Warehouse Groups'
);

export const setWarehouseGroups = createAction(
    '[HUB Warehouse Groups] Set Warehouse Groups',
    props<{ items: WarehouseGroup[] }>()
);
