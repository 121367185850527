import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ActiveZoneModule } from '../active-zone';

import { TimePickerComponent } from './time-picker.component';
import { TimeSelectorComponent } from './time-selector/time-selector.component';

@NgModule({
  declarations: [TimePickerComponent, TimeSelectorComponent],
  imports: [CommonModule, ActiveZoneModule],
  exports: [TimePickerComponent],
})
export class TimePickerModule {}
