<div
  *ngIf="size === 's'; else large"
  class="ts-pagination ts-pagination--sm"
  size="sm"
>
  <span>{{ firstItem }}-{{ lastItem }} из {{ totalItems }}</span>
  <button
    class="ts-icon ts-icon-arrow-Expand_left"
    [ngClass]="{ disabled: !canGoBack }"
    [disabled]="!canGoBack"
    (click)="onArrowClick('left')"
  ></button>

  <button
    class="ts-icon ts-icon-arrow-Expand_right"
    [ngClass]="{ disabled: !canGoNext }"
    [disabled]="!canGoNext"
    (click)="onArrowClick('right')"
  ></button>
</div>

<ng-template #large>
  <nav class="ts-pagination" size="lg" aria-label="Pagination">
    <button
      class="ts-btn btn-grey ts-btn-bezeled"
      [ngClass]="{ disabled: !canGoBack }"
      [disabled]="!canGoBack"
      (click)="onArrowClick('left')"
    >
      <span class="ts-icon ts-icon-arrow-Expand_left"></span>
      Назад
    </button>

    <ng-container *tsRepeatTimes="let elementIndex of elementsLength">
      <ng-container *tsLet="getItemIndexByElementIndex(elementIndex) as index">
        <button
          *ngIf="index !== null; else dotsTemplate"
          #element
          (click)="onElementClick(index)"
          class="ts-btn"
          [ngClass]="getElementMode(index)"
        >
          {{ index + 1 }}
        </button>
      </ng-container>
    </ng-container>

    <button
      class="ts-btn btn-grey ts-btn-bezeled"
      [ngClass]="{ disabled: !canGoNext }"
      [disabled]="!canGoNext"
      (click)="onArrowClick('right')"
    >
      Вперед
      <span class="ts-icon ts-icon-arrow-Expand_right"></span>
    </button>
  </nav>
</ng-template>

<ng-template #dotsTemplate>
  <div class="ts-dots"></div>
</ng-template>
