import { NgModule } from '@angular/core';

import { CurrencyFormatPipe } from './currency-format';
import { DecimalPipe } from '@angular/common';

@NgModule({
  imports: [],
  exports: [CurrencyFormatPipe],
  declarations: [CurrencyFormatPipe],
  providers: [DecimalPipe],
})
export class CurrencyFormatModule { }
