import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { loadWarehouseGroups } from '../pages/handbooks/storage-list/store/warehouse-groups.actions';

@Injectable()
export class WarehouseGroupsResolver {
  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    this.store.dispatch(loadWarehouseGroups());
  }
}
