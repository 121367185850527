import { createReducer, on } from "@ngrx/store";
import { WarehouseGroup } from "../../../../data/model/warehouseGroup";
import { setWarehouseGroups } from "./warehouse-groups.actions";

export interface WarehouseGroupState {
    isLoading: boolean;
    items: WarehouseGroup[];
}

export const initialState: WarehouseGroupState = {
    isLoading: false,
    items: [],
}

export const WAREHOUSE_GROUPS_FEATURE_KEY = 'warehouse-groups';

export const warehouseGroupsReducer = createReducer(
    initialState,
    on(setWarehouseGroups, (state, { items }) => ({
        ...state,
        items,
    })),
)
