import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { PeriodType } from "../types";
import { dateRangeToPeriodType, periodTypeToDateRange } from "../utils";
import { combineLatest, startWith } from "rxjs";
import { TsTime } from "../common";

@Component({
  selector: 'ts-input-date-range-selector[formGroup]',
  templateUrl: './input-date-range-selector.component.html',
  styleUrls: ['./input-date-range-selector.component.scss'],
})
export class InputDateRangeSelectorComponent implements OnInit {

  @Input() dateFromControlName = '';
  @Input() dateToControlName = '';
  @Input() dateFromInputLabel = '';
  @Input() dateToInputLabel = '';
  @Input() formGroup!: FormGroup;

  @Input() layout: 'vertical' | 'horizontal' = 'horizontal';

  endOfDay = new TsTime(23,59,59);

  public periodItems = [
    {label: "Вчера", type: PeriodType.yesterday},
    {label: "Сегодня", type: PeriodType.today},
    {label: "Неделя", type: PeriodType.week},
    {label: "Месяц", type: PeriodType.month},
  ]

  selectedPeriod: PeriodType = PeriodType.custom;

  ngOnInit() {
    this.selectPeriod(this.selectedPeriod);
    combineLatest(
      [
        this.formGroup.controls[this.dateFromControlName].valueChanges.pipe(startWith(this.formGroup.controls[this.dateFromControlName].value)),
        this.formGroup.controls[this.dateToControlName].valueChanges.pipe(startWith(this.formGroup.controls[this.dateToControlName].value))
      ])
      .subscribe(([value1, value2]) => {
        this.selectedPeriod = dateRangeToPeriodType(value1, value2);
      });
  }

  selectPeriod(type: PeriodType) {
    if (type === PeriodType.custom) {
      return;
    }
    this.selectedPeriod = type;
    const range = periodTypeToDateRange(type);
    // Установите значения startDate и endDate в вашей форме
    this.formGroup.get(this.dateFromControlName)?.setValue(range.dateFrom);
    this.formGroup.get(this.dateToControlName)?.setValue(range.dateTo);
  }

}
