import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavLink } from '@topseller/common';
import { NamesRoutesMarketplaces } from './data/models/rp-enums';
import { AccountsService } from './data/api/accounts.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subject, filter, take, takeUntil, map} from 'rxjs';
import {BillingAccountRestrictions, SecurityService, setCurrentProductInfo} from '@topseller/core';
import {Store} from "@ngrx/store";

@Component({
  selector: 'ts-repricer',
  templateUrl: './repricer.component.html',
  styleUrls: ['./repricer.component.scss']
})
export class RepricerComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  public links: NavLink[] = [
    {
      title: 'Товары',
      path: '/repricer/goods',
      iconName:'goods-box',
      sub: [
        { title: 'Список групп', path: '/repricer/goods/groups' },
        { title: 'Список товаров', path: '/repricer/goods/list' },
      ],
    },
    {
      title: 'История изменений',
      path: '/repricer/history',
      iconName:'document-note',
    },
    {
      title: 'Аккаунты маркетплейсов',
      path: '/repricer/accounts',
      iconName: 'charts-personalcard',
      sub: [
        { title: 'Все аккаунты', path: '/repricer/accounts/all' },
        {
          title: 'Wildberries',
          path: `/repricer/accounts/${NamesRoutesMarketplaces.wb}`,
        },
        { title: 'Ozon', path: `/repricer/accounts/${NamesRoutesMarketplaces.ozon}` },
        // { title: 'Яндекс Маркет', path: `/repricer/accounts/${NamesRoutesMarketplaces.yandexMarket}` },
        // { title: 'Сбер Маркет', path: `/repricer/accounts/${NamesRoutesMarketplaces.sberMarket}` },
        // { title: 'Aliexpress', path: `/repricer/accounts/${NamesRoutesMarketplaces.aliexpress}` },
      ],
    },
    {
      title: 'Подписка',
      path: './subscriptions',
      iconName:'money-wallet-2',
    },
  ];

  constructor(
    private accountsService: AccountsService,
    private securityService: SecurityService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store
  ) {}

  public ngOnInit(): void {
    this.activatedRoute.data
        .pipe(
            map(({ subscription }) => subscription),
            take(1)
        )
        .subscribe((subscription: BillingAccountRestrictions) => {
          this.store.dispatch(setCurrentProductInfo({currentProductInfo:{
              currentTariffName:subscription.tariffName,
              currentProduct:subscription.product,
              activeTillDate: subscription.expiresAt
            }}))
        });
    this.subscribeToRouter();

    this.redirect();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private subscribeToRouter() {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.redirect();
      });
  }

  private redirect() {
    if (this.router.url === '/repricer') {
      this.accountsService
        .getAccountsShort()
        .pipe(take(1))
        .subscribe((accounts) => {
          if (accounts.length > 0) {
            this.router.navigate(['repricer/goods']);
          } else {
            this.router.navigate(['repricer/accounts']);
          }
        });
    }
  }
}
