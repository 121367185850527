import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'tsProductType',
  standalone: true,
})

export class TsProductTypePipe implements PipeTransform {
  transform(type: string): string {
    switch (type.toLowerCase()) {
      case 'product':
        return 'Продукт'
      case 'set':
        return 'Комплект'
      case 'service':
        return 'Услуга'
      default:
        return 'Ошибка!'
    }

  }
}
