import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ts-form-controls-card',
  templateUrl: './form-controls-card.component.html',
  styleUrls: ['./form-controls-card.component.scss'],
  imports: [CommonModule],
  standalone: true,
})
export class FormControlsCardComponent implements OnInit {
  @Input() label = '';
  @Input() isExpandable = false;
  @Input() defaultExpanded = true;
  @Input() isEmpty = false;
  @Input() emptyIcon?: string; // for example: ts-icon-goods-box
  @Input() emptyText = '';

  @Input() isExpanded = true;

  ngOnInit() {
    this.isExpanded = this.defaultExpanded;
  }

  public expand(): void {
    this.isExpanded = true;
  }
}
