import { Component, Input, OnDestroy, OnInit, Self, } from '@angular/core';
import { HubEntity, Status } from '../../../data';
import { Observable, Subject, takeUntil } from 'rxjs';
import { selectStatus } from '../../../store';
import { Store } from '@ngrx/store';
import { StatusesEditComponent } from '../statuses-edit/statuses-edit.component';
import { SidebarService } from '@topseller/common/sidebar';
import { IdentityMatcher, StringHandler } from '../../types';
import { getColorByEnumValue } from '../../../data/model/colors';
import { Record } from "@topseller/core";
import { BaseStatusSelectorComponent } from "../base-status-selector.component";

@Component({
  selector: 'app-status-selector',
  templateUrl: './status-selector.component.html',
  styleUrls: ['./status-selector.component.scss'],
  providers: [SidebarService],
})
export class StatusSelectorComponent extends BaseStatusSelectorComponent{

}
