import { Record as TsRecord } from '../model';

export interface TableColumnSettings {
  sort: {
    order: string;
    target: string;
  };
  columns: ColumnConfig;
}

export interface ColumnSettings {
  settings: {
    sticky: boolean;
    order: number;
    width: number;
    hidden: boolean;
  };
  metadata: Metadata;
}

export interface ColumnConfig {
  [key: string]: ColumnSettings;
}

export enum FilterType {
  TEXT = 'text',
  SELECT = 'select',
  MULTISELECT = 'multiSelect',
  MULTICHECKBOX = 'multiCheckbox',
  CHECKBOX = 'checkbox',
  DATERANGEFROM = 'daterangeFrom',
  DATERANGETO = 'daterangeTo',
  DATE = 'date',
  DATERANGE = 'daterange',
}

export interface AppliedFilter {
  controlName: string;
  value: string | number | boolean | TsRecord | null;
  filterQueryParamName: string;
}

export interface TableFilter {
  type?: FilterType;
  target: string;
  source?: 'api' | 'basedata';
  name: string;
}

export interface Metadata {
  label: string;
  canBeSticked: boolean;
  canBeOrdered: boolean;
  canBeResized: boolean;
  canBeHidden: boolean;
  canBeSorted: boolean;
  textAlign: string;
  type?: string;
  minWidth?: number;
  filters?: TableFilter[];
}

export interface Column {
  key: string;
  order: number;
  width: string;
  label: string;
  canBeSorted: boolean;
  hidden?: boolean;
}

export const HubInterfaceTable: Record<string, string> = {
  project: 'hub_interface_table_handbooks_projects',
  inventory: 'hub_interface_table_goods_accounting_inventory',
  movement: 'hub_interface_table_goods_accounting_movements',
  posting: 'hub_interface_table_goods_accounting_postings',
  writeOff: 'hub_interface_table_goods_accounting_write_off',
  cashflow: 'hub_interface_table_handbooks_cashflow',
  company: 'hub_interface_table_handbooks_company',
  department: 'hub_interface_table_handbooks_department',
  employee: 'hub_interface_table_handbooks_employee',
  counterparty: 'hub_interface_table_handbooks_partners',
  source: 'hub_interface_table_handbooks_sources',
  warehouse: 'hub_interface_table_handbooks_storage',
  payment: 'hub_interface_table_payment',
  product: 'hub_interface_table_product',
  reportProduct: 'hub_interface_table_reports_leftovers_products',
  reportTurnover: 'hub_interface_table_reports_turnover',
  reportWarehouse: 'hub_interface_table_reports_leftovers_warehouse',
  reportProfit: 'hub_interface_table_reports_profitability',
  reportAbc: 'hub_interface_table_reports_abc',
  file: 'hub_interface_table_rest_files',
  trash: 'hub_interface_table_rest_trash',
  order: 'hub_interface_table_order',
  purchase: 'hub_interface_table_purchase',
  task: 'hub_interface_table_rest_tasks',
  salesProduct: 'hub_interface_table_order_products',
  purchaseProduct: 'hub_interface_table_purchase_products',
  demandProduct: 'hub_interface_table_demand_products',
  recallProduct: 'hub_interface_table_recall_products',
  receiveProduct: 'hub_interface_table_receive_products',
  revertProduct: 'hub_interface_table_revert_products',
  inventoryProduct: 'hub_interface_table_inventory_products',
  movementProduct: 'hub_interface_table_movement_products',
  postingProduct: 'hub_interface_table_posting_products',
  writeOffProduct: 'hub_interface_table_writeOff_products',
};
