<div
  class="dropdown"
  #parent="activeZone"
  [class.open]="isVisible"
  [class.disabled]="disabled"
  (activeZoneChange)="onActiveZone($event)"
  [dropdown]="dropdownTemplate"
  [show]="isVisible"
>
  <ts-base-input
    [value]="''"
    [label]="placeholder"
    [readonly]="true"
    [trailingIcon]="trailingIcon"
    [placeholder]="placeholder"
    [disabled]="disabled"
  >
    <ts-custom-content ngProjectAs='customContent' >
      <div class="d-flex gap-1 flex-grow-1">
        <ng-container *ngIf="computedValue.length; else text">
          <ng-container *ngIf="shortChipsDisplay && computedValue.length > 1">
            <ts-chip
              [value]="'Выбрано: ' + computedValue.length"
              [removable]="removable"
              (changeChip)="clear()"
            ></ts-chip>
          </ng-container>
          <ng-container *ngIf="!shortChipsDisplay || computedValue.length === 1">
            <ng-container *ngIf="computedValue.length">
              <ts-chip
                *ngFor="let cVal of computedValue"
                [value]="cVal"
                [stringify]="stringify"
                [removable]="removable"
                (changeChip)="handleOption($event)"
              ></ts-chip>
            </ng-container>
          </ng-container>

        </ng-container>

        <ng-template #text>
          <span class="ts-text-placeholder-color text-sm">Выберите</span>
        </ng-template>
      </div>
    </ts-custom-content>
  </ts-base-input>
</div>

<ng-template #dropdownTemplate>
  <div [activeZoneParent]="parent"><ng-content></ng-content></div>
</ng-template>
