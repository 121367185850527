import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectComponent } from './select.component';

import { ActiveZoneModule } from '../active-zone';
import { BaseInputModule } from '../base-input';
import { TsDropdownModule } from '../dropdown';
import { TsCustomContentComponent } from '../common';
import { TsSelectFocusDirective } from "./ts-select-focus.directive";

@NgModule({
  imports: [
    CommonModule,
    ActiveZoneModule,
    BaseInputModule,
    TsDropdownModule,
    TsCustomContentComponent,
  ],
  declarations: [SelectComponent, TsSelectFocusDirective],
  exports: [SelectComponent, TsSelectFocusDirective],
  providers: [],
})
export class TsSelectModule {}
