import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TsDropdownModule} from "@topseller/ui/dropdown";
import {BehaviorSubject, map, Observable, of, shareReplay} from "rxjs";
import {HubEntity, PrintFormType} from "../../../data";
import {DotLoaderComponent} from "@topseller/ui/dot-loader";
import {PrintFormsApiService} from "../../../data/api";
import {take, tap} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'ts-print-docs-selector',
  standalone: true,
  imports: [CommonModule, TsDropdownModule, DotLoaderComponent],
  templateUrl: './print-docs-selector.component.html',
  styleUrls: ['./print-docs-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintDocsSelectorComponent implements OnInit {

  @Input() entityId: string = '';
  @Input() entityName!: HubEntity;

  isLoading$ = new BehaviorSubject(true);
  docTypes$: Observable<PrintFormType[]> = of([])


  isExpanded: boolean = false;


  constructor(private printFormsApiService: PrintFormsApiService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.docTypes$ = this.printFormsApiService.getPrintForms(this.entityName).pipe(
      map(response => {
        return response.items;
      }),
      shareReplay(1),
      tap(_ => this.isLoading$.next(false))
    );
  }

  setIsExpanded(isExpanded: boolean) {
    this.isExpanded = isExpanded;
  }

  getDocument(printForm: PrintFormType) {
    this.toastr.info('Подготовка документа... По завершении будет открыт в новой вкладке');
    this.printFormsApiService.getDocument(this.entityName, this.entityId, printForm.identifier).pipe(take(1))
      .subscribe(
        res => window.open(res.url, '_blank')
      )
  }
}
