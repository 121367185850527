<ts-time-selector
  [value]="value?.hours || 0"
  title="Час"
  (valueChanges)="onHoursChanges($event)"
></ts-time-selector>

<ts-time-selector
  [value]="value?.minutes || 0"
  [size]="60"
  [skip]="10"
  title="Мин"
  (valueChanges)="onMinutesChanges($event)"
></ts-time-selector>

<ts-time-selector
  [value]="value?.seconds || 0"
  [size]="60"
  [skip]="10"
  title="Сек"
  (valueChanges)="onSecondsChanges($event)"
></ts-time-selector>
