import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarModule } from '@topseller/common/sidebar';
import { StatusSelectorComponent } from './status-selector.component';
import { TsSelectOptionModule } from '@topseller/ui/select-option';
import { ControlErrorModule } from '@topseller/common/control-error/control-error.module';
import { StatusesEditComponent } from '../statuses-edit';
import { TsScrollModule } from "@topseller/ui/scroll";

@NgModule({
  declarations: [StatusSelectorComponent],
    imports: [
        CommonModule,
        SidebarModule,
        TsSelectOptionModule,
        ControlErrorModule,
        StatusesEditComponent,
        TsScrollModule,
    ],
  providers: [],
  exports: [StatusSelectorComponent],
})
export class StatusSelectorModule {}
