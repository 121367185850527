import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { BaseData, BaseDataService } from './data';
import { setHubMetadata } from './store';
import { setCurrentProductInfo } from "@topseller/core";

@Injectable({
  providedIn: 'root',
})
export class HubMetadataResolver {
  constructor(
    private store: Store,
    private toastrService: ToastrService,
    private baseDataService: BaseDataService,
    private router: Router
  ) {
  }

  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.baseDataService.getAppBasedataIndex().pipe(
      catchError(() => {
        this.toastrService.error('Ошибка загрузки метаданных!');
        return EMPTY;
      }),
      tap((metadata: BaseData) => {
          this.store.dispatch(setHubMetadata({metadata}));
          this.store.dispatch(setCurrentProductInfo({
            currentProductInfo: {
              currentTariffName: metadata.userRestrictions?.tariffName,
              currentProduct: metadata.userRestrictions?.product,
              activeTillDate: metadata.userRestrictions?.expiresAt,
              product: metadata.userRestrictions?.product
            }
          }))
        }
      ),
      map(() => true)
    );
  }
}
