import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  Inject,
  Optional,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavChild, NavLink } from '../model/models';
import { APP_NAME, AuthService, hasRole } from '@topseller/core';
import { Observable, of } from "rxjs";

@Component({
  selector: 'ts-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() appLink = '/';
  @Input() appTitle = '';
  @Input() productName = '';
  @Input() public links: NavLink[] = [];

  public activeLink?: NavLink;
  isMobileMenuOpen = false;

  constructor(
    @Optional() @Inject(APP_NAME) public appName: string,
    private router: Router,
    private authService: AuthService,
  ) {
    router.events.subscribe((s) => {
      if (s instanceof NavigationEnd) {
        this.updateCurrentRoute();
      }
    });
  }

  ngOnInit() {
    this.updateCurrentRoute();
  }

  isCurrentRoute(link: NavChild): boolean {
    if (this.router.url.includes(link.path)) {
      return true;
    }

    if (!link.aliases) {
      return false;
    }

    return link.aliases.some((alias: string) => {
      return this.router.url.includes(alias);
    });
  }

  private updateCurrentRoute() {
    this.activeLink = this.links.find(({ path }) => {
      return this.router.isActive(path, {
        paths: 'subset',
        queryParams: 'subset',
        fragment: 'ignored',
        matrixParams: 'ignored',
      });
    });
  }

  hasAccess(link: NavLink):Observable<boolean> {
    if (!link.requiredRole){
      return of(false)
    }else{
      return this.authService.employeeHasRole(link.requiredRole);
    }
  }
}
