import { Route } from '@angular/router';
import { IdentityComponent } from './identity.component';
import { REPORTS_VIEW_ROLES, ROLES, RolesGuard, STAFF_VIEW_ROLES } from "@topseller/core";
import { TsMigrationsModule } from "./modules/migrations";

export const routedComponents = [IdentityComponent];

export const routes: Route[] = [
  {
    path: '',
    component: IdentityComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/topsellerid/profile',
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./modules/profile/profile.module').then(
            (m) => m.TsUserProfileModule
          ),
      },
      {
        path: 'subscription',
        loadChildren: () =>
          import('@topseller/subscription').then((m) => m.TsSubscriptionModule),
        data: {
          productId: 'hub',
        },
      },
      {
        path: 'accounts',
        loadChildren: () =>
          import('./account-marketplace/account-marketplace.module').then(
            (m) => m.TsAccountMarketplaceModule
          ),
        canActivate: [RolesGuard],
        data:{
          requiredRole: ROLES.ROLE_MARKETPLACE_ACCOUNT_VIEW,
          noAccessRedirect: '/topsellerid/noaccess'
        },
      },
      {
        path: 'staff',
        canActivate: [RolesGuard],
        data:{
          requiredRole: STAFF_VIEW_ROLES,
          noAccessRedirect: '/topsellerid/noaccess'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/topsellerid/staff/employee',
          },
          {
            path: 'employee',
            canActivate:[RolesGuard],
            data:{
              requiredRole: ROLES.ROLE_EMPLOYEE_VIEW,
              noAccessRedirect: '/topsellerid/staff/departments',
            },
            loadChildren: () =>
              import('./modules/employee/employee.module').then(
                (m) => m.EmployeeModule
              ),
          },
          {
            path: 'departments',
            canActivate:[RolesGuard],
            data:{
              requiredRole: ROLES.ROLE_DEPARTMENT_VIEW,
              noAccessRedirect: '/topsellerid/staff/employee',
            },
            loadChildren: () =>
              import('./modules/department/department.module').then(
                (m) => m.DepartmentModule
              ),
          },
        ],
      },
      {
        path:'migrations',
        loadChildren: () =>
          import('./modules/migrations/migrations.module').then(
            (m) => m.TsMigrationsModule
          ),
      },
      {
        path: 'noaccess',
        loadComponent: (() => import('./no-access-page/no-access-page.component').then((m) => m.NoAccessPageComponent))
      }
    ],
  },
];
