import { Component, ContentChildren, HostBinding, HostListener, Input, QueryList } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveZoneModule } from "../active-zone";
import { ViewModeDirective } from "./view-mode.directive";

@Component({
  selector: 'ts-view-edit-mode-switch',
  standalone: true,
  imports: [CommonModule, ActiveZoneModule],
  templateUrl: './view-edit-mode-switch.component.html',
  styleUrls: ['./view-edit-mode-switch.component.scss'],
})
export class ViewEditModeSwitchComponent {
  @HostBinding('class.disabled')
  @Input()
  public disable = false;

  @ContentChildren(ViewModeDirective) views!: QueryList<ViewModeDirective>

  mode: 'view' | 'edit' = "view";

  public onActiveZone(active: boolean) {
    if (!active) {
      this.mode = 'view';
    }
  }

  get viewModeTpl(): ViewModeDirective {
    return this.views.filter(x => x.tsViewMode === 'view')[0];
  }

  get editModeTpl(): ViewModeDirective {
    return this.views.filter(x => x.tsViewMode === 'edit')[0];
  }

  get currentView() {
    return this.mode === 'view' ? this.viewModeTpl?.tpl : this.editModeTpl?.tpl;
  }

  @HostListener('keydown.enter')
  setViewMode() {
    if (this.mode === 'edit') {
      this.mode = 'view';
    }
  }

  setEditMode() {
    if (this.mode === 'view' && !this.disable) {
      this.mode = 'edit';
    }
  }
}

