import { Observable } from 'rxjs';

export abstract class ItemDataService<T> {
  public abstract create(purchase: T): Observable<T>;

  public abstract read(id: string): Observable<T>;

  public abstract update(purchase: T): Observable<T>;

  public abstract delete(id: string): Observable<any>;
}
