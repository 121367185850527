import { isPlatformBrowser } from '@angular/common';
import {
  APP_INITIALIZER,
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  PLATFORM_ID,
} from '@angular/core';

export const LOCAL_URL = new InjectionToken<string>('Local URL');

const SUGGESTION_SCRIPT_URL =
  'https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-with-polyfills-latest.js';
const TOKEN_SCRIPT_URL =
  'https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-token-with-polyfills-latest.js';

// eslint-disable-next-line @typescript-eslint/ban-types
export function appSuggestionScriptFactory(platformId: Object) {
  if (isPlatformBrowser(platformId) && !(window as any).YaAuthSuggest) {
    return insertScript.bind(window, SUGGESTION_SCRIPT_URL);
  }

  return () => 'none';
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function appTokenScriptFactory(platformId: Object) {
  if (isPlatformBrowser(platformId) && !(window as any).YaSendSuggestToken) {
    return insertScript.bind(window, TOKEN_SCRIPT_URL);
  }

  return () => 'none';
}

function insertScript(scriptUrl: string) {
  const head = document.getElementsByTagName('head')[0];
  const suggestScript = document.createElement('script');

  suggestScript.type = 'text/javascript';
  suggestScript.src = scriptUrl;
  suggestScript.async = true;

  head.appendChild(suggestScript);
}

@NgModule()
export class YandexAuthModule {
  public static forRoot(
    localUrl: string
  ): ModuleWithProviders<YandexAuthModule> {
    return {
      ngModule: YandexAuthModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: appSuggestionScriptFactory,
          deps: [PLATFORM_ID],
          multi: true,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: appTokenScriptFactory,
          deps: [PLATFORM_ID],
          multi: true,
        },
        {
          provide: LOCAL_URL,
          useValue: localUrl,
        },
      ],
    };
  }
}
