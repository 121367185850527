<div class="ts-drawer_header" [ngClass]="{'icon-left': expandIconPosition === 'left'}">
  <div [ngStyle]="expandIconPosition === 'left' ? {'margin-right': 'auto'} : {'flex-grow': '1'}">
    <ng-content></ng-content>
  </div>
  <button type="button" [ngStyle]="expandIconPosition === 'right' ? {'margin-left': 'auto'} : {}"
          class="ts-btn btn-grey ts-btn-bezeled ts-btn-sm ts-btn-icon"
          (click)="onToggle()">
    <img *ngIf="expandIconType === 'round'" src="/assets/img/icons/round_chevron.svg" alt="Arrow"/>
    <i *ngIf="expandIconType === 'flat'" class="ts-icon ts-icon-arrow-Expand_up"></i>
  </button>
</div>

<div *ngIf="expanded" @contentAnimation class="ts-drawer_content">
  <ng-content select="[tsDrawerContent]"></ng-content>
</div>
