<div class="product-sku-cell">
  <div class="ts-text-tertiary">Артикул</div>
  <div class="ts-caption-strong text-truncate d-flex">
    {{product.sku}}
    <span *ngIf="canCopy" class="ts-icon ts-text-tertiary ts-icon-actions-Copy_alt cursor-pointer text-sm" (click)="copyToClipboard($event,product.sku)"></span>
  </div>
  <div class="ts-text-tertiary">Арт. Поставщика</div>
  <ng-container *ngIf="product.supplierSku; else empty">
    <div class="ts-caption-strong text-truncate">
      {{product.supplierSku}}
    <span *ngIf="canCopy" class="ts-icon ts-text-tertiary ts-icon-actions-Copy_alt text-sm" (click)="copyToClipboard($event,product.supplierSku)"></span>
    </div>
  </ng-container>

  <ng-template #empty> - </ng-template>
</div>
