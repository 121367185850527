<div class="d-flex flex-column h-100">
  <!-- *NAVBAR* starts -->
  <ts-navbar
    appTitle="Hub"
    productName="hub"
    appLink="/hub"
    [links]="links"
  ></ts-navbar>
  <!-- *NAVBAR* ends -->

  <div class="ts-page">
    <router-outlet></router-outlet>
  </div>
</div>
