import {Directive, Input, HostListener, ElementRef} from '@angular/core'

@Directive({
  selector: '[tsResize]',
  standalone: true,
})
export class TsResizeDirective {
  @Input() leftResize!: HTMLElement;
  @Input() rightResize!: HTMLElement;
  @Input() minWidth: number = 100;

  grabber: boolean = false;
  width: number = 0;

  constructor(private el: ElementRef<HTMLElement>) {
  }

  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.width = event.target.outerWidth;
  }

  @HostListener('mousedown') onMouseDown() {
    this.grabber = true;
    this.el.nativeElement.classList.add('side-panel');
    document.body.style.cursor = 'e-resize';
  }

  @HostListener('window:mouseup') onMouseUp() {
    this.grabber = false;
    this.el.nativeElement.classList.remove('side-panel');
    document.body.style.cursor = 'default';
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.grabber) {
      event.preventDefault();

      let newWidth = event.clientX - this.leftResize.getBoundingClientRect().left;

      // Проверка на минимальную ширину
      if (newWidth < this.minWidth) {
        newWidth = this.minWidth;
      }

      this.leftResize.style.width = `${newWidth}px`;
      this.rightResize.style.width = `calc(100% - ${newWidth}px)`;
    }
  }

  public reset() {
    this.leftResize.style.width = '';
    this.rightResize.style.width = '';
  }
}
