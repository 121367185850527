import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostBinding,
  HostListener,
  Input,
  Optional,
  Self,
  ViewChild,
  forwardRef, Output, EventEmitter,
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { TsAbstractControl } from '@topseller/cdk/abstract';
import { TextMaskConfig } from '@topseller/mask';

import { TsDropdownListDirective } from '../dropdown/dropdown-list.directive';
import { DATA_LIST_HOST } from '../tokens';
import { DataListHost, StringHandler } from '../types';
import { BaseInputComponent } from '../base-input';
import { defaultStringHandler } from '../utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-title',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [
    {
      provide: DATA_LIST_HOST,
      useExisting: forwardRef(() => TsInputTextComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsInputTextComponent
  extends TsAbstractControl<string>
  implements DataListHost<string>
{
  public open = false;

  @ViewChild(BaseInputComponent, { static: true })
  public baseInput?: BaseInputComponent;

  @ContentChild(TsDropdownListDirective, { static: false })
  public dropdownList?: TsDropdownListDirective;

  @Input()
  public title = '';

  @Input() public tsMask: TextMaskConfig | null = null;

  @Input() multirow = false;

  @Input()
  public stringify: StringHandler<any> = defaultStringHandler;

  @Input() public placeholder = '';

  @HostBinding('class.disabled')
  @Input()
  public readonly = false;

  @Input() public copier = false;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('maskTemplate') public mask = '';

  @Input() public hasCleaner = false;

  @Input()
  labelInside = false;

  @Input() leadingIcon = '';
  @Input() trailingIcon = '';

  @Output()
  readonly trailingIconClick = new EventEmitter<void>();

  @Input() trailingIconClickable = false;


  @Output()
  readonly valueChange = new EventEmitter<any>();

  constructor(
    @Self() @Optional() ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(ngControl, changeDetectorRef);
  }

  @HostListener('focusin', ['true'])
  @HostListener('focusout', ['false'])
  public onFocused(focused: boolean): void {
    this.updateFocused(focused);
  }

  public valueChanges(value: string): void {
    this.updateOpen(true);
    this.updateValue(value);
  }

  protected getFallbackValue(): string {
    return '';
  }

  public handleOption(option: string): void {
    this.baseInput?.inputElement?.nativeElement.focus();
    this.updateValue(this.stringify(option));
    this.updateOpen(false);
  }

  public onActiveZone(zone: boolean): void {
    if (!zone) {
      this.open = false;
    }
  }

  public updateOpen(open: boolean): void {
    this.open = open;
  }
}
