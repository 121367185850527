import { DepartmentService } from './department.service';
import { EmployeeService } from './employee.service';
import { SecurityService } from './security.service';
import { MarketplaceAccountService } from './marketplaceAccount.service';
import { MarketplaceService } from './marketplace.service';
import { MigratorApiService } from "./migrator.api.service";

export { DepartmentService } from './department.service';
export { EmployeeService } from './employee.service';
export { SecurityService } from './security.service';
export { MarketplaceAccountService } from './marketplaceAccount.service';
export { MarketplaceService } from './marketplace.service';

export const APIS = [
  SecurityService,
  DepartmentService,
  EmployeeService,
  MarketplaceAccountService,
  MarketplaceService,
  MigratorApiService
];
