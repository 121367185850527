/**
 * TOPSELLER App
 * Topseller backend API
 *
 * OpenAPI spec version: 22.1202.1329
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse, } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import {
  BatchDelete,
  Comment,
  Image,
  Log,
  PaginatedTotal,
  Payment,
  Purchase,
  PurchaseTotal,
  Revert,
} from '../model/models';

import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';
import { Receive } from '../model/receive';
import { TemplateRequest } from "../model/templateRequest";

@Injectable()
export class PurchaseService {
  protected basePath = 'http://denis.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * Удалить заказ поставщику
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteAppPurchaseDelete(
    id: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteAppPurchaseDelete.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/api/v1/purchases/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Принять весь товар на склад
   *
   * @param purchaseId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppPurchaseAcceptall(
    purchaseId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Purchase> {
    if (purchaseId === null || purchaseId === undefined) {
      throw new Error(
        'Required parameter purchaseId was null or undefined when calling getAppPurchaseAcceptall.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Purchase>(
      'get',
      `${this.basePath}/api/v1/purchases/${encodeURIComponent(
        String(purchaseId)
      )}/accept/all`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Все изображения заказа
   *
   * @param purchaseId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppPurchaseImageall(
    purchaseId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<Image>> {
    if (purchaseId === null || purchaseId === undefined) {
      throw new Error(
        'Required parameter purchaseId was null or undefined when calling getAppPurchaseImageall.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Image>>(
      'get',
      `${this.basePath}/api/v1/purchases/${encodeURIComponent(
        String(purchaseId)
      )}/images`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Основное изображение заказа поставщику
   *
   * @param purchaseId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppPurchaseImagedefault(
    purchaseId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Image>;
  public getAppPurchaseImagedefault(
    purchaseId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Image>>;
  public getAppPurchaseImagedefault(
    purchaseId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Image>>;
  public getAppPurchaseImagedefault(
    purchaseId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (purchaseId === null || purchaseId === undefined) {
      throw new Error(
        'Required parameter purchaseId was null or undefined when calling getAppPurchaseImagedefault.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Image>(
      'get',
      `${this.basePath}/api/v1/purchases/${encodeURIComponent(
        String(purchaseId)
      )}/image`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Список заказов поставщику
   *
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param filter Коллекция фильтров
   * @param search Строка поиска
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppPurchaseIndex(
    limit?: number,
    offset?: number,
    filter?: string,
    search?: string,
    sortName?: string,
    sortDir?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<PaginatedTotal<Purchase, PurchaseTotal>> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }

    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }
    if (sortName !== undefined && sortDir !== null) {
      queryParameters = queryParameters.set(`sort[${sortName}]`, <any>sortDir)
    }
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<PaginatedTotal<Purchase, PurchaseTotal>>(
      'get',
      `${this.basePath}/api/v1/purchases?${filter ?? ''}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Список записей в логах
   *
   * @param purchaseId
   * @param filterType Фильтр: /?filter[type]&#x3D;state
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppPurchaseLogindex(
    purchaseId: string,
    filterType?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<Log>>;
  public getAppPurchaseLogindex(
    purchaseId: string,
    filterType?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Log>>>;
  public getAppPurchaseLogindex(
    purchaseId: string,
    filterType?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Log>>>;
  public getAppPurchaseLogindex(
    purchaseId: string,
    filterType?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (purchaseId === null || purchaseId === undefined) {
      throw new Error(
        'Required parameter purchaseId was null or undefined when calling getAppPurchaseLogindex.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (filterType !== undefined && filterType !== null) {
      queryParameters = queryParameters.set('filter[type]', <any>filterType);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Log>>(
      'get',
      `${this.basePath}/api/v1/purchases/${encodeURIComponent(
        String(purchaseId)
      )}/log`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Сгенерировать наименование заказа
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppPurchaseName(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<string>;
  public getAppPurchaseName(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public getAppPurchaseName(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public getAppPurchaseName(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<string>(
      'get',
      `${this.basePath}/api/v1/purchases/name`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Сгенерировать номер заказа поставщику
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppPurchaseNumber(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<string>;
  public getAppPurchaseNumber(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public getAppPurchaseNumber(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public getAppPurchaseNumber(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<string>(
      'get',
      `${this.basePath}/api/v1/purchases/number`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Оплатить заказ полностью
   *
   * @param purchaseId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public payAll(
    purchaseId: string,
    body?: Payment,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Purchase> {
    if (purchaseId === null || purchaseId === undefined) {
      throw new Error(
        'Required parameter purchaseId was null or undefined when calling postAppPurchasePayall.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Purchase>(
      'post',
      `${this.basePath}/api/v1/purchases/${encodeURIComponent(
        String(purchaseId)
      )}/pay/all`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Заказ поставщику
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppPurchaseView(
    id: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Purchase>;
  public getAppPurchaseView(
    id: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Purchase>>;
  public getAppPurchaseView(
    id: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Purchase>>;
  public getAppPurchaseView(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getAppPurchaseView.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Purchase>(
      'get',
      `${this.basePath}/api/v1/purchases/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Изменить комментарий к заказу поставщику
   *
   * @param purchaseId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchAppPurchaseCommentupdate(
    purchaseId: string,
    body?: Comment,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Log>;
  public patchAppPurchaseCommentupdate(
    purchaseId: string,
    body?: Comment,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Log>>;
  public patchAppPurchaseCommentupdate(
    purchaseId: string,
    body?: Comment,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Log>>;
  public patchAppPurchaseCommentupdate(
    purchaseId: string,
    body?: Comment,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (purchaseId === null || purchaseId === undefined) {
      throw new Error(
        'Required parameter purchaseId was null or undefined when calling patchAppPurchaseCommentupdate.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Log>(
      'patch',
      `${this.basePath}/api/v1/purchases/${encodeURIComponent(
        String(purchaseId)
      )}/comment`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Заменить товар в заказе
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchAppPurchaseReplaceproduct(
    body?: Purchase,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Purchase>;
  public patchAppPurchaseReplaceproduct(
    body?: Purchase,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Purchase>>;
  public patchAppPurchaseReplaceproduct(
    body?: Purchase,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Purchase>>;
  public patchAppPurchaseReplaceproduct(
    body?: Purchase,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Purchase>(
      'patch',
      `${this.basePath}/api/v1/purchases/replace`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Отредактировать заказ поставщику
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchAppPurchaseUpdate(
    body?: Purchase,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'patch',
      `${this.basePath}/api/v1/purchases`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавить комментарий к заказу поставщику
   *
   * @param purchaseId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppPurchaseCommentcreate(
    purchaseId: string,
    body?: Comment,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Log>;
  public postAppPurchaseCommentcreate(
    purchaseId: string,
    body?: Comment,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Log>>;
  public postAppPurchaseCommentcreate(
    purchaseId: string,
    body?: Comment,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Log>>;
  public postAppPurchaseCommentcreate(
    purchaseId: string,
    body?: Comment,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (purchaseId === null || purchaseId === undefined) {
      throw new Error(
        'Required parameter purchaseId was null or undefined when calling postAppPurchaseCommentcreate.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Log>(
      'post',
      `${this.basePath}/api/v1/purchases/${encodeURIComponent(
        String(purchaseId)
      )}/comment`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавить заказ поставщика
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppPurchaseCreate(
    body?: Purchase,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Purchase>;
  public postAppPurchaseCreate(
    body?: Purchase,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Purchase>>;
  public postAppPurchaseCreate(
    body?: Purchase,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Purchase>>;
  public postAppPurchaseCreate(
    body?: Purchase,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Purchase>(
      'post',
      `${this.basePath}/api/v1/purchases`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Принять товар на склад
   *
   * @param purchaseId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppPurchaseCredit(
    purchaseId: string,
    body?: Purchase,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Purchase> {
    if (purchaseId === null || purchaseId === undefined) {
      throw new Error(
        'Required parameter purchaseId was null or undefined when calling postAppPurchaseCredit.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Purchase>(
      'post',
      `${this.basePath}/api/v1/purchases/${encodeURIComponent(
        String(purchaseId)
      )}/credit`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Принять товар.
   * Принять товар от поставщика на склад. Если товары не указаны (содержимое запроса пустое), то принимаем все (оставшиеся не принятыми) товары.
   * @param purchaseId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addIncomingTransfer(
    purchaseId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Receive> {
    if (purchaseId === null || purchaseId === undefined) {
      throw new Error(
        'Required parameter purchaseId was null or undefined when calling postAppPurchaseReceive.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<Receive>(
      'post',
      `${this.basePath}/api/v1/purchases/${encodeURIComponent(
        String(purchaseId)
      )}/receive`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Вернуть товар.
   * Возврат товара поставщику. Если товары не указаны (содержимое запроса пустое), то возвращаем все принятые по этой закупке товары.
   * @param purchaseId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addOutcomingTransfer(
    purchaseId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Revert> {
    if (purchaseId === null || purchaseId === undefined) {
      throw new Error(
        'Required parameter purchaseId was null or undefined when calling postAppPurchaseRevert.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<Revert>(
      'post',
      `${this.basePath}/api/v1/purchases/${encodeURIComponent(
        String(purchaseId)
      )}/revert`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  public returnAll(
    purchaseId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Purchase> {
    if (purchaseId === null || purchaseId === undefined) {
      throw new Error(
        'Required parameter purchaseId was null or undefined when calling getAppPurchaseReturnedall.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Purchase>(
      'get',
      `${this.basePath}/api/v1/purchases/${encodeURIComponent(
        String(purchaseId)
      )}/return/all`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавляет исходящий платеж
   */
  public addPayment(
    purchaseId: string,
    body?: Payment,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Payment> {
    if (purchaseId === null || purchaseId === undefined) {
      throw new Error(
        'Required parameter purchaseId was null or undefined when calling postAppPurchasePayment.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Payment>(
      'post',
      `${this.basePath}/api/v1/purchases/${encodeURIComponent(
        String(purchaseId)
      )}/payment`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Частичный возврат оплаты за заказ поставщику
   */
  public returnPayment(
    purchaseId: string,
    body?: Payment,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Payment> {
    if (purchaseId === null || purchaseId === undefined) {
      throw new Error(
        'Required parameter purchaseId was null or undefined when calling postAppPurchaseRefund.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Payment>(
      'post',
      `${this.basePath}/api/v1/purchases/${encodeURIComponent(
        String(purchaseId)
      )}/refund`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Вернуть оплату за заказ полностью
   *
   * @param purchaseId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public returnPayAll(
    purchaseId: string,
    body?: Payment,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Purchase> {
    if (purchaseId === null || purchaseId === undefined) {
      throw new Error(
        'Required parameter purchaseId was null or undefined when calling postAppPurchaseReturnpayall.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Purchase>(
      'post',
      `${this.basePath}/api/v1/purchases/${encodeURIComponent(
        String(purchaseId)
      )}/return/pay/all`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  public getUntrash(id: string) {
    return this.httpClient.get<Purchase>(
      `${this.basePath}/api/v1/trash/entity/${id}/restore`
    );
  }

  /**
   * Удалить несколько документов закупок
   */
  public batchDeleteInvoice(ids: { ids: string[] }): Observable<BatchDelete> {
    return this.httpClient.post(
      `${this.basePath}/api/v1/purchases/batch-delete`,
      ids
    );
  }

  public template(template: TemplateRequest) {
    return this.httpClient.patch(`${this.basePath}/api/v1/purchases/template`, template);
  }
}

