<ng-container *ngIf="mode==='view'">
  <div class="d-flex flex-column">
    <label class="ts-caption ts-text-tertiary mb-1">{{label}}</label>
    <div class="d-flex ps-3 gap-2">
      <a [href]="value" target="_blank" class="text-truncate">
        <span class="ts-text-link-color">{{value}}</span>
      </a>
      <i class="ts-icon ts-icon-actions-Edit text-2lg ts-text-icon-neutral-color cursor-pointer"
         (click)="editValue()"></i>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="mode==='edit'">
  <ts-base-input [label]="label"
                 [readonly]="readonly"
                 [disabled]="disabled"
                 [placeholder]="placeholder"
                 [labelInside]="labelInside"
                 [value]="value??''"
                 (valueChange)="changeValue($event)"
                 [trailingIconClickable]="canSave"
                 [trailingIcon]="canSave?'ts-icon-actions-Check_ring' : ''"
                 (trailingIconClick)="saveValue()"
                 (focusChanged)="handleFocusChange($event)"/>
</ng-container>
