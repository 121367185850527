import { ChangeDetectorRef, Component, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, exhaustMap, Subscription, take } from 'rxjs';
import { BaseInnItemComponent } from '../../../common/components/base-inn-item.component';

import { IdentityMatcher, StringHandler } from '../../../common/types';
import { ContactPerson, OrganizationService, Vat } from '../../../data';
import {
  ConfirmDialogComponent,
  DialogService,
  Organization,
  Record,
  ROLES,
  setShowLoader,
  SettlementAccount
} from '@topseller/core';
import { LegalFormsEnum } from '@topseller/common/legal-form';
import { ContactPersonBaseService } from '../../../common/services/contactPerson.service';
import { SettlementBaseService } from '../../../common/services/settlement.service';
import { LegalFormPipe } from '@topseller/common/pipe/legal-form.pipe';
import { Store } from '@ngrx/store';
import { selectVat } from '../../../store';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  providers: [
    SettlementBaseService,
    LegalFormPipe,
    ContactPersonBaseService,
    {provide: BaseInnItemComponent, useExisting: CompanyComponent}
  ],
})
export class CompanyComponent extends BaseInnItemComponent implements OnInit {
  public vatList?: Vat[];

  form: FormGroup = new FormGroup({
    id: new FormControl<string | undefined>(undefined),
    isDefault: new FormControl<boolean | null>(true),
    isVatPayer: new FormControl<boolean | null>(true),
    email: new FormControl<string>('', [Validators.email]),
    fullName: new FormControl<string>('', [Validators.maxLength(255)]),
    inn: new FormControl<string>('', [
      Validators.minLength(10),
      Validators.maxLength(12),
    ]),
    kpp: new FormControl<string>('', [
      Validators.minLength(9),
      Validators.maxLength(9),
    ]),
    legalAddress: new FormControl<string>('', [Validators.maxLength(255)]),
    groups: new FormControl<string>(''),
    vatType: new FormControl<Vat | null>(null),
    legalForm: new FormControl(this.legalFormObject[0]),
    actualAddress: new FormControl(''),
    name: new FormControl<string>('', [
      Validators.maxLength(255),
      Validators.required,
    ]),
    ogrn: new FormControl<string>(''),
    okpo: new FormControl<string>(''),
    phone: new FormControl<string>(''),
    settlementAccounts: this.settlementService.settlementAccounts,
    contactPersons: this.contactService.contactPersons,
    head: new FormControl<string>(''),
    signature: new FormControl<string>(''),
    stamp: new FormControl<string>(''),
    bookkeeper: new FormControl<string>(''),
    bookkeeperSignature: new FormControl<string>(''),
    logo: new FormControl<string>(''),
    warningText: new FormControl<string>('', [Validators.maxLength(512)]),
  });

  showHeaderBtns = false;
  showHeaderBtnsSub$?: Subscription;
  override backLinkUrl = '/hub/handbooks/company';
  public editItemRole = ROLES.ROLE_ORGANIZATION_EDIT;
  public deleteItemRole = ROLES.ROLE_ORGANIZATION_DELETE;
  private formChanges$?: Subscription;

  constructor(
    private legalFormPipe: LegalFormPipe,
    changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    router: Router,
    toastrService: ToastrService,
    matDialog: MatDialog,
    store: Store,
    public readonly settlementService: SettlementBaseService,
    public readonly contactService: ContactPersonBaseService,
    @Optional() private dialogRef: MatDialogRef<CompanyComponent>,
    protected override organizationService: OrganizationService,
    private dialogService: DialogService
  ) {
    super(
      activatedRoute,
      toastrService,
      router,
      changeDetectorRef,
      matDialog,
      store,
      organizationService,
    );
    this.store
      .select(selectVat)
      .pipe(take(1))
      .subscribe((vat: Vat[]) => {
        this.vatList = vat;
      });
  }

  get email() {
    return this.form.get('email');
  }

  get inn() {
    return this.form.get('inn');
  }

  get phone() {
    return this.form.get('phone');
  }

  get name() {
    return this.form.get('name');
  }

  get okpo() {
    return this.form.get('okpo');
  }

  get kpp() {
    return this.form.get('kpp');
  }

  get ogrn() {
    return this.form.get('ogrn');
  }

  get isVatPayer() {
    return this.form.get('isVatPayer')?.value;
  }

  public get isModal(): boolean {
    return this.dialogRef?.componentInstance instanceof CompanyComponent;
  }

  get showKpp(){
    return this.form.get('legalForm')?.value === LegalFormsEnum.COMPANY;
  }

  public legalFormStringify = (legalForm: LegalFormsEnum) =>
    this.legalFormPipe.transform(legalForm);

  public hasError(fieldName: string) {
    const formField = this.form.get(fieldName);
    return formField && formField.touched && formField.invalid;
  }

  ngOnInit(): void {
    this.getResolvedData(
      this.form,
      'company',
      this.accountsHandler,
      this.contactHandler
    );
    this.showHeaderBtnsSub$ = this.showHeaderBtns$.subscribe(
      (show) => (this.showHeaderBtns = show)
    );

    this.handleLegalFormChange();
  }

  public save(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.toastr.error('Форма содержит ошибки');
      return;
    }
    this.store.dispatch(setShowLoader({showLoader: true}))
    const organization = this.form.value;
    this.removeEmptyBanks(organization);
    if (organization.id === null || organization.id === undefined) {
      this.createOrganization(organization);
    } else {
      this.updateOrganization(organization);
    }
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  removeEmptyBanks(organization: Organization) {
    organization.settlementAccounts?.forEach(account => {
      if (!account.bank?.id) {
        account.bank = null;
      }
    })
  }

  public deleteOrganization(): void {
    const {id} = this.form.value;
    const data = {
      title: 'Удаление организации',
      content: 'Вы уверены, что хотите удалить организацию?',
      okBtn: 'Удалить'
    };
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialogService.openConfirmDialog(data);

    dialogRef
      .afterClosed()
      .pipe(
        exhaustMap((result: boolean) => {
          if (!result) {
            return EMPTY;
          }
          return this.organizationService.deleteAppOrganizationDelete(id);
        })
      )
      .subscribe({
        next: (result: Organization) => {
          this.toastr.success('Организация удалена');
          this.form.markAsPristine();
          this.router.navigate(['../'], {relativeTo: this.activatedRoute});
        },
        error: (err: any) => {
          this.toastr.error(
            err?.errors?.length && err.errors[0].message
              ? err.errors[0].message
              : err?.message || 'Что-то пошло не так'
          );
        },
      });
  }

  ngOnDestroy(): void {
    this.showHeaderBtnsSub$?.unsubscribe();
    this.formChanges$?.unsubscribe();
  }

  public recordIdentityMatcher: IdentityMatcher<Record> = (
    {id: previousId},
    {id: nextId}
  ) => previousId === nextId;

  public recordStringify: StringHandler<Record> = (item) => item.name;

  // Organization CRUD Operations
  private createOrganization(organization: Organization): void {
    this.organizationService
      .postAppOrganizationCreate(organization)
      .pipe(take(1))
      .subscribe({
        next: (result: Organization) => {
          this.toastr.success('Организация успешно создана');
          this.store.dispatch(setShowLoader({showLoader: false}));
          if (this.isModal) {
            this.dialogRef.close(result);
          } else {
            this.form.markAsPristine();
            this.router.navigateByUrl(`hub/handbooks/company/${result.id}`);
          }
        },
        error: (err: any) => {
          this.creationState$.next('error');
          this.store.dispatch(setShowLoader({showLoader: false}))

          this.toastr.error(
            err?.errors?.length && err.errors[0].message
              ? err.errors[0].message
              : err?.message || 'Что-то пошло не так'
          );
        },
      });
  }

  private updateOrganization(organization: Organization): void {
    this.organizationService
      .patchAppOrganizationUpdate(organization)
      .pipe(take(1))
      .subscribe({
        next: (result: Organization) => {
          this.toastr.success('Организация успешно сохранена');
          this.store.dispatch(setShowLoader({showLoader: false}))
          this.form.markAsPristine();
        },
        error: (err: any) => {
          this.creationState$.next('error');
          this.store.dispatch(setShowLoader({showLoader: false}))

          this.toastr.error(
            err?.errors?.length && err.errors[0].message
              ? err.errors[0].message
              : err?.message || 'Что-то пошло не так'
          );
        },
      });
  }

  private accountsHandler = (settlementAccounts: SettlementAccount[]) =>
    this.settlementService.setSettlementAccounts(settlementAccounts);

  private contactHandler = (contactPersons: ContactPerson[]) =>
    this.contactService.setContactPersons(contactPersons);

  private handleLegalFormChange(){
    this.form.get('legalForm')?.valueChanges.subscribe((legalForm: LegalFormsEnum) => {
      if (legalForm !== LegalFormsEnum.COMPANY) {
        this.form.get('kpp')?.reset();
      }
    });
  }
}
