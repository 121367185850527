<div class="nav-tree">
  <ts-scroll>
    <div class="d-flex justify-content-between align-items-center">
      <button (click)="refresh()" *ngIf="canRefresh" class="btn nav-tree__refresh-button">Сбросить</button>
    </div>
    <div class="navigation-root" [ngClass]="{'selected':!selectedItemId}">
      <div (click)="refresh()" class="nav-tree-root" *ngIf="navigationRootTitle">{{navigationRootTitle}}</div>
      <ts-role-access-wrapper [requiredRole]="editProductsRole" *ngIf="!isModal">
        <button class="ts-btn btn-grey ts-btn-bezeled ts-btn-sm ts-btn-icon" style="border-radius: 4px"
                (click)="createTreeItem.emit()" *ngIf="canAddEdit">
          <span class="ts-icon ts-icon-actions-Add"></span>Группа
        </button>
      </ts-role-access-wrapper>
    </div>
    <div class="nav-tree__menu">
      <app-nav-tree-item
        [selectedItemId]="selectedItemId"
        [index]="0"
        [menu]="items"
        [canEdit]="canAddEdit"
        [isModal]="isModal"
        (position)="settingBlockPosition = $event"
        (selectItem)="selectItem.emit($event)"
        (deleteTreeItem)="deleteTreeItem.emit($event)"
        (editTreeItem)="editTreeItem.emit($event)"
        (createTreeItem)="createTreeItem.emit($event)"
      />
    </div>
  </ts-scroll>
</div>
