import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from "@angular/material/dialog";

@Component({
  selector: 'ts-modal-dialog-header',
  standalone: true,
  imports: [CommonModule, MatDialogModule],
  templateUrl: './modal-dialog-header.component.html',
  styleUrls: ['./modal-dialog-header.component.scss'],
})
export class ModalDialogHeaderComponent {
  @Input() title!:string;
}
