<div
  *ngFor="let item of items"
  [class.current]="isCurrent(item)"
  [currentYear]="isCurrent(item)"
  [class.today]="itemIsToday(item)"
  class="item"
  (click)="onItemClick(item)"
>
  {{ item }}
</div>
