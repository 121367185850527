import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ts-confirm-delete-modal',
  templateUrl: 'confirm-delete-modal.component.html',
  styleUrls: ['confirm-delete-modal.component.scss']
})

export class ConfirmDeleteModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {question: string},
  ) { }




  close(): void {
    this.dialogRef.close({delete: false});
  }

  delete(): void {
    this.dialogRef.close({delete: true});
  }
}