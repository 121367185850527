<div class="attached-file-container">
  <i [class]="'ts-icon ts-icon-'+fileTypeIcon()" class="flex-grow-0 ts-text-tertiary text-xl"></i>
  <div class="ts-caption-strong">
    <div class="d-flex">
      <div class="fileName ts-icon-">{{fileNameWithoutExtension()}}</div>
      <span>.{{fileExtension()}}</span></div>
    <div class="mt-1">
      {{file.size | bytesToMegabytes}}
    </div>
  </div>
  <i *ngIf="state==='new'" type="button" (click)="onDelete()"
     class="ts-icon ts-icon-actions-Trash text-xl ts-text-control-primary"></i>
  <a type="button" [href]="file?.url" target="_blank" *ngIf="state === 'saved'"
     class="ts-icon ts-icon-arrow-Import text-xl ts-text-control-primary"></a>
</div>
