import { Pipe, PipeTransform } from '@angular/core';

import { legalForms, LegalFormsEnum } from '../legal-form';

@Pipe({ name: 'legalForm' })
export class LegalFormPipe implements PipeTransform {
  transform(legalForm: LegalFormsEnum): string {
    return legalForms[legalForm];
  }
}
