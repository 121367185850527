/**
 * TOPSELLER App
 * Topseller backend API
 *
 * OpenAPI spec version: 23.0118.1617
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { BatchDelete, Paginated, Payment, WriteOff } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { DocumentUntrashService } from "../../common/services";
import { TemplateRequest } from "../model/templateRequest";

@Injectable()
export class WriteOffService implements DocumentUntrashService<WriteOff> {
  protected basePath = 'http://denis.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Удалить списание товаров
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteAppWriteoffDelete(
    id: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteAppWriteoffDelete(
    id: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteAppWriteoffDelete(
    id: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteAppWriteoffDelete(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteAppWriteoffDelete.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/api/v1/write-offs/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Отправить списание в корзину
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteAppWriteoffTrash(
    id: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<WriteOff>;
  public deleteAppWriteoffTrash(
    id: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<WriteOff>>;
  public deleteAppWriteoffTrash(
    id: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<WriteOff>>;
  public deleteAppWriteoffTrash(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteAppWriteoffTrash.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<WriteOff>(
      'delete',
      `${this.basePath}/api/v1/write-offs/trash/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Список всех списаний товаров
   *
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param filter Коллекция фильтров:                     &lt;p&gt;                     &lt;br/&gt;&lt;code&gt;?filter[id]&#x3D;cf5fbf8e-297c-4012-9d0d-4fc03faa7e44&lt;/code&gt;                     &lt;br/&gt;&lt;code&gt;?filter[number]&#x3D;Example write-off number&lt;/code&gt;                     &lt;br/&gt;&lt;code&gt;?filter[createdBy][0]&#x3D;203e9a42-fffa-423e-965c-acfd8ede78af&amp;filter[createdBy][1]&#x3D;dd6bba03-75d0-4495-ad15-1754e89e9628&lt;/code&gt;                     &lt;br/&gt;&lt;code&gt;?filter[writeOffProduct][product][id]&#x3D;e7e777ad-7602-4532-ba99-a4bd414ed93b&lt;/code&gt;                      &lt;br/&gt;&lt;code&gt;?filter[writeOffProduct][product][productGroup][id]&#x3D;e7e777ad-7602-4532-ba99-a4bd414ed93b&lt;/code&gt;                      &lt;/p&gt;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppWriteoffIndex(
    limit?: number,
    offset?: number,
    filter?: string,
    search?: string,
    sortName?: string,
    sortDir?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<WriteOff>>;
  public getAppWriteoffIndex(
    limit?: number,
    offset?: number,
    filter?: string,
    search?: string,
    sortName?: string,
    sortDir?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Paginated<WriteOff>>>;
  public getAppWriteoffIndex(
    limit?: number,
    offset?: number,
    filter?: string,
    search?: string,
    sortName?: string,
    sortDir?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Paginated<WriteOff>>>;
  public getAppWriteoffIndex(
    limit?: number,
    offset?: number,
    filter?: string,
    search?: string,
    sortName?: string,
    sortDir?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }
    if(sortName !== undefined && sortDir !== null) {
      queryParameters = queryParameters.set(`sort[${sortName}]`, <any>sortDir)
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<WriteOff>>(
      'get',
      `${this.basePath}/api/v1/write-offs?${filter ?? ''}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Вернуть списание из корзины
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppWriteoffUntrash(
    id: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<WriteOff>;
  public getAppWriteoffUntrash(
    id: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<WriteOff>>;
  public getAppWriteoffUntrash(
    id: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<WriteOff>>;
  public getAppWriteoffUntrash(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getAppWriteoffUntrash.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<WriteOff>(
      'get',
      `${this.basePath}/api/v1/write-offs/untrash/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Представление списания товаров
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppWriteoffView(
    id: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<WriteOff> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getAppWriteoffView.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<WriteOff>(
      'get',
      `${this.basePath}/api/v1/write-offs/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  public createByInventory(
    inventoryId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<WriteOff> {
    if (inventoryId === null || inventoryId === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getAppWriteoffView.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<WriteOff>(
      'get',
      `${this.basePath}/api/v1/write-offs/inventory/${encodeURIComponent(
        String(inventoryId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Отредактировать списание товаров
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchAppWriteoffUpdate(
    body?: WriteOff,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<WriteOff>;
  public patchAppWriteoffUpdate(
    body?: WriteOff,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<WriteOff>>;
  public patchAppWriteoffUpdate(
    body?: WriteOff,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<WriteOff>>;
  public patchAppWriteoffUpdate(
    body?: WriteOff,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<WriteOff>(
      'patch',
      `${this.basePath}/api/v1/write-offs`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавить частичное списание товаров
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppWriteoffCreate(
    body?: WriteOff,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<WriteOff>;
  public postAppWriteoffCreate(
    body?: WriteOff,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<WriteOff>>;
  public postAppWriteoffCreate(
    body?: WriteOff,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<WriteOff>>;
  public postAppWriteoffCreate(
    body?: WriteOff,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<WriteOff>(
      'post',
      `${this.basePath}/api/v1/write-offs`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавить списание товаров в категории
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppWriteoffCreategroup(
    body?: WriteOff,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<WriteOff>;
  public postAppWriteoffCreategroup(
    body?: WriteOff,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<WriteOff>>;
  public postAppWriteoffCreategroup(
    body?: WriteOff,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<WriteOff>>;
  public postAppWriteoffCreategroup(
    body?: WriteOff,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<WriteOff>(
      'post',
      `${this.basePath}/api/v1/write-offs/createGroup`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Удалить несколько документов списания
   */
  public batchDeleteWriteOffs(ids: { ids: string[] }): Observable<BatchDelete> {
    return this.httpClient.post(
      `${this.basePath}/api/v1/write-offs/batch-delete`,
      ids
    );
  }

  public getUntrash(id: string): Observable<WriteOff> {
    return this.httpClient.get<WriteOff>(
      `${this.basePath}/api/v1/trash/entity/${id}/restore`
    );
  }

  public template(template: TemplateRequest){
    return this.httpClient.patch(`${this.basePath}/api/v1/write-offs/template`,template);
  }
}
