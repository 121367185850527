<div class="cell position-relative">
  <div class="ts-table-header-cell" (click)="sort()">
    <div class="ts-table-header-cell__sort-icon" *ngIf="tableHeader.isSortable"
    [ngClass]="{'applied':tableHeader.isSortedBy}">
      <span class="ts-icon cursor-pointer"
            [ngClass]="{'ts-icon-arrow-Move_2':!tableHeader.isSortedBy,
                        'ts-icon-arrow-Expand_up':tableHeader.isSortedBy && tableHeader.sortDir === 'asc',
                        'ts-icon-arrow-Expand_down':tableHeader.isSortedBy && tableHeader.sortDir === 'desc'
                        }"
      ></span>
    </div>
    <div class="ts-table-header-cell__label">
      {{tableHeader.label}}
    </div>
    <div class="ms-auto" (click)="$event.stopPropagation();" *ngIf="tableHeader.filter">
      <ts-table-header-filter [filters]="tableHeader.filter" [entityName]="entityName"/>
    </div>
    <!--    TODO: раскомментировать ниже когда будет готово все для пинов-->
    <!--    <div class="ts-table-header-cell__pin-icon" *ngIf="tableHeader.isPinned || tableHeader.isPinnable">-->
    <!--      <span class="ts-icon ts-icon-actions-Pined" *ngIf="tableHeader.isPinned"></span>-->
    <!--    </div>-->
  </div>
  <div [class.resizer]="tableHeader.isResizable" #resizer></div>
</div>

