import { Directive, Input } from "@angular/core";
import { getColorByEnumValue, HubEntity, Status } from "../../data";
import { Observable, Subject, takeUntil } from "rxjs";
import { Store } from "@ngrx/store";
import { SidebarService } from "@topseller/common/sidebar";
import { selectStatus } from "../../store";
import { IdentityMatcher, StringHandler } from "../types";
import { Record } from "@topseller/core";
import { StatusesEditComponent } from "./statuses-edit";

@Directive()
export class BaseStatusSelectorComponent {
  @Input() entityName!: HubEntity;
  @Input() relatedEntities?: HubEntity[];

  isSidebarOpen = false;
  public statusList$!: Observable<Status[]>;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    protected store: Store,
    private sidebarService: SidebarService
  ) {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.statusList$ = this.store.select(selectStatus(this.entityName));
  }

  public getColor(key: string) {
    return getColorByEnumValue(key);
  }

  public recordIdentityMatcher: IdentityMatcher<Record> = (
    {id: previousId},
    {id: nextId}
  ) => previousId === nextId;

  public recordStringify: StringHandler<Record> = (item) => item.name;

  openStatusSettings() {
    if (this.isSidebarOpen) {
      return;
    }
    this.isSidebarOpen = true;
    this.sidebarService
      .sidebar<StatusesEditComponent, unknown>(StatusesEditComponent, {
        locals: {entityName: this.entityName, relatedEntities: this.relatedEntities},
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isSidebarOpen = false;
      });
  }
}
