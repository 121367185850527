import {
  Directive,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
} from '@angular/core';
import {
  Observable,
  Subject,
  fromEvent,
  map,
  merge,
  switchMap,
  takeUntil,
  tap,
  throttleTime,
} from 'rxjs';
import { DOCUMENT, ViewportScroller } from '@angular/common';

import { ANIMATION_FRAME } from '@topseller/cdk/common';

import { TsScrollComponent } from '../scroll/scroll.component';

import { TsScrollbarWrapperDirective } from './scrollbar-wrapper.directive';
import { TsScrollbarOrientation } from './scrollbar.types';

function getOffsetVertical(
  { clientY }: PointerEvent,
  { top, height }: DOMRect
): number {
  return (clientY - top) / height;
}

function getOffsetHorizontal(
  { clientX }: PointerEvent,
  { left, width }: DOMRect
): number {
  return (clientX - left) / width;
}

const MIN_WIDTH = 24;

@Directive({
  selector: '[tsScrollbar]',
})
export class TsScrollbarDirective implements OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  @Input()
  tsScrollbar: TsScrollbarOrientation = 'vertical';

  private get scrolled(): number {
    const {
      scrollTop,
      scrollHeight,
      clientHeight,
      scrollLeft,
      scrollWidth,
      clientWidth,
    } = this.computedContainer;

    return this.tsScrollbar === 'vertical'
      ? scrollTop / (scrollHeight - clientHeight)
      : scrollLeft / (scrollWidth - clientWidth);
  }

  private get computedContainer(): Element {
    const { browserScrollRef } = this.container || {};

    return browserScrollRef?.nativeElement || this.document.documentElement;
  }

  private get view(): number {
    const { clientHeight, scrollHeight, clientWidth, scrollWidth } =
      this.computedContainer;

    const rate =
      this.tsScrollbar === 'vertical'
        ? clientHeight / scrollHeight
        : clientWidth / scrollWidth;

    return Math.ceil(rate * 100) / 100;
  }

  private get compensation(): number {
    const { clientHeight, scrollHeight, clientWidth, scrollWidth } =
      this.computedContainer;

    if (
      ((clientHeight * clientHeight) / scrollHeight > MIN_WIDTH &&
        this.tsScrollbar === 'vertical') ||
      ((clientWidth * clientWidth) / scrollWidth > MIN_WIDTH &&
        this.tsScrollbar === 'horizontal')
    ) {
      return 0;
    }

    return this.tsScrollbar === 'vertical'
      ? MIN_WIDTH / clientHeight
      : MIN_WIDTH / clientWidth;
  }

  private get thumb(): number {
    const compensation = this.compensation || this.view;

    return this.scrolled * (1 - compensation);
  }

  private get nativeElement() {
    return this.elementRef.nativeElement;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(ANIMATION_FRAME) readonly animationFrame: Observable<void>,
    private readonly container: TsScrollComponent,
    private readonly wrapper: TsScrollbarWrapperDirective,
    private elementRef: ElementRef,
    private readonly viewportScroller: ViewportScroller,
    ngZone: NgZone
  ) {
    merge(animationFrame.pipe(throttleTime(50)))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() =>
        ngZone.runOutsideAngular(() => {
          const { style } = this.nativeElement;
          if (this.tsScrollbar === 'vertical') {
            style.top = `${this.thumb * 100}%`;
            style.height = `${this.view * 100}%`;
          } else {
            style.left = `${this.thumb * 100}%`;
            style.width = `${this.view * 100}%`;
          }
        })
      );

    fromEvent<PointerEvent>(this.nativeElement, 'pointerdown')
      .pipe(
        tap((event: PointerEvent) => {
          event.stopPropagation();
          event.preventDefault();
        }),
        takeUntil(this.destroy$),
        switchMap((event) => {
          const rect = this.nativeElement.getBoundingClientRect();
          const vertical = getOffsetVertical(event, rect);
          const horizontal = getOffsetHorizontal(event, rect);

          return fromEvent<PointerEvent>(this.document, 'pointermove').pipe(
            takeUntil(fromEvent(this.document, 'pointerup')),
            map((event: PointerEvent) =>
              this.getScrolled(event, vertical, horizontal)
            )
          );
        })
      )
      .subscribe(([scrollTop, scrollLeft]) => {
        ngZone.runOutsideAngular(() => {
          if (this.tsScrollbar === 'vertical') {
            this.container.browserScrollRef.nativeElement.scrollTop = scrollTop;
          } else {
            this.container.browserScrollRef.nativeElement.scrollLeft =
              scrollLeft;
          }
        });
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getScrolled(
    { clientX, clientY }: PointerEvent,
    offsetVertical: number,
    offsetHorizontal: number
  ): [number, number] {
    const { offsetHeight, offsetWidth } = this.nativeElement;
    const { top, left, width, height } =
      this.wrapper.nativeElement.getBoundingClientRect();

    const maxTop = this.computedContainer.scrollHeight - height;
    const maxLeft = this.computedContainer.scrollWidth - width;

    const scrolledTop =
      (clientY - top - offsetHeight * offsetVertical) / (height - offsetHeight);
    const scrolledLeft =
      (clientX - left - offsetWidth * offsetHorizontal) / (width - offsetWidth);

    return [maxTop * scrolledTop, maxLeft * scrolledLeft];
  }
}
