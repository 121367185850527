import { Employee } from "./employee";
import { TsDataEntity } from "./entity";

export interface Record extends TsDataEntity {
  name: string;
}

export interface TsExtendedDataEntity extends TsDataEntity {
  modifiedAt: Date;
  modifiedBy: Employee;
}

export function isRecord(obj: any): obj is Record {
  return obj && typeof obj.id === 'string' && typeof obj.name === 'string';
}
