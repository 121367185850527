import {
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { Subject, distinctUntilChanged, filter, map, takeUntil } from 'rxjs';

import { TsTableDirective } from './table.directive';

@Directive({
  selector: '[tsTableCell],',
  exportAs: `tsTableCellDirective`,
})
export class TsTableCellDirective implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  @Input() public tsTableCell?: string;
  @Input() public widthOffset = 0;

  constructor(
    @Optional() private tableDirective: TsTableDirective,
    private ngZone: NgZone,
    private elementRef: ElementRef
  ) {}

  public ngOnInit(): void {
    const { nativeElement } = this.elementRef;
    this.tableDirective?.resize
      ?.pipe(
        takeUntil(this.destroy$),
        filter((data) => !!this.tsTableCell && !!data[this.tsTableCell]),
        map((data) => data[this.tsTableCell as string]),
        distinctUntilChanged()
      )
      .subscribe((width) => {
        this.ngZone.runOutsideAngular(() => {
          const { style } = nativeElement;

          requestAnimationFrame(() => {
            style.width = `${width + this.widthOffset}px`;
            style.flexBasis = `${width + this.widthOffset}px`;
          });
        });
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
