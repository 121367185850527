<ts-modal-dialog-header title="Настройка таблицы"></ts-modal-dialog-header>
<mat-dialog-content>
  <div class="ts-bg-table-update rounded-lg px-3 py-2 mb-3">
    <div class="d-flex gap-3 align-items-start">
      <i class="ts-icon ts-icon-actions-Info text-2lg ts-text-icon-info-primary-color"></i>
      <div class="ts-text-info-color ts-caption-strong">
        Поля в таблице можно менять местами, перетягивая их вверх-вниз
      </div>
    </div>
  </div>

  <div class="ts-subtitle-small ts-text-primary mb-2">Наименование поля</div>

  <div cdkDropList (cdkDropListDropped)="onDrop($event)">
    <ng-container *ngFor="let columnName of columnNames; let i = index">
      <ng-container *ngIf="settingsColumns[columnName] as   column">
        <div cdkDrag
             [cdkDragData]="column"
             [cdkDragDisabled]="!column.metadata.canBeOrdered"
             class="modal-table-setting-content">
          <i class="ts-icon ts-icon-menu-Move text-2lg ts-drag-handle cursor-not-allowed" cdkDragHandle
             [ngClass]="{'cursor-grab':column.metadata.canBeOrdered}"
          ></i>
          <label class="ts-caption-strong-bold ts-text-secondary"
                 [ngClass]="{'ts-text-icon-neutral-color':column.settings.hidden}">
            {{column.metadata.label}}
          </label>

          <button class="ts-btn btn-grey ts-btn-bezeled ts-btn-sm ts-btn-icon rounded-1 ms-auto me-1"
                  (click)="toggleVisibility(columnName)"
                  *ngIf="column.metadata.canBeHidden">

            <i class="ts-icon ts-icon-security-eye" *ngIf="!column.settings.hidden"></i>

            <i class="ts-icon ts-icon-security-eye-slash ts-text-icon-neutral-color"
               *ngIf="column.settings.hidden"></i>
          </button>

        </div>
      </ng-container>
    </ng-container>

  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="ts-modal-actions-column">
    <button class="ts-btn primary ts-btn-md ts-btn-filled" (click)="save()">Сохранить</button>
    <button class="ts-btn primary ts-btn-md ts-btn-bezeled" type="button" (click)="close()">
      Отмена
    </button>
  </div>
</mat-dialog-actions>
