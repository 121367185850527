import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

const ANIMATION_TIME = 250;

@Component({
  selector: 'ts-time-selector',
  templateUrl: './time-selector.component.html',
  styleUrls: ['./time-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSelectorComponent implements OnInit {
  private clonesCount = 0;

  @ViewChild('container', { read: ElementRef, static: false })
  container!: ElementRef<HTMLDivElement>;

  @Input() public title?: string;

  @Input() public size = 24;

  @Input() public height = 28;

  @Input() public margin = 5;

  @Input() public siblings = 1;

  @Input() public value: number | null = 0;

  @Input() public skip = 5;

  @Output() public valueChanges: EventEmitter<number> =
    new EventEmitter<number>();

  public isEdit = false;
  public items: number[] = [];

  public get blockHeight(): number {
    return (
      (this.siblings * 2 + 1) * this.height + this.siblings * 2 * this.margin
    );
  }

  public get offset(): number {
    return this.value !== null
      ? this.value + this.clonesCount - this.siblings
      : this.clonesCount - this.siblings;
  }

  public ngOnInit(): void {
    const items = [...Array(this.size)].map((_, index: number) => index);
    const prependClones = [];
    const appendClones = [];

    const view = Math.max(3);
    const size = Math.ceil(items.length / 2) * 2;
    this.clonesCount = Math.max(view, size) / 2;

    for (let clone = 0; clone < this.clonesCount; clone++) {
      appendClones.push(items[clone]);
      prependClones.unshift(items[items.length - clone - 1]);
    }

    this.items = [...prependClones, ...items, ...appendClones];
  }

  public onActiveZone(active: boolean) {
    if (!active && this.isEdit) {
      this.isEdit = false;
    }
  }

  public setEdit(value: boolean) {
    if (this.isEdit === value) {
      return;
    }
    this.isEdit = value;
    this.init();
  }

  public moveTo(step: number = 1): void {
    this.value = (this.value || 0) + step;

    const { size, value, clonesCount } = this;
    const maximum = clonesCount + size - 1;

    const revert = (((value || 0) % size) + size) % size;

    if (revert !== value && revert - step <= maximum && revert - step > 0) {
      this.value = revert - step;
      this.animate();
      this.value += step;
    }

    this.valueChanges.emit(this.value || 0);
    setTimeout(() => this.animate(ANIMATION_TIME));
  }

  private init(): void {
    setTimeout(() => this.animate());
  }

  private animate(speed: number = 0) {
    const { style } = this.container.nativeElement;

    style.marginTop = `-${
      this.offset * (this.height + this.margin) + this.margin
    }px`;
    style.transition = `margin ${speed / 1000}s ease-in-out`;
  }
}
