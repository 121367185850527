import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {Product} from "../../../data";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {TsProductTypePipe} from "../../../pages/products-services/product-list/products/product-type.pipe";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  imports: [CommonModule, TsProductTypePipe, NgOptimizedImage],
  selector: 'ts-product-name-cell',
  templateUrl: './product-name-cell.component.html',
  styleUrls: ['./product-name-cell.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductNameCellComponent {
  @Input() product!: Product;
  @Input() showButtonOpenProduct: boolean = false;

  constructor(private router:Router,
              private activatedRoute: ActivatedRoute) {
  }


  public openInModal($event:MouseEvent){
    $event.stopPropagation();
    $event.preventDefault();
    this.router.navigate(
      [{outlets: {modal: ['item', this.product.id]}}],
      {relativeTo: this.activatedRoute, queryParamsHandling:"preserve"}
    );
  }
}
