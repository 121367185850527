import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TsScrollbarComponent } from './scrollbar.component';
import { TsScrollbarDirective } from './scrollbar.directive';
import { TsScrollbarWrapperDirective } from './scrollbar-wrapper.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    TsScrollbarComponent,
    TsScrollbarDirective,
    TsScrollbarWrapperDirective,
  ],
  exports: [
    TsScrollbarComponent,
    TsScrollbarDirective,
    TsScrollbarWrapperDirective,
  ],
  providers: [],
})
export class TsScrollbarModule {}
