import { LocationStrategy } from '@angular/common';
import {
  Directive,
  Attribute,
  ElementRef,
  HostListener,
  Renderer2,
  Input,
} from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { NavbarService } from '@topseller/common/navbar';

@Directive({
  selector: '[tsRouterLink]',
})
export class StateNavigationDirective extends RouterLink {
  @Input() set tsRouterLink(value: string) {
    this.routerLink = value;
  }

  @Input() appKey: string = '';

  private tsAnchorElement: boolean;

  constructor(
    private navbarService: NavbarService,
    router: Router,
    route: ActivatedRoute,
    @Attribute('tabindex')
    tabIndexAttribute: string | null | undefined,
    renderer: Renderer2,
    el: ElementRef,
    locationStrategy?: LocationStrategy
  ) {
    super(router, route, tabIndexAttribute, renderer, el, locationStrategy);

    const tagName = el.nativeElement.tagName?.toLowerCase();
    this.tsAnchorElement = tagName === 'a' || tagName === 'area';
  }

  @HostListener('click', [
    '$event.button',
    '$event.ctrlKey',
    '$event.shiftKey',
    '$event.altKey',
    '$event.metaKey',
  ])
  public override onClick(): boolean {
    this.navbarService.getHistory(this.appKey).then((history: any) => {
      if (history) {
        this.routerLink = history as any;
      }
    });
    return this.tsAnchorElement;
  }
}
