import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TsDropdownModule} from "../dropdown";
import {ActionItem} from "../types";
import {ActiveZoneModule} from "../active-zone";
import {RoleAccessWrapperComponent} from '../role-access-wrapper';

@Component({
  selector: 'ts-actions-dropdown',
  standalone: true,
  imports: [CommonModule, TsDropdownModule, ActiveZoneModule, RoleAccessWrapperComponent],
  templateUrl: './actions-dropdown.component.html',
  styleUrls: ['./actions-dropdown.component.scss'],
})
export class ActionsDropdownComponent {

  isExpanded: boolean = false;

  @Input() actions: ActionItem[] = [];
  @Input() isDisabledBtnAction: boolean = false;
  @Input() label = "Действия";

  setIsExpanded(isExpanded: boolean) {
    this.isExpanded = isExpanded;
  }

  onClick(action: ActionItem) {
    action.action();
  }

}
