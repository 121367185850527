import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Record } from "@topseller/core";
import { BASE_PATH } from '../variables';
import { Cashflow } from './../model/cashflow';
import { Paginated } from './../model/paginated';
import { Observable } from "rxjs";
import { BatchDelete } from "../model/batch-delete";

@Injectable()
export class CashflowService {

    protected basePath = 'http://denis.dev.topseller.ru';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration
    ) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    public getCashflowList(limit?: number, offset?: number, search?: string, filter?: string, sortName?: string, sortDir?: string,) {

      let queryParameters = new HttpParams({
            encoder: new CustomHttpUrlEncodingCodec(),
        });

        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter[cashflowSourceGroup]', <any>filter);
        }
      if(sortName !== undefined && sortDir !== null) {
        queryParameters = queryParameters.set(`sort[${sortName}]`, <any>sortDir)
      }

        return this.httpClient.get<Paginated<Cashflow>>(`${this.basePath}/api/v1/cashflow-sources`, { params: queryParameters })
    }

    public getCashflowItem(id: string) {
        return this.httpClient.get<Cashflow>(`${this.basePath}/api/v1/cashflow-sources/${id}`)
    }

    public postCashflowItem(obj: Cashflow) {
        return this.httpClient.post<Cashflow>(`${this.basePath}/api/v1/cashflow-sources`, obj)
    }

    public patchCashflowItem(obj: Cashflow) {
        return this.httpClient.patch<Cashflow>(`${this.basePath}/api/v1/cashflow-sources`, obj)
    }

    public deleteCashflowItem(id: string) {
        return this.httpClient.delete(`${this.basePath}/api/v1/cashflow-sources/${id}`)
    }

    public getCashflowGroups(limit?: number, offset?: number, search?: string) {
        let queryParameters = new HttpParams({
            encoder: new CustomHttpUrlEncodingCodec(),
        });

        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', search);
        }

        return this.httpClient.get<Paginated<Record>>(`${this.basePath}/api/v1/cashflow-sources/groups`, { params: queryParameters })

    }

    public patchCashflowGroup(obj: Record) {
        return this.httpClient.patch(`${this.basePath}/api/v1/cashflow-sources/groups`, obj)
    }

    public postCashflowGroup(obj: Record) {
        return this.httpClient.post(`${this.basePath}/api/v1/cashflow-sources/groups`, obj)
    }

    public deleteCashflowGroup(id: string) {
        return this.httpClient.delete(`${this.basePath}/api/v1/cashflow-sources/groups/${id}`)
    }

    /**
     * Удалить несколько документов Статьи ДДС
     */
    public batchDeleteCashflow(ids: { ids: string[] }): Observable<BatchDelete> {
      return this.httpClient.post(
        `${this.basePath}/api/v1/cashflow-sources/batch-delete`,
        ids
      );
    }
}
