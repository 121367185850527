import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
  InputDateRangeSelectorComponent
} from "../input-date-range-selector/input-date-range-selector.component";
import {InputDateModule} from "../input-date";
import {InputDateTimeModule} from "../input-date-time";

@NgModule({
  declarations: [InputDateRangeSelectorComponent],
    imports: [
        CommonModule,
        FormsModule,
        InputDateModule,
        ReactiveFormsModule,
        InputDateTimeModule,
    ],

  exports: [InputDateRangeSelectorComponent],
})
export class InputDateRangeSelectorModule {}
