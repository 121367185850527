import { createAction, props } from '@ngrx/store';

import { HubEntity } from '../../../../data';

export const openCustomFieldsModal = createAction(
  '[Custom Fields] Open custom fields modal',
  props<{ entity: HubEntity, relatedEntities?:HubEntity[] }>()
);

export const closeCustomFieldsModal = createAction(
  '[Custom Fields] Close custom fields modal'
);
