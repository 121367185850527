import { Route } from '@angular/router';

import { RepricerComponent } from './repricer.component';
import {RepricerResolver} from "./repricer.resolver";

export const repricerRoutes: Route[] = [
  {
    path: '',
    redirectTo: '/repricer',
    pathMatch: 'full',
  },
  {
    path: '',
    component: RepricerComponent,
    resolve: {subscription: RepricerResolver},
    children: [
      {
        path: 'accounts',
        loadChildren: () =>
        import('./pages/accounts/accounts.module').then(
          (m) => m.AccountsModule
        )
      },
      {
        path: 'goods',
        loadChildren: () =>
        import('./pages/goods/goods.module').then(
          (m) => m.GoodsModule
        )
      },
      {
        path: 'history',
        loadChildren: () =>
        import('./pages/history/history.module').then(
          (m) => m.HistoryModule
        )
      },
      {
        path: 'subscriptions',
        loadChildren: () =>
          import('@topseller/subscription').then(
            (m) => m.TsSubscriptionModule
          ),
        data: {
          productId: 'repricer',
        },
      },
    ]
  },

];
