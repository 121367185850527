<div
  class="label"
  [ngStyle]="{gap, padding}"
  (keydown.enter)="editing && editableHandler(false)"
  [class]="labelType"
  [ngClass]="{editing}"
  (activeZoneChange)="editing && !$event && editableHandler(false)"
>
  <p
    *ngIf="!editing"
    (dblclick)="editableHandler(true)"
    [ngStyle]="{fontSize, cursor}"
  >
    {{ text }}
  </p>

  <span *ngIf="editing" [ngStyle]="{fontSize}">{{ text }}</span>
  <input
    #input
    maxlength="20"
    [ngStyle]="{fontSize, cursor, padding}"
    *ngIf="editing"
    type="text"
    [(ngModel)]="text"
  />

  <img
    *ngIf="isRemovable"
    (click)="remove.emit()"
    src="/assets/img/icons/close.svg"
  />
</div>
