import { ElementRef, Injectable } from '@angular/core';
import { Observable, fromEvent, map } from 'rxjs';
import { TS_COLUMN_RESIZE } from "../table-headers/constants";

@Injectable()
export class TableColumnResizeService extends Observable<any> {
  constructor({ nativeElement }: ElementRef<Element>) {
    super((subscriber) => {
      fromEvent(nativeElement, TS_COLUMN_RESIZE, { passive: true })
        .pipe(map(({ detail }: any) => detail))
        .subscribe(subscriber);
    });
  }
}
