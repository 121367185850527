import { Component, Input } from '@angular/core';
import { TotalLine } from "../../interfaces";

@Component({
  selector: 'ts-item-products-table-summary',
  templateUrl: './item-products-table-summary.component.html',
  styleUrls: ['./item-products-table-summary.component.scss'],
})
export class ItemProductsTableSummaryComponent {
  @Input() totals: TotalLine[] = []
}
