<div class="ts-input" [class.multirow]="multirow">
  <div class="label-wrapper" [class.raised]="labelRaised">
    <div class="label-wrapper__left"></div>
    <div class="label-wrapper__center" #tsInputLabel>
      <label
        *ngIf="label"
        class="ts-input__label text-truncate"
        [class.raised]="labelRaised"
        [ngClass]="{ 'has-leading-icon': !!leadingIcon }"
        >{{ label }}</label
      >
    </div>
    <div class="label-wrapper__right"></div>
  </div>

  <div class="ts-input__content">
    <span
      class="ts-input__icon leading-icon ts-icon"
      *ngIf="leadingIcon"
      [ngClass]="leadingIcon"
    ></span>

    <ng-container *ngIf="!customContent && !multirow">
      <input
        *ngIf="tsMask; else oldMask"
        #input
        type="text"
        [ngModel]="value"
        [ngModelOptions]="{ standalone: true }"
        (ngModelChange)="onModelChange($event)"
        [tsMask]="tsMask"
        [readOnly]="readonly"
        [placeholder]="placeholder"
      />

      <ng-template #oldMask>
        <input
          #input
          type="text"
          [ngModel]="value"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="onModelChange($event)"
          [mask]="maskExpression"
          [readOnly]="readonly"
          [placeholder]="placeholder"
        />
      </ng-template>
    </ng-container>

    <textarea
      *ngIf="!customContent && multirow"
      #input
      type="text"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="onModelChange($event)"
      rows="5"
      [readOnly]="readonly"
      [placeholder]="placeholder"
      spellcheck="true"
    >
    </textarea>

    <ng-content #customContent select="customContent"></ng-content>

    <span
      *ngIf="hasCleaner && !disabled"
      class="ts-input__icon trailing-icon ts-icon ts-icon-actions-Close cursor-pointer"
      (click)="clear()"
    >
    </span>
    <span
      *ngIf="hasCopy && !disabled"
      class="ts-input__icon trailing-icon ts-icon ts-icon-actions-Copy cursor-pointer"
      (click)="copy()"
    ></span>
    <span
      *ngIf="trailingIcon"
      class="ts-input__icon trailing-icon ts-icon"
      [ngClass]="trailingIcon"
      (click)="trailingIconClick.emit()"
      [class.trailingIconClickable]="trailingIconClickable"
    ></span>
  </div>

  <div *ngIf="multirow" class="ts-input--resizer" tsInputResizer></div>
</div>
