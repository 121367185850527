<ng-template tsSidebarTitle>Настройки</ng-template>

<form [formGroup]="form" (ngSubmit)="save()" class="h-100 d-flex flex-column p-4">


  <div class="mb-2">
    <ts-toggle-buttons-group
      *ngIf="menu.length>0" [selectedId]="selectedEntity"
      [items]="menu"
      (selectItem)="selectEntity($event)"
    />
  </div>
  <div class="flex-grow-1 overflow-auto">
    <ts-role-access-wrapper [requiredRole]="editItemRole">
      <div cdkDropList (cdkDropListDropped)="onDrop($event)" formArrayName="statuses" class="pe-4">
        <div *ngFor="let group of (statusesFormArray)?.controls; let i=index">
          <div cdkDrag [formArrayName]="i" class="mb-3 d-flex flex-row gap-2 align-items-center">
            <i class="ts-icon ts-icon-menu-Move text-2lg ts-drag-handle mt-2" cdkDragHandle></i>
            <div class="flex-grow-1">
              <app-title title="Наименование статуса" formControlName="name"/>
            </div>
            <div class="mt-2">
              <ts-colors-selector formControlName="color">
              </ts-colors-selector>
            </div>

            <ts-dropdown class="settings-icon ms-3 mt-2" [content]="actions">
              <button type="button" class="ts-btn ts-btn-grey ts-btn-bezeled ts-btn-md ts-btn-icon">
                <i class="ts-icon ts-icon-settings-more text-2lg"></i>
              </button>
            </ts-dropdown>
            <ng-template #actions>
              <ts-role-access-wrapper [requiredRole]="deleteItemRole(i)">
                <button type="button" (click)="deleteStatus(i)"
                        class="dropdown-item settings-block__item"
                        dropdownOption>
                  <i class="ts-icon ts-icon-actions-Trash"></i>
                  <span class="ts-caption-strong">Удалить</span>
                </button>
              </ts-role-access-wrapper>
            </ng-template>

          </div>
        </div>
      </div>
    </ts-role-access-wrapper>

    <ts-role-access-wrapper [requiredRole]="editItemRole">
      <div (click)="addStatus()" class="addStatus">
        <i class="ts-icon ts-icon-actions-Add text-2lg"></i>
        <span>Добавить статус</span>
      </div>
    </ts-role-access-wrapper>
  </div>
</form>

<ng-template tsSidebarActions>
  <div class="actions">
    <ts-role-access-wrapper [requiredRole]="editItemRole">
      <button type="submit" class="ts-btn primary ts-btn-filled ts-btn-md w-100" (click)="save()">Сохранить</button>
    </ts-role-access-wrapper>
    <button class="ts-btn primary ts-btn-bezeled ts-btn-md" type="button" (click)="clear()">
      Отмена
    </button>
  </div>
</ng-template>




