/**
 * TOPSELLER HUB API
 * Topseller HUB backend API
 *
 * OpenAPI spec version: 24.0719.1509
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { BASE_PATH } from '../tokens';
import { NewsItem } from '../model/news-item';
import { Paginated } from '../model/paginated';

@Injectable()
export class NewsItemService {
  protected basePath = 'http://hub.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
  }

  /**
   * Удалить новость
   * Удалить новость
   * @param newsItemId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteNewsItem(
    newsItemId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<unknown> {
    if (newsItemId === null || newsItemId === undefined) {
      throw new Error(
        'Required parameter newsItemId was null or undefined when calling deleteAppV1NewsitemDelete.'
      );
    }

    return this.httpClient.request<unknown>(
      'delete',
      `${this.basePath}/api/v1/news-items/${encodeURIComponent(
        String(newsItemId)
      )}`,
      {
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Все доступные новости. Без проверки прав доступа
   * Все доступные новости. Без проверки прав доступа
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getNewsItems(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<NewsItem>> {
    return this.httpClient.request<Paginated<NewsItem>>(
      'get',
      `${this.basePath}/api/v1/news-items/list`,
      {
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Одна доступная новость. Без проверки прав доступа
   * Одна доступная новость. Без проверки прав доступа
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRandomNewsItem(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NewsItem> {
    return this.httpClient.request<NewsItem>(
      'get',
      `${this.basePath}news-items/single`,
      {
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Все новости
   * Все новости
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param filter Коллекция фильтров
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getNewsItemList(
    limit?: number,
    offset?: number,
    filter?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<NewsItem>> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }
    if (filter) {
      filter.forEach((element) => {
        queryParameters = queryParameters.append('filter', <any>element);
      });
    }

    return this.httpClient.request<Paginated<NewsItem>>(
      'get',
      `${this.basePath}/api/v1/news-items`,
      {
        params: queryParameters,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Новость
   * Новость
   * @param newsItemId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getNewsitemById(
    newsItemId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NewsItem> {
    if (newsItemId === null || newsItemId === undefined) {
      throw new Error(
        'Required parameter newsItemId was null or undefined when calling getAppV1NewsitemView.'
      );
    }
    return this.httpClient.request<NewsItem>(
      'get',
      `${this.basePath}/api/v1/news-items/${encodeURIComponent(
        String(newsItemId)
      )}`,
      {
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Отредактировать новость
   * Отредактировать новость
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateNewsitem(
    body?: NewsItem,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NewsItem> {
    return this.httpClient.request<NewsItem>(
      'patch',
      `${this.basePath}/api/v1/news-items`,
      {
        body: body,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавить новость
   * Добавить новость
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createNewsitem(
    body?: NewsItem,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NewsItem> {
    return this.httpClient.request<NewsItem>(
      'post',
      `${this.basePath}/api/v1/news-items`,
      {
        body: body,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
