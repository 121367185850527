import { Component, ElementRef, EventEmitter, Inject, Input, NgZone, Output } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { TableHeader } from "@topseller/ui";
import { Store } from "@ngrx/store";
import { combineLatest, map, Observable, shareReplay } from "rxjs";
import { Record as TsRecord } from "@topseller/core";
import { selectDefaultPriceGroup, selectNotDefaultPriceGroups } from "../../../store";
import { ProductPrices } from "../../../data/model/product-prices";
import { TsDropdownModule } from "@topseller/ui/dropdown";
import { TsScrollModule } from "@topseller/ui/scroll";
import { TableHeaderCell2Component, TableSettingsService } from "@topseller/common";

@Component({
  selector: 'ts-product-price-table-header',
  standalone: true,
  imports: [CommonModule, TsDropdownModule, TsScrollModule],
  templateUrl: './product-price-table-header.component.html',
  styleUrls: ['./product-price-table-header.component.scss'],
})
export class ProductPriceTableHeaderComponent extends TableHeaderCell2Component{

  @Output() selectProductPrice = new EventEmitter<string>();

  prices$: Observable<TsRecord[]> = combineLatest(
    [this.store.select(selectDefaultPriceGroup),
      this.store.select(selectNotDefaultPriceGroups)])
    .pipe(
      map(([defaultGroup, otherGroups]) => {
          const allPrices = [];
          if (defaultGroup) {
            allPrices.push(...this.mapPriceGroupToPrices(defaultGroup));
          }

          otherGroups.forEach(group => {
            allPrices.push(...this.mapPriceGroupToPrices(group));
          });
          return allPrices;
        }
      ),
      shareReplay(1)
    )

  constructor(private store: Store,
              @Inject(DOCUMENT) document: Document,
              elementRef: ElementRef,
              ngZone: NgZone,
              tableSettingsService: TableSettingsService) {
    super(document, elementRef, ngZone, tableSettingsService);
  }

  selectPrice(price: TsRecord) {
    this.selectProductPrice.emit(price.id);
  }

  private mapPriceGroupToPrices(group: ProductPrices): TsRecord[] {
    return group.productPriceTypes?.map(price => {
      return {
        id: price.id!,
        name: group.name + ' ' + price.name
      };
    }) ?? [];
  }
}
