import { NgModule } from '@angular/core';

import { TsHintModule } from '@topseller/ui/hint';


import { TableImageComponent } from './table-image.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    TsHintModule,
    CommonModule
  ],
  exports: [
    TableImageComponent
  ],
  declarations: [
    TableImageComponent
  ],
  providers: [],
})
export class TableImageModule { }
