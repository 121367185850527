import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TsRefreshComponent } from './refresh.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TsRefreshComponent],
  exports: [TsRefreshComponent],
})
export class TsRefreshModule {}
