import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleButtonModel } from "../types";
import { RoleAccessWrapperComponent } from '../role-access-wrapper';

@Component({
  selector: 'ts-toggle-buttons-group',
  standalone: true,
  imports: [CommonModule, RoleAccessWrapperComponent],
  templateUrl: './toggle-buttons-group.component.html',
  styleUrls: ['./toggle-buttons-group.component.scss'],
})
export class ToggleButtonsGroupComponent {
  @Input() items: ToggleButtonModel[] = [];
  @Input() selectedId: any = '';

  @Output() selectItem = new EventEmitter();

  onSelectItem(id: any) {
    this.selectedId = id;
    this.selectItem.emit(id);
  }
}
