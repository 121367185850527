import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import {
  ProductShowVariablesService,
  ShowNames,
} from '../pages/products-services/product/services/show-variables.service';

export interface ShowProductVars {
  showBarcodes: boolean;
  showDimensions: boolean;
  showExtraFields: boolean;
  showProduct: boolean;
  showPrices: boolean;
  showStocks: boolean;
  showModif: boolean;
}

@Injectable()
export class ProductShowResolver {
  constructor(
    private showVariablesService: ProductShowVariablesService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ShowProductVars> {
    const showVars = {
      showBarcodes: true,
      showDimensions: true,
      showExtraFields: true,
      showProduct: true,
      showPrices: true,
      showStocks: true,
      showModif: true,
    };

    this.showVariablesService.getVar(ShowNames.SHOW_BARCODES).then((value) => {
      showVars.showBarcodes = value;
    });
    this.showVariablesService
      .getVar(ShowNames.SHOW_DIMENSIONS)
      .then((value) => {
        showVars.showDimensions = value;
      });
    this.showVariablesService
      .getVar(ShowNames.SHOW_EXTRA_FIELDS)
      .then((value) => {
        showVars.showExtraFields = value;
      });
    this.showVariablesService.getVar(ShowNames.SHOW_PRODUCT).then((value) => {
      showVars.showProduct = value;
    });

    this.showVariablesService.getVar(ShowNames.SHOW_PRICES).then((value) => {
      showVars.showPrices = value;
    });

    this.showVariablesService.getVar(ShowNames.SHOW_STOCKS).then((value) => {
      showVars.showStocks = value;
    });

    this.showVariablesService.getVar(ShowNames.SHOW_MODIF).then((value) => {
      showVars.showModif = value;
    });

    return of(showVars);
  }
}
