<div class="ts-table-header-cell" >
  <div class="ts-table-header-cell__sort-icon" *ngIf="tableHeader.isSortable">
    <span class="ts-icon ts-icon-arrow-Move_2"></span>
  </div>
  <div class="ts-table-header-cell__label">
    {{tableHeader.label}}
  </div>
  <div class="ts-table-header-cell__pin-icon" *ngIf="tableHeader.isPinned || tableHeader.isPinnable">
    <span class="ts-icon ts-icon-actions-Pined" *ngIf="tableHeader.isPinned"></span>
  </div>
</div>
