<ts-dropdown [content]="filterForm" [isVisible]="(isVisible$ | async)!" (changeVisible)="onChangeVisible($event)">
  <button
    type="button"
    class="ts-btn text-xs ts-btn-icon header-filter-button"
    [ngClass]="{'expanded':isVisible$|async, 'applied':isFilterSet$|async}"
  >
    <i class="ts-icon ts-icon-actions-filter"></i>
  </button>
</ts-dropdown>

<ng-template #filterForm let-activeZone>
  <div class="filter-form">
    <form [formGroup]="form">
      <div class="filter-item text-sm" *ngFor="let filter of filters!">
        <ng-container *ngIf="filter.type === 'daterange'; else otherControls">
          <ts-input-date-range-selector
            [formGroup]="form"
            [activeZoneParent]="activeZone"
            [dateFromControlName]="filter.target + '_' +DATE_FROM"
            [dateToControlName]="filter.target + '_'+ DATE_TO"
            dateFromInputLabel="От"
            dateToInputLabel="До"
            layout="vertical"
          >
          </ts-input-date-range-selector>
        </ng-container>
        <ng-template #otherControls>
          <ts-filter-control
            [activeZoneParent]="activeZone"
            [entityName]="entityName"
            [filter]="filter"
            [form]="form"
          ></ts-filter-control>
        </ng-template>
      </div>
      <div class="d-flex gap-2 p-2">
        <button type="button" class="ts-btn primary ts-btn-filled ts-btn-sm w-100" (click)="apply()">Применить</button>
        <button type="button" class="ts-btn btn-grey ts-btn-bezeled ts-btn-sm w-100" (click)="cleanAll()">Сбросить</button>
      </div>

    </form>
  </div>
</ng-template>
