import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BillingAccount, Configuration } from '../model';
import { BASE_PATH } from '../tokens';

@Injectable()
export class AccountSettingsApiService {
  protected basePath = 'http://denis.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  updateAccountInfo(obj: BillingAccount) {
    return this.httpClient.patch<BillingAccount>(
      `${this.basePath}account-settings`,
      obj
    );
  }
  getAccountInfo() {
    return this.httpClient.get<BillingAccount>(
      `${this.basePath}account-settings`
    );
  }
}
