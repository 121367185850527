import { Directive, Input, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { filter, map, Observable, startWith, Subject, takeUntil } from "rxjs";
import { TotalLine } from "../interfaces";
import { ActionItem, TableHeader } from "@topseller/ui";
import { Source } from "../../data";
import { DocumentItemsTableTotalService } from "../services";
import { ExpenseTableLine } from "../types";
import { createExpenseFormGroup } from "../utils";

const expensesTableHeaders: TableHeader[] = [
  {label: '№', key: 'lineNumber', width: '60', isSortable: true, isResizable: false, textAlign: 'center'},
  {key: 'source', label: 'Статья расходов', width: 260,},
  {key: 'sourceGroup', label: 'Группа расходов', width: 190,},
  // {key: 'expenseSpreadType', label: 'Способ размазывания затрат', width: 260,}, TODO: for later
  {key: 'amount', label: 'Сумма расхода, ₽', width: 200, textAlign: 'right', isSortable: true, type: 'currency'},
  {key: 'expensedAt', label: 'Дата и время', width: 200, isSortable: true},
  {key: 'empty', label: '', width: 260},
]

@Directive()
export class DocumentExpensesTableBase implements OnInit {

  @Input() form!: FormGroup;

  public tableLinePlaceholder: FormControl = new FormControl(null);
  public selectedItems: number[] = [];
  public isTableIdle = true;
  public items$?: Observable<any>;
  public totals$?: Observable<TotalLine[]>;
  public tableHeaders = expensesTableHeaders;
  public isDisabledBtnAction: boolean = true;

  public tableActionsList: ActionItem[] = [{
    title: `Удалить`,
    action: () => this.deleteSelectedLines()
  }];
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private documentTotalService: DocumentItemsTableTotalService<ExpenseTableLine>) {
  }

  public get allSelected(): boolean {
    return !!(
      this.expenses.value.length &&
      this.expenses.value.length === this.selectedItems.length
    );
  }

  protected get expenses(): FormArray {
    return this.form.controls['expenses'] as FormArray;
  }

  protected get expenseControls(): FormGroup[] {
    return this.expenses.controls as FormGroup[];
  }


  private deleteSelectedLines() {
    for (let i = this.selectedItems.length - 1; i >= 0; i--) {
      this.expenses.removeAt(this.selectedItems[i]);
    }

    this.selectedItems = [];
    this.form.markAsDirty();
  }

  private updateIsDisabledBtnAction(): void {
    this.isDisabledBtnAction = this.selectedItems.length === 0;
  }

  public isSelected(index: number): boolean {
    return this.selectedItems.includes(index);
  }

  public selectItem(value: boolean, idx: number): void {
    const index = this.selectedItems.indexOf(idx);

    if (!value && index > -1) {
      this.selectedItems.splice(index, 1);
    } else if (value && index === -1) {
      this.selectedItems.push(idx);
    }

    this.updateIsDisabledBtnAction();
  }

  public toggleAll(value: boolean): void {
    if (!value) {
      this.selectedItems = [];
    } else {
      this.selectedItems = this.expenses.value.map((_: unknown, idx: number) => idx);
    }

    this.updateIsDisabledBtnAction();
  }

  public lineAdded(): void {
    if (!this.isTableIdle) {
      this.isTableIdle = true;
    }
  }

  public addItem(item: Source) {
    const newGroup = createExpenseFormGroup();
    this.tableLinePlaceholder.setValue(null, {
      onlySelf: true, emitEvent: false,
    });
    // Добавим строку
    this.isTableIdle = false;
    newGroup.patchValue({
      source: {
        ...item,
      },
    }, {
      onlySelf: true, emitEvent: false,
    });
    this.expenses.push(newGroup);
    this.form.markAsDirty();

  }

  ngOnInit(): void {
    this.handleNewLine();
    this.setupExpensesTable();
    this.setupTotals();
  }

  setupExpensesTable() {
    this.items$ = this.expenses.valueChanges.pipe(
      map(() => this.expenseControls),
      startWith(this.expenseControls)
    )
  }

  setupTotals() {
    this.totals$ = this.expenses.valueChanges.pipe(
      startWith(this.expenses.value),
      map((value) => this.documentTotalService.getTotals({items: value})))
  }

  protected handleNewLine() {
    this.tableLinePlaceholder.valueChanges.pipe(
      takeUntil(this.destroy$),
      filter((value: Source) => !!value)
    )
      .subscribe((source: Source) => {
          this.addItem(source);
        }
      )
  }

}
