import { Route } from "@angular/router";
import { AnalyticsComponent } from "./analytics.component";

export const analyticsRoutes: Route[] = [
  {
    path: '',
    redirectTo: '/analytics/main/categories',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AnalyticsComponent,
    children: [
      {
        path: 'main',
        loadChildren: () =>
        import('./pages/main/main.module').then(
          (m) => m.MainModule
        )
      },
      {
        path: 'favorites',
        loadChildren: () =>
        import('./pages/favorites/favorites.module').then(
          (m) => m.FavoritesModule
        )
      },
      {
        path: 'comparison',
        loadChildren: () =>
        import('./pages/comparison/comparison.module').then(
          (m) => m.ComparisonModule
        )
      },
    ]
  }
]