import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationStart, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, filter, Observable } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import {BillingAccountRestrictions, SecurityService} from "@topseller/core";

@Injectable()
export class RepricerResolver  {
    constructor(private readonly router: Router, private securityService: SecurityService) {}

    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<BillingAccountRestrictions> {
        // Complete the stream upon navigating away
        const navigateAway$ = this.router.events.pipe(
            filter((event) => event instanceof ActivationStart)
        );

        return this.securityService
            .getSecurityRestrictions('repricer')
            .pipe(
                takeUntil(navigateAway$),
                catchError(() => EMPTY)
            );
    }
}
