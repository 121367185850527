import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { File as AttachedFile, getFileExtension, getFileNameWithoutExtension, getFileTypeIcon } from "@topseller/core";
import { BytesToMegabytesPipe } from "@topseller/ui/pipes/bytes-to-megabytes/bytes-to-megabytes.pipe";

@Component({
  selector: 'ts-attached-file',
  standalone: true,
  imports: [CommonModule, BytesToMegabytesPipe],
  templateUrl: './attached-file.component.html',
  styleUrls: ['./attached-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachedFileComponent {
  @Input() file!: AttachedFile;
  @Input() state: 'new' | 'saved' = 'saved';

  @Output() delete = new EventEmitter();

  fileTypeIcon = () => getFileTypeIcon(this.file.originalName ?? '');

  fileExtension = () => getFileExtension(this.file.originalName);

  fileNameWithoutExtension = () => getFileNameWithoutExtension(this.file.originalName);
  protected readonly onclick = onclick;

  onDelete() {
    this.delete.emit();
  }
}
