import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
/**
 * @deprecated since version 0.3
 */
export class LabelComponent implements OnInit {
  @Input() text: string | undefined = '';
  @Input() fontSize = '14px';
  @Input() labelType: 'default' | 'outlined' = 'default';
  @Input() gap = '12px';
  @Input() padding = '6px 14px';
  @Input() isEditable = false;
  @Input() isRemovable = false;

  @ViewChild('input', { static: false }) inputEl?: ElementRef;

  @Output() remove = new EventEmitter();
  @Output() isEditing = new EventEmitter<boolean>();
  @Output() editedText = new EventEmitter<string>();

  cursor = 'pointer';

  editing = false;

  ngOnInit(): void {
    this.cursor = this.isEditable ? 'pointer' : 'text';
  }

  editableHandler(editable: boolean): void {
    if (this.isEditable) {
      this.cursor = editable ? 'text' : 'pointer';
      this.editing = editable;
      this.isEditing.emit(editable);
      this.editedText.emit(this.text);

      setTimeout(() => {
        if (editable && this.inputEl) {
          this.inputEl.nativeElement.focus();
        }
      }, 0);
    }

    if (!editable && !this.text?.length) {
      this.remove.emit();
    }
  }
}
