import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { MatDialogModule } from '@angular/material/dialog';

import { TsSelectModule } from '@topseller/ui/select';
import { TsSelectOptionModule } from '@topseller/ui/select-option';
import { TsInputTextModule } from '@topseller/ui/input-text';

import { CustomFieldsModalComponent } from './add-edit-custom-fields-modal.component';
import { CustomFieldModalEffects } from './store';
import {ToggleButtonsGroupComponent} from "@topseller/ui/toggle-buttons-group/toggle-buttons-group.component";
import { CdkDrag, CdkDragHandle, CdkDropList } from "@angular/cdk/drag-drop";
import { FocusableDirective } from "@topseller/ui/directives/focusable.directive";
import { RoleAccessWrapperComponent } from "@topseller/ui/role-access-wrapper";

@NgModule({
  declarations: [CustomFieldsModalComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatDialogModule,
        TsSelectModule,
        TsSelectOptionModule,
        TsInputTextModule,
        EffectsModule.forFeature([CustomFieldModalEffects]),
        ToggleButtonsGroupComponent,
        CdkDropList,
        CdkDrag,
        CdkDragHandle,
        FocusableDirective,
        RoleAccessWrapperComponent,
    ],
  providers: [],
})
export class TsCusomFieldModalModule {}
