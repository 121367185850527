import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { AuthService } from "../providers";
import { tap } from "rxjs/operators";

@Injectable()
export class RolesGuard {

  constructor(private authService: AuthService, private router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const requiredRole = route.data['requiredRole'];
    const redirectTo = route.data['noAccessRedirect'];
    return this.authService.employeeHasRole(requiredRole).pipe(
      tap((hasRole: boolean) => {
        if (!hasRole) {
          this.router.navigate([redirectTo ?? '/']);
        }
      })
    );
  }
}
