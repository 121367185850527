import { select, Store } from '@ngrx/store';
import { filter, map, Observable } from 'rxjs';

import { LinkedDocument } from '../../../data';
import { selectLinkedDocuments } from './store/linked-documents.selector';
import { TsLinkedDocuments } from './linked-documents.type';
import { Injectable } from '@angular/core';

@Injectable()
export class BaseLinkedDocumentsService {
  static getTotal(entities: { amountWithVat: number }[]): number {
    return entities.reduce((acc, current) => acc + current.amountWithVat, 0);
  }

  constructor(protected readonly store: Store) {}

  public getLinkedDocuments(
    mapper?: (data: LinkedDocument) => TsLinkedDocuments
  ): Observable<TsLinkedDocuments> {
    return this.store.pipe(
      select(selectLinkedDocuments),
      filter((data: LinkedDocument) => !!data),
      map(mapper || this.linkedDocumentMapper)
    );
  }

  protected linkedDocumentMapper(data: LinkedDocument): TsLinkedDocuments {
    return [];
  }
}
