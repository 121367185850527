import { ChangeDetectorRef, Directive } from '@angular/core';
import { ControlValueAccessor, NgControl, NgModel } from '@angular/forms';

@Directive()
export abstract class AbstractFormControl<T> implements ControlValueAccessor {
  onChange: (value: T) => void = () => { return };
  onTouched: () => void = () => { return };

  private previousValue?: T | null;
  protected readonly fallbackValue = this.getFallbackValue();

  public get value(): T {
    return this.previousValue ?? this.fallbackValue;
  }

  public get disabled(): boolean {
    return this.safeNgControlData<boolean>(({ disabled }) => disabled, false);
  }

  constructor(
    protected readonly ngControl: NgControl,
    protected readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  protected abstract getFallbackValue(): T;

  public writeValue(value: T): void {
    this.previousValue = value;
    this.changeDetectorRef.markForCheck();
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  protected updateValue(value: T): void {
    if (this.valueIdenticalComparator(this.value, value)) {
      return;
    }

    this.previousValue = value;
    this.controlSetValue(value);
  }

  protected valueIdenticalComparator(oldValue: T, newValue: T): boolean {
    return oldValue === newValue;
  }

  private controlSetValue(value: T): void {
    this.onChange(value);
    this.changeDetectorRef.markForCheck();
  }

  private safeNgControlData<T>(
    extractor: (ngControl: NgControl) => T | null | undefined,
    defaultFieldValue: T
  ): T {
    return (this.ngControl && extractor(this.ngControl)) ?? defaultFieldValue;
  }
}
