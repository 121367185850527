<div class="ts-entity-list-container">
  <div class="ts-entity-list-container__search" *ngIf="hasSearch">
    <span class="ts-icon ts-icon-search-search-normal"></span>
    <input
      [formControl]="searchControl"
      placeholder="Поиск"
      (click)="$event.stopPropagation()"
    />
  </div>

  <ts-scroll class="scroll-wrapper">
    <ts-data-scroll (scrolled)="onScroll()" [options]="dataScrollOptions">
      <div *ngIf="items$ | async as items" class="items-wrapper">
        <button
          [tsSelectOption]="item"
          class="dropdown-item"
          *ngFor="let item of items"
          [multi]="multi"
        >
          <ng-container
            *ngTemplateOutlet="
              itemTemplate || default;
              context: { $implicit: item }
            "
          ></ng-container>
        </button>

        <div class="empty-title" *ngIf="!items?.length && isLoaded">
          {{ emptyMessage }}
        </div>
      </div>

      <ts-dot-loader *ngIf="isLoading"></ts-dot-loader>
    </ts-data-scroll>
  </ts-scroll>

  <button
    *ngIf="canAdd"
    class="btn btn-link list-item ms-2"
    (click)="add.emit($event)"
  >
    <i class="ts-icon ts-icon-actions-Add primary ts-subtitle-strong"></i>
    <span>Добавить</span>
  </button>

  <ng-content></ng-content>
</div>

<ng-template #default let-item>
  {{ item.name }}
</ng-template>
