import { TableSettingsService } from "../table/table-settings-service";
import { map, Observable } from "rxjs";
import {
    selectSettingsTableColumns,
    TableColumnSettings,
    updateTableColumnWidth,
    updateTableSortTarget,
} from "@topseller/core";
import { Store } from "@ngrx/store";
import { ActivatedRoute, Router } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable()
export class UpdatableTableSettingsService implements TableSettingsService {

    constructor(private store: Store,
                private activatedRoute: ActivatedRoute,
                private router: Router,) {
    }

    getDefaultSort(tableIdentifier: string): Observable<{
        columnKey: string;
        sortDirection: string
    }> {
        return this.store.select(selectSettingsTableColumns(tableIdentifier)).pipe(
            map((setting: string) => JSON.parse(setting)),
            map((setting: TableColumnSettings) => {
                return {columnKey: setting.sort.target, sortDirection: setting.sort.order}
            })
        )
    }

    updateColumnWidth(columnKey: string, tableIdentifier: string, width: number): void {
        this.store.dispatch(updateTableColumnWidth({columnKey, tableIdentifier, width}));
    }

    updateSortTarget(columnKey: string, tableIdentifier: string, sortDirection: string): void {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                sortName: columnKey,
                sortDir: sortDirection,
            },
            queryParamsHandling: 'merge',
        });
        this.store.dispatch(updateTableSortTarget({columnKey, tableIdentifier, sortDirection}));
    }

}

