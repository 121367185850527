<div slot="table-summary" class="ts-bg-primary products-table-summary">
  <div class="ts-table__row">
    <div class="cell" [ngStyle]="{ 'width.px': 70, 'flex-basis.px': 70 }">
      <div class="ts-content">
        <span class="ts-subtitle">Итог</span>
      </div>
    </div>
    <div *ngFor="let info of totals" class="cell" [ngStyle]="{ 'width.px': 160, 'flex-basis.px': 160 }">
      <div class="ts-content">
        <p class="ts-caption-small mb-1">{{ info.title }}:</p>
        <p *ngIf="info.currencyFormat; else withoutCurrencyFormat" class="ts-caption-strong">{{ info.value | currencyFormat }}</p>
        <ng-template #withoutCurrencyFormat>
          <p class="ts-caption-strong">{{ info.value }}</p>
        </ng-template>
      </div>
    </div>
  </div>
</div>
