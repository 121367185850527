import { Observable } from "rxjs";
import { Paginated } from "../types";

export abstract class AccountsAbstractService {
  abstract getAccounts(
    limit?: number,
    offset?: number,
    search?: string,
    marketplace_id?: string,
  ): Observable<Paginated<any>>;
  abstract getMarketplaces(): any;
  abstract postAccount(json: any): any;
  abstract patchAccount(id: any, json: any): any;
  abstract deleteAccount(id: any): any;
}

