import { Component, Input, OnInit } from '@angular/core';
import { Expense, HubDocument, HubEntity, Product } from "../../../data";
import { TradeDocumentEditBase } from "../../trade-document-edit-base";
import { MatDialog } from "@angular/material/dialog";
import { DataItemToTableValueMapper, FromStoreTableHeaderService } from "@topseller/common/table";
import { ProductExpensesModalComponent } from "../product-expenses-modal/product-expenses-modal.component";
import { FormArray, FormGroup } from "@angular/forms";
import { createExpenseFormGroup } from "../../utils";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";
import { openSettingsTableModal } from "@topseller/common/table-settings-modal";
import { Store } from "@ngrx/store";

@Component({
  selector: 'ts-trade-document-products-table',
  templateUrl: './trade-document-products-table.component.html',
  styleUrls: ['./trade-document-products-table.component.scss'],
})
export class TradeDocumentProductsTableComponent<T extends HubDocument> implements OnInit{

  constructor(
    public host: TradeDocumentEditBase<T>,
    private dialog: MatDialog,
    public dataItemToTableValueMapper: DataItemToTableValueMapper,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store) {
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const state = window.history.state;
      if (state && state.selectedProducts) {
        this.host.item.products = state.selectedProducts;
        this.host.setProductsToTable();
      }
    });
  }

  openExpensesModal(productGroup: FormGroup) {
    const dialogConfig = {
      disableClose: true,
      backdropClass: 'ts-modal-backdrop',
      data: {
        expenses: productGroup.value.expenses,
        form: this.host.form,
      },
      panelClass: ['ts-modal-panel', 'ts-modal-panel-lg'],
      width: '100vw',
      maxWidth: '100vw',
      id: 'ts-modal',
    };

    const dialogRef = this.dialog.open(
      ProductExpensesModalComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((expenses: Expense[]) => {
      if (expenses) {
        const expensesArray = ((productGroup.get('expenses') ?? []) as FormArray);
        expensesArray.clear();
        expenses.forEach(expense => {
          const group = createExpenseFormGroup();
          group.patchValue({...expense});
          expensesArray.push(group);
        })
      }
    });
  }

  openHandbookModal() {
    this.router.navigate([{
      outlets: {
        modal: ['products'],
      },
    }], {relativeTo: this.route, state: {products: this.host.productControlsValue}});

  }

  calculateExpensesSum(expenses: Expense[]) {
    return expenses?.reduce((a, b) => a + b.amount, 0) ?? 0;
  }

  openSettingsModal() {
    this.store.dispatch(openSettingsTableModal({entity: this.host.productTableEntityName!}));

  }
}
