<ng-container
  [formGroup]="form"
  [ngSwitch]="filter.type"
>


  <!-- TYPE TEXT -->
  <app-title
    *ngSwitchCase="'text'"
    [title]="filter.name"
    [formControlName]="filter.target"
  ></app-title>

  <!-- TYPE DATE -->
  <ts-input-date-time
    [title]="filter.name"
    [formControlName]="filter.target"
    *ngSwitchCase="'date'"
  ></ts-input-date-time>

  <!-- TYPE CHECKBOX -->
  <ng-container *ngSwitchCase="'checkbox'">
    <span *ngIf="filter.name">{{ filter.name }}</span>
    <div class="d-flex flex-column gap-3 mt-2">
      <div class="d-flex flex-row gap-2 align-items-center">
        <div class="form-radio">
          <input
            type="radio"
            [id]="filter.target + 'true'"
            [value]="'true'"
            [formControlName]="filter.target"
          />
          <label [for]="filter.target + 'true'"></label>
        </div>
        <label>Да</label>
      </div>
      <div class="d-flex flex-row gap-2 align-items-center">
        <div class="form-radio">
          <input
            type="radio"
            [id]="filter.target + 'false'"
            [value]="'false'"
            [formControlName]="filter.target"
          />
          <label [for]="filter.target + 'false'"></label>
        </div>
        <label>Нет</label>
      </div>
    </div>
  </ng-container>

  <!-- TYPE CHECKBOX -->
  <ng-container *ngSwitchCase="'multiCheckbox'">
    <span>{{ filter.name }}</span>
    <div class="d-flex flex-row gap-2 align-items-center">
      <!-- Я ДУМАЮ ЧТО БЕКУ НУЖНО ОТДАВАТЬ СРАЗУ ЗНАЧЕНИЯ ДЛЯ НИХ
      ЛИБО ПОДГРУЖАТЬ ЗНАЧЕНИЯ ВМЕСТЕ С СОЗДАНИЕМ ФОРМЫ (ТОГДА НУЖЕН ЛОУДЕР ДЛЯ ФИЛЬТРОВ) -->
    </div>
  </ng-container>

  <!-- TYPE SELECT -->
  <ts-select
    *ngSwitchCase="'select'"
    [label]="filter.name"
    [hasCleaner]="false"
    [formControlName]="filter.target"
    [stringify]="recordStringify"
    [identityMatcher]="recordIdentityMatcher"
  >
    <ts-entity-list
      *ngIf="filter.source === 'api'"
      [queryFn]="filterValuesQueryFromApiFn(filter.target)"
    />
    <ng-container *ngIf="filter.source === 'basedata'">
      <ng-container *ngIf="filterValuesFromBaseDataFn(filter.target!)|async as items">
        <button
          [tsSelectOption]="item"
          class="list-item"
          *ngFor="let item of items"
        >
          {{ item.name }}
        </button>
      </ng-container>
    </ng-container>
  </ts-select>

  <ts-multiselect
    *ngSwitchCase="'multiSelect'"
    [identityMatcher]="recordIdentityMatcher"
    [stringify]="recordStringify"
    [removable]="true"
    [shortChipsDisplay]="true"
    [formControlName]="filter.target"
    [placeholder]="filter.name"
  >
    <ts-datalist *ngIf="filter.source === 'api'">
      <ts-entity-list
        [multi]="true"
        [queryFn]="filterValuesQueryFromApiFn(filter.target)"
      />
    </ts-datalist>
    <ng-container *ngIf="filter.source === 'basedata'">
      <ng-container *ngIf="filterValuesFromBaseDataFn(filter.target!)|async as items">
        <ts-datalist>
          <button
            [tsSelectOption]="item"
            class="dropdown-item"
            [multi]="true"
            *ngFor="let item of items"
          >
            {{ item.name }}
          </button>
        </ts-datalist>
      </ng-container>
    </ng-container>
  </ts-multiselect>
</ng-container>
