import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { DotLoaderComponent } from '@topseller/ui/dot-loader';
import { TsScrollModule } from '@topseller/ui/scroll';
import { TsSelectOptionModule } from '@topseller/ui/select-option';

import { TsEntityListComponent } from './entity-list.component';
import { TsDataScrollComponent } from '../data-scroll';
import { TsEntityListDirective } from './entity-list.directive';
import { TsAddItemDirective } from './add-item.directive';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DotLoaderComponent,
    TsScrollModule,
    TsSelectOptionModule,
  ],
  declarations: [
    TsEntityListComponent,
    TsDataScrollComponent,
    TsEntityListDirective,
    TsAddItemDirective,
  ],
  exports: [
    TsEntityListComponent,
    TsEntityListDirective,
    TsDataScrollComponent,
    TsAddItemDirective,
  ],
  providers: [],
})
export class TsEntityListModule {}
