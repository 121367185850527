import { createReducer, on } from '@ngrx/store';

import { LinkedDocument } from '../../../../data';

import {
  clearLinkedDocuments,
  setLinkedDocuments,
} from './linked-documents.actions';

export interface LinkedDocumentState {
  isLoading: boolean;
  items: LinkedDocument | null;
}

export const initialState: LinkedDocumentState = {
  isLoading: false,
  items: null,
};

export const LINKED_DOCUMENTS_FEATURE_KEY = 'linked-documents';

export const linkedDocumentsReducer = createReducer(
  initialState,
  on(setLinkedDocuments, (state, { items }) => ({
    ...state,
    items,
  })),
  on(clearLinkedDocuments, () => initialState)
);
