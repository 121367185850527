<ts-dropdown [content]="actionsTemplate" [isDisabledBtnAction]="isDisabledBtnAction"
             (changeVisible)="setIsExpanded($event)" #actionsDropdown>
  <button
    type="button"
    class="ts-btn btn-grey ts-btn-bezeled ts-btn-md ts-btn-icon w-100"
    [ngClass]="isDisabledBtnAction ? 'ts-btn disabled' : ''"
  >
    {{ label }}
    <i
      class="ts-icon"
      [ngClass]="isExpanded ? 'ts-icon-arrow-Expand_up' : 'ts-icon-arrow-Expand_down'"
    ></i>
  </button>
</ts-dropdown>
<ng-template #actionsTemplate>
  <ts-role-access-wrapper *ngFor="let action of actions" [requiredRole]="action.requiredRole">
    <button type="button" class="dropdown-item" (click)="onClick(action); actionsDropdown.updateOpen(false)">
      {{action.titleFn ? action.titleFn() : action.title}}
    </button>
  </ts-role-access-wrapper>
</ng-template>

