import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { HubEntity, LinkedDocument } from '../../../data';

import { BaseLinkedDocumentsService } from './linked-documents.service';
import {
  clearLinkedDocuments,
  loadLinkedDocuments,
} from './store/linked-documents.actions';
import {
  LinkedDocumentEntity,
  TsLinkedDocuments,
} from './linked-documents.type';
import { getEntityLink } from "../../utils/get-entity-link";

@Component({
  selector: 'ts-linked-documents',
  templateUrl: './linked-documents.component.html',
  styleUrls: ['./linked-documents.component.scss'],
})
export class LinkedDocumentsComponent implements OnInit, OnDestroy {
  @Input() public entityId!: string;
  @Input() public linkedDocumentEntity!: LinkedDocumentEntity;
  @Input() public entityName!: HubEntity;
  @Input() public mapper?: (data: LinkedDocument) => TsLinkedDocuments;

  public linkedDocuments$?: Observable<TsLinkedDocuments>;

  constructor(
    private readonly linkedDocumentsService: BaseLinkedDocumentsService,
    private readonly router: Router,
    private store: Store
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(
      loadLinkedDocuments({ id: this.entityId, entity: this.entityName })
    );

    this.linkedDocuments$ = this.linkedDocumentsService.getLinkedDocuments(
      this.mapper
    );
  }

  public ngOnDestroy(): void {
    this.store.dispatch(clearLinkedDocuments());
  }

  public onClick({ entity, id }: { entity: HubEntity; id: string }): void {
    const entityLink = getEntityLink(entity);

    if (entityLink) {
      this.router.navigate([entityLink, id], {
        state: { referrer: this.router.url },
      });
    }
  }
}
