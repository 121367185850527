import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import { SelectedProductLine } from "./catalog.data";

@Injectable()
export class SelectedProductsService {
  private _selectedProducts$ = new BehaviorSubject<SelectedProductLine[]>([]);

  public selectedProducts$ = this._selectedProducts$.asObservable();

  public setProducts(products: SelectedProductLine[]): void {
    this._selectedProducts$.next(products);
  }

  public getProducts(): SelectedProductLine[] {
    return this._selectedProducts$.getValue();
  }
}
