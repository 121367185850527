import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {InputNumberModule} from "../input-number";
import {InputCurrencyComponent} from "./input-currency.component";
import { BaseInputModule } from "../base-input";
import { TsCustomContentComponent } from "../common";
import { NgxMaskDirective } from "ngx-mask";

@NgModule({
  declarations: [InputCurrencyComponent],
  imports: [CommonModule, FormsModule, InputNumberModule, BaseInputModule, TsCustomContentComponent, NgxMaskDirective],
  exports: [InputCurrencyComponent],
})
export class InputCurrencyModule {}
