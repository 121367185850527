import { TsMonth } from './month';
import { TsYear } from './year';
import { normalizeToIntNumber } from './utils';

export class TsDay extends TsMonth {
  public get formattedDay(): string {
    return String(this.day).padStart(2, `0`);
  }

  public get formattedDate(): string {
    return `${this.formattedDay}.${this.formattedMonth}.${this.formattedYear}`;
  }

  constructor(year: number, month: number, readonly day: number) {
    super(year, month);
  }

  protected static normalizeDay(
    day: number,
    month: number,
    year: number
  ): number {
    const monthDaysCount = TsMonth.getMonthDaysCount(
      month,
      TsYear.isLeapYear(year)
    );

    return normalizeToIntNumber(day, 1, monthDaysCount);
  }

  public static currentLocal(): TsDay {
    const nativeDate = new Date();
    const year = nativeDate.getFullYear();
    const month = nativeDate.getMonth();
    const day = nativeDate.getDate();

    return new TsDay(year, month, day);
  }

  public static normalizeOf(year: number, month: number, day: number): TsDay {
    const normalizedYear = TsYear.normalizeYear(year);
    const normalizedMonth = TsMonth.normalizeMonth(month);
    const normalizedDay = TsDay.normalizeDay(
      day,
      normalizedMonth,
      normalizedYear
    );

    return new TsDay(normalizedYear, normalizedMonth, normalizedDay);
  }

  public static normalizeParse(rawDate: string): TsDay {
    const { day, month, year } = this.parseRawDateString(rawDate);
    return TsDay.normalizeOf(year, month, day);
  }

  public static parseRawDateString(date: string): {
    day: number;
    month: number;
    year: number;
  } {
    return {
      day: parseInt(date.slice(8, 10), 10),
      month: parseInt(date.slice(5, 7), 10) - 1,
      year: parseInt(date.slice(0, 4), 10),
    };
  }

  public daySame(another: TsDay): boolean {
    return this.monthSame(another) && this.day === another.day;
  }

  public dayLimit(min: TsDay | null, max: TsDay | null): TsDay {
    if (min !== null && this.dayBefore(min)) {
      return min;
    }

    if (max !== null && this.dayAfter(max)) {
      return max;
    }

    return this;
  }

  public dayBefore(another: TsDay): boolean {
    return (
      this.monthBefore(another) ||
      (this.monthSame(another) && this.day < another.day)
    );
  }

  public dayAfter(another: TsDay): boolean {
    return (
      this.monthAfter(another) ||
      (this.monthSame(another) && this.day > another.day)
    );
  }

  public override toString(): string {
    return this.formattedDate;
  }
}
