import { ChangeDetectorRef, Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs';

import { OrganizationService } from '../../data';
import { LegalFormsEnum } from '@topseller/common/legal-form';

import { ContactPerson } from '../../data';
import { BaseItemInfoComponent } from './base-item-info.component';
import { Store } from "@ngrx/store";
import { SettlementAccount } from "@topseller/core";

export interface CompanyForm {
  inn: FormControl<string>;
  legalForm: FormControl<LegalFormsEnum>;
  settlementAccounts: FormArray;
  contactPersons?: FormArray;
}

export abstract class BaseInnItemComponent extends BaseItemInfoComponent {
  public legalFormObject = [LegalFormsEnum.COMPANY, LegalFormsEnum.PRIVATE];

  public get accountControls(): FormGroup[] {
    return (this.form.get('settlementAccounts') as FormArray)
      .controls as FormGroup[];
  }

  public get contactControls(): FormGroup[] {
    return (this.form.get('contactPersons')  as FormArray)
      .controls as FormGroup[];
  }

  public get legalForm() {
    return this.form.value.legalForm;
  }

  constructor(
    activatedRoute: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    changeDetectorRef: ChangeDetectorRef,
    matDialog: MatDialog,
    store: Store,
    protected readonly organizationService: OrganizationService
  ) {
    super(activatedRoute, toastr, router, changeDetectorRef, matDialog, store);
  }

  public abstract form: FormGroup<CompanyForm>;

  public override getResolvedData(
    form: FormGroup,
    itemName: string,
    settlementAccountsHandler?: (result: SettlementAccount[]) => void,
    contactPersonsHandler?: (result: ContactPerson[]) => void
  ): void {
    this.activatedRoute.data.subscribe((data) => {
      const item = data[itemName];
      if (!item) {
        this.showHeaderBtns$.next(false);
      } else {
        this.itemId$.next(item.id);
        this.showHeaderBtns$.next(true);
        for (const key in item) {
          if (form.controls[key]) {
            if (key === 'settlementAccounts') {
              if (settlementAccountsHandler) {
                settlementAccountsHandler(item[key]);
              }
            } else if (key === 'contactPersons') {
              if (contactPersonsHandler) {
                contactPersonsHandler(item[key]);
              }
            } else {
              form.controls[key].setValue(item[key]);
            }
          }
        }
      }
    });
  }

  public fillByInn(form: FormGroup) {
    const inn = this.form.get('inn')?.value;
    const legalForm = this.form.get('legalForm')?.value;

    if (inn) {
      this.innLoading$.next(true);
      this.organizationService
        .getAppOrganizationFindbyinn(inn)
        .pipe(take(1))
        .subscribe({
          next: (data) => {
            this.innLoading$.next(false);
            for (const key in data) {
              if (form.controls[key]) {
                form.controls[key].setValue(data[key as keyof typeof data]);
              }
            }
            this.changeDetectorRef.detectChanges();
          },
          error: (err) => {
            this.innLoading$.next(false);
            this.toastr.error(
              err && err.error
                ? err.error
                : 'По указанному ИНН ничего не найдено'
            );
            this.changeDetectorRef.detectChanges();
          },
        });
    } else {
      this.innFill$.next(false);
    }
  }

  onCounterpartySelectorChange(form: FormGroup): void {
    if (this.legalForm === LegalFormsEnum.COMPANY) {
      form
        .get('inn')
        ?.setValidators([Validators.minLength(10), Validators.maxLength(10)]);
      form
        .get('ogrn')
        ?.setValidators([Validators.minLength(13), Validators.maxLength(13)]);
      form
        .get('okpo')
        ?.setValidators([Validators.minLength(8), Validators.maxLength(8)]);
    } else {
      form
        .get('inn')
        ?.setValidators([Validators.minLength(12), Validators.maxLength(12)]);
      form
        .get('ogrn')
        ?.setValidators([Validators.minLength(15), Validators.maxLength(15)]);
      form
        .get('okpo')
        ?.setValidators([Validators.minLength(10), Validators.maxLength(10)]);
    }
  }
}
