import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationInstance } from '../base-list';

const DOTS_LENGTH = 1;
const ACTIVE_ITEM_LENGTH = 1;

type HoryzontalDirection = 'left' | 'right';

function clamp(value: number, min: number, max: number): number {
  return Math.min(max, Math.max(min, value));
}

export function horizontalDirectionToNumber(
  direction: HoryzontalDirection
): -1 | 1 {
  switch (direction) {
    case 'left':
      return -1;
    case 'right':
      return 1;
  }
}

@Component({
  selector: 'ts-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsPaginatorComponent {
  private itemsPerPage = 0;

  @Input({ required: true }) set pagination({
    itemsPerPage,
    currentPage,
    totalItems,
  }: PaginationInstance) {
    this.totalItems = totalItems;
    this.index = currentPage;
    this.itemsPerPage = itemsPerPage;
  }

  @Input()
  public size: 's' | 'l' = 's';

  @Input()
  public activePadding = 1;

  @Input()
  public sidePadding = 1;

  public index = 0;
  public total = 500;
  public totalItems = 1;

  @Output()
  public readonly pageChange = new EventEmitter<number>();

  private get lastIndex(): number {
    return this.totalPages - 1;
  }

  private get totalPages(): number {
    return this.itemsPerPage
      ? Math.ceil(this.totalItems / this.itemsPerPage)
      : 1;
  }

  private get lastElementIndex(): number {
    return this.elementsLength - 1;
  }

  private get maxHalfLength(): number {
    return this.sidePadding + DOTS_LENGTH + this.activePadding;
  }

  private get maxElementsLength(): number {
    return this.maxHalfLength * 2 + ACTIVE_ITEM_LENGTH;
  }

  private get itemsFit(): boolean {
    return this.totalPages <= this.maxElementsLength;
  }

  private get reverseIndex(): number {
    return this.lastIndex - this.index;
  }

  public get canGoBack(): boolean {
    return !!this.index;
  }

  public get canGoNext(): boolean {
    return this.index < this.totalPages - 1;
  }

  public get lastItem(): number {
    const lastItem = this.totalItems
      ? this.index * this.itemsPerPage + this.itemsPerPage
      : 0;

    return lastItem > this.totalItems ? this.totalItems : lastItem;
  }

  public get elementsLength(): number {
    return this.itemsFit ? this.totalPages : this.maxElementsLength;
  }

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  public get firstItem(): number {
    return this.total ? this.index * this.itemsPerPage + 1 : 0;
  }

  public onArrowClick(direction: HoryzontalDirection): void {
    this.tryChangeTo(direction);
  }

  public onElementClick(index: number): void {
    this.updateIndex(index);
  }

  protected getElementMode(index: number): string {
    return this.index === index ? 'ts-btn-bezeled primary' : 'ts-flat';
  }

  public getItemIndexByElementIndex(elementIndex: number): number | null {
    if (elementIndex < this.sidePadding) {
      return elementIndex;
    }

    if (
      elementIndex === this.sidePadding &&
      this.hasCollapsedItems(this.index)
    ) {
      return null;
    }

    const reverseElementIndex = this.lastElementIndex - elementIndex;

    if (
      reverseElementIndex === this.sidePadding &&
      this.hasCollapsedItems(this.reverseIndex)
    ) {
      return null;
    }

    if (reverseElementIndex < this.sidePadding) {
      return this.lastIndex - reverseElementIndex;
    }

    const computedIndex = this.index - this.maxHalfLength + elementIndex;

    return clamp(
      computedIndex,
      elementIndex,
      this.lastIndex - reverseElementIndex
    );
  }

  private hasCollapsedItems(index: number): boolean {
    return !this.itemsFit && index > this.maxHalfLength;
  }

  private tryChangeTo(direction: HoryzontalDirection): void {
    this.updateIndex(
      clamp(
        this.index + horizontalDirectionToNumber(direction),
        0,
        this.lastIndex
      )
    );
  }

  private updateIndex(index: number): void {
    if (this.index === index) {
      return;
    }

    this.index = index;
    this.pageChange.emit(index);
    this.onPageChange(index);
  }

  private onPageChange(page: number): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: page ? page + 1 : null,
      },
      queryParamsHandling: 'merge',
    });
  }
}
