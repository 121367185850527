import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'ts-dot-loader',
  template: `
    <div class="stage">
      <div class="dot-pulse"></div>
    </div>
  `,
  styleUrls: ['./dot-loader.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class DotLoaderComponent {}
