import {DropdownButtonComponent} from "./dropdown-button.component";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {TsDropdownModule} from "@topseller/ui//dropdown";
import {ActiveZoneModule} from "@topseller/ui/active-zone";


@NgModule({
    declarations: [DropdownButtonComponent],
    imports: [CommonModule, TsDropdownModule,ActiveZoneModule],
    providers: [],
    exports: [DropdownButtonComponent],
})
export class DropdownButtonModule {
}
