import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsAbstractControl } from "@topseller/cdk/abstract";
import { FormsModule } from "@angular/forms";
import { BaseInputModule } from '../base-input';

@Component({
  selector: 'ts-input-link',
  standalone: true,
  imports: [CommonModule, BaseInputModule, FormsModule],
  templateUrl: './input-link.component.html',
  styleUrls: ['./input-link.component.scss'],
})
export class InputLinkComponent extends TsAbstractControl<string | null> implements OnInit {

  mode: 'view' | 'edit' = 'edit';
  @Input() public label = '';
  @Input() public placeholder = '';
  @Input() public labelInside = false;
  @Output() valueChange = new EventEmitter<string>();
  @Input() public readonly = false;


  get canSave(): boolean {
    if (this.value) {
      return this.value.length > 0;
    }
    return false;
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.value) {
        this.mode = "view";
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  saveValue() {
    if (this.canSave) {
      if (!this.value?.startsWith('https://') && !this.value?.startsWith('http://')) {
        this.changeValue(`https://${this.value}`);
      }
      this.mode = 'view';
    }
  }

  changeValue($event: string) {
    this.valueChange.emit($event);
  }

  editValue() {
    this.mode = 'edit';
  }

  handleFocusChange(focusIn: boolean) {
    if (!focusIn) {
      this.saveValue();
    }
  }

  protected getFallbackValue(): string | null {
    return null;
  }
}
