<div class="ts-textarea">
  <textarea
    #textarea
    [ngModel]="value"
    (ngModelChange)="onModelChange($event)"
    [readOnly]="readonly"
    [placeholder]="placeholder"
    spellcheck="true"
    ts-autoresize-textarea
  ></textarea>
</div>
