<div
  class="btn btn-custom"
  #parent="activeZone"
  (activeZoneChange)="onActiveZone($event)"
  [dropdown]="dropdownTemplate"
  [show]="isVisible"
  [class.disabled]="disabled"
>
  <div>{{ computedValue || placeholder }}</div>
  <span style="margin-left: auto">
    <svg id="check">
      <use xlink:href="/assets/img/icons/chevron-down.svg#main"></use>
    </svg>
  </span>
</div>

<ng-template #dropdownTemplate>
  <div [activeZoneParent]="parent">
    <ng-content></ng-content>
  </div>
</ng-template>
