import { DocumentItemsTableTotalService } from "./document-items-table-total.service";
import { Injectable } from "@angular/core";
import { ExpenseTableLine } from "../types";
import { TotalCalculationInput, TotalLine, Totals } from "../interfaces";

@Injectable()
export class DocumentExpensesTotalService implements DocumentItemsTableTotalService<ExpenseTableLine>{
  getTotals(value: TotalCalculationInput<ExpenseTableLine>): TotalLine[] {
    const {total} = this.calculateTotals(value.items);
    return [
      {title: 'Сумма расходов, ₽', value: total!, currencyFormat: true},
    ]
  }

  protected calculateTotals = (value: ExpenseTableLine[]): Totals => {
    return value.reduce(
      (acc: Totals, curr: ExpenseTableLine): Totals => {
        const {total = 0} = acc;
        return {
          total: total + curr.amount
        };
      },
      {total: 0}
    );
  };
}
