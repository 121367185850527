import { createAction, createFeatureSelector, createSelector } from '@ngrx/store';
import { WarehouseGroup } from "../../../../data/model/warehouseGroup";
import { WAREHOUSE_GROUPS_FEATURE_KEY, WarehouseGroupState } from "./warehouse-groups.reducer";

export const selectWarehouseGroupsFeature =
    createFeatureSelector<WarehouseGroupState>(WAREHOUSE_GROUPS_FEATURE_KEY);

export const selectWarehouseGroups = createSelector(
    selectWarehouseGroupsFeature,
    ({ items }: WarehouseGroupState) => items as WarehouseGroup[]
);

// export const selectWarehouseById = (id: string) =>  createAction(
//         selectWarehouseGroupsFeature,
//         ({ item }: WarehouseGroupState) => item?.find((el) => el.id === id)
//     )

export const selectWarehouseById = (id: string) => {
    return  createSelector(
        selectWarehouseGroupsFeature,
        ({ items }: WarehouseGroupState) => items.find( (x) => x.id === id)!
    );
}
