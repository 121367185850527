<div class="batch-selector">
  <ng-container *ngIf="showBatchSelection && batchSelectionConfig && batchSelectionConfig.selectedItemsCount">
    <div class="d-flex align-items-center gap-2 ts-control-medium">
      <span class="ts-text-secondary">Выбрано: </span>
      <span class="ts-text-success">{{ batchSelectionConfig.selectedItemsCount }}</span>
      <button class="ts-btn primary ts-btn-sm ts-btn-filled" *ngIf="!batchSelectionConfig.allSelected" (click)="batchSelectionConfig.selectAllFn()">Выбрать всё</button>
      <button class="ts-btn btn-grey ts-btn-bezeled ts-btn-sm" *ngIf="batchSelectionConfig.allSelected" (click)="batchSelectionConfig.deselectAllFn()">Снять выделение</button>
    </div>
  </ng-container>
</div>
<ts-paginator *ngIf="pagination" [pagination]="pagination" size="l"/>
<div class="items-count">
  Отображать строк:
  <ts-dropdown [content]="count">
    <button class="items-count--dropdown" type="button">
      {{ limit$ | async }}
    </button>
  </ts-dropdown>
  <ng-template #count>
    <ul class="items-count--list">
      <li
        class="items-count--list__item"
        *ngFor="let count of [20, 50, 100]"
        dropdownOption
        (click)="onCountChange(count)"
      >
        {{ count }}
      </li>
    </ul>
  </ng-template>
</div>
