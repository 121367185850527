import { KeyboardActionExctractor } from '@topseller/cdk/keyboard';
import * as Keyboard from '@topseller/cdk/keyboard/identifiers';

export const commaAliases = [
  Keyboard.isCodeComma,
  Keyboard.isCodePeriod,
  Keyboard.isCodeSlash,
  Keyboard.isCodeBackslash,
  Keyboard.isCodeIntlBackslash,
  Keyboard.isCodeNumpadDecimal,
];

export const INPUT_NUMBER_ACTIONS = {
  Unknown: 0,
  Ignore: 1,
  MoveCursorLeft: 11,
  MoveCursorRight: 12,
  Home: 13,
  End: 14,
  ExtendSelectionLeft: 21,
  ExtendSelectionRight: 22,
  FullSelection: 23,
  ExtendSelectionToStart: 24,
  ExtendSelectionToEnd: 25,
  Backspace: 31,
  Delete: 32,
  Submit: 33,
  Comma: 34,
};

export const extractAction = new KeyboardActionExctractor()
  .add(INPUT_NUMBER_ACTIONS.Submit, Keyboard.isKeyEnter)
  .add(
    INPUT_NUMBER_ACTIONS.ExtendSelectionLeft,
    Keyboard.isModShift(Keyboard.isKeyArrowLeft)
  )
  .add(
    INPUT_NUMBER_ACTIONS.ExtendSelectionRight,
    Keyboard.isModShift(Keyboard.isKeyArrowRight)
  )
  .add(INPUT_NUMBER_ACTIONS.FullSelection, Keyboard.isShortcutSelectAll)
  .add(
    INPUT_NUMBER_ACTIONS.ExtendSelectionToStart,
    Keyboard.isModShift(Keyboard.isKeyHome)
  )
  .add(
    INPUT_NUMBER_ACTIONS.ExtendSelectionToEnd,
    Keyboard.isModShift(Keyboard.isKeyEnd)
  )
  .add(INPUT_NUMBER_ACTIONS.MoveCursorLeft, Keyboard.isKeyArrowLeft)
  .add(INPUT_NUMBER_ACTIONS.MoveCursorRight, Keyboard.isKeyArrowRight)
  .add(INPUT_NUMBER_ACTIONS.Home, Keyboard.isUnmodified(Keyboard.isKeyHome))
  .add(INPUT_NUMBER_ACTIONS.End, Keyboard.isUnmodified(Keyboard.isKeyEnd))
  .add(INPUT_NUMBER_ACTIONS.Backspace, Keyboard.isKeyBackspace)
  .add(INPUT_NUMBER_ACTIONS.Delete, Keyboard.isKeyDelete)
  .add(
    INPUT_NUMBER_ACTIONS.Ignore,
    (e) => Keyboard.isModified()(e) || Keyboard.isKeyTab(e)
  )
  .add(INPUT_NUMBER_ACTIONS.Comma, Keyboard.someKeys(...commaAliases))
  .build(INPUT_NUMBER_ACTIONS.Unknown);
