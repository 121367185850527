import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActionItem, TableHeader } from "@topseller/ui";
import { DataItemToTableValueMapper } from "@topseller/common";
import { SelectedProductsService } from "../selectedProducts.service";
import { FormControl } from "@angular/forms";
import { debounceTime, map, Observable, startWith, Subject, switchMap, takeUntil, tap } from "rxjs";
import { SelectedProductLine } from "../catalog.data";
import { MAX_ITEMS_COUNT } from "@topseller/core";

@Component({
  selector: 'ts-selected-products',
  templateUrl: './selected-products.component.html',
  styleUrls: ['./selected-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectedProductsComponent implements OnInit, OnDestroy {

  maxItemsCount = MAX_ITEMS_COUNT;

  @Input() tableHeaders: TableHeader[] = [];
  @Input() selectedProducts: SelectedProductLine[] = [];
  public productsToDisplay$: Observable<SelectedProductLine[]>;
  @Output() changeView = new EventEmitter();
  @Output() closeDialog = new EventEmitter();
  @Output() addSelected = new EventEmitter();
  selectedProductsIds: string[] = [];
  destroy$ = new Subject<void>();
  search: FormControl = new FormControl(null);
  actions: ActionItem[] = [
    {
      title: 'Удалить выбранные',
      action: () => this.deleteSelected()
    }
  ];
  private productsToDisplay: SelectedProductLine[] = [];

  constructor(public dataItemToTableValueMapper: DataItemToTableValueMapper,
              private selectedProductsService: SelectedProductsService) {
    this.productsToDisplay$ = this.search.valueChanges.pipe(
      startWith(''),
      map((search: string) => search.toLowerCase()),
      debounceTime(300),
      takeUntil(this.destroy$),
      // tap(() => (this.selectedProductsIds = [])),
      switchMap((search: string) =>
        this.selectedProductsService.selectedProducts$.pipe(
          map((selectedProducts: SelectedProductLine[]) =>
            selectedProducts.filter((item) => {
              const {name = '', sku = ''} = item.product;
              return (
                name.toLowerCase().includes(search) ||
                sku.toLowerCase().includes(search)
              );
            })
          )
        )
      ),
      tap(products => this.productsToDisplay = products)
    );
  }

  public get allSelected(): boolean {
    return !!(
      this.productsToDisplay.length &&
      this.productsToDisplay.length === this.selectedProductsIds.length
    );
  }

  ngOnInit() {
    this.productsToDisplay = this.selectedProducts;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public isSelectedProduct(id: string): boolean {
    return this.selectedProductsIds.includes(id);
  }

  public selectProduct(value: boolean, id: string): void {
    const index = this.selectedProductsIds.indexOf(id);

    if (!value && index > -1) {
      this.selectedProductsIds.splice(index, 1);
    } else if (value && index === -1) {
      this.selectedProductsIds.push(id);
    }
  }

  public toggleAll(value: boolean): void {
    if (!value) {
      this.selectedProductsIds = [];
    } else {
      this.selectedProductsIds = this.productsToDisplay.map(x => x.product.id);
    }
  }

  isDisabled(item: any): boolean {
    return item.product.isArchived;
  }

  apply() {
    this.addSelected.emit();
  }

  public deleteSelected() {
    const products = this.selectedProducts.filter(item => !this.selectedProductsIds.includes(item.product.id));
    this.selectedProducts = products;
    this.selectedProductsService.setProducts(products);
    this.search.setValue('');
    this.selectedProductsIds = [];
  }
}
