import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SidebarActionsDirective } from './directives/sidebar-actions.directive';
import { SidebarTitleDirective } from './directives/sidebar-title.directive';
import { SidebarOutletComponent } from './sidebar-outlet.component';
import { SidebarComponent } from './sidebar.component';
import { SidebarService } from './sidebar.service';
import { TsScrollModule } from "@topseller/ui/scroll";

const DECLARATION = [
  SidebarComponent,
  SidebarOutletComponent,
  SidebarTitleDirective,
  SidebarActionsDirective,
];

@NgModule({
    imports: [CommonModule, TsScrollModule],
  declarations: [...DECLARATION],
  exports: [...DECLARATION],
  providers: [SidebarService],
})
export class SidebarModule {}
