import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Configuration } from "../configuration";
import { TemplateRequest } from "../model/templateRequest";
import { BASE_PATH } from "../variables";

@Injectable()
export class CommonBatchActionsApiService {
  protected basePath = 'http://denis.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  public template(apiEntityName: string, template: TemplateRequest, filter: string | null) {
    let url = `${this.basePath}/api/v1/${apiEntityName}/template`;
    const body: Partial<TemplateRequest> = {template: template.template};

    if (filter) {
      url += `?${filter}`;
    } else {
      body.itemsId = template.itemsId;
    }

    return this.httpClient.patch(url, body);
  }
}
