<div class="anchor" *ngIf="links && links.length !== 0">
  <span
    [tsScrollAnchor]="link.id"
    class="anchor__item"
    *ngFor="let link of links"
    [ngClass]="{ 'active-anchor': active_link === link.title }"
    (click)="changeActiveLink(link.title)"
  >
    {{ link.title }}
  </span>
</div>
