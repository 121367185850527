import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component,
  Inject,
  Input,
  Optional
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BaseListComponent, ListOptions} from '@topseller/common/base-list';
import {ToastrService} from 'ngx-toastr';
import { EMPTY, of, switchMap } from 'rxjs';
import { HubEntity, ItemRolesConfig } from '../../../data';
import {
  FileService,
  File as AttachedFile,
  ChangeableRecord,
  allowedFilesFormats,
  ConfirmDialogComponent, AuthService
} from '@topseller/core';
import { EDIT_ITEM_ROLES_CONFIG, ENTITY_NAME } from '../../tokens';
import {fileHeaders} from './files-block.mock';
import {TableHeader} from "@topseller/ui";
import {PaginatedFile} from "@topseller/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'ts-files-block',
  templateUrl: './files-block.component.html',
  styleUrls: ['./files-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: BaseListComponent, useExisting: FilesBlockComponent}]
})
export class FilesBlockComponent extends BaseListComponent<AttachedFile> {
  public tableHeaders: TableHeader[] = fileHeaders;

  public formats = allowedFilesFormats;

  @Input() public entityId?: string;
  @Input() public entityType: string = 'payment';

  constructor(
    @Optional() @Inject(ENTITY_NAME) private readonly entityName: HubEntity,
    private fileService: FileService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    @Inject(EDIT_ITEM_ROLES_CONFIG) public editItemRoles:  ItemRolesConfig,
    private authService: AuthService
  ) {
    super(activatedRoute);
    this._checkEntity();
    this._superSetQueryFn();
  }

  public deleteFile(file: AttachedFile): void {
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(
      ConfirmDialogComponent,
      {
        data: {
          title: 'Удаление файла',
          content: `Вы уверены что хотите удалить файл "${file.originalName}" ?`,
        },
        backdropClass: 'ts-modal-backdrop',
        panelClass: ['ts-modal-panel','ts-modal-panel-lg' ],
        width: '470px',
        id: 'ts-modal',
      }
    );

    dialogRef
      .afterClosed()
      .pipe(
        switchMap((result: boolean) => {
          return result
            ? this.fileService.deleteAppFileDelete(file.id)
            : EMPTY;
        })
      ).subscribe({
        next: () => {
          this.toastr.success('Файл успешно удалён');
          this.items = this.items?.filter((item: PaginatedFile) => item?.id !== file.id);
          this.changeDetectorRef.detectChanges();
        },
        error: (err: any) => {
          this.toastr.error(
            err?.errors?.length && err.errors[0].message
              ? err.errors[0].message
              : err?.message || 'Что-то пошло не так'
          );
        },
      });
  }

  private _checkEntity(): void {
    if (this.entityName) {
      return;
    }

    throw new Error('Entity name for comments shoud be defined!');
  }

  private _superSetQueryFn(): void {
    const listQueryFn = ({limit, offset = 0, search}: ListOptions) => {
      // Проверяем наличие роли перед запросом к API
      return this.authService.employeeHasRole(this.editItemRoles.filesViewRole).pipe(
        switchMap(hasRole => {
          // Если роль есть, выполняем запрос к API
          if (hasRole) {
            return this.fileService.getAppFileIndex(limit, offset, search, {
              entity: this.entityName,
              entityId: this.entityId || ''
            });
          }
          // Если роли нет, возвращаем пустой массив
          else {
            return of({items:[], pagination:{total:0}});
          }
        })
      );
    };

    super.setQueryFn(listQueryFn);
  }

  toChangeableRecord(file: AttachedFile): ChangeableRecord {
    return {
      id: file?.id,
      modifiedBy: file?.createdBy,
      modifiedAt: file.createdAt?.toString(),
      name: file.originalName ?? ''
    };
  }

  public updateItems(files:AttachedFile[]){
    this.items = files;
    this.changeDetectorRef.detectChanges();
  }
}
