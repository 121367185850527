import {
  ChangeDetectionStrategy,
  Component,
  ContentChild, EventEmitter,
  HostBinding,
  Input, Output,
} from '@angular/core';
import {TsDrawerContentDirective} from './drawer-content.directive';
import {drawerAnimation} from './drawer.animations';

@Component({
  selector: 'ts-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [drawerAnimation],
})
export class TsDrawerComponent {
  @Input()
  @HostBinding('class.expanded')
  public expanded = true;

  @Output() expandedChanged = new EventEmitter();

  @Input() expandIconType: 'round' | 'flat' = 'round';
  @Input() expandIconPosition: 'left' | 'right' = 'right';

  @ContentChild(TsDrawerContentDirective)
  readonly eagerContent?: TsDrawerContentDirective;

  public get contentVisible(): boolean {
    return this.expanded;
  }

  public onToggle(): void {
    this.expanded = !this.expanded;
    this.expandedChanged.emit(this.expanded);
  }
}
