import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter, HostBinding,
  HostListener,
  Input,
  Optional, Output,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { TsAbstractControl } from '@topseller/cdk/abstract';

import { TsDay, TsMonth, TsTime } from '../common';

type Maybe<T> = T | null;

const DATE_TIME_SEPARATOR = ' ';

@Component({
  selector: 'ts-input-date-time',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputDateTimeComponent extends TsAbstractControl<
  [Maybe<TsDay>, Maybe<TsTime>]
> {
  @Input() label = '';
  @Input() cleaner = true;
  @Input() defaultTime?:TsTime;
  public isVisible = false;
  @ViewChild('input', {read: ElementRef, static: true})
  public input?: ElementRef<HTMLInputElement>;
  private initialMonth = TsMonth.currentMonth();

  @Output() valueChange = new EventEmitter<string>();

  constructor(
    @Self() @Optional() ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(ngControl, changeDetectorRef);
  }

  @HostBinding('class.disabled')
  @Input()
  public readonly = false;

  public get computedMonth(): TsMonth {
    const [day] = this.value || [];
    return day || this.initialMonth;
  }

  public get nativeValue(): string {
    return this.input?.nativeElement.value || ``;
  }

  public get computedValue(): string {
    const {value, nativeValue} = this;
    const [date, time] = value;

    if (!date || !time) {
      return nativeValue;
    }
    return this.getDateTimeString(date, time);
  }

  @HostListener('click')
  public onClick() {
    if (this.isVisible) {
      return;
    }
    if(!this.disabled) {
      this.isVisible = true;
      this.input?.nativeElement.focus();
    }
  }

  public override writeValue(rawValue: any): void {
    const value = this.fromControlValue(rawValue);
    super.writeValue(value);
  }

  public override registerOnChange(
    onChange: (value: [Maybe<TsDay>, Maybe<TsTime>] | unknown) => void
  ): void {
    this.onChange = (componentValue: [Maybe<TsDay>, Maybe<TsTime>]) => {
      onChange(this.toControlValue(componentValue));
    };
  }

  public onFocused(): void {
    const [, time] = this.nativeValue.split(' ');
    if (!time) {
      return;
    }
    const parsedTime = TsTime.fromString(time);
  }

  public onValueChange(value: string): void {
    this.updateOpen(false);

    if (value.length < 'dd.MM.yyyy'.length) {
      this.updateValue([null, null]);
      return;
    }

    const [date, time] = value.split(DATE_TIME_SEPARATOR);

    const parsedDate = TsDay.normalizeParse(date);
    const parsedTime =
      time && time.length === 'HH:mm:YYYY'.length
        ? TsTime.fromString(time)
        : null;

    this.updateValue([parsedDate, parsedTime]);
  }

  public onActiveZone(active: boolean) {
    if (!active && this.isVisible) {
      this.isVisible = false;
    }
  }

  public onDayTimeSelect(value: [TsDay | null, TsTime | null] | null): void {
    if (value) {
      const [day, time] = value;
      this.updateValue([day, time || TsTime.fromString('00:00:00')]);
      this.valueChange.emit(this.getDateTimeString(day as TsDay, time as TsTime));
    }
    this.isVisible = false;
    this.changeDetectorRef.markForCheck();
  }

  public clear(): void {
    this.updateValue([null, null]);
  }

  protected getFallbackValue(): [TsDay | null, TsTime | null] {
    return [null, null];
  }

  private getDateTimeString(
    date: TsDay | string,
    time: TsTime | string
  ): string {
    const dateString = date instanceof TsDay ? date.toString() : date;
    const timeString =
      time instanceof TsTime ? time.toString() : time || '00:00:00';
    return `${dateString}${DATE_TIME_SEPARATOR}${timeString}`;
  }

  private fromControlValue(
    controlValue: string
  ): [Maybe<TsDay>, Maybe<TsTime>] {
    if (!controlValue) {
      return [null, this.defaultTime ?? null];
    }
    const dateObj = new Date(controlValue);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth();
    const day = dateObj.getDate();
    return day
      ? [
        TsDay.normalizeOf(year, month, day),
        TsTime.fromLocalNativeDate(dateObj),
      ]
      : [null, null];
  }

  private toControlValue([date, time]: [Maybe<TsDay>, Maybe<TsTime>]): string {
    if (date) {
      const {day, month, year} = date;
      const {hours = 0, minutes = 0, seconds = 0} = time || {};
      return new Date(year, month, day, hours, minutes, seconds).toISOString();
    }

    return '';
  }

  private updateOpen(value: boolean): void {
    if (this.isVisible === value) {
      return;
    }
    this.isVisible = value;
  }
}
