import { createReducer, on } from '@ngrx/store';
import { setCurrentProductInfo, setShowLoader, setTheme, toggleTheme } from './actions';
import { state } from "@angular/animations";

export interface CurrentProductInfo {
  currentTariffName?: string | null;
  currentProduct?: string | null;
  activeTillDate?: Date | null;
  product?: string | null;
}

export interface CommonState {
  showLoader: boolean;
  currentProductInfo?: CurrentProductInfo;
  currentTheme: string;
}

export const initialCommonState: CommonState = {
  showLoader: false,
  currentTheme: "light"
};

export const commonReducer = createReducer(
  initialCommonState,

  on(setShowLoader, (state, {showLoader}) => ({
    ...state,
    showLoader,
  })),

  on(setCurrentProductInfo, (state, {currentProductInfo}) => ({
    ...state,
    currentProductInfo,
  })),

  on(setTheme, (state, {theme}) => ({
    ...state,
    currentTheme: theme
  })),

  on(toggleTheme, (state) => {
    const isDark = state.currentTheme === 'dark';
    return {...state, currentTheme: isDark ? 'light' : 'dark'};
    }
  )
);
