import { Injectable } from '@angular/core';
import { catchError, EMPTY, exhaustMap, map, Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CommonBatchDeleteApiService, ConfirmDialogComponent } from "@topseller/core";
import { ToastrService } from "ngx-toastr";
import { tap } from "rxjs/operators";

export type DeleteModalConfig = {
  modalTitle?: string;
  modalConfirmMessage?: string;
  toasterSuccessText?: string;
  okBtn?: string;
  cancelBtn?: string;
}

@Injectable()
export class ListItemsDeleteService {
  constructor(
    protected matDialog: MatDialog,
    protected toastrService: ToastrService,
    private batchDeleteApiService: CommonBatchDeleteApiService
  ) {
  }

  public delete(config: DeleteModalConfig, apiEntityName: string, ids: string[], filter: string | null): Observable<void> {
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.matDialog.open(
      ConfirmDialogComponent,
      {
        data: {
          title: `${config.modalTitle ?? 'Удаление'}`,
          content: `${config.modalConfirmMessage ?? 'Вы уверены, что хотите удалить выбранные элементы?'}`,
          okBtn: `${config?.okBtn ?? 'Удалить'}`,
          cancelBtn: `${config?.cancelBtn ?? 'Отменить'}`
        },
        backdropClass: 'ts-modal-backdrop',
        panelClass: ['ts-modal-panel', 'ts-modal-panel-lg'],
        width: '470px',
        id: 'ts-modal',
      }
    );

    return dialogRef.afterClosed().pipe(
      exhaustMap((result: boolean) => {
        if (!result) {
          return EMPTY;
        }

        return this.batchDeleteApiService.batchDelete(apiEntityName, ids, filter).pipe(
          tap(() => {
            this.toastrService.success(`${config.toasterSuccessText ?? 'Удаление прошло успешно'}`);
          }),
          catchError((err: any) => {
            this.toastrService.error(
              err?.errors?.length && err.errors[0].message
                ? err.errors[0].message
                : err?.message || 'Что-то пошло не так'
            );
            return EMPTY; // Прерываем поток в случае ошибки
          }),
          map(() => undefined) // Преобразуем успешный результат в void
        );
      })
    );
  }
}
