import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { ChangeableRecord } from "@topseller/core";

@Component({
  selector: 'ts-modified-by-cell',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './modified-by-cell.component.html',
  styleUrls: ['./modified-by-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModifiedByCellComponent {
  @Input() changeableRecord!: ChangeableRecord;

  get modifiedBy() {
    return this.changeableRecord.modifiedBy;
  }
}
