import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ActiveZoneModule } from '../active-zone';

import { DropdownHostComponent } from './dropdown-host.component';
import { DropdownOptionDirective } from './dropdown-option.directive';
import { DropdownPositionDirective } from './dropdown-position.directive';
import { TsDropdownComponent } from './dropdown.component';
import { DropdownDirective } from './dropdown.directive';
import { TsDropdownListDirective } from './dropdown-list.directive';
import { TsDropdownHostedDirective } from './dropdown-hosted.directive';

const DECLARATIONS = [
  DropdownDirective,
  DropdownOptionDirective,
  DropdownPositionDirective,
  TsDropdownComponent,
  TsDropdownListDirective,
  TsDropdownHostedDirective,
];

@NgModule({
  declarations: [DropdownHostComponent, ...DECLARATIONS],
  imports: [CommonModule, ActiveZoneModule],
  exports: [...DECLARATIONS],
})
export class TsDropdownModule {}
