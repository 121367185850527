import { Directive, HostListener, Input } from '@angular/core';
import { ScrollManagerDirective } from './scroll-manager.directive';

@Directive({
    selector: '[tsScrollAnchor]',
})
export class ScrollAnchorDirective {
    @Input('tsScrollAnchor') id!: string | number;

    constructor(private manager: ScrollManagerDirective) { }

    @HostListener('click')
    scroll() {
        this.manager.scroll(this.id);
    }
}