import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[tsScrollbarWrapper]',
})
export class TsScrollbarWrapperDirective {
  public get nativeElement() {
    return this.elementRef.nativeElement;
  }

  constructor(private elementRef: ElementRef) {}
}
