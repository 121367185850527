import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BillingProduct, Configuration, Invoice, Paginated, PaymentHistoryItem, PaywallResponse } from "../model";
import { Observable } from "rxjs";
import { BASE_PATH } from "../tokens";
import { CustomHttpUrlEncodingCodec } from "../encoder";

@Injectable()
export class BillingApiService {
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = 'http://denis.dev.topseller.ru';

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  getBillingTariffList(id: string): Observable<PaywallResponse> {
    return this.httpClient.get<PaywallResponse>(`${this.basePath}billing/paywalls/identifier/${id}`);
  }

  makePayment(invoice: Invoice) {
    return this.httpClient.post<Invoice>(`${this.basePath}billing/payments`, invoice);
  }

  calculatePrice(invoice: Invoice) {
    return this.httpClient.post<Invoice>(`${this.basePath}billing/payments/calculate`, invoice);
  }

  getPaymentStatus(id: string) {
    return this.httpClient.get<Invoice>(`${this.basePath}billing/payments/${id}/status`);
  }

  getBillingProducts(): Observable<Paginated<BillingProduct>> {
    return this.httpClient.get<Paginated<BillingProduct>>(`${this.basePath}billing/products`);
  }

  getEnabledBillingProducts(): Observable<Paginated<BillingProduct>> {
    return this.httpClient.get<Paginated<BillingProduct>>(`${this.basePath}billing/products/enabled`);
  }

  getPaymentHistory(limit: number, offset: number): Observable<Paginated<PaymentHistoryItem>> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    queryParameters = queryParameters.set('limit', <any>limit);
    queryParameters = queryParameters.set('offset', <any>offset);
    return this.httpClient.get<Paginated<PaymentHistoryItem>>(`${this.basePath}billing/payments`, {params: queryParameters})
  }

  deletePayment(id:string){
    return this.httpClient.delete(`${this.basePath}billing/payments/${id}`)
  }
}
