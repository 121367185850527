import { Inject, Injectable, Optional } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import { map, switchMap } from 'rxjs/operators';
import {
  HubEntity,
  LinkedDocument,
  LinkedDocumentService,
} from '../../../../data';
import { ENTITY_NAME } from '../../../tokens';

import {
  loadLinkedDocuments,
  setLinkedDocuments,
} from './linked-documents.actions';

@Injectable()
export class LinkedDocumentEffects {
  public loadLinkedDocuments = createEffect(() =>
    this.actions.pipe(
      ofType(loadLinkedDocuments),
      switchMap(({ id, entity }) => {
        return this.linkedDocumentService
          .getLinkedDocuments(entity, id)
          .pipe(map((items: LinkedDocument) => setLinkedDocuments({ items })));
      })
    )
  );

  constructor(
    private readonly linkedDocumentService: LinkedDocumentService,
    private actions: Actions
  ) {}
}
