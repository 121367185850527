import {ChangeDetectorRef, Directive, EventEmitter, Input, Output} from "@angular/core";

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Directive()
export class BaseFileDropComponent{

  @Input()
  formats!: string[];

  public value: File[] = [];

  @Output()
  public readonly valueChange = new EventEmitter<File[]>();

  @Output()
  public readonly focusChanged = new EventEmitter<boolean>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onChange = (value: File[]) => { };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouch = () => { };


  get formatsString(): string {
    return this.formats.toString();
  }

  constructor(
    private changeDetector: ChangeDetectorRef
  ) { }

  onFileDropped(event: any) {
    this.prepareFilesList(event);
  }

  fileBrowseHandler(event: HTMLInputEvent) {
    this.prepareFilesList(event.target.files!);
  }

  deleteFile(index: number) {
    this.value.splice(index, 1);
  }

  public writeValue(value: any): void {
    this.value = value;

    this.changeDetector.detectChanges();
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public onModelChange(value: File[]): void {
    this.updateValue(value);
  }

  private updateValue(value: File[]): void {
    this.value = value;
    this.valueChange.emit(value);
  }

  private prepareFilesList(files: FileList) {
    const arr = Array.from(files)
    for (const item of arr) {
      this.value.push(item);
    }
    this.onChange(this.value);
  }
}
