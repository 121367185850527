import { Component, Input } from '@angular/core';
import { Source } from "../../../../data";

@Component({
  selector: 'ts-source-list-item',
  templateUrl: './source-list-item.component.html',
  styleUrls: ['./source-list-item.component.scss'],
})
export class SourceListItemComponent {
  @Input() item?: Source;
}
