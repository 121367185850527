import { Component, Input } from '@angular/core';
import { HubEntity } from "../../../data";

@Component({
  selector: 'ts-hub-table-empty',
  templateUrl: './hub-table-empty.component.html',
  styleUrls: ['./hub-table-empty.component.scss'],
})
export class HubTableEmptyComponent {
  @Input() entity!: HubEntity;

  @Input() addBtnText: string = '+Документ';

}
