<ts-items-table [tableHeaders]="tableHeaders" entityName="product" [isSelectable]="false">
  <ts-applied-filters-row [entityName]="'product'" slot="table-filters"></ts-applied-filters-row>

  <div *tsTableCellTemplate="'counter', let product">
    <ts-input-counter [ngModel]="getQuantity(product)" [maxValue]="maxItemsCount" [minValue]="0"
                      (countChanged)="changeProductsCount($event,product)"/>
  </div>

  <ts-product-name-cell *tsTableCellTemplate="'name', let product" [product]="product"></ts-product-name-cell>
  <ts-product-skus-cell *tsTableCellTemplate="'identifiers' let product" [product]="product"></ts-product-skus-cell>
  <ts-product-dimensions-cell *tsTableCellTemplate="'dimensions' let product"
                              [product]="product"></ts-product-dimensions-cell>
  <ts-modified-by-cell *tsTableCellTemplate="'modifiedAt' let product"
                       [changeableRecord]="product"></ts-modified-by-cell>

  <ng-container *ngFor="let priceHeader of priceHeaders">
    <ts-product-price-group-cell *tsTableCellTemplate="priceHeader.key, let product" [priceGroupId]="priceHeader.key"
                                 [product]="product"></ts-product-price-group-cell>
  </ng-container>

  <!--  <ng-template #emptyContent>-->
  <!--    <ts-hub-table-empty class="ts-table__no-items" entity="product" [addBtnText]="'+Позиция'"/>-->
  <!--  </ng-template>-->
</ts-items-table>




