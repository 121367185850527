<div tsFiledrop (fileDropped)="onFileDropped($any($event))">
  <input
    type="file"
    #inputDialog
    id="inputDialog"
    multiple
    class="d-none"
    (change)="fileBrowseHandler($any($event))"
    [ngModel]="value"
    [accept]="formatsString"
  />
  <ng-content></ng-content>
</div>
