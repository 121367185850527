import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { OverlayModule } from '@angular/cdk/overlay';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IConfig } from 'ngx-mask';

// import { ClickOutsideModule } from 'ng-click-outside';
// import { AutocompleteModule } from './components/autocomplete/autocomplete.module';
// import { PipeModule } from 'app/shared/pipes/pipe.module';


//DIRECTIVES
import { SidebarLinkDirective } from './directives/sidebar-link.directive';
import { SidebarDropdownDirective } from './directives/sidebar-dropdown.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebar-anchor-toggle.directive';
import { SidebarDirective } from './directives/sidebar.directive';
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { ToastrModule } from 'ngx-toastr';

// import { TopMenuDirective } from './directives/topmenu.directive';
// import { TopMenuLinkDirective } from './directives/topmenu-link.directive';
// import { TopMenuDropdownDirective } from './directives/topmenu-dropdown.directive';
// import { TopMenuAnchorToggleDirective } from './directives/topmenu-anchor-toggle.directive';

export const maskConfig: Partial<IConfig> | (() => Partial<IConfig>) = {};

const toastrSetttings = {
  autoDismiss: true,
  positionClass: 'toast-top-right',
};

@NgModule({
  exports: [
    CommonModule,
    HttpClientModule,
    NgbModule,
    SidebarDirective,
    ToastrModule,
    ToggleFullscreenDirective,
    // TopMenuDirective,
    // TranslateModule,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
    ToastrModule.forRoot(toastrSetttings),
    // ClickOutsideModule,
    // AutocompleteModule,
    // PipeModule
  ],
  declarations: [
    SidebarLinkDirective,
    SidebarDropdownDirective,
    SidebarAnchorToggleDirective,
    SidebarDirective,
    ToggleFullscreenDirective,
    // TopMenuLinkDirective,
    // TopMenuDropdownDirective,
    // TopMenuAnchorToggleDirective,
    // TopMenuDirective,
  ],
})
export class SharedModule {}
