import { Directive, Input, OnInit } from '@angular/core';
import { TableColumnResizeService } from './column-size.service';
import { Observable, scan, share, shareReplay, startWith } from 'rxjs';

const DEFAULT_WIDTH = 250;

@Directive({
  selector: '[tsTable],',
  exportAs: `tsTableDirective`,
  providers: [TableColumnResizeService],
})
export class TsTableDirective implements OnInit {
  @Input() public tsTable: any = [];

  public resize?: Observable<Record<string, number>>;

  constructor(private tableColumnService: TableColumnResizeService) {}

  public ngOnInit() {
    const initialWidth = this.tsTable.reduce(
      (acc: any, { name, width = DEFAULT_WIDTH }: any) => ({
        ...acc,
        [name]: width,
      }),
      {}
    );

    this.resize = this.tableColumnService.pipe(
      startWith({}),
      scan(
        (acc, { column, width }) =>
          column ? { ...acc, [column]: width } : acc,
        initialWidth
      ),
      shareReplay(1)
    );
  }
}
