import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsAbstractControl } from "@topseller/cdk/abstract";
import { FormsModule } from "@angular/forms";
import { InputNumberModule } from "../input-number";

@Component({
  selector: 'ts-input-counter',
  standalone: true,
  imports: [CommonModule, FormsModule, InputNumberModule],
  templateUrl: './input-counter.component.html',
  styleUrls: ['./input-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputCounterComponent extends TsAbstractControl<number> {
  @Input() minValue?: number;
  @Input() maxValue?: number;

  @Output() countChanged = new EventEmitter();

  private intervalId?: number;
  private timeoutId?: number;

  onModelChange(value: number) {
    if (this.minValue !== undefined && this.minValue !== null && value < this.minValue) {
      value = this.minValue;
    } else if (this.maxValue !== undefined && this.maxValue !== null && value > this.maxValue) {
      value = this.maxValue;
    }
    this.updateValue(value);
    this.countChanged.emit(value);
  }

  public setNewValue(step:number){
    this.onModelChange(this.value + step);

    // Компонент позволяет изменять значение путем зажатия кнопки мышки.
    // Иногда бывает такое что пользователь нажал один раз, но this.intervalId = window.setInterval успевало срабатывать
    // до того как отпустили мышку. Поэтому ставим еще небольшую задержку прежде чем запускать изменение счетчика при зажатой мышки
    this.timeoutId = window.setTimeout(() => {
      this.intervalId = window.setInterval(() => this.onModelChange(this.value + step), 100);
    }, 500);
  }

  protected getFallbackValue(): number {
    return 0;
  }

  stopChanging() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  }

  handleMouseDown(event: MouseEvent, step: number) {
    if (event.button === 0) { // 0 is the code for the left mouse button
      this.setNewValue(step);
    }
  }
}
