import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ts-service-initial-modal',
  templateUrl: './service-initial-modal.component.html',
  styleUrls: ['./service-initial-modal.component.scss'],
})
export class ServiceInitialModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ServiceInitialModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { serviceId: string; title: string }
  ) {}

  save() {
    this.dialogRef.close(true)
  }

  close() {
    this.dialogRef.close(false)
  }
}
