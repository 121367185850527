<ts-role-access-wrapper [requiredRole]="requiredRole">
  <div class="document--step" *ngIf="items.length <= 1; else list">

    <div *ngIf="items && items[0] as firstItem; else empty"
         (click)="itemClick.emit({ entity: firstItem.entity, id: firstItem.id })">
      <div class="document--step__title">
        <span class="ts-subtitle-small ts-text-primary">{{ edge.title}}</span>
        <span>№ {{ firstItem.number }}</span>
        <span>от</span>
        <span>{{ firstItem.datedAt | date : 'dd.MM.yyyy HH:mm:ss'}}</span>
      </div>

      <div class="ts-caption-strong status mt-1" [tsStatusColorizer]="firstItem.status">
        {{ items && firstItem.status?.name }}</div>

      <div class="ts-subtitle mt-2"
           *ngIf="firstItem?.amountWithVat !== null"> {{ firstItem.amountWithVat | currencyFormat :{addCurrencySymbol: true} }} </div>
    </div>

    <ng-template #empty>
      <div class="document--step__title">
        <span class="ts-subtitle-small ts-text-primary">{{ edge.title }}</span>
      </div>

      <div class="ts-caption-strong ts-text-placeholder-color mt-1">Документ не создан</div>
    </ng-template>

  </div>

  <ng-template #list>
    <ts-dropdown [content]="document">
      <div class="document--step">
        <div class="document--step__title justify-content-between">
          <span class="ts-subtitle-small  ts-text-primary">{{ edge.title }}</span>
          <i class="ts-icon ts-icon-arrow-Expand_down text-2lg"></i>
        </div>

        <div *ngIf="edge.totals !== null" class="ts-caption-strong ts-text-secondary">
          <span class="ts-text-tertiary">Сумма: </span> {{ edge.totals | multiply: 0.01 | number: '1.2-2' }}
        </div>

        <div class="ts-caption-strong ts-text-secondary mt-1">
          <span>Документов:</span> {{ items?.length }}
        </div>

        <div class="ts-subtitle mt-2">{{ edge.total | currencyFormat :{addCurrencySymbol: true} }}</div>
      </div>
    </ts-dropdown>

    <ng-template #document>
      <a class="dropdown-item" dropdownOption *ngFor="let item of items"
         (dropdownSelect)="itemClick.emit({ entity: item.entity, id: item.id })">
        <div class="document--step__title">
          <span class="ts-subtitle-small ts-text-primary">{{ edge.title }}</span>
          <span>№ {{ item.number }}</span>
          <span>от</span>
          <span>{{ item.datedAt | date : 'dd.MM.yyyy HH:mm:ss' }}</span>
        </div>

        <div class="ts-caption-strong status mt-1" [tsStatusColorizer]="item.status">{{ item.status?.name }}</div>

        <div *ngIf="edge.totals !== null"
             class="ts-subtitle mt-2">{{ item.amountWithVat | currencyFormat :{addCurrencySymbol: true} }}</div>
      </a>
    </ng-template>
  </ng-template>
</ts-role-access-wrapper>
