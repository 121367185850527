<ts-form-controls-card label="Учет расходов" [isExpandable]="true" [formGroup]="form">
  <div slot=card-actions>
    <ts-actions-dropdown [actions]="tableActionsList" [isDisabledBtnAction]="isDisabledBtnAction" />
  </div>

  <div class="ts-table-container ts-table-container-short">
    <div class="overflow-auto h-100 flex-grow-1 me-3">
      <ts-scroll class="ts-trade-document-scroll">
        <div class="ts-table ts-table-fixed-row d-flex flex-column h-100 flex-grow-1">
          <div class="ts-table__header">
            <ts-checkbox
              size="medium"
              [active]="allSelected"
              [indeterminate]="!allSelected && !!selectedItems.length"
              (toggle)="toggleAll($event)"
            ></ts-checkbox>

            <ng-container *ngFor="let header of tableHeaders; let i = index">
              <div class="cell" [ngStyle]="{ 'min-width.px': header.width, 'flex-basis.px': header.width }">
                <ts-table-header-cell [tableHeader]="header"></ts-table-header-cell>
              </div>
            </ng-container>
          </div>
          <div class="d-flex flex-column">
            <div class="ts-table__row " *ngFor="let line of items$ | async;
                  index as i;
                  let last = last;
                  let ind = index"
                 [ngClass]="{'selected':isSelected(i)}"
                 [formGroup]="line"
            >
              <ts-checkbox
                size="medium"
                [active]="isSelected(i)"
                (toggle)="selectItem($event, i)"></ts-checkbox>
              <ng-container *ngFor="let header of tableHeaders; let i = index">
                <ng-container [ngSwitch]="header.key">
                  <div class="cell" [ngStyle]="{ 'width.px': header.width, 'flex-basis.px': header.width }">

                    <div class="ts-content ts-caption-strong" [ngStyle]="{'text-align': header.textAlign || 'left'}">

                      <div *ngSwitchCase="'lineNumber'">
                        <span>{{ ind + 1 }}</span>
                      </div>

                      <div *ngSwitchCase="'source'">
                        <span>{{ line.value.source.name }}</span>
                      </div>

                      <div *ngSwitchCase="'sourceGroup'">
                        <span>{{ line.value.source.sourceGroup.name }}</span>
                      </div>
                      <div *ngSwitchCase="'amount'" [ngClass]="{'cell-error':!line.get('amount')?.valid}">
                        <ts-view-edit-mode-switch>
                          <ng-template tsViewMode="view">
                            <div class="w-100">
                              <div class="table-editable-cell"
                                   *ngIf="line.value.amount !== null">{{ line.value.amount | currencyFormat }}
                              </div>
                              <div class="w-100 ts-caption-strong ts-text-placeholder-color"
                                   *ngIf="line.value.amount === null">
                                Сумма расхода
                              </div>
                            </div>
                          </ng-template>
                          <ng-template tsViewMode="edit">
                            <input
                              class="cell-input error-control" tsFocusable [tsInputNumber]="{ formatMultiplier: 100 }"
                              formControlName="amount" />
                          </ng-template>
                        </ts-view-edit-mode-switch>
                      </div>

                      <div *ngSwitchCase="'expensedAt'">
                        <ts-input-date-time [cleaner]="false" formControlName="expensedAt"
                                            data-size="cell"></ts-input-date-time>
                      </div>
                    </div>

                  </div>
                </ng-container>
              </ng-container>

            </div>
          </div>
          <ts-document-expenses-table-empty-row [selectedItemControl]="tableLinePlaceholder"
                                                [tableHeaders]="tableHeaders" />

        </div>
      </ts-scroll>
    </div>
    <ts-item-products-table-summary
      *ngIf="totals$ | async as totals" [totals]="totals"
    />
  </div>

</ts-form-controls-card>
