import { Component, Input } from '@angular/core';
import { DocumentEditBase } from "../../document-edit-base";
import { HubDocument, HubEntity } from "../../../data";

@Component({
  selector: 'ts-document-edit-header',
  templateUrl: './document-edit-header.component.html',
  styleUrls: ['./document-edit-header.component.scss'],
})
export class DocumentEditHeaderComponent<T extends HubDocument> {
  @Input() public subtitle?: string;
  public HubEntity = HubEntity;
  constructor(public host: DocumentEditBase<T>) {}
}
