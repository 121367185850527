import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { SelectComponent } from "./select.component";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: `ts-select[focused]`,
})
export class TsSelectFocusDirective<T> {
    @Input() public focused = false;

    @Output() public focusChanged: EventEmitter<void> = new EventEmitter<void>();

    constructor(private selectComponent: SelectComponent<T>) {
    }

    public ngAfterViewInit() {
        if (this.focused && this.selectComponent?.textfield) {
            setTimeout(() => {
                this.selectComponent?.textfield?.inputElement?.nativeElement.focus();
                this.focusChanged.emit();
            });
        }
    }
}
