import {
  ContentChildren,
  Directive,
  HostListener,
  Input,
  QueryList,
} from '@angular/core';

import { TsSelectOptionDirective } from '../select-option';

@Directive({
  selector: '[tsDataList]',
})
export class TsDropdownListDirective {
  @ContentChildren(TsSelectOptionDirective, { descendants: true })
  public options?: QueryList<TsSelectOptionDirective>;

  private origin?: HTMLElement;

  @Input() tsDataList?: string;

  @HostListener('focusin', ['$event.relatedTarget', '$event.currentTarget'])
  public onFocusIn(
    relatedTarget: HTMLElement,
    currentTarget: HTMLElement
  ): void {
    if (!currentTarget.contains(relatedTarget) && !this.origin) {
      this.origin = relatedTarget;
    }
  }

  @HostListener('wheel.silent.passive')
  @HostListener('mouseleave')
  @HostListener('document:keydown.escape')
  public handleFocusLossIfNecessary(): void {
    if (this.origin) {
      this.origin.focus();
    }
  }

  @HostListener('keydown.arrowDown', ['$event', '1'])
  @HostListener('keydown.arrowUp', ['$event', '-1'])
  public onKeyDownArrow(event: KeyboardEvent, step: number): void {
    event.preventDefault();

    const current = event.target;

    const index = this.options
      ?.toArray()
      .findIndex(
        ({ nativeElement }: TsSelectOptionDirective) =>
          nativeElement === current
      );

    this.options?.get((index || 0) + step)?.nativeElement.focus();
  }
}
