import { Pipe, PipeTransform } from '@angular/core';

import { TsMonth } from '../../common';

const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

@Pipe({
  name: 'textMonth',
})
export class TextMonthPipe implements PipeTransform {
  public transform({ month }: TsMonth): string {
    return months[month];
  }
}
