<ng-container *ngIf="(refresh$ | async) as bars">

  <div tsScrollbarWrapper *ngIf="bars.vertical" class="ts-bar ts-bar_vertical">
    <div tsScrollbar="vertical" class="ts-thumb"></div>
  </div>

  <div tsScrollbarWrapper *ngIf="bars.horizontal" class="ts-bar ts-bar_horizontal">
    <div tsScrollbar="horizontal" class="ts-thumb"></div>
  </div>

</ng-container>