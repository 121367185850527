import { BillingTariff } from './billing-tariff';
import { BillingPayment } from './billing-payment';
import { BillingProduct } from './billing-product';
import { ServiceInfo } from './service-info';

export interface BillingSubscription {
  id?: number;
  product?: BillingProduct;
  tariff?: BillingTariff;
  isActive?: boolean;
  isRecurrentEnabled?: boolean;
  startsAt?: Date;
  expiresAt?: Date;
  days?: number;
  lastPayment?: BillingPayment;
}

export type ServiceAvailabilityFn = (serviceId: string, subscriptions: BillingSubscription[]) => boolean;

// функция проверки доступности коннекта
// TODO: это временная реализация, в дальнейшем с бэка будет приходить нормальная информация о доступности сервисов
export const checkConnectAvailable = (
  serviceId: string,
  subscriptions: BillingSubscription[]
): boolean => {
  const hubSubscription = subscriptions.find(
    (x) => x.product?.identifier === 'hub'
  );
  // если нет подписки на хаб, то коннект доступен
  if (!hubSubscription) {
    return true;
  }

  if (hubSubscription.tariff?.name === 'Lite') {
    return false;
  }

  if (hubSubscription.expiresAt) {
    const expiresAt = new Date(hubSubscription.expiresAt);
    const today = new Date();
    return expiresAt > today;
  }

  return true;
};

// функции проверки доступности сервисов (словарь по serviceId)
// пока что только для коннекта, по умолчанию считаем что остальные сервисы доступны.
export const servicesAvailabilityCheckFunctions: Record<string, ServiceAvailabilityFn> = {
  connect: checkConnectAvailable,
};
