import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableHeader } from "@topseller/ui";
import { HubEntity, PRODUCT_LIST_NAV_TREE_CONFIG, ProductService } from "../../../../data";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { SidebarService } from "@topseller/common/sidebar";
import { ValueUnitPipe } from "@topseller/ui/pipes";
import { FiltersService } from "../../../services";
import { FromStoreTableHeaderService, TableSettingsService } from "@topseller/common";
import { BaseListComponent } from "@topseller/common/base-list";
import { ListOptions } from "../../../types";
import { tap } from "rxjs";
import { HubInterfaceTable } from "@topseller/core";
import { BaseProductsListComponent } from "../../products/base-products-list.component";
import {
  ProductShowVariablesService
} from "../../../../pages/products-services/product/services/show-variables.service";
import { NAV_TREE_CONFIG } from "../../nav-tree";
import { map } from "rxjs/operators";
import { counterTableHeader, SelectedProductLine } from "../catalog.data";

@Component({
  selector: 'ts-products-catalog',
  templateUrl: './products-catalog.component.html',
  styleUrls: ['./products-catalog.component.scss'],
  providers: [
    ProductShowVariablesService,
    {provide: BaseListComponent, useExisting: ProductsCatalogComponent},
    {provide: NAV_TREE_CONFIG, useValue: PRODUCT_LIST_NAV_TREE_CONFIG}
  ]
})
export class ProductsCatalogComponent extends BaseProductsListComponent implements OnInit {

  @Input() tableHeaders: TableHeader[] = [];
  @Input() parentEntity!: HubEntity;
  @Output() changeView = new EventEmitter();
  @Output() closeDialog = new EventEmitter();
  @Input() selectedProducts: SelectedProductLine[] = [];
  override tableIdentifier = HubInterfaceTable[HubEntity.PRODUCT]
  override entity: HubEntity = HubEntity.PRODUCT;
  @Output() addSelected = new EventEmitter();

  protected override canUseSavedFilters = false;

  constructor(
    router: Router,
    productService: ProductService,
    store: Store,
    dialog: MatDialog,
    showVariablesService: ProductShowVariablesService,
    activatedRoute: ActivatedRoute,
    sidebarService: SidebarService,
    valueUnitPipe: ValueUnitPipe,
    filtersService: FiltersService,
    hubTableHeaderService: FromStoreTableHeaderService,
    tableSettingsService: TableSettingsService,
  ) {
    super(router, productService, store, dialog, showVariablesService, activatedRoute, sidebarService, valueUnitPipe, filtersService, hubTableHeaderService, tableSettingsService);


    const listQueryFn = ({
                           limit,
                           offset = 0,
                           search,
                           filterString,
                           sortName,
                           sortDir
                         }: ListOptions) => {

      return this.productService
        .getAppProductIndex(
          limit,
          offset,
          search,
          filterString,
          sortName,
          sortDir
        )
        .pipe(
          map(paginatedProducts => ({
            ...paginatedProducts,
            items: paginatedProducts.items.map(product => {
              const selectedProduct = this.selectedProducts.find(p => p.product.id === product.id);
              return {
                ...product,
                quantity: selectedProduct ? selectedProduct.quantity : 0
              };
            })
          })),
          tap((_) => this.isLoading$.next(false))
        );

    };
    super.setQueryFn(listQueryFn);

  }

  override ngOnInit() {
    super.ngOnInit();
    this.configureTableHeaders();
    this.displayableTableHeaders$ = this.displayableTableHeaders$.pipe(
      map(headers => [counterTableHeader, ...headers]),
    )
  }

  apply() {
    this.addSelected.emit();
  }
}
