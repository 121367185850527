import { createReducer, on } from '@ngrx/store';
import { StateLinks } from "../state-nav";
import { initNavLinks, updateNavLinks } from './state-nav.action';


export const initialState: StateLinks[] = [
    {
        url: '/hub',
        name: 'hub',
        match: 'hub'
    },
    {
        url: '/connect',
        name: 'connect',
        match: 'connect'
    },
    {
        url: '/wb-ads',
        name: 'ads',
        match: 'wb-ads'
    },
    {
        url: '/dropship',
        name: 'dropship',
        match: 'dropship'
    },
    {
        url: '/ai',
        name: 'ai-tools',
        match: 'ai'
    },
]

export const NAVLINKS_FEATURE_KEY = 'navlinks';

export const appNavlinkReducer = createReducer(
    initialState,
    on(updateNavLinks, (state) => (state))
)