/**
 * TOPSELLER HUB API
 * Topseller HUB backend API
 *
 * OpenAPI spec version: 23.1018.0907
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import {ModifiedBy} from "./modified-by";


export interface File {
  id: string; // required param for satisfying the constraint 'TsDataEntity<any>'
  entity?: string;
  entityId?: string;
  url?: string;
  originalName?: string;
  isImage?: boolean;
  size?: number;
  sort?: number;
  createdAt?: Date;
  createdBy?: ModifiedBy;

}

export interface FileStatsModel {
  availableVolume?: number;
  currentVolume?: number;
  count?: number;
  id?: string;
  readonly modifiedAt: Date;
}

export interface DisplayedFile extends File {
  isLoading?: boolean;
  hasError?: boolean;
}

export enum FileType {
  Audio = "Audio",
  Video = "Video",
  Document = "Document",
  Image = "Image",
  Unknown = "Unknown"
}

export function getFileExtension(fileName?: string): string {
  return fileName?.split('.').pop()?.toLowerCase() ?? '';
}

export function getFileTypeIcon(fileName:string) {
  switch (getFileType(fileName)) {
    case FileType.Audio:
      return 'media-audio-square';
    case FileType.Video:
      return 'media-video-square';
    case FileType.Document:
      return 'files-File_dock';
    case FileType.Image:
      return 'media-gallery';
    case FileType.Unknown:
    default:
      return 'files-folder-cross';
  }
}


export function getFileType(fileName?: string): FileType {
  const extension = getFileExtension(fileName);

  switch (extension) {
    // Аудио
    case 'mp3':
    case 'wav':
    case 'flac':
    case 'aac':
    case 'ogg':
      return FileType.Audio;

    // Видео
    case 'mp4':
    case 'avi':
    case 'mov':
    case 'wmv':
    case 'flv':
      return FileType.Video;

    // Документы
    case 'doc':
    case 'docx':
    case 'pdf':
    case 'txt':
    case 'ppt':
    case 'pptx':
    case 'xls':
    case 'xlsx':
      return FileType.Document;

    // Изображения
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'tiff':
    case 'svg':
      return FileType.Image;

    // Другие типы файлов
    default:
      return FileType.Unknown;
  }
}

export function getFileNameWithoutExtension(fileName?: string): string {
  if (!fileName) {
    return '';
  }

  // Находим последнюю точку в имени файла
  const lastDotIndex = fileName.lastIndexOf('.');

  // Если точка не найдена, возвращаем исходное имя файла
  if (lastDotIndex === -1) {
    return fileName;
  }

  // Возвращаем имя файла без расширения
  return fileName.substring(0, lastDotIndex);
}

export const allowedFilesFormats: string[] = [
  '.jpg',
  '.jpeg',
  '.png',
  '.webp',
  '.heif',
  '.heic',
  '.pdf',
  '.csv',
  '.txt',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.odt',
  '.ods',
];
