import { InjectionToken } from '@angular/core';
import { HubEntity, ItemRolesConfig, ListControlsConfig } from '../data';

export const ENTITY_NAME = new InjectionToken<HubEntity>(
  '[ENTITY_NAME] Name of the current entity'
);

// необходимые роли для редактирования документа/товара
export const EDIT_ITEM_ROLES_CONFIG  = new InjectionToken<ItemRolesConfig>('EDIT_ITEM_ROLES_CONFIG');

// необходимые роли для работы со списками
export const LIST_CONTROLS_CONFIG = new InjectionToken<ListControlsConfig>("ListControlsConfig")
