<div class="px-3">
  <div (click)="openStatusSettings()" class="editStatuses ts-text-primary ">
    <i class="ts-icon ts-icon-settings-setting-2 text-2lg"></i>
    <span>Настройки статусов</span>
  </div>
  <ng-container *ngIf="statusList$|async as statuses">
    <div class="statuses-list-container">
      <ts-scroll>
        <div *ngFor="let status of statuses" class="status-dropdown-item">
          <div class="color" [ngStyle]="{'background-color':getColor(status.color!)}"></div>
          <button class="dropdown-item"
                  [tsSelectOption]="status">{{
            status.name}}</button>
        </div>
      </ts-scroll>
    </div>
  </ng-container>
</div>
