import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { WINDOW } from '@topseller/cdk/common';

@Component({
  selector: 'ts-data-scroll',
  template: `<ng-content></ng-content>
    <div #anchor></div>`,
  styleUrls: ['./data-scroll.component.scss'],
})
export class TsDataScrollComponent implements OnInit, OnDestroy {
  private observer?: IntersectionObserver;

  @Input() options: IntersectionObserverInit = {};

  @Output() scrolled = new EventEmitter();

  @ViewChild('anchor', { read: ElementRef, static: true })
  public anchor?: ElementRef<HTMLElement>;

  constructor(
    @Inject(WINDOW) private window: Window,
    private host: ElementRef
  ) {}

  public get element() {
    return this.host.nativeElement;
  }

  public ngOnInit(): void {
    const options = {
      root: this.isHostScrollable() ? this.host.nativeElement : null,
      ...this.options,
    };

    this.observer = new IntersectionObserver(([entry]) => {
      entry.isIntersecting && this.scrolled.emit();
    }, options);

    if (this.anchor) {
      this.observer.observe(this.anchor?.nativeElement);
    }
  }

  public ngOnDestroy(): void {
    this.observer?.disconnect();
  }

  private isHostScrollable(): boolean {
    const style = this.window.getComputedStyle(this.element);

    return (
      style.getPropertyValue('overflow') === 'auto' ||
      style.getPropertyValue('overflow-y') === 'scroll'
    );
  }
}
