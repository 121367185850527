import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavTreeItem } from '../../../data';
import { ROLES } from "@topseller/core";

@Component({
  selector: 'app-nav-tree',
  templateUrl: './nav-tree.component.html',
  styleUrls: ['./nav-tree.component.scss'],
})
export class NavTreeComponent {
  public menu: any;
  protected editProductsRole = ROLES.ROLE_PRODUCT_EDIT;
  @Input() items!: NavTreeItem[];
  @Input() selectedItemId?: string = '';
  // @Input() width = 200;
  @Input() canAddEdit = false;
  @Input() canRefresh = false; // TODO: попросили скрыть кнопку "Сбросить". убрать это или оставить - зависит от результатов обсуждения
  @Input() navigationRootTitle = '';
  @Input() isModal  =  false;

  @Output() selectItem = new EventEmitter();
  @Output() deleteTreeItem = new EventEmitter();
  @Output() editTreeItem = new EventEmitter();
  @Output() createTreeItem = new EventEmitter();

  settingBlockPosition = 0;

  refresh() {
    this.selectItem.emit(null);
  }
}
