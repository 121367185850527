import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TsDropdownModule } from '@topseller/ui/dropdown';

import { TsTablePaginationComponent } from './table-pagination.component';
import { TsPaginatorModule } from '../paginator';

@NgModule({
  imports: [CommonModule, TsPaginatorModule, TsDropdownModule],
  declarations: [TsTablePaginationComponent],
  exports: [TsTablePaginationComponent],
  providers: [],
})
export class TsTablePaginationModule {}
