import { ChangeDetectorRef, Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first, Observable, Subject, Subscription, take, takeUntil } from 'rxjs';

import { Store } from '@ngrx/store';
import { BaseItemInfoComponent } from '../../../common/components/base-item-info.component';
import { IdentityMatcher, StringHandler, } from '../../../common/types';
import { WarehouseService } from '../../../data';
import { WarehouseGroupService } from '../../../data/api/warehouse-group.service';
import { WarehouseGroup } from '../../../data/model/warehouseGroup';
import { selectWarehouseGroups } from '../storage-list/store/warehouse-groups.selector';
import { ConfirmDialogComponent, Counterparty, DialogService, Record, ROLES, setShowLoader } from '@topseller/core';
import { BaseInnItemComponent } from "../../../common/components/base-inn-item.component";

@Component({
  selector: 'app-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['../company/company.component.scss'],
  providers: [
    {provide: BaseInnItemComponent, useExisting: StorageComponent}
  ]
})
export class StorageComponent
  extends BaseItemInfoComponent
  implements OnInit, OnDestroy {
  showHeaderBtns = false;
  showHeaderBtnsSub$?: Subscription;

  form: FormGroup = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    warehouseGroup: new FormControl<WarehouseGroup | null>(null),
    address: new FormControl<string>(''),
    note: new FormControl<string>('', [Validators.maxLength(255)]),
    isDefault: new FormControl<boolean>(false),
  });

  public warehouseGroups$?: Observable<WarehouseGroup[]>;
  override backLinkUrl = '/hub/handbooks/storage';
  public editItemRole = ROLES.ROLE_WAREHOUSE_EDIT;
  public deleteItemRole = ROLES.ROLE_WAREHOUSE_DELETE;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public warehouseService: WarehouseService,
    activatedRoute: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    changeDetectorRef: ChangeDetectorRef,
    matDialog: MatDialog,
    private warehouseGroupService: WarehouseGroupService,
    store: Store,
    @Optional() private dialogRef: MatDialogRef<StorageComponent>,
    private dialogService: DialogService
  ) {
    super(activatedRoute, toastr, router, changeDetectorRef, matDialog, store);
  }

  public get isModal(): boolean {
    return this.dialogRef?.componentInstance instanceof StorageComponent;
  }

  ngOnInit(): void {
    this.getResolvedData(this.form, 'storage');
    this.showHeaderBtnsSub$ = this.showHeaderBtns$.subscribe(
      (show) => (this.showHeaderBtns = show)
    );

    this.warehouseGroups$ = this.store
      .select(selectWarehouseGroups)
      .pipe(takeUntil(this.destroy$));
  }

  createWarehouse(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.toastr.error('Форма содержит ошибки')
      return
    }
    this.store.dispatch(setShowLoader({showLoader: true}))

    this.warehouseService
      .postAppWarehouseCreate(this.form.value)
      .pipe(take(1))
      .subscribe({
        next: (result: Counterparty) => {
          this.store.dispatch(setShowLoader({showLoader: false}))
          this.toastr.success('Склад успешно сохранен');
          const url = '/hub/handbooks/storage';
          if (this.isModal) {
            this.dialogRef.close(result);
          } else {
            this.form.markAsPristine();
            this.navigateByUrl(`${url}/${result.id}`);
          }
        },
        error: (err: any) => {
          this.creationState$.next('error');
          this.store.dispatch(setShowLoader({showLoader: false}))

          this.toastr.error(
            err?.errors?.length && err.errors[0].message
              ? err.errors[0].message
              : err?.message || 'Что-то пошло не так'
          );
        },
      });
  }

  updateWarehouse(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.store.dispatch(setShowLoader({showLoader: true}))
    this.updateItem(
      this.form,
      this.warehouseService,
      'patchAppWarehouseUpdate',
      'Склад'
    );
  }

  public deleteWarehouse(): void {
    const data = {
      title: 'Удаление склада',
      content: 'Вы уверены, что хотите удалить склад ?',
      okBtn: 'Удалить'
    };
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialogService.openConfirmDialog(data);

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe({
        next: (result: boolean) => {
          this.deleteItem(
            this.warehouseService,
            'deleteAppWarehouseDelete',
            '/hub/handbooks/storage',
            result ? 'да' : 'нет'
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.showHeaderBtnsSub$?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  public recordIdentityMatcher: IdentityMatcher<Record> = (
    {id: previousId},
    {id: nextId}
  ) => previousId === nextId;

  public recordStringify: StringHandler<Record> = (item) => item.name;

  public cancel(): void {
    this.dialogRef.close();
  }
}
