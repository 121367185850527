import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {BaseFileDropComponent} from "@topseller/ui/drop-upload-container/base-file-drop.component";

@Component({
    selector: 'app-filedrop',
    templateUrl: './file-drop.component.html',
    styleUrls: ['./file-drop.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileDropComponent),
            multi: true,
        },
    ],
})

export class FileDropComponent extends BaseFileDropComponent{
}
