import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { UserInfoComponent } from './user-info/user-info.component';
import { NavbarComponent } from './navbar';
import { MainNavComponent } from './main-nav/main-nav.component';
import { TsDropdownModule } from '@topseller/ui/dropdown';
import { KeyInfoComponent } from './key-info/key-info.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { TsChipModule } from '@topseller/ui/chip/chip.module';
import { ActiveZoneModule } from '@topseller/ui/active-zone';
import { FormsModule } from '@angular/forms';
import { TsScrollModule } from '@topseller/ui/scroll';

import { NavbarDirective } from './navbar/navbar.directive';
import { TsHintModule } from "@topseller/ui/hint";
import { RoleAccessWrapperComponent } from "@topseller/ui/role-access-wrapper";
import { BaseInputModule } from "@topseller/ui/base-input";
import { TsCustomContentComponent } from "@topseller/ui";
import { MultiSelectModule } from "./multi-select";
import { DotLoaderComponent } from '@topseller/ui/dot-loader';

const COMPONENTS = [
  UserInfoComponent,
  NavbarComponent,
  KeyInfoComponent,
  CheckboxComponent,
  NavbarDirective,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TsDropdownModule,
    TsChipModule,
    ActiveZoneModule,
    FormsModule,
    TsScrollModule,
    TsHintModule,
    RoleAccessWrapperComponent,
    BaseInputModule,
    TsCustomContentComponent,
    MultiSelectModule,
    DotLoaderComponent,
  ],
  declarations: [MainNavComponent, ...COMPONENTS],
  exports: [...COMPONENTS],
})
export class TsCommonModule {}
