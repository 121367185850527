import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';


import { DataListHost } from '../types';
import { TsSelectOptionDirective } from "../select-option/option.directive";
import { DATA_LIST_HOST } from "../tokens";


export const EMPTY_QUERY = new QueryList<any>();

@Component({
  selector: 'ts-datalist',
  templateUrl: './data-list.component.html',
  styleUrls: ['./data-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsDataListComponent<T> {
  @Input() public items?: readonly T[] | null;

  @ViewChildren(TsSelectOptionDirective, { read: ElementRef })
  private readonly options: QueryList<ElementRef<HTMLElement>> = EMPTY_QUERY;

  constructor(@Inject(DATA_LIST_HOST) private readonly host: DataListHost<T>) {}

  public onClick(option: T): void {
    if (this.host && option !== undefined) {
      this.host.handleOption(option);
    }
  }
}
