import {Injectable} from "@angular/core";
import {BatchActionTemplate, TemplateRequest} from "../../data/model/templateRequest";
import {Observable} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {CommonBatchActionsApiService} from "../../data/api";
import { CommonStateEffects } from "@topseller/core";

export type BatchActionsConfig = {
  successMessage: string;
  postback?: () => void; // действие при успешном выполнение
  fallback?: ()=>void; // действие при ошибке во время выполнения
}

@Injectable()
export class BatchActionsService {

  constructor(private toastrService: ToastrService,
              private batchActionsApiService: CommonBatchActionsApiService) {

  }

  public performAction(apiEntityName: string, itemsId: string[], template: BatchActionTemplate, config: BatchActionsConfig, filter: string | null): void {
    this.batchActionsApiService.template(apiEntityName, {itemsId, template}, filter).subscribe({
        next: (result: any) => {
          const successMessage = filter ? "Задача поставлена в очередь" :`${config.successMessage ?? 'Успешно'}`
          this.toastrService.success(successMessage);
          config.postback && config.postback();
        },
        error: (err: any) => {
          config.fallback && config.fallback();
          this.toastrService.error(
            err?.errors?.length && err.errors[0].message
              ? err.errors[0].message
              : err?.message || 'Что-то пошло не так'
          );
        },
      }
    )
  }
}


