import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'ts-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent {
  @Input() active = false;

  @Input() disabled = false;

  @HostBinding('class.indeterminate')
  @Input()
  public indeterminate = false;

  @Output() toggle = new EventEmitter<boolean>();

  @Input() size: string = 'large';

  activeToggle(): void {
    if(!this.disabled) {
      this.active = !this.active;
      this.toggle.emit(this.active);
    }
  }
}
