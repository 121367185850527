import { ROLES } from "./restrictions";

export function hasRole(role: string | string[] | undefined | null, userRoles: string[]): boolean {
  if (!role) {
    return true;
  }
  const requiredRoles = Array.isArray(role) ? [...role, ROLES.ROLE_OWNER] : [role, ROLES.ROLE_OWNER];
  return requiredRoles.some(r => userRoles.includes(r));
}

// удаление из объекта всех полей, в которых нет значения.
export const filterNonNullValues = (obj: any) => {
  return Object.entries(obj).reduce((acc: any, [key, value]) => {
    if (value !== null && value !== undefined && value != '') {
      acc[key] = value;
    }
    return acc;
  }, {});
};
