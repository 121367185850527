<div
  class="position-relative loader-animated"
[ngClass]="{ loading: isLoading, 'blur':(!skeleton && isLoading) }"
  style="height: 100%"
>
  <ng-container *ngIf="!(isLoading && skeleton)">
    <ng-content></ng-content>
  </ng-container>
  <ng-container *ngIf="isLoading">
    <div *ngIf="skeleton; else loader" class="loader-skeleton">
      <ngx-skeleton-loader
        *tsLet="theme$ | async as currentTheme"
        count="25"
        [theme]="{
          'border-radius': '5px',
          height: '38px',
          'background-color': currentTheme === 'dark' ? '#333' : '#f6f6f6',
          'margin-bottom': '5px'
        }"
      ></ngx-skeleton-loader>
    </div>

    <ng-template #loader>
      <div class="loader-container">
        <ng-lottie [options]="loaderAnimationOptions"></ng-lottie>
      </div>
    </ng-template>
  </ng-container>
</div>
