import {Inject, Injectable, Optional} from "@angular/core";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {BASE_PATH} from '../variables';
import {ReportLoss, ReportLossDetail} from "../model/reports/report-opiu";
import {Observable} from "rxjs";
import {Paginated} from "@topseller/core";

@Injectable()
export class ReportLossApiService {
  protected basePath = 'http://denis.dev.topseller.ru';

  constructor(protected httpClient: HttpClient,
              @Optional() @Inject(BASE_PATH) basePath: string,) {
    if (basePath) {
      this.basePath = basePath;
    }
  }

  createReport(filter: string): Observable<ReportLoss> {
    return this.httpClient.get<ReportLoss>(`${this.basePath}/api/v1/report/loss?${filter}`);
  }

  getReportDetail(reportId: string): Observable<HttpResponse<Paginated<ReportLossDetail>>> {
    return this.httpClient.get<Paginated<ReportLossDetail>>(`${this.basePath}/api/v1/report/loss/${encodeURIComponent(
      String(reportId)
    )}/detail`, {observe: 'response'});
  }
}


