import { createAction, props } from '@ngrx/store';

import {BaseData, CustomField, HubEntity, Status} from '../data';

export const setHubMetadata = createAction(
  '[HUB] Set Metadata',
  props<{ metadata: BaseData }>()
);

export const updateStatuses = createAction(
  '[HUB] Update Status',
  props<{statuses:Status[], entityName:HubEntity}>()
)

export const updateCustomFields = createAction(
  '[HUB] Update Custom Fields',
  props<{fields:CustomField[], entityName:HubEntity}>()
)
